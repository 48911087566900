import Count from "./count";
import Judge from "./judge";
import Report from "./report";
import Query from "./query";
import Manage from "./manage";

//攻击类
class Attack{
    constructor(attacker){
        this.attacker = attacker;
    }
    //寻找一个对象,self表示自己,range指定范围，isFri是否指定友军,isEn是否指定敌军,number表示选定几个,includeSelf是否包含自己considerMad是否考虑暴走
    SelectTarget(self,range,isFri,isEn,number,includeSelf,considerMad = true,tacticsItem = null){
        let j = new Judge();
        let res = [];//存放最终结果
        let arr = [];//中间结果
        let ju = new Judge();
        let dis = range;
        //减去队友的距离
        for(let i = Number(self.position) + 1;i< 3;i++){
            if(self.ally[i].alive){
                dis -= 1;
            }
        }
        dis > 3 ? dis = 3 : null;
        let isMad = ju.IsGetStatus(self,"暴走");
        if(ju.IsGetStatus(self,"洞察")) isMad = false;
        //放入可选范围的敌军
        if((considerMad && isMad) || isEn){
            for(let i = 0;i<3 && dis > 0;i++){
                if(self.enemy[Math.abs(i-2)].alive){
                    if(tacticsItem != null && tacticsItem.type == '指挥' && (j.IsGetStatus(self.enemy[Math.abs(i-2)],'不受敌方指挥战法影响') || j.IsGetStatus(self.enemy[Math.abs(i-2)],'不受指挥战法影响')) ){
                        let r = new Report();
                        r.InitExecuteReport(3,self.enemy[Math.abs(i-2)],`不受敌方指挥战法影响`);
                    }
                    else{
                        arr.push(self.enemy[Math.abs(i-2)]);
                    }
                    dis --;
                }
            }
        }
        //放入可选择的友军
        if((considerMad && isMad) || isFri){
            if(range > 1 || range == 1 && self.position == 1){
                for(let i in self.ally){
                    if(self.ally[i].alive){
                        if(tacticsItem != null && tacticsItem.type == '指挥' && j.IsGetStatus(self.ally[i],'不受指挥战法影响')){
                            let r = new Report();
                            r.InitExecuteReport(3,self.ally[i],`不受指挥战法影响`);
                        }
                        else{
                            if(self.ally[i] == self){
                                includeSelf ? arr.push(self) : null;
                            }
                            else
                                arr.push(self.ally[i]);
                        }
                    }
                }
            }
            else if(range == 1) {
                if(self.ally[1].alive) arr.push(self.ally[1]);
                else if(self.ally[Math.abs(self.position-2)].alive) arr.push(self.ally[Math.abs(self.position-2)]);
                includeSelf ? arr.push(self) : null;
            }
            else if(range == 0) includeSelf ? arr.push(self) : null;
        }
        //判断挑衅，普通攻击才会触发挑衅
        if(tacticsItem == null && j.IsGetStatus(self,'挑衅') && !j.IsGetStatus(self,'洞察')){
            //查看arr中是否含有挑衅者
            let target = null;
            for(let i in self.STATUS){
                if(self.STATUS[i].type == '挑衅'){
                    target = self.STATUS[i].applicator;
                    break;
                }
            }
            for(let i in arr){
                if(arr[i] == target){
                    res.push(arr[i]);
                    arr = [];
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,target,`受到`,`挑衅`);
                    break;
                }
            }
            // arr = [];
        }
        
        //从结果数组中挑选number个放入res
        for(let i = 0;i<number && arr.length > 0;i++){
            let rand = Math.floor(Math.random()*arr.length);
            res.push(arr[rand]);
            arr.splice(rand,1);
        }
        //判断援护,只在普通攻击时判定
        if(tacticsItem == null){
            let temp = [];
            for(let i in res){
                //援护者
                let target = null;
                for(let j in res[i].STATUS){
                    if(res[i].STATUS[j].type == '援护'){
                        target = res[i].STATUS[j].applicator;
                        break;
                    }
                }
                if(target != null){
                    let r = new Report();
                    r.InitTwoTargetReport(2,res[i],target,`受到`,`援护`);
                    temp.push(target);
                }
                else temp.push(res[i]);
            }
            res = temp;
        }
        //对结果进行按位置排序
        for(let i=0;i<res.length;i++){
            for(let j=i+1;j<res.length;j++){
                if(Number(res[i].position) > Number(res[j].position)){
                    let temp = res[i];
                    res[i] = res[j];
                    res[j] = temp;
                }
            }
        }
        return res;
    }
    //获取分兵的目标对象
    GetDivideForceTarget(target){
        let res = [];
        //选择前面的
        for(let i=Number(target.position)-1;i>=0;i--){
            if(target.ally[i].alive){
                res.push(target.ally[i])
                break;
            }
        }
        //选择后面的
        for(let i = Number(target.position)+1;i<3;i++){
            if(target.ally[i].alive){
                res.push(target.ally[i]);
                break;
            }
        }
        return res;
    }
    //发动普通攻击
    LaunchAttack(target){
        let c = new Count();
        let q = new Query();
        let adRange = q.GetRealAdRange(target);
        let receiver =  this.SelectTarget(target,adRange,false,true,1,false,true,null);
        //计算伤害
        if(receiver.length>0){
            //战报填入
            let report = new Report();
            report.InitTwoTargetReport(2,target,receiver[0],"对","发动普通攻击");
            //执行普通攻击后执行函数
            for(let i in target.LAUNCH_ATTACK_EXECUTE){
                if(target.LAUNCH_ATTACK_EXECUTE[i].duration > 0 && !target.LAUNCH_ATTACK_EXECUTE[i].isAfterExecute){
                    target.LAUNCH_ATTACK_EXECUTE[i].Execute(receiver[0]);
                }
            }
            let damage = c.CountDamage(target,receiver[0],100,"攻击");
            this.GetHurt(target,receiver[0],damage,true,"攻击",null);
            //执行普通攻击后执行函数
            for(let i in target.LAUNCH_ATTACK_EXECUTE){
                if(target.LAUNCH_ATTACK_EXECUTE[i].duration > 0 && target.LAUNCH_ATTACK_EXECUTE[i].isAfterExecute && target.LAUNCH_ATTACK_EXECUTE[i].detailExecuteTime == '造成伤害后'){
                    target.LAUNCH_ATTACK_EXECUTE[i].Execute(receiver[0]);
                }
            }
            //启动目标反击
            if(receiver[0].alive) this.LaunchCounterAttack(target,receiver[0],"反击");
            //执行分兵
            this.LaunchCounterAttack(target,receiver[0],"分兵");
            //执行追击
            if(receiver[0].alive) this.LaunchComboAttack(target,receiver[0]);
            //执行普通攻击后执行函数
            for(let i in target.LAUNCH_ATTACK_EXECUTE){
                if(target.LAUNCH_ATTACK_EXECUTE[i].duration > 0 && target.LAUNCH_ATTACK_EXECUTE[i].isAfterExecute && target.LAUNCH_ATTACK_EXECUTE[i].detailExecuteTime == '追击后'){
                    target.LAUNCH_ATTACK_EXECUTE[i].Execute(receiver[0]);
                }
            }
        }
        else{
            //战报填入
            let report = new Report();
            report.InitExecuteReport(2,target,"攻击范围内没有目标")
        }

    }
    //试图发动主动战法
    LaunchActiveTactics(target){
        let q = new Query();
        let m = new Manage();
        for(let i in target.TacticsArr){
            if(target.TacticsArr[i].type == '主动'){
                //触发试图发动阶段
                for(let j in target.EXECUTE){
                    if(target.EXECUTE[j].executeStage == "试图发动主动战法") target.EXECUTE[j].execute();
                }
                //发动准备战法,launchTactics发动的准备型战法
                let launchTactics =  m.ManageWaitTactics(target,target.TacticsArr[i]);
                let isLaunched = launchTactics.launch == null ? false : true;
                let odd =  q.GetRealTacticsOdd(target,target.TacticsArr[i]);
                let rand = Math.floor(Math.random()*100);
                if(rand < odd){
                    //瞬发战法直接发动
                    if(q.GetRealTacticsPrepareTime(target,target.TacticsArr[i]) <= 0){
                        let report = new Report();
                        report.InitExecuteReport(2,target,`发动【${target.TacticsArr[i].name}】!`)
                        target.TacticsArr[i].execute(target);
                        isLaunched = true;
                    }
                    //准备型战法进入准备队列
                    else if(launchTactics.wait == null && launchTactics.launch == null){
                        let r = new Report();
                        r.InitExecuteReport(2,target,`【${target.TacticsArr[i].name}】开始准备`);
                        let block = {
                            tactics : target.TacticsArr[i],//战法实体
                            waitTime : q.GetRealTacticsPrepareTime(target,target.TacticsArr[i])//战法等待时间
                        }
                        target.WaitTacticsArr.push(block);
                    }
                }
                else if(launchTactics.wait == null && launchTactics.launch == null){
                    let r = new Report();
                    r.InitExecuteReport(2,target,`【${target.TacticsArr[i].name}】未发动，发动几率为${odd}%`)
                }
                //消耗掉执行下一次战法增伤
                if(target.TacticsArr[i].isDamageMaker && isLaunched) m.ManageOnceTacticsDamageBlock(target,true);
                //发动战法后调用执行函数
                if(isLaunched){
                    for(let j in target.ACTIVE_TACTICS_EXECUTE){
                        if(target.ACTIVE_TACTICS_EXECUTE[j].waitTime <= 0 && target.ACTIVE_TACTICS_EXECUTE[j].isAfterExecute
                            && target.ACTIVE_TACTICS_EXECUTE[j].tacticsType == '主动'){
                            target.ACTIVE_TACTICS_EXECUTE[j].Execute(target.TacticsArr[i]);
                        }
                    }
                }
            }
            
        }
    }
    //发动特殊攻击
    LaunchCounterAttack(attacker,receiver,type = '反击'){
        let c = new Count();
        let j = new Judge();
        if(type == "反击"){
            for(let i in receiver.COUNTER_ATTACK){
                if(receiver.COUNTER_ATTACK[i].waitTime <= 0 && receiver.COUNTER_ATTACK[i].type == '反击'){
                    let avail = j.IsCanAttackSomeone(receiver,attacker);
                    if(avail){
                        let odd = receiver.COUNTER_ATTACK[i].damageOdd;
                        let rand = Math.floor(Math.random()*100);
                        //发动反击
                        if(rand < odd){
                            let r = new Report();
                            r.InitExecuteReport(3,receiver,`进行反击`);
                            let damage = c.CountDamage(receiver,attacker,receiver.COUNTER_ATTACK[i].damageValue,"攻击",true,null,null);
                            this.GetHurt(receiver,attacker,damage,true,"攻击",null);
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(3,receiver,`由于射程不足，无法反击`);
                    }
                    
                }
            }
        }
        else if(type == "分兵"){
            for(let i in attacker.COUNTER_ATTACK){
                if(attacker.COUNTER_ATTACK[i].waitTime <= 0 && attacker.COUNTER_ATTACK[i].type == '分兵'){
                    let odd = attacker.COUNTER_ATTACK[i].damageOdd;
                    let rand = Math.floor(Math.random()*100);
                    if(rand < odd){
                        let targetArr = this.GetDivideForceTarget(receiver);
                        for(let j in targetArr){
                            let r = new Report();
                            r.InitExecuteReport(3,attacker,`执行分兵`);
                            let damage = c.CountDamage(attacker,targetArr[j],attacker.COUNTER_ATTACK[i].damageValue,"攻击",true,null,null);
                            this.GetHurt(attacker,targetArr[j],damage,true,"攻击",null);
                        }
                    }
                }
            }
        }
    }
    //发动追击
    LaunchComboAttack(attacker,receiver){
        let q = new Query();
        for(let i in attacker.TacticsArr){
            if(attacker.TacticsArr[i].type == '追击'){
                //触发试图发动阶段
                for(let j in attacker.EXECUTE){
                    if(attacker.EXECUTE[j].executeStage == "试图发动追击战法") attacker.EXECUTE[j].execute();
                }
                let realOdd = q.GetRealTacticsOdd(attacker,attacker.TacticsArr[i]);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(3,attacker,`的攻击发动【${attacker.TacticsArr[i].name}】！`);
                    attacker.TacticsArr[i].execute(attacker,receiver);
                    //发动追击战法后调用执行函数
                    for(let k in attacker.ACTIVE_TACTICS_EXECUTE){
                        if(attacker.ACTIVE_TACTICS_EXECUTE[k].waitTime <= 0 && attacker.ACTIVE_TACTICS_EXECUTE[k].isAfterExecute
                        && attacker.ACTIVE_TACTICS_EXECUTE[k].tacticsType == '追击'){
                            attacker.ACTIVE_TACTICS_EXECUTE[k].Execute(attacker.TacticsArr[i]);
                        }
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(3,attacker,`的攻击未发动【${attacker.TacticsArr[i].name}】，发动几率为${realOdd}%`);
                }
            }
        }
    }
    
    //受到伤害
    GetHurt(attacker,receiver,damage,isCommonAttack,damageType,tacticsItem,needReport = true,reportParma = null,isContinueDamageType = false){
        //damageType:策略伤害还是攻击伤害
        //isCommonAttack：是否是普通攻击造成的伤害
        //tacticsItem:受到的何种战法的实体
        //自己传战报参数格式reportParma：{    }
        //考虑规避
        let m = new Manage();
        let reportLevel = 3;
        //真正扣血
        receiver.hp -= damage;
        if(receiver.hp <= 0){
            receiver.hp = 0;
            receiver.alive = false;
        }
        //战报填入
        if(needReport){
            let report = new Report();
            report.InitExecuteReport(reportLevel,receiver,`损失了${damage}兵力(${receiver.hp})`);
        }
        //自定义战报，可使得战报在执行被攻击函数前生成
        else if(reportParma != null){
            let r = new Report();
            r.PutReportToHeap(receiver.global.ReportHeap,reportParma);
        }
        //消耗掉一次性的增减伤加成块
        m.ManageOnceDamageBlock(attacker,damage,isCommonAttack,damageType,tacticsItem,true);
        m.ManageOnceDamageBlock(receiver,damage,isCommonAttack,damageType,tacticsItem,false);
        //统计攻击方输出
        if(isCommonAttack || tacticsItem == null) attacker.STATISTICS.atk += damage;
        else{
            tacticsItem.STATISTICS.damage += damage;
        }
        //调用攻击方造成伤害后执行函数
        if(attacker.alive && damage != 0){
            let len = attacker.MAKE_DAMAGE.length;
            for(let i=0;i<len;i++){
                if(!attacker.MAKE_DAMAGE[i]['action']){
                    attacker.MAKE_DAMAGE[i].Execute(damage,receiver,tacticsItem,isCommonAttack,damageType);
                    attacker.MAKE_DAMAGE[i]['action'] = true;
                }
                if(len != attacker.MAKE_DAMAGE.length){
                    i=0;
                    len = attacker.MAKE_DAMAGE.length;
                }
            }
            //清空action
            for(let i in attacker.MAKE_DAMAGE){
                attacker.MAKE_DAMAGE[i]['action'] = false;
            }
        }
        //调用受到伤害后执行函数,刘备等急救
        if(receiver.alive && damage != 0){
            let length = receiver.AFTER_DAMAGE.length;
            for(let i=0;i<length;i++){
                if(isCommonAttack){
                    if(receiver.AFTER_DAMAGE[i].getAdDmgExecute || receiver.AFTER_DAMAGE[i].getCAttackExecute){
                        receiver.AFTER_DAMAGE[i].GetDamageData(damage,attacker,tacticsItem,isCommonAttack,damageType)
                        receiver.AFTER_DAMAGE[i].Execute();
                    }
                }
                else{
                    if(tacticsItem.type == '指挥' && receiver.AFTER_DAMAGE[i].getTacticsDmgExecute.command
                    || tacticsItem.type == '主动' && receiver.AFTER_DAMAGE[i].getTacticsDmgExecute.active
                    || tacticsItem.type == '追击' && receiver.AFTER_DAMAGE[i].getTacticsDmgExecute.combo
                    || tacticsItem.type == '被动' && receiver.AFTER_DAMAGE[i].getTacticsDmgExecute.passive){
                        receiver.AFTER_DAMAGE[i].GetDamageData(damage,attacker,tacticsItem,isCommonAttack,damageType)
                        receiver.AFTER_DAMAGE[i].Execute();//执行后receiver.AFTER_DAMAGE可能会变短
                    }
                }
                if(receiver.AFTER_DAMAGE.length != length){
                    i--;
                    length = receiver.AFTER_DAMAGE.length;
                }
            }
        }
        //调用受到持续型伤害后执行的函数，xp周瑜主战法
        if(receiver.alive && damage != 0 && isContinueDamageType){
            let length = receiver.AFTER_CONTINUE_DAMAGE.length;
            for(let i=0;i<length;i++){
                if(tacticsItem.type == '指挥' && receiver.AFTER_CONTINUE_DAMAGE[i].getTacticsDmgExecute.command
                || tacticsItem.type == '主动' && receiver.AFTER_CONTINUE_DAMAGE[i].getTacticsDmgExecute.active
                || tacticsItem.type == '追击' && receiver.AFTER_CONTINUE_DAMAGE[i].getTacticsDmgExecute.combo
                || tacticsItem.type == '被动' && receiver.AFTER_CONTINUE_DAMAGE[i].getTacticsDmgExecute.passive){
                    receiver.AFTER_CONTINUE_DAMAGE[i].GetData(damage,attacker,tacticsItem,damageType);
                    receiver.AFTER_CONTINUE_DAMAGE[i].Execute();//执行后receiver.AFTER_DAMAGE可能会变短
                }
                if(receiver.AFTER_CONTINUE_DAMAGE.length != length){
                    i--;
                    length = receiver.AFTER_CONTINUE_DAMAGE.length;
                }
            }
        }
        return true;
    }
    //受到治疗
    GetCure(initiator,receiver,cureNumber,cureOriginTactics = null,needReport = true,reportParma = null){
        let cure = cureNumber > 0 ? cureNumber : -cureNumber;
        let j = new Judge();
        let q = new Query();
        if(j.IsGetStatus(receiver,"围困")){
            let report = new Report();
            report.InitExecuteReport(3,receiver,`处于围困状态，不可回复兵力`);
            return false;
        }
        else{
            if((receiver.hp + cure) > q.GetMaxHp(receiver)) cure = q.GetMaxHp(receiver)-receiver.hp;
            receiver.hp += cure;
            //统计
            cureOriginTactics.STATISTICS.cure += cure;
            if(needReport){
                if(reportParma == null){
                    let report = new Report();
                    report.InitExecuteReport(3,receiver,`恢复了${cure}兵力(${receiver.hp})`);
                }
                else if(reportParma != null){
                    let r = new Report();
                    r.PutReportToHeap(receiver.global.ReportHeap,reportParma);
                }
            }
            //执行受到恢复后执行函数
            for(let i in receiver.GET_CURE){
                receiver.GET_CURE[i].Execute(cureOriginTactics,initiator,cureNumber);
            }
        }
        return true;
    }
}




export default Attack;