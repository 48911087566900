class Report{
    constructor(){
        this.type =  '';//战报类型
        this.belong = "";//发起者所属的对象是我还是敌军
        this.receiver_belong = "";//接受者所属的对象是我还是敌军
        this.img1 = "";//发起者图像
        this.img2 = "";//接受者图像

        this.level = 0,//战报级别

        this.text = "";
        this.text1 = '';//文本信息1
        this.text2 = '';//文本信息2
        this.text3 = "";
        this.text4 = "";
        this.text5 = "";
    }
    //生成战报
    BuildReport(container,reportHeap){
        for(let i in reportHeap){
            let report = document.createElement('div');
            report.classList.add('report');
            if(reportHeap[i].level > 1 && reportHeap[i].level < 3){
                let left = document.createElement('div');
                left.classList.add('left_padding');
                report.appendChild(left);
            }
            if(reportHeap[i].level > 2){
                let left = document.createElement('div');
                left.classList.add('left_padding');
                report.appendChild(left);
                let left1 = document.createElement('div');
                left1.classList.add('left_padding1');
                report.appendChild(left1);
            }
            //系统的横幅战报
            if(reportHeap[i].type == '系统'){
                let span1 = document.createElement('div');
                span1.classList.add('span_box');
                let span2 = document.createElement('div');
                span2.classList.add('span_box');
                let text = document.createElement('div');
                text.classList.add('sys_text');
                text.innerText = reportHeap[i].text;
                report.appendChild(span1);
                report.appendChild(text);
                report.appendChild(span2);
                
            }
            //执行战报(一个头像，后面跟着文本)
            else if(reportHeap[i].type == '执行'){
                let head = document.createElement('div');
                head.classList.add('warrior_head');
                let loop = document.createElement('div');
                if(reportHeap[i].belong == 'blue') loop.classList.add('blue_loop');
                else loop.classList.add('red_loop');
                let imgBox = document.createElement('div');
                imgBox.classList.add('head_img');
                let headImg = document.createElement('img');
                headImg.src = reportHeap[i].img1;
                imgBox.appendChild(headImg);
                head.appendChild(loop);
                head.appendChild(imgBox);
                report.appendChild(head);
                //文本
                let str = reportHeap[i].text.split('损失了');
                if(str.length > 1){
                    let text = document.createElement('div');
                    text.classList.add('text');
                    text.innerText = str[0] + '损失了';
                    report.appendChild(text);
                    let numberText = str[1].split('兵力');
                    let number = document.createElement('div');
                    number.classList.add('number');
                    number.innerText = numberText[0];
                    report.appendChild(number);
                    let text1 = document.createElement('div');
                    text1.classList.add('text');
                    text1.innerText = '兵力' + numberText[1];
                    report.appendChild(text1);
                }
                else{
                    str = reportHeap[i].text.split('恢复了');
                    if(str.length > 1){
                        let text = document.createElement('div');
                        text.classList.add('text');
                        text.innerText = str[0] + '恢复了';
                        report.appendChild(text);
                        let numberText = str[1].split('兵力');
                        let number = document.createElement('div');
                        number.classList.add('number1');
                        number.innerText = numberText[0];
                        report.appendChild(number);
                        let text1 = document.createElement('div');
                        text1.classList.add('text');
                        text1.innerText = '兵力' + numberText[1];
                        report.appendChild(text1);
                    }
                    else{
                        let text = document.createElement('div');
                        text.classList.add('text');
                        text.innerText = str;
                        report.appendChild(text);
                    }
                }
            }
            //A对B战报
            else if(reportHeap[i].type == '两目标'){
                //放入发起者的头像
                let head = document.createElement('div');
                head.classList.add('warrior_head');
                let loop = document.createElement('div');
                if(reportHeap[i].belong == 'blue') loop.classList.add('blue_loop');
                else loop.classList.add('red_loop');
                let imgBox = document.createElement('div');
                imgBox.classList.add('head_img');
                let headImg = document.createElement('img');
                headImg.src = reportHeap[i].img1;
                imgBox.appendChild(headImg);
                head.appendChild(loop);
                head.appendChild(imgBox);
                report.appendChild(head);
                //文本1
                let text = document.createElement('div');
                text.classList.add('text');
                text.innerText = reportHeap[i].text;
                report.appendChild(text);
                //接受者头像
                let head1 = document.createElement('div');
                head1.classList.add('warrior_head');
                let loop1 = document.createElement('div');
                if(reportHeap[i].receiver_belong == 'blue') loop1.classList.add('blue_loop');
                else loop1.classList.add('red_loop');
                let headImg1 = document.createElement('img');
                headImg1.src = reportHeap[i].img2;
                let imgBox1 = document.createElement('div');
                imgBox1.classList.add('head_img');
                imgBox1.appendChild(headImg1)
                head1.appendChild(loop1);
                head1.appendChild(imgBox1);
                report.appendChild(head1);
                //文本2
                let str = reportHeap[i].text1.split('损失了');
                if(str.length > 1){
                    let text = document.createElement('div');
                    text.classList.add('text');
                    text.innerText = str[0] + '损失了';
                    report.appendChild(text);
                    let numberText = str[1].split('兵力');
                    let number = document.createElement('div');
                    number.classList.add('number');
                    number.innerText = numberText[0];
                    report.appendChild(number);
                    let text1 = document.createElement('div');
                    text1.classList.add('text');
                    text1.innerText = '兵力' + numberText[1];
                    report.appendChild(text1);
                }
                else{
                    str = reportHeap[i].text1.split('恢复了');
                    if(str.length > 1){
                        let text = document.createElement('div');
                        text.classList.add('text');
                        text.innerText = str[0] + '恢复了';
                        report.appendChild(text);
                        let numberText = str[1].split('兵力');
                        let number = document.createElement('div');
                        number.classList.add('number1');
                        number.innerText = numberText[0];
                        report.appendChild(number);
                        let text1 = document.createElement('div');
                        text1.classList.add('text');
                        text1.innerText = '兵力' + numberText[1];
                        report.appendChild(text1);
                    }
                    else{
                        let text = document.createElement('div');
                        text.classList.add('text');
                        text.innerText = str;
                        report.appendChild(text);
                    }
                }
            }
            container.appendChild(report);
        }
    }
    //初始化系统消息
    InitSysMsg(reportHeap,type,text,isPutReportHeap = true){
        this.type = type;
        this.text = text;
        this.level = 0;
        if(isPutReportHeap) reportHeap.push(this);
        else return this;
    }
    //初始化执行式战报
    InitExecuteReport(level,target,text,isPutReportHeap = true){
        this.level = level;
        this.type = "执行";
        this.belong = target.belong;
        this.img1 = target.img.head;
        this.text = text;
        if(isPutReportHeap) target.global.ReportHeap.push(this);
        else return this;
    }
    //初始化两目标式战报
    InitTwoTargetReport(level,target1,target2,text1,text2,isPutReportHeap = true){
        this.type = "两目标";
        this.level = level;
        this.belong = target1.belong;
        this.receiver_belong = target2.belong;
        this.img1 = target1.img.head;
        this.img2 = target2.img.head;
        this.text = text1;
        this.text1 = text2;
        if(isPutReportHeap) target1.global.ReportHeap.push(this);
        else return this;
    }
    //将战报放入战报堆区
    PutReportToHeap(reportHeap,target){
        reportHeap.push(target);
    }


    //初始化一条战报,type为战报类型，attacker为发起者，receiver为接受者，damage为造成伤害
    InitAttackReport(type,attacker,receiver,damage){
        
        this.level = 1;
        if(type == '普通攻击'){
            //战报类型是普通攻击
            //战报例子【A】对【B】发动普通攻击，{造成damage伤害，【B】还剩B。current_attr.hp点兵力。}，{}内为文本信息
            this.type = '普通攻击';
            this.attacker_img = attacker.img.head;
            this.receiver_img = receiver.img.head;
            this.text1 = ``
            this.text2 = `损失了${damage}兵力(${receiver.current_attr.hp})`
        }else if(type == 'ballistic_attack'){
            this.type = 'ballistic_attack';
            this.attacker_img = attacker.img.head;
            this.receiver_img = receiver.img.head;
            this.text1 = `造成${damage}点伤害。`
            this.text2 = `还剩(${receiver.current_attr.hp})兵力`
        }
    }

    
    
    
    
    
    
    
    
    
    
    
    //初始化阵容信息
    InitTeamInfo(target){
        this.type = 'info',
        this.level = 0;
        this.belong = target.belong;
        this.attacker_img = target.img.head;
        this.text1 = `【${target.camp}-${target.name}-${target.arms}】兵力:${target.origin_attr.hp}`
    }

    //武将战法准备阶段执行
    InitTacticsPrepareExecute(target,index){
        this.level = 0;
        this.type = 'info',
        this.belong = target.belong;
        this.attacker_img = target.img.head;
        this.text1 = `${target.TACTICS_STACK[index].info}`;
    }


    

}

export default Report;