<template>
    <div class="alert_container">
        <div class="mask cover" @click="Close"></div>
        
        <div class="container">
            <div class="title">提 示</div>
            <div class="close_btn" ref="close_btn" 
            @touchstart="$refs.close_btn.classList.add('click')"
            @touchend="$refs.close_btn.classList.remove('click')" 
            @click="Close"></div>
            <div class="text">{{ $store.state.AlertPanel.text }}</div>
            <div class="confirm_btn" ref="confirm_btn"
            @touchstart="$refs.confirm_btn.classList.add('click')"
            @touchend="$refs.confirm_btn.classList.remove('click')" 
            @click="Confirm">确 定</div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Alert',
    methods:{
        Close(){
            this.$store.state.AlertPanel.status = false;
        },
        Confirm(){
            this.$store.state.AlertPanel.callBack();
            this.Close();
        }
    }
}
</script>

<style lang="less">
.alert_container{
    z-index: 100;
    position: fixed; left: 0; top: 0;
    width: 100vw; height: 100vh;
    
    .mask{
        background:radial-gradient(rgba(0, 0, 0, 0.5),rgb(0, 0, 0));
    }
    .container{
        left: 0; top: 50%;
        width: 100vw; height: 60vw;
        margin: -30vw 0 0 0;
        background: url('../../assets/background/bg (7).png')#393b3e bottom right no-repeat;
        background-size: cover;
        animation: lively_emerge .12s linear 1;
        
        .title{
            left: 0; top: 5vw;
            width: 50vw;height: 10vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
            text-indent: 10vw;
            text-align: left;
            font-size: 5.5vw;
            font-family: "FZLBJW";
            line-height: 10vw;
        }
        .close_btn{
            left: auto;right: 3vw; top: 5vw;
            height: 7vw; width: 7vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: cover;
        }
        .close_btn.click{
            transform: scale(.8);
        }
        .text{
            left: 50%; top: 45%;
            font-size: 3.5vw;
            width: 60vw; 
            color: white;
            transform: translate(-50%,-50%);
            // letter-spacing: .8vw;
        }
        .confirm_btn{
            left: 39vw; top: auto;
            bottom: 10vw;
            width: 22vw; height: 6vw;
            line-height: 6vw;
            background: white;
            color: black;
            font-weight: bold;
        }
        .confirm_btn.click{
            transform: scale(.8);
        }
    }
    
}
</style>