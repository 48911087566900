import Report from "../js/report";
import Attack from "../js/attack";
import Manage from "../js/manage";
import Judge from "../js/judge";
//武将实例
class Warrior{
    constructor(data,position,belong,global,driver){
        this.driver = driver;
        this.global = global;
        this.belong = belong;
        this.position = position;
        this.red = data.red;
        this.sign = data.sign;
        //武将id
        this.id = data.id;
        //是否存活
        this.alive = true;
        //我军
        this.ally = null;
        //敌军
        this.enemy = null;
        //本回合行动力
        this.action = true;
        //等级
        this.level = data.level;
        //性别
        this.gender = data.gender;
        //名字
        this.name = data.name;
        //阵营
        this.camp = data.camp;
        //兵种
        this.arms = data.arms;
        //统帅值
        this.cost = data.cost;
        //普通攻击距离
        this.ackRange = data.range;
        //兵力
        this.hp = data.level * 100;
        //士气
        this.morale = 100;
        //进阶兵种
        this.up_arms = data.up_arms;
        this.choseArms = data.choseArms;
        //属性值
        this.attr = {
            ad : Math.floor(data.attr.ad + data.grow.ad * (data.level - 1) + data.plus.ad),
            de : Math.floor(data.attr.de + data.grow.de * (data.level - 1) + data.plus.de),
            tact : Math.floor(data.attr.tact + data.grow.tact * (data.level - 1) + data.plus.tact),
            speed : Math.floor(data.attr.speed + data.grow.speed * (data.level - 1) + data.plus.speed),
        }
        //图像
        this.img = {
            body : data.img.body,
            preview : data.img.preview,
            head : data.img.head,
            banner : data.img.banner,
        }
        //战法栏
        this.TacticsArr = [];
        //战法准备队列
        this.WaitTacticsArr = [];
        //无视区
        this.IGNORE = [];
        //状态区(非伤害类型)
        this.STATUS = [];
        //伤害变化区
        this.DAMAGE_VAR = [];
        //攻击距离增减益
        this.RANGE = [];
        //概率变化区
        this.ODD_VAR = [];
        //特殊类型状态区
        this.SPECIAL_STATUS = [];
        //治疗类状态
        this.CURE_STATUS = [];
        //属性堆加区
        this.ATTR = [];
        //规避堆区
        this.DODGE = [];
        //执行堆区
        this.EXECUTE = [];
        //造成伤害后执行
        this.MAKE_DAMAGE = [];
        //受到伤害后执行
        this.AFTER_DAMAGE = [];
        //受到持续型伤害后执行
        this.AFTER_CONTINUE_DAMAGE = [];
        //受到恢复效果后执行
        this.GET_CURE = [];
        //发动战法前后执行
        this.ACTIVE_TACTICS_EXECUTE = [];
        //发动普通攻击前后执行
        this.LAUNCH_ATTACK_EXECUTE = [];
        //伤害产生前执行
        this.BEFORE_MAKE_DAMAGE = [];
        //反击队列
        this.COUNTER_ATTACK = [];
        //对最终伤害或者是治疗量*某数
        this.DISCOUNT = [];

        //陷入持续伤害状态时执行
        this.FALL_INTO_CONTINUE_DAMAGE = [];

        

        //结果统计区
        this.STATISTICS = {
            atk : 0,//普通攻击造成伤害统计
            tact : 0,//战法造成伤害统计
            cure : 0,//治疗量统计
        }
    }
    //执行自己的回合   
    Execute(){
        let a = new Attack();
        let m = new Manage();
        let j = new Judge();

        let report = new Report();
        report.InitExecuteReport(1,this,`----------  行动开始 (${this.hp})  ----------`);
        //-----------------------------结算状态控制块-------------------------------------
        m.ManageExecuteBlock(this,"行动开始前");//结算执行函数块
        m.ManageRangBlock(this);//结算距离加成
        m.ManageOddBlock(this);//结算发动几率
        m.ManageDamageStatusBlock(this); //结算妖术，恐慌等状态
        m.ManageCureStatusBlock(this); //结算休整等治疗状态
        m.ManageStatusBlock(this);//结算状态
        m.ManageAttrBlock(this);//结算属性变化
        m.ManageDamageVarBlock(this); //结算增减伤
        m.ManageIgnoreBlock(this);
        m.ManageCounterAttackBlock(this);//结算反击和分兵

        
        m.ManageBeforeMakeDamageBlock(this);//结算即将造成或受到伤害的执行函数
        m.ManageAfterGetDamagedBlock(this);//结算受到伤害后执行函数
        m.ManageGetCureExecuteBlock(this);//结算受到治疗后执行函数
        m.ManageAttackExecuteBlock(this);//结算发动普通攻击前后执行函数
        m.ManageTacticsExecutedBlock(this);//结算发动战法前后执行函数
        m.ManageExecuteBlock(this,"行动开始");//结算执行函数块
        if(!this.alive){
            //结束
            this.action = false;
            return;
        }
        //是否处于洞察
        let insight = j.IsGetStatus(this,"洞察");
        //判断是否暴走
        if(j.IsGetStatus(this,"暴走") && !insight){
            let r = new Report();
            r.InitExecuteReport(2,this,`不分敌我目标`);
        }
        else if(j.IsGetStatus(this,"暴走") && insight){
            let r = new Report();
            r.InitExecuteReport(2,this,`处于洞察状态，免疫暴走`);
        }
        if(j.IsGetStatus(this,"混乱") && !insight){
            let report = new Report();
            report.InitExecuteReport(2,this,"处于混乱中，无法行动");
        }
        else{
            if(j.IsGetStatus(this,"混乱") && insight){
                let r = new Report();
                r.InitExecuteReport(2,this,`处于洞察状态，免疫混乱`);
            }
            //试图发动主动战法
            if(j.IsGetStatus(this,"犹豫") && !insight){
                let report = new Report();
                report.InitExecuteReport(2,this,"处于犹豫中，无法发动主动战法");
            }
            else if(j.IsGetStatus(this,"无法施法")){
                let report = new Report();
                report.InitExecuteReport(2,this,"无法发动主动战法");
            }
            else{
                if(j.IsGetStatus(this,"犹豫") && insight){
                    let r = new Report();
                    r.InitExecuteReport(2,this,`处于洞察状态，免疫犹豫`);
                }
                a.LaunchActiveTactics(this);
            } 
            //发动普通攻击
            if(j.IsGetStatus(this,"怯战") && !insight && !j.IsGetStatus(this,"免疫怯战")){
                let report = new Report();
                report.InitExecuteReport(2,this,"处于怯战中，无法进行普通攻击");
            }

            else if(j.IsGetStatus(this,"无法普通攻击") && !j.IsGetStatus(this,"免疫怯战")){
                let report = new Report();
                report.InitExecuteReport(2,this,"无法进行普通攻击");
            }
            else{
                if(j.IsGetStatus(this,"怯战") && insight){
                    let r = new Report();
                    r.InitExecuteReport(2,this,`处于洞察状态，免疫怯战`);
                }
                a.LaunchAttack(this);
                if(j.IsGetStatus(this,"连击")){
                    let report = new Report();
                    report.InitExecuteReport(2,this,"执行两次攻击");
                    a.LaunchAttack(this);
                }
            }
        }
        
        //结束
        m.ManageExecuteBlock(this,"行动结束");//结算执行函数块
        this.action = false;
        
    }
}

export default Warrior;