import Report from "../js/report";
class DamageBlock{
    constructor(applicator,tactics,damageType,isMakeDamage,value,isOnceAttack,isOnceTactics,duration,waitTime){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //伤害作用类型，是攻击伤害还是谋略伤害
        this.damageType = damageType;
        //是造成伤害还是受到伤害
        this.isMakeDamage = isMakeDamage
        //数值
        this.value = value;
        //是否是只作用一次攻击
        this.isOnceAttack = isOnceAttack;
        //是否只作用一次主动战法
        this.isOnceTactics = isOnceTactics == undefined ? false : isOnceTactics;
        //持续时间
        this.duration = duration;
        //等待事件
        this.waitTime = waitTime == undefined ? 0 : waitTime;
        //作用于哪个战法 (指定特定战法为其增伤减伤)
        this.tacticsItem = null;
        //是否是增益
        this.isBenefit = false;
    }
    Init(target,tacticsItem = null,needReport = true,reportLevel = 3){
        //不需要说明特地为某战法增伤不需要传入tacticsItem
        if(this.isMakeDamage){
            if(this.value > 0 ) this.isBenefit = true;
        }
        else{
            if(this.value < 0 ) this.isBenefit = true;
        }
        this.tacticsItem = tacticsItem;
        //所有类型的增减伤块会进行降级
        if(this.damageType == '所有'){
            let ad = false; let tact = false;
            for(let i in target.DAMAGE_VAR){
                if(target.DAMAGE_VAR[i].isMakeDamage == this.isMakeDamage
                && target.DAMAGE_VAR[i].isBenefit == this.isBenefit
                && target.DAMAGE_VAR[i].tactics.type == this.tactics.type){
                    if(target.DAMAGE_VAR[i].damageType == '攻击') ad = true;
                    if(target.DAMAGE_VAR[i].damageType == '谋略') tact = true;
                }
            }
            if(ad && tact){
                let str = "已存在"
                if(!this.isMakeDamage) str += "受到"
                str += "所有伤害";
                if(this.value >=  666) str += "大幅提高";
                else if(this.value >= 0  && this.value < 666) str += '提高';
                else if(this.value < -666) str += "大幅降低"
                else str += '降低';
                str += '效果';
                let report = new Report();
                report.InitExecuteReport(3,target,str);
                return false;
            }
            //退化
            else if(ad) this.damageType = '谋略';
            else if(tact) this.damageType = '攻击';
        }
        //冲突判断
        for(let i in target.DAMAGE_VAR){
            //解决一次性增减伤
            let onceControl = true;
            if(target.DAMAGE_VAR[i].isOnceAttack && target.DAMAGE_VAR[i].tactics == this.tactics) onceControl = false;
            else if(target.DAMAGE_VAR[i].isOnceAttack != this.isOnceAttack && target.DAMAGE_VAR[i].tactics == this.tactics) onceControl = false;
            //说明作用于同一个战法
            if(this.tacticsItem == target.DAMAGE_VAR[i].tacticsItem && this.tacticsItem != null 
            && target.DAMAGE_VAR[i].tactics.type == this.tactics.type){
                let txt = `已存在【${this.tacticsItem.name}】造成伤害`
                if(this.value >=  666) txt += "大幅提高";
                else if(this.value >= 0  && this.value < 666) txt += '提高';
                else if(this.value < -666) txt += "大幅降低"
                else txt += '降低';
                txt += "效果"
                let report = new Report();
                report.InitExecuteReport(3,target,txt);
                return false;
            }
            //作用类型一样
            else if(target.DAMAGE_VAR[i].damageType == this.damageType
            && target.DAMAGE_VAR[i].isMakeDamage == this.isMakeDamage
            && target.DAMAGE_VAR[i].tactics.type == this.tactics.type
            && target.DAMAGE_VAR[i].isBenefit == this.isBenefit
            && onceControl
            && this.tacticsItem == null){
                let str = "已存在";
                if(!target.DAMAGE_VAR[i].isMakeDamage) str += "受到"
                if(target.DAMAGE_VAR[i].damageType == '谋略') str += "策略伤害"
                else if(target.DAMAGE_VAR[i].damageType == '攻击') str += "攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '主动') str += '主动战法伤害';
                else if(target.DAMAGE_VAR[i].damageType == '追击') str += "追击战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '普通攻击') str += "普通攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '所有') str += "所有攻击伤害";
                if(this.value >=  666) str += "大幅提高";
                else if(this.value >= 0  && this.value < 666) str += '提高';
                else if(this.value < -666) str += "大幅降低"
                else str += '降低';
                str += '效果';
                let report = new Report();
                report.InitExecuteReport(3,target,str);
                return false;
            }
        }
        target.DAMAGE_VAR.push(this);
        if(needReport){
            if(this.tacticsItem == null){
                let str = "";
                if(this.tacticsItem != null) str += `【${this.tacticsItem.name}】`
                if(!this.isMakeDamage) str += "受到";
                if(this.damageType == '谋略') str += "策略伤害";
                else if(this.damageType == '攻击') str += "攻击伤害";
                else if(this.damageType == '主动') str += "主动战法伤害";
                else if(this.damageType == '追击') str += "追击战法伤害";
                else if(this.damageType == '普通攻击') str += "普通攻击伤害";
                else if(this.damageType == '所有') str += "所有伤害";
                if(this.value >=  666) str += "大幅提高";
                else if(this.value >= 0  && this.value < 666) str += '提高';
                else if(this.value < -666) str += "大幅降低"
                else str += '降低';
                if(this.value >= -666 && this.value < 666) str += `${Math.floor(Math.abs(this.value))}%`;
                let report = new Report();
                report.InitExecuteReport(reportLevel,target,str);
            }
            else{
                let txt = `【${this.tacticsItem.name}】造成伤害`
                if(this.value >=  666) txt += "大幅提高";
                else if(this.value >= 0  && this.value < 666) txt += '提高';
                else if(this.value < -666) txt += "大幅降低"
                else txt += '降低';
                if(this.value >= -666 && this.value < 666) txt += `${Math.floor(Math.abs(this.value))}%`;
                let report = new Report();
                report.InitExecuteReport(reportLevel,target,txt);
            }
        }
        return true;
    }
}
export default DamageBlock;