import Report from "../js/report";
//受到伤害后执行块
class AfterDamageBlock{
    constructor(applicator,tactics,execute,duration,getAdDmgExecute,getTactDmgExecute,getCAttackExecute,getCommand,getActive,getCombo,getPassive){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //执行函数
        this.execute = execute;
        //被执行人
        this.beExecuted = null,
        //持续时间
        this.duration = duration;
        //受到攻击伤害执行
        this.getAdDmgExecute = getAdDmgExecute;
        //受到谋略伤害执行
        this.getTactDmgExecute = getTactDmgExecute;
        //受到普通攻击后执行
        this.getCAttackExecute = getCAttackExecute;
        //受到战法伤害执行
        this.getTacticsDmgExecute = {
            command : getCommand,
            active : getActive,
            combo : getCombo,
            passive : getPassive
        };
        //伤害数值
        this.damageValue = 0;
        //伤害来自于
        this.damageFromWho = null;
        //伤害来源的战法
        this.damageFromTactics = null;
        //伤害是否来源于普通攻击
        this.isCommonAttack = false;
        //伤害的类型是谋略还是物理
        this.damageType = "攻击";
        //类型
        this.type = "";
        //是否是有害效果
        this.isAbnormal = false;
    }
    Init(target,type){
        if(this.type == "火势风威"){
            this.isAbnormal = true;
        }
        //冲突判断
        this.type = type;
        for(let i in target.AFTER_DAMAGE){
            if(target.AFTER_DAMAGE[i].type == this.type && this.type == '急救'){
                let report = new Report();
                report.InitExecuteReport(3,target,`已存在急救效果`);
                return false;
            }
        }
        this.beExecuted = target;
        target.AFTER_DAMAGE.push(this);
        return true;
    }
    GetDamageData(damageValue,damageFromWho,damageFromTactics,isCommonAttack,damageType){
        this.damageValue = damageValue;
        this.damageFromWho = damageFromWho;
        this.damageFromTactics = damageFromTactics;
        this.isCommonAttack = isCommonAttack;
        this.damageType = damageType;
    }
    Execute(){
        this.execute();
    }
}

export default AfterDamageBlock;