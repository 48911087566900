import Report from "../js/report";
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import StatusBlock from "../block/status";
import DamageBlock from "../block/damage";
import DamageStatus from "../block/damageStatus";
import AfterDamageBlock from "../block/execute1";
import ExecuteBlock from "../block/execute";
import AttrBlock from '../block/attr.js';
import Util from "../js/util";
import AttackExecuteBlock from "../block/execute4.js";
import ActiveTacticsBlock from "../block/execute3.js";
let weiAArr = [
    //张春华
    {
        id : 14,
        name : "强势",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let index = q.GetAdditionValue(self,32,'谋略',0.11); //伤害降低幅度

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            //施加减伤
            for(let i in res){
                let block = new DamageBlock(self,this,"攻击",true,-index,false,false,2);
                block.Init(res[i]);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,"犹豫",2);
                block.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //司马懿
    {
        id : 36,
        name : "破凰",
        type : "主动",
        odd : 45,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = q.GetAdditionValue(self,155,'谋略',1.75);//伤害率
            let damageValue2 = q.GetAdditionValue(self,130,'谋略',1.5);


            let tag = "破凰" + self.position + self.belong;
            let action = function(){
                if(that == this.damageFromTactics) return;
                let target = this.beExecuted;
                //如果妖术效果已经被刷新覆盖了，则不执行且重置tag
                let refresh = true;
                for(let i in target.SPECIAL_STATUS){
                    if(target.SPECIAL_STATUS[i].tactics == that) refresh = false;
                }
                if(refresh){
                    target[tag] = 0;
                    let temp = [];
                    for(let i in target.AFTER_DAMAGE){
                        if(target.AFTER_DAMAGE[i].tactics != that) temp.push(target.AFTER_DAMAGE[i]);
                    }
                    target.AFTER_DAMAGE = temp;
                    return;
                }
                if(target[tag] > 0){
                    target[tag] -=1;
                    let damage = c.CountDamage(self,target,damageValue2,"谋略",false,that,"妖术");
                    let work = a.GetHurt(self,target,damage,false,"谋略",that,false,null);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(3,target,`由于【破凰】损失了${damage}兵力(${target.hp})，还剩${target[tag]}次`);
                    }
                    //如果全部消耗完，就清除妖术状态,并清除执行函数
                    if(target[tag] == 0){
                        let temp = [];
                        for(let k in target.SPECIAL_STATUS){
                            if(target.SPECIAL_STATUS[k].tactics != that) temp.push(target.SPECIAL_STATUS[k]);
                            else{
                                let r = new Report();
                                r.InitExecuteReport(3,target,`【破凰】妖术效果消失了`);
                                let temp = [];
                                for(let i in target.AFTER_DAMAGE){
                                    if(target.AFTER_DAMAGE[i].tactics != that) temp.push(target.AFTER_DAMAGE[i]);
                                }
                                target.AFTER_DAMAGE = temp;
                            }
                        }
                        target.SPECIAL_STATUS = temp;
                    }
                }
            }
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in res){
                //立即引发剩余妖术效果
                for(let j in res[i].ally){
                    //先看妖术效果有没有被刷新
                    let refresh = true;
                    for(let k in res[i].ally[j].SPECIAL_STATUS){
                        if(res[i].ally[j].SPECIAL_STATUS[k].tactics == this) refresh = false;
                    }
                    if(refresh){
                        res[i].ally[j][tag] = 0;
                        //被刷新同时清除执行函数
                        let arr = [];
                        for(let m in res[i].ally[j].AFTER_DAMAGE){
                            if(res[i].ally[j].AFTER_DAMAGE[m].tactics != this) arr.push(res[i].ally[j].AFTER_DAMAGE[m])
                        }
                        res[i].ally[j].AFTER_DAMAGE = arr;
                    }
                    else if(res[i].ally[j][tag]>0){
                        for(let k=0;k<res[i].ally[j][tag];k++){
                            let damage = c.CountDamage(self,res[i].ally[j],damageValue2,"谋略",false,this,"妖术");
                            let work = a.GetHurt(self,res[i].ally[j],damage,false,"谋略",that,false,null);
                            if(work){
                                let r = new Report();
                                r.InitExecuteReport(3,res[i].ally[j],`由于【破凰】损失了${damage}兵力(${res[i].ally[j].hp})，还剩${res[i].ally[j][tag] - k - 1}次`);
                            }
                        }
                        res[i].ally[j][tag] = 0;
                        //并且清除妖术效果
                        let temp = [];
                        for(let k in res[i].ally[j].SPECIAL_STATUS){
                            if(res[i].ally[j].SPECIAL_STATUS[k].tactics != this) temp.push(res[i].ally[j].SPECIAL_STATUS[k]);
                            else{
                                let r = new Report();
                                r.InitExecuteReport(3,res[i].ally[j],`【破凰】妖术效果消失了`);
                                let arr = [];
                                for(let m in res[i].ally[j].AFTER_DAMAGE){
                                    if(res[i].ally[j].AFTER_DAMAGE[m].tactics != this) arr.push(res[i].ally[j].AFTER_DAMAGE[m])
                                }
                                res[i].ally[j].AFTER_DAMAGE = arr;
                            }
                        }
                        res[i].ally[j].SPECIAL_STATUS = temp;
                    }
                }
                let damage = c.CountDamage(self,res[i],damageValue1,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                let block = new DamageStatus(self,this,"妖术",damageValue2,"谋略",3,0,false);
                let work = block.Init(res[i],true);
                if(work){
                    res[i][tag] = 3;//标记目标层数
                    let block1 = new AfterDamageBlock(self,this,action,3,true,true,true,true,true,true,true);
                    block1.Init(res[i],"破凰");
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //曹纯
    {
        id : 51,
        name : "虎豹督军",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let index = q.GetAdditionValue(self,40,'攻击',0.2);
            if(self.driver.version == '平衡'){
                index = q.GetAdditionValue(self,40,'攻击',0.26);
            }

            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,false,this);
            let action = function(){
                if(self.driver.turn < 5){
                    let target = this.beExecuted;
                    for(let i in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[i].tactics == that){
                            let value = Math.floor(index - (self.driver.turn - 1) * 0.25 * index);
                            target.DAMAGE_VAR[i].value = value;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【虎豹督军】令`,`攻击伤害提高${value}%`)
                            break;
                        }
                    }
                }
            }
            for(let i in res){
                let block = new DamageBlock(self,this,"攻击",true,index,false,false,4,0);
                let work = block.Init(res[i],null,false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`攻击伤害提高${index}%`);
                    let execute = new ExecuteBlock(self,this,"行动开始",action,4);
                    execute.Init(res[i]);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //钟会
    {
        id : 59,
        name : "剑关叛立",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = q.GetAdditionValue(self,240,'谋略',1.5);
            let damageValue2 = 240;

            let realRange = q.GetTacticsRange(self,this);
            
            for(let j=0;j<2;j++){
                //策略攻击
                if(j == 0){
                    let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue1,'谋略',false,this,null);
                        a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                    }
                }
                //攻击
                else{
                    let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue2,'攻击',false,this,null);
                        a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                    }
                }
            }
            //敌我全体禁疗
            for(let i in self.ally){
                let block = new StatusBlock(self,this,'围困',2,0);
                block.Init(self.ally[i],true);
            }
            for(let i in self.enemy){
                let block = new StatusBlock(self,this,'围困',2,0);
                block.Init(self.enemy[i],true);
            }


            this.STATISTICS.times += 1;
        }
    },
    //夏侯惇
    {
        id : 63,
        name : "盲侯奋勇",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 40;//触发反击的几率
            let damageValue = 60;//伤害率

            //无视兵种
            let status = new StatusBlock(self,this,'无视兵种',99,0);
            let work =  status.Init(self,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,self,`无视兵种效果已施加`);
            }
            let action = function(){
                //盲侯奋勇不会触发该反击效果
                if(this.damageFromTactics != that){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`执行【盲侯奋勇】`);
                        let res = a.SelectTarget(self,4,false,true,2,false,false,that);
                        for(let i in res){
                            let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                            a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                        }
                    }
                }
            }
            let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
            block.Init(self,'盲侯奋勇');
            let r = new Report();
            r.InitExecuteReport(2,self,`【盲侯奋勇】效果已施加`);
            
            this.STATISTICS.times += 1;
        }
    },
    //郝昭
    {
        id : 68,
        name : "不动如山",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let value1 = 100;//防御属性提高
            let value2 = 25;//谋略属性提高

            //属性提高
            let block1 = new AttrBlock(self,this,'防御',value1,99);
            let work1 = block1.Init(self,false);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,self,`防御属性提高${value1}(${q.GetAttr(self,'防御')})`);
            }
            let block2 = new AttrBlock(self,this,'谋略',value2,99);
            let work2 = block2.Init(self,false);
            if(work2){
                let r = new Report();
                r.InitExecuteReport(2,self,`谋略属性提高${value2}(${q.GetAttr(self,'谋略')})`);
            }
            
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【不动如山】`);
                u.ClearStatus(self,that,false,3);
                //消除白衣渡江
                for(let i in self.EXECUTE){
                    if(self.EXECUTE[i].tactics.name == '白衣渡江' && self.EXECUTE[i].duration > 0){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`【白衣渡江】预备伤害被移除了`);
                        self.EXECUTE[i].duration-=99;
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始前',action,99);
            block.Init(self,true);
            let r = new Report();
            r.InitExecuteReport(2,self,`【不动如山】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //王异
    {
        id : 83,
        name : "世仇",
        type : "追击",
        odd : 60,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = q.GetAdditionValue(self,233,'谋略',2.1);//基础伤害率
            if(self.driver.version == '平衡'){
                damageValue = q.GetAdditionValue(self,210,'谋略',2);
            }
            
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"谋略",false,this,null);
            a.GetHurt(self,target,damage,false,"谋略",this,true);
            //围困
            let block = new StatusBlock(self,this,'围困',2,0);
            block.Init(target,true);
            
            this.STATISTICS.times += 1;
        }
    },
    //曹仁
    {
        id : 93,
        name : "平壑拒吴",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 210;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            //造成伤害
            let all = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            if(all != []){
                let lowDe = all[0];
                let lowHp = all[0];
                for(let i in all){
                    if(q.GetAttr(all[i],'防御') < q.GetAttr(lowDe,'防御')) lowDe = all[i];
                    else if(q.GetAttr(all[i],'防御') == q.GetAttr(lowDe,'防御')){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1) lowDe = all[i];
                    }
                    if(all[i].hp < lowHp.hp) lowHp = all[i];
                    else if(all[i].hp == lowHp.hp){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1) lowHp = all[i];
                    }
                }
                let res = [lowDe,lowHp];
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,this,null);
                    a.GetHurt(self,res[i],damage,false,'攻击',this,true,null);
                }
            }

            //怯战
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let block = new StatusBlock(self,this,"怯战",2);
                block.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //卞夫人
    {
        id : 100,
        name : "俭仪蹈和",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = 100;
            let odd = 50;

            let highestAd = self.enemy[0];
            let highestTact = self.enemy[0];
            for(let i in self.enemy){
                if(q.GetAttr(self.enemy[i],'攻击') > q.GetAttr(highestAd,'攻击')) highestAd = self.enemy[i];
                else if(q.GetAttr(self.enemy[i],'攻击') == q.GetAttr(highestAd,'攻击')){
                    let rand = Math.floor(Math.random()*2);
                    if(rand == 1) highestAd = self.enemy[i];
                }
                if(q.GetAttr(self.enemy[i],'谋略') > q.GetAttr(highestTact,'谋略')) highestTact = self.enemy[i];
                else if(q.GetAttr(self.enemy[i],'谋略') == q.GetAttr(highestTact,'谋略')){
                    let rand = Math.floor(Math.random()*2);
                    if(rand == 1) highestTact = self.enemy[i];
                }
            }
            let block1 = new AttrBlock(self,this,'攻击',-value,3);
            block1.Init(highestAd,true,2);
            let block2 = new AttrBlock(self,this,'谋略',-value,3);
            block2.Init(highestTact,true,2);
            let res = a.SelectTarget(self,5,false,true,2,false,false,this);
            for(let i in res){
                let action = function(){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let type = "怯战";
                        let randNumber = Math.floor(Math.random()*2);
                        if(randNumber == 1) type = '犹豫'
                        let block = new StatusBlock(self,that,type,1,0);
                        let work = block.Init(res[i],false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【俭仪蹈和】令`,`陷入${type}`);
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(3,self,`【俭仪蹈和】没有生效，生效几率为${realOdd}%`);
                    }
                }
                let block1 = new AttackExecuteBlock(self,this,action,4,0,true);
                block1.Init(res[i],false);
                let block2 = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
                block2.Init(res[i],false);
            }
            this.STATISTICS.times += 1;
        }
    },
    //曹植
    {
        id : 109,
        name : "七步释嫌",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let cureValue = 196;
            let cureIndex = 2;
            let value = q.GetAdditionValue(self,10,'谋略',0.06);
            
            let realRange = q.GetTacticsRange(self,this);
            let tag = "七步释嫌" + self.belong + self.position;
            let action = function() {
                if(self[tag] < 6){
                    self[tag] += 1;
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【七步释嫌】执行${self[tag]}次`)
                }
                else{
                    self[tag] = 0;
                    let res = a.SelectTarget(self,realRange,true,false,2,true,true,that);
                    for(let j in res){
                        let cure = c.CountCure(self,res[j],cureValue,cureIndex,that);
                        a.GetCure(self,res[j],cure,that,false);
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,res[j],`【七步释嫌】令`,`恢复了${cure}兵力(${res[j].hp})`);
                    }
                }
                //施加减伤
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,that);
                for(let i in res){
                    let avail = true;
                    for(let j in res[i].DAMAGE_VAR){
                        if(res[i].DAMAGE_VAR[j].tactics == that){
                            res[i].DAMAGE_VAR[j].value -= value;
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【七步释嫌】令`,`所有伤害降低${Math.abs(res[i].DAMAGE_VAR[j].value)}%`);
                            avail = false;
                        }
                    }
                    if(avail){
                        let block = new DamageBlock(self,that,'所有',true,-value,true,false,99,0);
                        let work = block.Init(res[i],null,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【七步释嫌】令`,`所有伤害降低${value}%`);
                        }
                    }
                }
            }
            for(let i in self.ally){
                let target = self.ally[i];
                let block1 = new AttackExecuteBlock(self,this,action,99,0,true);
                block1.Init(target,false);
                let block2 = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
                block2.Init(target,false);
                let r = new Report();
                r.InitExecuteReport(2,target,`【七步释嫌】效果已施加`);
            }
            self[tag] = 0;
            this.STATISTICS.times += 1;
        }
    },
]

export default weiAArr;