import AttrBlock from "../block/attr";
import DamageBlock from "../block/damage";
import DamageStatus from "../block/damageStatus";
import ActiveTacticsBlock from "../block/execute3";
import StatusBlock from "../block/status";
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import Report from "../js/report";
import Util from "../js/util";
import ExecuteBlock from '../block/execute.js';
import DodgeBlock from '../block/dodge.js'
import BeforeMakeDamageBlock from "../block/execute2.js";
import AfterDamageBlock from "../block/execute1.js";

let weiBArr = [
    //曹丕
    {
        id : 13,
        name : "魏武之泽",
        type : "主动",
        odd : 35,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value = 15 + Math.floor((q.GetAttr(self,"谋略")-80)*0.12);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,true,this);
            for(let i in res){
                //施加增伤
                let block1 = new DamageBlock(self,this,"普通攻击",true,value,false,false,2,0);
                block1.Init(res[i],null,true);
                let block2 = new DamageBlock(self,this,"追击",true,value,false,false,2,0);
                block2.Init(res[i],null,true);
            }
            //施加连击
            for(let i in res){
                let block = new StatusBlock(self,this,"连击",2,0);
                block.Init(res[i],true);
            }


            this.STATISTICS.times += 1;

        }
    },
    //张郃
    {
        id : 18,
        name : "以逸待劳",
        type : "主动",
        odd : 35,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 125;//基础伤害率
            let cureValue = 200;//恢复率
            
            //回血
            let cure = c.CountCure(self,self,cureValue,0,this);
            a.GetCure(self,self,cure,this);
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                a.GetHurt(self,res[i],damage,false,"谋略",this);
            }
            this.STATISTICS.times += 1;
        }
    },
    //关羽
    {
        id : 32,
        name : "千里单骑",
        type : "追击",
        odd : 50,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = 180;//基础伤害率
            let cureValue = 0.5;//恢复率
            if(self.driver.version == '平衡'){
                damageValue = 200;
            }

            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);
            
            //回血
            let cure = damage * cureValue;
            //最多只可以恢复失去兵力中80%的兵力
            let cure_max = (q.GetMaxHp(self) - self.hp) * 0.8;
            if(cure > cure_max){
                //如果质量量溢出
                cure = cure_max;
            }
            cure = Math.floor(cure);
            a.GetCure(self,self,cure,this,true);
            this.STATISTICS.times += 1;
        }
    },
    //夏侯渊
    {
        id : 33,
        name : "虎步关右",
        type : "主动",
        odd : 120,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let value = 70 + Math.floor((q.GetAttr(self,"速度")-80)*0.06);

            let block = new DamageBlock(self,this,"攻击",true,value,true,true,0);
            block.Init(self,null,true);
            this.STATISTICS.times += 1;

        }
    },
    //邓艾
    {
        id : 34,
        name : "险途暗渡",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = 150;//一段伤害
            let damageValue2 = 80;//恐慌伤害
            let value = 10;//士气降低值

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(2,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true);
            }
            //施加恐慌
            for(let i in res){
                let block = new DamageStatus(self,this,"动摇",damageValue2,"攻击",1,0,true);
                block.Init(res[i],true);
            }
            for(let i in res){
                let realValue = value;
                if(res[i].morale < value) realValue = res[i].morale;
                res[i].morale -= realValue;
                let r = new Report();
                r.InitExecuteReport(3,res[i],`士气降低了${realValue}(${res[i].morale})`)
            }


            this.STATISTICS.times += 1;
        }
    },
    //荀彧&荀攸
    {
        id : 54,
        name : "辅王抑寇",
        type : "指挥",
        odd : 100,
        range : 6,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let value = 22 + Math.floor((q.GetAttr(self,"谋略")-80)*0.18);
            let adValue = 11;
            let tactValue = 13;
            let decline_value = 7 + Math.floor((q.GetAttr(self,"谋略")-80)*0.02);

            let fri = a.SelectTarget(self,6,true,false,3,true,false,this);
            let enemy = a.SelectTarget(self,6,false,true,3,false,false,this);
            let res = [self.ally[1],self.ally[2]];
            //减伤
            for(let i in res){
                for(let j=0;j<2;j++){
                    let block = new DamageBlock(self,this,"主动",false,-value,true,false,4,0);
                    block.Init(res[i],null,false);
                }
                let r = new Report();
                r.InitExecuteReport(2,res[i],`受到下2次主动战法伤害降低${value}%`);
            }
            //我军主动战法后的效果
            let action1 = function(){
                let target = this.beExecuted;
                for(let i in target.ATTR){
                    if(target.ATTR[i].tactics == that && target.ATTR[i].attrType == '攻击'){
                        target.ATTR[i].value += adValue;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【辅王抑寇】令`,`攻击属性提高${target.ATTR[i].value}(${q.GetAttr(target,'攻击')})`);
                    }
                    else if(target.ATTR[i].tactics == that && target.ATTR[i].attrType == '谋略'){
                        target.ATTR[i].value += tactValue;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【辅王抑寇】令`,`谋略属性提高${target.ATTR[i].value}(${q.GetAttr(target,'谋略')})`);
                    }
                }
            }
            //敌军主动战法后的效果
            let action2 = function(){
                let target = this.beExecuted;
                //找到对应的块
                for(let i in target.ATTR){
                    let realValue = decline_value;
                    if(target.ATTR[i].tactics == that && target.ATTR[i].attrType == '防御'){
                        //避免属性扣成负数
                        let targetDe = q.GetAttr(target,'防御');
                        if(targetDe < realValue) realValue = targetDe;
                        target.ATTR[i].value -= realValue;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【辅王抑寇】令`,`防御属性下降${Math.abs(target.ATTR[i].value)}(${q.GetAttr(target,'防御')})`);
                    }
                    else if(target.ATTR[i].tactics == that && target.ATTR[i].attrType == '速度'){
                        //避免属性扣成负数
                        let targetSpeed = q.GetAttr(target,'速度');
                        if(targetSpeed < realValue) realValue = targetSpeed;
                        target.ATTR[i].value -= realValue;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【辅王抑寇】令`,`速度属性下降${Math.abs(target.ATTR[i].value)}(${q.GetAttr(target,'速度')})`);
                    }
                }
            }
            for(let i in fri){
                let block = new ActiveTacticsBlock(self,this,action1,99,0,true);
                let work =  block.Init(fri[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,fri[i],`【辅王抑寇】效果已施加`);
                    let block1 = new AttrBlock(self,this,'攻击',0,99);
                    block1.Init(fri[i],false);
                    let block2 = new AttrBlock(self,this,'谋略',0,99);
                    block2.Init(fri[i],false);
                }
            }
            for(let i in enemy){
                let block = new ActiveTacticsBlock(self,this,action2,99,0,true);
                let work =  block.Init(enemy[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,enemy[i],`【辅王抑寇】效果已施加`);
                    let block1 = new AttrBlock(self,this,'防御',0,99);
                    block1.Init(enemy[i],false);
                    let block2 = new AttrBlock(self,this,'速度',0,99);
                    block2.Init(enemy[i],false);
                }
            }



            this.STATISTICS.times += 1;

        }
    },
    //荀攸
    {
        id : 79,
        name : "谋主",
        type : "主动",
        odd : 40,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let u = new Util();
            let odd = 50;//触发几率

            //找到兵力最多的和最少的
            let most = self;
            let lest = self;
            for(let i in self.ally){
                if(self.ally[i].hp < lest.hp) lest = self.ally[i];
                else if(self.ally.hp == lest.hp){
                    let rand = Math.floor(Math.random()*2);
                    if(rand == 1) lest = self.ally[i];
                }
                if(self.ally[i].hp > most.hp) most = self.ally[i];
                else if(self.ally[i].hp == most.hp){
                    let rand = Math.floor(Math.random()*2);
                    if(rand == 1) most = self.ally[i];
                }
            }
            //清除异常效果
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,3,true,true,this);
            for(let i in res){
                let work = u.ClearStatus(res[i],this,false,3);
                if(!work){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`没有效果可以移除`);
                }
            }
            //进入洞察
            let action = function(){
                let realOdd = q.GetMoraleOdd(most,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block1 = new StatusBlock(self,that,'洞察',1,0);
                    let work1 =  block1.Init(most,false);
                    if(work1){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,most,`【谋主】令`,`进入洞察`);
                    }
                    let block2 = new StatusBlock(self,that,'先手',1,0);
                    let work2 =  block2.Init(most,false);
                    if(work2){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,most,`【谋主】令`,`获得先手`);
                    }
                }
            }
            //检查是否存在谋主效果
            let work1 = true;
            for(let i in most.EXECUTE){
                if(most.EXECUTE[i].tactics == this){
                    let r = new Report();
                    r.InitExecuteReport(3,most,'已存在【谋主】洞察，先手效果');
                    work1 = false;
                    break;
                }
            }
            if(work1){
                let block = new ExecuteBlock(self,this,'行动开始',action,2);
                block.Init(most,false);
                let r = new Report();
                r.InitExecuteReport(3,most,`【谋主】洞察，先手效果已施加`)
            }
            //兵力低的获得规避
            let dodgeFunction = function(){
                let realOdd = q.GetMoraleOdd(lest,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new DodgeBlock(self,this,99);
                    let work =  block.Init(lest,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,lest,`【谋主】令`,`规避下一次伤害`);
                    }
                }
            }
            let work2 = true;
            for(let i in lest.BEFORE_MAKE_DAMAGE){
                if(lest.BEFORE_MAKE_DAMAGE[i].tactics == this){
                    let r = new Report();
                    r.InitExecuteReport(3,most,'已存在【谋主】规避效果');
                    work2 = false;
                    break;
                }
            }
            if(work2){
                let block = new BeforeMakeDamageBlock(self,this,dodgeFunction,2,false);
                block.Init(lest);
                let r = new Report();
                r.InitExecuteReport(3,lest,`【谋主】规避效果已施加`)
            }
            this.STATISTICS.times += 1;
        }
    },
    //许褚
    {
        id : 80,
        name : "虎痴嗔怒",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let index = q.GetAdditionValue(self,37,'攻击',0.06);//属性降低比例
            let damageValue = 300;//伤害率
            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            if(res == []) return;
            let target = res[0];
            let maxAd = q.GetAttr(target,'攻击');
            for(let i in res){
                let ad = q.GetAttr(res[i],'攻击');
                if(ad > maxAd){
                    target = res[i];
                    maxAd = ad;
                }
                else if(ad == maxAd){
                    let rand = Math.floor(Math.random()*2);
                    if(rand == 1){
                        target = res[i];
                        maxAd = ad; 
                    }
                }
            }
            for(let i=0;i<3;i++){
                let attrType = "";
                let value = 0;
                switch(i){
                    case 0 : attrType = '攻击';value = Math.floor(q.GetAttr(target,'攻击') * index/100);break;
                    case 1 : attrType = '防御';value = Math.floor(q.GetAttr(target,'防御') * index/100);break;
                    case 2 : attrType = '谋略';value = Math.floor(q.GetAttr(target,'谋略') * index/100);break;
                }
                let block = new AttrBlock(self,this,attrType,-value,2);
                let work =  block.Init(target,false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(3,target,`${attrType}属性降低了${index}%(${value})(${q.GetAttr(target,attrType)})`)
                }
            }
            let damage = c.CountDamage(self,target,damageValue,'攻击',false,this,null);
            a.GetHurt(self,target,damage,false,'攻击',this,true,null);

            this.STATISTICS.times += 1;
        }
    },
    //王朗
    {
        id : 101,
        name : "言出必克",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let value = q.GetAdditionValue(self,40,'谋略',0.14);
            let odd = 60;

            let action = function(){
                let target = this.beExecuted;
                if(this.damageType == '谋略'){
                    //分摊伤害
                    if(self[`言出必克分摊伤害${self.position}`] == 1){
                        self[`言出必克分摊伤害${self.position}`] = 0;
                        let damage = Math.floor(0.5*this.damageValue);
                        target.hp += damage;
                        let r = new Report().InitTwoTargetReport(3,self,target,`为`,`分摊伤害，损失了${damage}兵力(${self.hp-damage})`);
                        a.GetHurt(this.damageFromWho,self,damage,this.isCommonAttack,this.damageType,this.damageFromTactics,false,r);
                        
                    }
                    for(let i in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i][`言出必克${self.position}`] > 0){
                            target.DAMAGE_VAR[i].value += Math.floor(value/8);
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,target,`【言出必克】令`,`受到策略伤害降低${Math.abs(target.DAMAGE_VAR[i].value)}%`);
                            target.DAMAGE_VAR[i][`言出必克${self.position}`]--;
                            if(target.DAMAGE_VAR[i][`言出必克${self.position}`] == 0){
                                let r = new Report();
                                r.InitExecuteReport(3,target,`来自【言出必克】受到策略伤害降低效果消失了`)
                            }
                        }
                    }
                }       
            }
            for(let i in self.ally){
                let target = self.ally[i];
                let block = new DamageBlock(self,this,'谋略',false,-value,false,false,99,0);
                block[`言出必克${self.position}`] = 8;
                block.Init(target,null,true,2);
                let block2 = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                block2.Init(target,'言出必克')
            }
            //分摊伤害
            let divide = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    self[`言出必克分摊伤害${self.position}`] = 1;
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,self,`【言出必克】令`,`为友军分摊伤害`)
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【言出必克】没有生效，生效几率为${realOdd}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',divide,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //郭皇后
    {
        id : 103,
        name : "文德椒房",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let value = q.GetAdditionValue(self,10,'谋略',0.08);//增伤幅度
            let time = 3;//叠加上限
            
            let tag = '文德椒房' + self.belong + self.position;
            let action = function(){
                //每回合第一次施放主动战法
                if(self[tag]){
                    let r = new Report();
                    r.InitExecuteReport(3,self,`执行【文德椒房】`);
                    let res = a.SelectTarget(self,2,true,false,2,true,true,that);
                    for(let i in res){
                        //检查是否已存在文德椒房增伤
                        let work = true;
                        for(let j in res[i].DAMAGE_VAR){
                            if(res[i].DAMAGE_VAR[j].tactics == that && res[i].DAMAGE_VAR[j][tag] < time){
                                res[i].DAMAGE_VAR[j].value += value;
                                res[i].DAMAGE_VAR[j][tag]++;
                                let r = new Report();
                                r.InitExecuteReport(3,res[i],`策略伤害提高${res[i].DAMAGE_VAR[j].value}%`);
                                work = false;
                                break;
                            }
                        }
                        if(work){
                            let block = new DamageBlock(self,that,'谋略',true,value,false,false,99,0);
                            block[tag] = 1;
                            block.Init(res[i],null,true,3);
                        }
                    }
                    self[tag] = false;
                }
            }
            let block = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
            block.Init(self,false);
            self[tag] = true;
            let action1 = function(){
                self[tag] = true;
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action1,99);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【文德椒房】效果已施加`);

            this.STATISTICS.times += 1;
        }
    },
    //sp夏侯渊
    {
        id : 5033,
        name : "定军绝战",
        type : "主动",
        odd : 120,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = 140;
            if(self.driver.version == '平衡'){
                damageValue = 150;
            }

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,this,null);
                a.GetHurt(self,res[i],damage,false,'攻击',this,true,null);
            }
            this.STATISTICS.times += 1;

        }
    },
]

export default weiBArr;