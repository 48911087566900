import Report from "../js/report";
import Query from "../js/query";
class RangeBlock{
    constructor(applicator,tactics,isCommonAttack,value,duration,waitTime){
        //施加该块的武将
        this.applicator = applicator;
        //施加该战法的类型
        this.tactics = tactics;
        //是普通攻击增加或减少距离,否则就是战法增加或减少距离
        this.isCommonAttack = isCommonAttack;
        //数值
        this.value = value;
        //持续时间
        this.duration = duration
        //等待时间
        this.waitTime = waitTime;
        //是否增益
        this.isBenefit = value > 0 ? true : false;
    }
    Init(target,needReport = true,reportLevel = 3,isRepeat = false){
        //冲突检测
        if(!isRepeat){
            for(let i in target.RANGE){
                if(target.RANGE[i].tactics.type == this.tactics.type
                &&target.RANGE[i].isCommonAttack == this.isCommonAttack){
                    let text = "";
                    if(this.isCommonAttack) text = "已存在攻击距离";
                    else text = "已存在战法距离";
                    let report = new Report();
                    if(this.value > 0 && target.RANGE[i].value > 0){
                        text += "提升效果";
                        report.InitExecuteReport(3,target,text);
                        return false;
                    }
                    else if(this.value < 0 && target.RANGE[i].value < 0){
                        text += "降低效果";
                        report.InitExecuteReport(3,target,text);
                        return false;
                    }
                }
            }
        }
        
        target.RANGE.push(this);
        if(needReport){
            let q = new Query();
            let text = "";
            let rangNum = 0;
            if(this.isCommonAttack){
                text = "攻击距离";
                rangNum = q.GetRealAdRange(target);
            }
            else{
                text = "战法距离";
            }
            if(this.value > 0) text += this.isCommonAttack ? `提高${this.value}(${rangNum})` : `提升${this.value}` ;
            else text += this.isCommonAttack ? `降低${Math.abs(this.value)}(${rangNum})` : `提高${Math.abs(this.value)}` ;
            let report = new Report();
            report.InitExecuteReport(reportLevel,target,text);
        }
        return true;
    }
}

export default RangeBlock;