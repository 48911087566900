

class Tactics{
    constructor(){
        this.id = -1;
        this.name = "";
        this.quality = "";
        this.type = "";
        this.describe = "";
        this.odds = "";
        this.range = "";
        this.arms = [];
        this.tgt_number = "";
        this.sign = "";
    }
    LoadData(data){
        switch(data.type){
            case "command" : this.id = data.id; break;
            case "active" : this.id = data.id + 1000; break;
            case "combo" : this.id = data.id + 2000; break;
            case "passive" : this.id = data.id + 3000; break;
        }
        
        this.name = data.name;
        this.quality = data.quality;
        this.type = data.type;
        this.describe = data.describe;
        this.odds = data.odds;
        this.range = data.range;
        this.arms = data.arms;
        this.tgt_number = data.tgt_number ? data.tgt_number : "";
        this.sign = data.sign;
        console.log('data,odds',data.odds);
    }
    CopyData(data){
        this.id = data.id;
        this.name = data.name;
        this.quality = data.quality;
        this.type = data.type;
        this.describe = data.describe;
        this.odds = data.odds;
        this.range = data.range;
        this.arms = data.arms;
        this.tgt_number = data.tgt_number;
        this.sign = data.sign;
    }
}

class Warrior{
    constructor(){
        //等级
        this.level = 50;
        //红度
        this.red = 0;
        //ID
        this.id = -1;
        //阵营
        this.camp = "";
        //名字
        this.name = "";
        //统帅
        this.cost = "-";
        //性别
        this.gender = "";
        //兵种
        this.arms = "";
        //攻击范围
        this.range = "";
        //进阶兵种
        this.up_arms = [];
        //兵种特性选择则
        this.arms_character_chose = [-1,-1];
        //士气
        this.moral = 100;
        //战法
        this.tactics_name = "";
        this.tactics_type = "";
        this.tactics_odds = "";
        this.tactics_quality = "";
        this.tactics_range = "";
        this.tactics_describe = "";
        //属性
        this.attr = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
            demolition : 0,
        }
        this.grow = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
            demolition : 0,
        }
        //加点属性
        this.plus = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
        }
        
        //标记
        this.sign = "";
        //图像
        this.img = {
            body : "",
            preview : "",
            head : "",
            banner : "",
        }
        //原画
        this.originSkin = "";
        
        this.choseArms = 0;

        //是否拥有
        this.own = false;
        //是否展示
        this.isShow = true;

        //战法队列
        this.tacticsArr = [
            {} = new Tactics(),
            {} = new Tactics(),
        ]
    }
    GetWarriorData(data){
        console.log('data',data);
        //ID
        this.id = data.id;
        //阵营
        this.camp = data.camp;
        //名字
        this.name = data.name;
        //统帅
        this.cost = data.cost;
        //性别
        this.gender = data.gender;
        //兵种
        this.arms = data.arms;
        //攻击范围
        this.range = data.range;
        //进阶兵种
        this.up_arms = data.up_arms;
        //战法
        this.tactics_name = data.tactics_name;
        this.tactics_type = data.tactics_type;
        this.tactics_odds = data.tactics_odds;
        this.tactics_quality = data.tactics_quality;
        this.tactics_range = data.tactics_range;
        this.tactics_describe = data.tactics_describe;
        //属性
        this.attr = {
            ad : data.attr.ad,
            de : data.attr.de,
            tact : data.attr.tact,
            speed : data.attr.speed,
            demolition : data.attr.demolition,
        }
        this.grow = {
            ad : data.grow.ad,
            de : data.grow.de,
            tact : data.grow.tact,
            speed : data.grow.speed,
            demolition : data.grow.demolition,
        }
        //加点属性
        this.plus = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
        }
        //标记
        this.sign = data.sign;
        //图像
        this.img = {
            body : data.img.body,
            preview : data.img.preview,
            head : data.img.head,
            banner : data.img.banner,
        }
        this.originSkin = data.originSkin;
        
        this.choseArms = 0;
        //红度
        this.red = 0;
    }
    //从配将队伍列表中读取武将数据
    GetMyTeamWarriorData(data){
        //等级
        this.level = 50;
        //红度
        this.red = data.red;
        //ID
        this.id = data.id;
        //阵营
        this.camp = data.camp;
        //名字
        this.name = data.name;
        //统帅
        this.cost = data.cost;
        //性别
        this.gender = data.gender;
        //兵种
        this.arms = data.arms;
        //攻击范围
        this.range = data.range ? data.range : 0;
        //进阶兵种
        this.up_arms = data.up_arms;
        //兵种特性选择则
        this.arms_character_chose = [-1,-1];
        //士气
        this.moral = 100;
        //战法
        this.tactics_name = data.mainTactics.name;
        this.tactics_type = data.mainTactics.type;
        this.tactics_odds = data.mainTactics.odds;
        this.tactics_quality = data.mainTactics.quality;
        this.tactics_range = data.tactics_range ? data.tactics_range : "???";
        this.tactics_describe = data.mainTactics.describe;
        //属性
        this.attr = {
            ad : data.attr.ad,
            de : data.attr.de,
            tact : data.attr.tact,
            speed : data.attr.speed,
            demolition : data.attr.demolition,
        }
        this.grow = {
            ad : data.grow.ad,
            de : data.grow.de,
            tact : data.grow.tact,
            speed : data.grow.speed,
            demolition : data.grow.demolition,
        }
        //加点属性
        this.plus = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
        }
        
        //标记
        this.sign = data.sign;
        //图像
        this.img = {
            body : data.img.body,
            preview : data.img.preview,
            head : data.img.head,
            banner : data.img.banner,
        }
        //原画
        this.originSkin = data.originSkin;
        
        this.choseArms = 0;

        //是否拥有
        this.own = true;
        //是否展示
        this.isShow = true;
    }
    CopyData(data){
        //等级
        this.level = data.level;
        //红度
        this.red = data.red;
        //ID
        this.id = data.id;
        //阵营
        this.camp = data.camp;
        //名字
        this.name = data.name;
        //统帅
        this.cost = data.cost;
        //性别
        this.gender = data.gender;
        //兵种
        this.arms = data.arms;
        //攻击范围
        this.range = data.range;
        //进阶兵种
        this.up_arms = data.up_arms;
        //兵种特性选择则
        this.arms_character_chose = data.arms_character_chose;
        //士气
        this.moral = data.moral;
        //战法
        this.tactics_name = data.tactics_name;
        this.tactics_type = data.tactics_type;
        this.tactics_odds = data.tactics_odds;
        this.tactics_quality = data.tactics_quality;
        this.tactics_range = data.tactics_range;
        this.tactics_describe = data.tactics_describe;
        //属性
        this.attr = {
            ad : data.attr.ad,
            de : data.attr.de,
            tact : data.attr.tact,
            speed : data.attr.speed,
            demolition : data.attr.demolition,
        }
        this.grow = {
            ad : data.grow.ad,
            de : data.grow.de,
            tact : data.grow.tact,
            speed : data.grow.speed,
            demolition : data.grow.demolition,
        }
        //加点属性
        this.plus = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
        }
        //标记
        this.sign = data.sign;
        //图像
        this.img = {
            body : data.img.body,
            preview : data.img.preview,
            head : data.img.head,
            banner : data.img.banner,
        }
        //原画
        this.originSkin = data.originSkin;
        
        this.choseArms = data.choseArms;

        //是否拥有
        this.own = data.own;
        //是否展示
        this.isShow = data.isShow;

        //战法队列
        this.tacticsArr = [
            {} = new Tactics(),
            {} = new Tactics(),
        ]
        for(let i in this.tacticsArr){
            this.tacticsArr[i].CopyData(data.tacticsArr[i]);
        }
    }
    
}




class Team{
    constructor(vue){
        this.vue = vue;
        //武将队列
        this.warriorArr = [
            {} = new Warrior(),
            {} = new Warrior(),
            {} = new Warrior(),
        ];
    }
    //返回武将空的武将队列
    ReturnEmptyTeam(){
        let res = [
            {} = new Warrior(),
            {} = new Warrior(),
            {} = new Warrior(),
        ];
        return res;
    }
    //返回新的武将队列
    ReturnNewTeam(arr){
        let res = [
            {} = new Warrior(),
            {} = new Warrior(),
            {} = new Warrior(),
        ];
        for(let i in res){
            res[i].GetWarriorData(arr[i]);
            for(let j in res[i].tacticsArr){
                res[i].tacticsArr[j].CopyData(arr[i].tacticsArr[j]);
            }
        }
        return res;
    }
    //移除一个武将
    RemoveWarrior(target){
        //等级
        target.level = 50;
        //红度
        target.red = 0;
        //ID
        target.id = -1;
        //阵营
        target.camp = "";
        //名字
        target.name = "";
        //统帅
        target.cost = "-";
        //性别
        target.gender = "";
        //兵种
        target.arms = "";
        //攻击范围
        target.range = "";
        //进阶兵种
        target.up_arms = [];
        //兵种特性选择则
        target.arms_character_chose = [-1,-1];
        //士气
        target.moral = 100;
        //战法
        target.tactics_name = "";
        target.tactics_type = "";
        target.tactics_odds = "";
        target.tactics_quality = "";
        target.tactics_range = "";
        target.tactics_describe = "";
        //属性
        target.attr = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
            demolition : 0,
        }
        target.grow = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
            demolition : 0,
        }
        //加点属性
        target.plus = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
        }
        //标记
        target.sign = "";
        //图像
        target.img = {
            body : "",
            preview : "",
            head : "",
            banner : "",
        }
        target.originSkin = "";
        
        target.choseArms = 0;

        //是否拥有
        target.own = false;
        //是否展示
        target.isShow = true;

        //战法队列
        target.tacticsArr = [
            {} = new Tactics(),
            {} = new Tactics(),
        ]
    }
    //移除一个战法
    RemoveTactics(target){
        target.id = -1;
        target.name = "";
        target.quality = "";
        target.type = "";
        target.describe = "";
        target.odds = "";
        target.range = "";
        target.arms = [];
        target.tgt_number = "";
        target.sign = "";
    }
}


export default Team;