import localForage from 'localforage';
import warriorsData from '../data/warrior.json';
import spWarriorData from '../data/special.json'
import guardWarriorData from '../data/guard.json';
//导入战法
import command_tact from '../data/command.json';
import active_tact from '../data/active.json';
import combo_tact from '../data/combo.json';
import passive_tact from '../data/passive.json';

import configTeam from "./configTeam.js";
//app的核心武将对象
class Item{
    constructor(data){
        //ID
        this.id = data.id;
        //阵营
        this.camp = data.camp;
        //名字
        this.name = data.name;
        //统帅
        this.cost = data.cost;
        //性别
        this.gender = data.gender;
        //兵种
        this.arms = data.arms;
        //攻击范围
        this.range = data.range;
        //进阶兵种
        this.up_arms = data.up_arms;
        //战法
        this.tactics_name = data.tactics_name;
        this.tactics_type = data.tactics_type;
        this.tactics_odds = data.tactics_odds;
        this.tactics_quality = data.tactics_quality;
        this.tactics_range = data.tactics_range ? data.tactics_range : "?";
        this.tactics_describe = data.tactics_describe;
        //属性
        this.attr = {
            ad : data.attr.ad,
            de : data.attr.de,
            tact : data.attr.tact,
            speed : data.attr.speed,
            demolition : data.attr.demolition,
        }
        this.grow = {
            ad : data.grow.ad,
            de : data.grow.de,
            tact : data.grow.tact,
            speed : data.grow.speed,
            demolition : data.grow.demolition,
        }
        //标记
        this.sign = data.sign;
        //皮肤
        this.skin = [];
        // this.skin.unshift(data.img);
        //图像
        this.img = {
            body : require(`../static/card_0/${data.img}`),
            preview : require(`../static/card_1/${data.img}`),
            head : require(`../static/card_2/${data.img}`),
            banner : require(`../static/card_3/${data.img}`),
            post : require(`../static/card_4/${data.img}`)
        }
        //是否觉醒
        this.isUp = false;
        //是否深造
        this.isUpWork = false;
        //是否进阶兵种
        this.isUpArms = false;
        this.choseArms = -1;
        //红度
        this.red = 0;
        //是否拥有
        this.own = false;
        //是否展示
        this.isShow = true;
        //当前选择的画像
        this.skinChose = 0;//0表示选择原画
        //原画
        this.originSkin = data.img;
    }
    GetSkinArr(warriorsData){
        this.skin.push(warriorsData.img);
        for(let i in warriorsData.skin){
            let newStr = warriorsData.skin[i];
            this.skin.push(newStr);
        }
    }
    //切换画像
    ChangePortrayal(index){
        let tgt = this.skin[index];
        this.skinChose = index;
        //图像
        this.img = {
            body : require(`../static/card_0/${tgt}`),
            preview : require(`../static/card_1/${tgt}`),
            head : require(`../static/card_2/${tgt}`),
            banner : require(`../static/card_3/${tgt}`),
            post : require(`../static/card_4/${tgt}`)
        }
    }
}
class Tact{
    constructor(data,type){
        this.id = data.id;
        this.name = data.name;
        this.quality = data.quality;
        this.type = type;
        this.describe = data.describe;
        this.odds = data.odds;
        this.range = data.range;
        this.arms = data.arms;
        this.tgt_number = data.tgt_number;
        this.sign = data.sign;
        //是否锁住
        this.lock = true;
    }
}
class User{
    constructor(name,time){
        this.uuid = 0;
        this.name = name;
        this.time = time;
        //宝物容器
        this.weapon = [];
        //武将的容器
        this.WarriorsStorage = [];
        //战法数据
        this.TacticsStorage = [];
        //配将存储
        this.TeamStorage = [];
        //描述
        this.describe = "";
    }
    getUUID(){
        this.uuid = Math.floor(Math.random()*999999) + Math.floor(Math.random()*999999);
    }
}


class Load{
    constructor(vue){
        this.vue = vue;
    }
    //总的初始化
    Init(){
        return new Promise(async (resolve,reject)=>{
            //初始化数据库
            await this.InitStorage();
            this.InitAllWarriors();
            this.InitTactics();
            this.LoadWarriorData();
            this.LoadTacticsData();
            this.InitMyTeam();
            await this.LoadMyTeam();
            await this.LoadBattleLineup();
            resolve(true);
        })
        
    }
    //添加一个新的存档
    AddNewAccount(){
        let date = new Date();
        let data_str = `${date.getFullYear()} ${date.getMonth()+1}/${date.getDate()}`
        let user = new User(`存档${this.vue.$store.state.App.userList.length + 1}`,data_str);
        user.getUUID();
        this.vue.$store.state.App.userList.push(user);
        return true;
    }
    //数据库
    InitStorage(){
        return new Promise(async (resolve,reject)=>{
            let res = await localForage.getItem(this.vue.$store.state.DATABASE_NAME);
            if(res == null){
                //如果是第一次登录app，则创建该app的数据库，使用默认用户
                let date = new Date();
                let data_str = `${date.getFullYear()} ${date.getMonth()+1}/${date.getDate()}`
                let user = new User("默认用户",data_str);
                user.getUUID();
                let app = {
                    current_user_uuid : null,//用来指定使用哪个用户
                    userList : [],
                }
                app.userList.push(user);
                app.current_user_uuid = user.uuid;
                localForage.setItem(this.vue.$store.state.DATABASE_NAME,app);
                this.vue.$store.state.App = app;
                this.vue.$store.state.User = user;
            }
            else{
                //否则直接导入数据
                this.vue.$store.state.App = res;
                for(let i = 0;i<res.userList.length;i++){
                    if(res.userList[i].uuid == res.current_user_uuid){
                        this.vue.$store.state.User = res.userList[i];
                        break;
                    }
                }
            }
            resolve(true);
        })
    }
    //初始化武将
    InitAllWarriors(){
        this.vue.$store.state.AllWarriors = [];
        let tempArr = [];
        let qunGongArr = [];let qunBuArr = [];let qunQiArr = [];
        let weiGongArr = [];let weiBuArr= [];let weiQiArr = [];
        let shuGongArr = [];let shuBuArr= [];let shuQiArr = [];
        let wuGongArr = [];let wuBuArr= [];let wuQiArr = [];
        let hanGongArr = [];let hanBuArr= [];let hanQiArr = [];
        let jinGongArr = [];let jinBuArr = [];let jinQiArr = [];

        

        for(let i in warriorsData){
             console.log(warriorsData[i].skin,i);
            let newItem = new Item(warriorsData[i]);
            newItem.GetSkinArr(warriorsData[i]);
            if(newItem.camp == '群'){
                switch(newItem.arms){
                    case '弓' : qunGongArr.unshift(newItem); break;
                    case '步' : qunBuArr.unshift(newItem); break;
                    case '骑' : qunQiArr.unshift(newItem); break;
                }
            }
            else if(newItem.camp == '魏'){
                switch(newItem.arms){
                    case '弓' : weiGongArr.unshift(newItem); break;
                    case '步' : weiBuArr.unshift(newItem); break;
                    case '骑' : weiQiArr.unshift(newItem); break;
                }
            }
            else if(newItem.camp == '蜀'){
                switch(newItem.arms){
                    case '弓' : shuGongArr.unshift(newItem); break;
                    case '步' : shuBuArr.unshift(newItem); break;
                    case '骑' : shuQiArr.unshift(newItem); break;
                }
            }
            else if(newItem.camp == '吴'){
                switch(newItem.arms){
                    case '弓' : wuGongArr.unshift(newItem); break;
                    case '步' : wuBuArr.unshift(newItem); break;
                    case '骑' : wuQiArr.unshift(newItem); break;
                }
            }
            else if(newItem.camp == '汉'){
                switch(newItem.arms){
                    case '弓' : hanGongArr.unshift(newItem); break;
                    case '步' : hanBuArr.unshift(newItem); break;
                    case '骑' : hanQiArr.unshift(newItem); break;
                }
            }
            else if(newItem.camp == '晋'){
                switch(newItem.arms){
                    case '弓' : jinGongArr.unshift(newItem); break;
                    case '步' : jinBuArr.unshift(newItem); break;
                    case '骑' : jinQiArr.unshift(newItem); break;
                }
            }
        }
        //排序
        tempArr = qunGongArr.concat(qunBuArr,qunQiArr,weiGongArr,weiBuArr,weiQiArr,shuGongArr,shuBuArr,shuQiArr,wuGongArr,wuBuArr,wuQiArr,hanGongArr,hanBuArr,hanQiArr,jinGongArr,jinBuArr,jinQiArr);
        tempArr.sort((a,b)=>{
            return b.cost - a.cost;
        })

        
        this.vue.$store.state.AllWarriors = tempArr;
        //插入sp武将
        for(let i = 0;i<this.vue.$store.state.AllWarriors.length;i++){
            for(let j in spWarriorData){
                if(this.vue.$store.state.AllWarriors[i].id == (spWarriorData[j].id - 5000)){
                    let newItem = new Item(spWarriorData[j]);
                    newItem.GetSkinArr(spWarriorData[j]);
                    this.vue.$store.state.AllWarriors.splice(i,0,newItem);
                    i+=1;
                    break;
                }
            }
        }
        //插入侍卫
        for(let i =0;i<guardWarriorData.length;i++){
            let newItem = new Item(guardWarriorData[i]);
            newItem.GetSkinArr(guardWarriorData[i]);
            this.vue.$store.state.AllWarriors.push(newItem);
        }
    }
    //初始化战法
    InitTactics(){
        this.vue.$store.state.Tactics = {
            command : [],
            active : [],
            combo : [],
            passive : [],
        }
        let commandB = []; let commandA = []; let commandS = [];
        let activeB = []; let activeA = []; let activeS = [];
        let comboB = []; let comboA = []; let comboS = [];
        let passiveB = []; let passiveA = []; let passiveS = [];
        for(let j=0;j<4;j++){
            let tgtArr = [];
            let str = "";
            switch(j){
                case 0 : tgtArr = command_tact;str = "command";break;
                case 1 : tgtArr = active_tact;str = "active";break;
                case 2 : tgtArr = combo_tact;str = "combo";break;
                case 3 : tgtArr = passive_tact;str = "passive";break;
            }
            for(let i in tgtArr){
                let item = new Tact(tgtArr[i],str);
                if(item.quality == "S"){
                    if(j == 0) commandS.push(item);
                    else if(j == 1) activeS.push(item);
                    else if(j == 2) comboS.push(item);
                    else if(j == 3) passiveS.push(item);
                }
                else if(item.quality == 'A'){
                    if(j == 0) commandA.push(item);
                    else if(j == 1) activeA.push(item);
                    else if(j == 2) comboA.push(item);
                    else if(j == 3) passiveA.push(item);
                }
                else{
                    if(j == 0) commandB.push(item);
                    else if(j == 1) activeB.push(item);
                    else if(j == 2) comboB.push(item);
                    else if(j == 3) passiveB.push(item);
                }
                [str].push(item);
            }
        }
        this.vue.$store.state.Tactics.command = commandS.concat(commandA,commandB);
        this.vue.$store.state.Tactics.active = activeS.concat(activeA,activeB);
        this.vue.$store.state.Tactics.combo = comboS.concat(comboA,comboB);
        this.vue.$store.state.Tactics.passive = passiveS.concat(passiveA,passiveB);
    }
    //加载数据库武将数据到app
    LoadWarriorData(){
        if(this.vue.$store.state.User.WarriorsStorage.length == 0) return;
        let p = 0;
        for(let i in this.vue.$store.state.AllWarriors){
            if(p < this.vue.$store.state.User.WarriorsStorage.length && this.vue.$store.state.AllWarriors[i].id == this.vue.$store.state.User.WarriorsStorage[p].id){
                this.vue.$store.state.AllWarriors[i].own = true;
                this.vue.$store.state.AllWarriors[i].red = this.vue.$store.state.User.WarriorsStorage[p].red,
                this.vue.$store.state.AllWarriors[i].isUp = this.vue.$store.state.User.WarriorsStorage[p].isUp,
                this.vue.$store.state.AllWarriors[i].isUpWork = this.vue.$store.state.User.WarriorsStorage[p].isUpWork,
                this.vue.$store.state.AllWarriors[i].isUpArms = this.vue.$store.state.User.WarriorsStorage[p].isUpArms,
                this.vue.$store.state.User.WarriorsStorage[p].skinChose != 0 ? this.vue.$store.state.AllWarriors[i].ChangePortrayal(this.vue.$store.state.User.WarriorsStorage[p].skinChose) : null;
                p++;
            }
        }
    }
    //加载数据库战法数据到app
    LoadTacticsData(){
        if(this.vue.$store.state.User.TacticsStorage.length == 0) return;
        let p = 0;
        for(let j =0;j<4;j++){
            let item = "";
            switch(j){
                case 0 : item = "command" ;break;
                case 1 : item = "active";break;
                case 2 : item = "combo";break;
                case 3 : item = 'passive';break;
            }
            for(let i in this.vue.$store.state.Tactics[item]){
                if(p < this.vue.$store.state.User.TacticsStorage.length && this.vue.$store.state.Tactics[item][i].name == this.vue.$store.state.User.TacticsStorage[p]){
                    this.vue.$store.state.Tactics[item][i].lock = false;
                    p++;
                }
            }
        }
        
    }
    //初始化配将队伍结构
    InitMyTeam(){
        let cfg = new configTeam(this.vue);
        cfg.Init();
    }
    //加载配將队伍到面板
    LoadMyTeam(){
        return new Promise(async (resolve,reject)=>{
            let res = await localForage.getItem(this.vue.$store.state.DATABASE_NAME + '_TEAM');
            console.log('teamData',res);
            let c = new configTeam(this.vue);
            this.vue.$store.state.MyTeam = [];
            for(let i =0;i<res.length;i++) c.ConfigOneTeam();
            //加载信息
            for(let i in res){
                for(let j in res[i]){
                    let tgt = res[i][j];
                    //遍历warrior列表，找到对应warrior
                    for(let k = 0;k<this.vue.$store.state.AllWarriors.length;k++){
                        //find it
                        if(tgt.id == this.vue.$store.state.AllWarriors[k].id){
                            this.vue.$store.state.MyTeam[i][j].GetData(this.vue.$store.state.AllWarriors[k]);
                            //同时加载战法数据
                            for(let n in tgt.tacticsArr){
                                if(tgt.tacticsArr[n].name != "" && tgt.tacticsArr[n].type != ""){
                                    let tacticsPool = ""; let tacticsStr = "";
                                    // let lockTacticsPool = [];
                                    switch(res[i][j].tacticsArr[n].type){
                                        case "command" : 
                                            tacticsPool = command_tact;tacticsStr = "command"; 
                                            // lockTacticsPool = this.vue.$store.state.Tactics.command; 
                                            break;
                                        case "active" : 
                                            tacticsPool = active_tact;tacticsStr = "active";
                                            // lockTacticsPool = this.vue.$store.state.Tactics.active;
                                            break;
                                        case "combo" : 
                                            tacticsPool = combo_tact;tacticsStr = "combo";
                                            // lockTacticsPool = this.vue.$store.state.Tactics.combo;
                                            break;
                                        case "passive" : 
                                            tacticsPool = passive_tact;tacticsStr = "passive";
                                            // lockTacticsPool = this.vue.$store.state.Tactics.passive;
                                            break;
                                    }
                                    for(let m in tacticsPool){
                                        if(tacticsPool[m].name == tgt.tacticsArr[n].name){
                                            this.vue.$store.state.MyTeam[i][j].LoadTactics(tacticsPool[m],n,tacticsStr) 
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            resolve(true);
        })
    }
    //加载对局模块阵容保护数据
    LoadBattleLineup(){
        return new Promise(async (resolve,reject)=>{
            let res = await localForage.getItem(this.vue.$store.state.DATABASE_NAME + '_Lineup');
            this.vue.$store.state.BorderChess.LineupContainer = res;
            resolve(true);
        })
    }
}

export default Load;