import Report from "../js/report";

//对最终伤害或者是治疗量*某数
class DamageCureDiscountBlock{
    constructor(applicator,tactics,output,isDamageOrCure,value,duration,waitTime,workTactics,isRatio){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //是输出还是接受
        this.output = output;
        //是治疗还是伤害
        this.isDamageOrCure = isDamageOrCure
        //数值
        this.value = value;
        //持续时间
        this.duration = duration;
        //等待事件
        this.waitTime = waitTime == undefined ? 0 : waitTime;
        //作用于哪个战法 (指定特定战法为其增伤减伤)
        this.workTactics = workTactics == undefined ? null : workTactics;
        //是否是增益
        this.isBenefit = false;
        //是否是百分比还是固定值
        this.isRatio = isRatio == undefined ? true : isRatio;
    }
    Init(target,needReport = false,reportLevel = 3){
        if(this.isDamageOrCure != '伤害' && this.isDamageOrCure != '治疗'){
            console.log('this.isDamageOrCure字段出错',this.isDamageOrCure);
            return;
        }
        if(this.value > 0) this.isBenefit = true;
        if(needReport){
            let r = new Report();
            let txt = ``
            r.InitExecuteReport(reportLevel,target,txt,true);
        }
        target.DISCOUNT.push(this);
    }
    //清除自己
    Clear(target){
        let temp = [];
        for(let i in target.DISCOUNT){
            if(target.DISCOUNT[i] != this){
                temp.push(target.DISCOUNT[i]);
            }
        }
        target.DISCOUNT = temp;
    }
}
export default DamageCureDiscountBlock;