<template>
    <div class="statistics_panel">
        <div class="head"></div>
        <div class="close_btn" ref="close_btn" @touchstart="$refs.close_btn.classList.add('click')"
        @touchend="$refs.close_btn.classList.remove('click')" @click="CloseThisPanel"></div>
        <div class="texture_branch">
            <div class="text">结 果 统 计</div>
        </div>
        
        <!-- 总体统计数据 -->
        <div class="container">
            <div class="title">
                <li>武将</li>
                <li>普通攻击</li>
                <li>战法伤害</li>
                <li>治疗量</li>
                <li>战法次数</li>
            </div>
            <div class="teams_container" v-for="(t,t_index) in $store.state.BorderChess.Statistics.dataMatrices" :key="t.index">
                <div class="warrior_info" v-for="w in t" :key="w.index">
                    <div class="warrior_head">
                        <div class="warrior_img_bg_blue" v-if="t_index == 0"></div>
                        <div class="warrior_img_bg_red" v-if="t_index == 1"></div>
                        <img :src="w.img.head" alt="" class="cover">
                    </div>
                    <div class="attack_damage">{{w.commonDamage}}</div>
                    <div class="tactics_damage">{{GetTacticsDamage(w)}}</div>
                    <div class="cure">{{GetTacticsCure(w)}}</div>
                    <div class="ratio">{{GetTacticsTimes(w)}}</div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name : 'Statistics',
    data(){
        return{

        }
    },
    methods:{
        //获取该武将造成的战法伤害
        GetTacticsDamage(target){
            let res = 0;
            for(let i in target.tacticsArr){
                res += target.tacticsArr[i].damage;
            }
            return res;
        },
        //获取战法治疗量
        GetTacticsCure(target){
            let res = 0;
            for(let i in target.tacticsArr){
                res += target.tacticsArr[i].cure;
            }
            return res;
        },
        //获取战法释放次数
        GetTacticsTimes(target){
            let res = 0;
            for(let i in target.tacticsArr){
                res += target.tacticsArr[i].times;
            }
            return res;
        },  
        //关闭本界面
        CloseThisPanel(){
            this.$store.state.BorderChess.isShowBattleStatistics = false;
        },
        //战法释放次数统计
        TacticsTime(w){
            let times = 0;
            for(let i=0;i<w.TACTICS_STACK.length;i++){
                times += w.TACTICS_STACK[i].statistics.times;
            }
            return times;
        }
    },
    mounted(){

    }
}
</script>

<style lang="less">
.statistics_panel{
    position: absolute; left: 0; top : 0;
    width : 100%; height : 100%;
    background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
    background-size: cover;
    z-index: 10;
    .texture_branch{
        left: 0; top: 12vh;
        margin: -12vw 0 0 0;
        width: 52vw;height: 11vw;
        background: url('../../assets/background/bg (5).png')no-repeat;
        background-size: 100% 100%;
        .text{
            width: 100%; height: 11vw;
            line-height: 11vw;
            font-size: 5.4vw;
            text-align: center;
            color: rgb(193, 193, 82);
            text-shadow: 0 0 0 2px black;
            text-indent: -15vw;
            transition: all .3s;
            font-family: 'FZLBJW';
        }
    }
    .head{
        left: 0; top: 0;
        width: 100vw;height: 12vh;
        background: url('../../assets/background/bg (8).png')rgb(21, 21, 21) no-repeat;
        background-size: 100% 100%;
    }
    .close_btn{
        right:7vw; top: 12vh;
        margin: -11vw 0 0 0;
        width: 7vw;
        height: 7vw;
        background: url('../../assets/ui/ui\ \(85\).png')no-repeat;
        background-size: 100% 100%;
    }
    .close_btn.click{
        transform: scale(.8);
    }
    .container{
        position: absolute; left: 0; top : 12vh;
        
        width: 100vw;height: 200vw;
        // background: #000;
        .title{
            left: 0; top: 0;
            width: 100vw;height: 10vw;
            background: rgba(0, 0, 0, 0.5);
            z-index: 1;
            li{
                position: relative;float: left;
                line-height: 10vw;
                width: 20vw;height: 10vw;
            }
        }
        .teams_container{
            position: relative;
            width: 100vw;height: 65vw;
            margin: 10vw 0 0 0;
            .warrior_info{
                position: relative;
                width: 100vw;height: 18vw;
                border-bottom:.2vw solid rgb(33, 33, 33) ;
                .warrior_head{
                    position: relative;float: left;
                    margin: 0 0 0 2vw;
                    width: 18vw;height: 18vw;
                    .warrior_img_bg_blue,.warrior_img_bg_red{
                        position: absolute; left: 50%; top : 50%;
                        transform : translate(-50%,-50%);
                        width: 66%;height: 66%;
                        background: rgb(162, 4, 4);
                        border-radius: 50%;
                    }
                    .warrior_img_bg_blue{
                        background: rgb(0, 84, 153);
                    }
                    img{
                        transform: scale(.6);
                        border-radius: 50%;
                    }
                }
                .attack_damage,.tactics_damage,.cure,.ratio{
                    position: relative;float: left;
                    width: 20vw;height: 18vw;
                    line-height: 18vw;
                }
            }
        }
    }
}
</style>