import Query from '../js/query.js'
import Count from '../js/count.js';
import Attack from '../js/attack.js';
import Report from '../js/report.js';
let comboS = [
    //乘胜追击
    {
        id : 10,
        name : "乘胜追击",
        type : "追击",
        odd : 30,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = 150;//基础伤害率
            let damageValue2 = 100;//第二段伤害
            let odd = 60;//触发几率
            let odd_decline = 20;//几率衰减
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);

            let time = 1;
            for(let i=0;i<time;i++){
                let realOdd = q.GetMoraleOdd(self,odd) - (time-1)*odd_decline;
                if(realOdd <= 0) return;
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let damage = c.CountDamage(self,target,damageValue2,"攻击",false,this,null);
                    a.GetHurt(self,target,damage,false,"攻击",this,true);
                    time += 1;
                }
            }
            
            
            this.STATISTICS.times += 1;
        }
    },
]

export default comboS;