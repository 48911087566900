import Report from "../js/report";
//状态类型块
class StatusBlock{
    constructor(applicator,tactics,type,duration,waitTime){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //状态类型
        this.type = type;
        //持续时间
        this.duration = duration;
        //是否是增益
        this.isBenefit = false;
        //等待时间
        this.waitTime = waitTime == undefined ? 0 : waitTime;
    }
    //初始化
    Init(target,needReport = true,reportLevel = 3){
        //target是被施加该块的对象
        switch(this.type){
            case "连击" : this.isBenefit = true; break;
            case "援护" : this.isBenefit = true; break;
            case "洞察" : this.isBenefit = true; break;
            case "先手" : this.isBenefit = true; break;
            case "无视兵种" : this.isBenefit = true; break;
            case "无视规避" : this.isBenefit = true; break;
            case "免疫怯战" : this.isBenefit = true; break;
            case "不受敌方指挥战法影响" : this.isBenefit = true; break;
        }
        //检查冲突
        for(let i in target.STATUS){
            //找到同类型效果，冲突
            if(target.STATUS[i].type == this.type && this.type != '无法普通攻击' && this.type != '无法施法'){
                let report = new Report();
                report.InitExecuteReport(3,target,`已存在${target.STATUS[i].type}效果`);
                return false;
            }
        }
        target.STATUS.push(this);
        if(needReport){
            let str = ``;
            if(this.type == '怯战' || this.type == '犹豫' || this.type == '混乱' || this.type == '暴走')str = `陷入${this.type}中`;
            else if(this.type == "围困")    str = `不可回复兵力`;
            else if(this.type == "无视兵种" || this.type == '无视规避' || this.type == '免疫怯战' || this.type == "连击")    str = `${this.type}效果已施加`;
            else if(this.type == "先手" || this.type == "洞察")    str = `获得${this.type}`;
            else if(this.type == '挑衅' || this.type == '援护') str = `受到${this.type}`
            else if(this.type == '不受指挥战法影响' || this.type == '不受敌方指挥战法影响') str = `${this.type}`;
            let report = new Report();
            report.InitExecuteReport(reportLevel,target,str);
        }
        return true;
    }
}

export default StatusBlock;