import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';
import ExecuteSysBlock from '../block/executeSys.js';
let commandB = [
    //胜兵求战
    {
        id : 7,
        name : "胜兵求战",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 80;//触发几率
            let value = 15;
            
            let action = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand  = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    for(let i in self.TacticsArr){
                        if(self.TacticsArr[i].type == '主动' && self.TacticsArr[i].wait > 0){
                            let lock = false;
                            //如果该战法已经存在在准备队列，则不进行加速
                            for(let j in self.WaitTacticsArr){
                                if(self.WaitTacticsArr[j].tactics == self.TacticsArr[i]){
                                    lock = true;
                                }
                            }
                            if(!lock){
                                let block = new OddBlock(self,that,self.TacticsArr[i],0,0,0,1);
                                let work = block.Init(self,false,3);
                                if(work){
                                    let r = new Report();
                                    r.InitTwoTargetReport(2,self,self,`【胜兵求战】令`,`【${self.TacticsArr[i].name}】跳过一回合准备`)
                                }
                            }
                            
                        }
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【胜兵求战】没有生效，生效几率为${realOdd}%`);
                }
            }
            let activeTactics = function(){
                let avail = true;
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        self.DAMAGE_VAR[i].value += value;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,self,`【胜兵求战】令`,`${self.DAMAGE_VAR[i].damageType}伤害提高${self.DAMAGE_VAR[i].value}%`);
                        avail = false;
                    }
                }
                if(avail){
                    let block = new DamageBlock(self,that,'所有',true,value,true,0,99,0);
                    let work = block.Init(self,null,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,self,`【胜兵求战】令`,`${block.damageType}伤害提高${block.value}%`);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【胜兵求战】效果已施加`);
            let fri = a.SelectTarget(self,2,true,false,2,false,false,this);
            for(let i in fri){
                let block = new ActiveTacticsBlock(self,this,activeTactics,99,0,true,'主动');
                block.Init(fri[i],false);
            }
            this.STATISTICS.times += 1;
        }
    },
    //远攻秘策
    {
        id : 8,
        name : "远攻秘策",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let a = new Attack();
            let value = 20;
            let times = 3;//队友享受加成的回合数
            if(self.driver.version == '平衡'){
                times = 4;
            }
            
            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            for(let i in res){
                let time = times;
                if(res[i] == self) time = 99;
                let block1 = new AttrBlock(self,this,'攻击',value,time,false);
                block1.Init(res[i],true,2);
                let block2 = new AttrBlock(self,this,'谋略',value,time,false);
                block2.Init(res[i],true,2);
                let block = new RangeBlock(self,this,true,1,time,0);
                block.Init(res[i],true,2);
            }
            this.STATISTICS.times += 1;
        }
    },
    //行兵之极
    {
        id : 21,
        name : "行兵之极",
        type : "指挥",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let odd = 10;
            let value1 = 40;//增伤
            let value2 = 50;//减伤幅度

            //判断是否符合条件
            let work = true;
            let count = {};
            for(let i in self.ally){
                let arm = u.TransferArms(self.ally[i].up_arms[self.ally[i].choseArms]);
                if(count[arm]){
                    work = false; break;
                }
                count[arm] = true;
            }
            let action = function(){
                let target = this.beExecuted;
                let block = new DamageBlock(self,that,'所有',true,value1,true,false,0,0);
                let work = block.Init(target,null,false,3);
                if(work){
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,target,`【行兵之极】令`,`所有伤害提高${value1}%`);
                }
            }
            if(work){
                let res = a.SelectTarget(self,3,true,false,3,true,false,this);
                for(let i in res){
                    let tgt = res[i];
                    //大营加几率
                    if(tgt.position == '0'){
                        if(tgt.TacticsArr[0].type == '主动' || tgt.TacticsArr[0].type == '追击'){
                            let block = new OddBlock(self,this,tgt.TacticsArr[0],odd,99,0,0);
                            block.Init(tgt,true,2);
                        }
                    }
                    //中军增伤
                    else if(tgt.position == '1'){
                        let block = new ExecuteBlock(self,this,'行动开始',action,4);
                        block.Init(tgt,false);
                        let r = new Report();
                        r.InitExecuteReport(2,tgt,`【行兵之极】效果已施加`);
                    }
                    //前锋减伤
                    else if(tgt.position == '2'){
                        let block = new DamageBlock(self,this,'所有',false,-value2,false,false,4,0);
                        block.Init(tgt,null,true,2);
                    }
                }
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【行兵之极】不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //疾风迅雷
    {
        id : 22,
        name : "疾风迅雷",
        type : "指挥",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let odd = 40;//触发混乱几率
            if(self.driver.version == '平衡'){
                odd = 60;
            }

            let attack = function(){
                if(self.driver.turn > 3){
                    let target = this.attackTarget;
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let block = new StatusBlock(self,that,'混乱',1,0);
                        let work = block.Init(target,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,target,`【疾风迅雷】令`,`陷入混乱`);
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(3,self,`【疾风迅雷】没有生效，生效几率为${realOdd}%`);
                    }
                }
            }
            let block = new StatusBlock(self,this,'先手',99,0);
            block.Init(self,true,2);
            let block1 = new AttackExecuteBlock(self,this,attack,99,0,true);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【疾风迅雷】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //美人计
    {
        id : 23,
        name : "美人计",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let value1 = 14;//增伤幅度
            let value2 = 60;//减伤幅度
            
            let work = true;
            for(let i in self.ally){
                if(self.ally[i].gender == '男') work = false;
            }
            if(work){
                //大营
                let block1 = new DamageBlock(self,this,'所有',true,value1,false,false,99,0);
                block1.Init(self.ally[0],null,true,2);
                //中军
                let action = function(){
                    let target = self.ally[1];
                    let res = a.SelectTarget(target,5,false,true,3,false,false,that);
                    let tgtArr = []
                    for(let i in res){
                        if(res[i].gender == '男') tgtArr.push(res[i]);
                    }
                    if(tgtArr.length > 0){
                        let final = tgtArr[Math.floor(Math.random()*tgtArr.length)];
                        let block = new DamageBlock(self,that,'所有',true,-value2,true,false,1,0);
                        let work =  block.Init(final,null,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,final,`【美人计】令`,`下一次所有伤害降低${value2}%`);
                        }
                    }
                }
                let block2 = new ExecuteBlock(self,this,'行动开始',action,99);
                block2.Init(self.ally[1],false);
                let r1 = new Report();
                r1.InitExecuteReport(2,self.ally[1],`【美人计】效果已施加`);
                //前锋
                let dodge = function(){
                    let target = this.beExecuted;
                    let block = new DodgeBlock(self,that,99);
                    let work = block.Init(self,false,3);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(1,self,target,`【美人计】令`,`规避下一次伤害`);
                    }
                }
                let block3 = new ExecuteBlock(self,this,'回合开始',dodge,4);
                block3.Init(self.ally[2]);
                let r2 = new Report();
                r2.InitExecuteReport(2,self.ally[2],`【美人计】效果已施加`);
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【美人计】不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //空城
    {
        id : 24,
        name : "空城",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let odd = 70;
            
            let getHurt = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new DodgeBlock(self,that,99);
                    block.Init(self,false);
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【空城】令`,`规避下一次伤害`);
                }
            }
            let block = new BeforeMakeDamageBlock(self,this,getHurt,2,false);
            block.Init(self);
            let r = new Report();
            r.InitExecuteReport(2,self,`【空城】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //以直报怨
    {
        id : 27,
        name : "以直报怨",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let value = 10;
            
            let getHurt = "以直报怨1" + self.belong + self.position;
            let makeDamage = "以直报怨2" + self.belong + self.position;
            //自己受到伤害后
            let getHurt1 = function(){
                if(self[getHurt]) return;
                let res = a.SelectTarget(self,3,true,false,1,true,false,that);
                for(let i in res){
                    let avail = true;
                    for(let j in res[i].DAMAGE_VAR){
                        let tgt = res[i].DAMAGE_VAR[j];
                        if(tgt.tactics == that && tgt.value < 60){
                            tgt.value -= value;
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【以直报怨】令`,`受到${tgt.damageType}伤害降低${Math.abs(tgt.value)}%`);
                            self[getHurt] = true;
                            avail = false;
                        }
                    }
                    if(avail){
                        let block = new DamageBlock(self,that,'所有',false,-value,false,false,99,0);
                        let work =  block.Init(res[i],null,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【以直报怨】令`,`受到所有伤害降低${value}%`);
                            self[getHurt] = true;
                        }
                    }
                }
            }
            //自己造成伤害后
            let getHurt2 = function(){
                if(self[makeDamage]) return;
                let target = this.damageFromWho;
                if(target == self){
                    let tgt = this.beExecuted;
                    let avail = true;
                    for(let i in tgt.DAMAGE_VAR){
                        if(tgt.DAMAGE_VAR[i].tactics == that && tgt.DAMAGE_VAR[i].value < 60){
                            tgt.DAMAGE_VAR[i].value -= value;
                            let r = new Report();
                            let txt = `${tgt.DAMAGE_VAR[i].damageType}伤害降低${Math.abs(tgt.DAMAGE_VAR[i].value)}%`;
                            r.InitTwoTargetReport(3,self,tgt,`【以直报怨】令`,txt);
                            self[makeDamage] = true;
                            avail = false;
                        }
                    }
                    if(avail){
                        let block = new DamageBlock(self,that,'所有',true,-value,false,false,99,0);
                        let work =  block.Init(tgt,null,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,tgt,`【以直报怨】令`,`所有伤害降低${value}%`);
                            self[makeDamage] = true;
                        }
                    }
                }
            }
            for(let i in self.enemy){
                let block = new AfterDamageBlock(self,this,getHurt2,99,true,true,true,true,true,true,true);
                block.Init(self.enemy[i],'以直报怨');
            }
            let block = new AfterDamageBlock(self,this,getHurt1,99,true,true,true,true,true,true,true);
            block.Init(self,'以直报怨');
            let clear = function(){
                self[getHurt] = false;
                self[makeDamage] = false;
            }
            let block1 = new ExecuteBlock(self,this,'回合开始',clear,99);
            block1.Init(self,false);
            self[getHurt] = false;
            self[makeDamage] = false;
            this.STATISTICS.times += 1;
        }
    },
    //方圆
    {
        id : 28,
        name : "方圆",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let u = new Util();
            let value1 = 20;//普通攻击减伤
            let value2 = q.GetAdditionValue(self,16.8,'防御',0.085);

            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            for(let i in res){
                if(u.TransferArms(res[i].up_arms[res[i].choseArms]) == 'b'){
                    let block1 = new DamageBlock(self,this,'普通攻击',true,-value1,false,false,99,0);
                    block1.Init(res[i],null,true,2);
                    let block2 = new DamageBlock(self,this,'主动',true,value2,false,false,99,0);
                    block2.Init(res[i],null,true,2);
                    let block3 = new DamageBlock(self,this,'追击',true,value2,false,false,99,0);
                    block3.Init(res[i],null,true,2);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //鹤翼
    {
        id : 29,
        name : "鹤翼",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ["弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let value1 = 20;//普通攻击减伤
            let damageValue = 49;
            
            
            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            let action = function(){
                let waitTime = self.driver.turn%2 == 0? 99 : 0;
                for(let i in res){
                    for(let j in res[i].COUNTER_ATTACK){
                        let tgt = res[i].COUNTER_ATTACK[j];
                        if(tgt.tactics == that){
                            tgt.waitTime = waitTime;
                            if(waitTime == 0){
                                let r = new Report();
                                r.InitTwoTargetReport(1,self,res[i],`【鹤翼】令`,`获得分兵能力`);
                            }
                            break;
                        }
                    }
                }   
            }
            for(let i in res){
                if(u.TransferArms(res[i].up_arms[res[i].choseArms]) == 'g'){
                    let block1 = new DamageBlock(self,this,'普通攻击',true,-value1,false,false,99,0);
                    block1.Init(res[i],null,true,2);
                    let realDamage = damageValue + q.GetAdditionValue(res[i],0,'谋略',0.175);
                    let block2 = new CounterAttackBlock(self,this,'分兵',realDamage,100,99,0);
                    let work =  block2.Init(res[i],false);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(2,res[i],`分兵(预备)效果已施加`);
                    }
                    
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //锋矢
    {
        id : 30,
        name : "锋矢",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            
            let u = new Util();
            let value1 = 25;//普通攻击减伤
            let value2 = q.GetAdditionValue(self,18,'速度',0.1);

            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            for(let i in res){
                if(u.TransferArms(res[i].up_arms[res[i].choseArms]) == 'q'){
                    let block1 = new DamageBlock(self,this,'普通攻击',true,-value1,false,false,99,0);
                    block1.Init(res[i],null,true,2);
                    let block2 = new DamageBlock(self,this,'主动',true,value2,false,false,99,0);
                    block2.Init(res[i],null,true,2);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //兵贵神速
    {
        id : 32,
        name : "兵贵神速",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = q.GetAdditionValue(self,10,'防御',0.05);//增伤幅度;
            
            let tag = '兵贵神速' + self.belong + self.position;
            let getHurt = function(){
                let target = this.beExecuted;
                if(this.damageType == '攻击' && target[tag] < 8){
                    let block = new AttrBlock(self,that,'防御',value,99,true);
                    let work = block.Init(target,false);
                    if(work){
                        let r = new Report();
                        let txt = `防御属性提高了${value}(${q.GetAttr(target,'防御')})`
                        r.InitTwoTargetReport(3,self,target,`【兵贵神速】令`,txt);
                        target[tag] += 1;
                    }
                }
                
            }
            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            for(let i in res){
                let block = new DamageBlock(self,this,'所有',false,0,false,false,99,0);
                let work = block.Init(res[i],null,false);
                block.isBenefit = true;
                res[i][tag] = 0;
                if(work){
                    let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                    block.Init(res[i],'攻其不备');
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`【兵贵神速】效果已施加`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //衡轭
    {
        id : 33,
        name : "衡轭",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let a = new Attack();
            let u = new Util();
            let attrValue = 50;//谋略属性提升
            let value = 50;//增伤

            let work = true;
            for(let i in self.ally){
                let tgt = self.ally[i];
                if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'g') work = false;
            }
            if(work){
                let res = a.SelectTarget(self,2,true,false,3,true,false,this);
                for(let i in res){
                    let tgt = res[i];
                    if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'q'){
                        let block = new AttrBlock(self,this,'谋略',attrValue,99,false);
                        block.Init(tgt,true,2);
                    }
                    if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'b'){
                        let block = new DamageBlock(self,this,'普通攻击',true,value,false,false,99,0);
                        block.Init(tgt,null,true,2);
                    }
                }
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【衡轭】不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //疏数
    {
        id : 34,
        name : "疏数",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let attrValue = 50;//防御属性提升
            let odd = 40;
            let damageValue = 100;
            
            let work = true;
            for(let i in self.ally){
                let tgt = self.ally[i];
                if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'b') work = false;
            }
            let action = function(){
                let tgt = this.beExecuted;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(2,tgt,`执行【疏数】`);
                    let res = a.SelectTarget(tgt,3,false,true,1,false,true,that);
                    for(let i in res){
                        let damage = c.CountDamage(tgt,res[i],damageValue,'攻击',false,that,null);
                        a.GetHurt(tgt,res[i],damage,false,'攻击',that,true);
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,tgt,`【疏数】没有生效，生效几率为${realOdd}%`);
                }
            }
            if(work){
                let res = a.SelectTarget(self,2,true,false,3,true,false,this);
                for(let i in res){
                    let tgt = res[i];
                    if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'g'){
                        let block = new AttrBlock(self,this,'防御',attrValue,99,false);
                        block.Init(tgt,true,2);
                    }
                    if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'q'){
                        let block = new ExecuteBlock(self,this,'行动开始',action,99);
                        block.Init(tgt,false);
                        let r = new Report();
                        r.InitExecuteReport(2,tgt,`【疏数】效果已施加`);
                    }
                }

            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【疏数】不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //鱼鳞
    {
        id : 35,
        name : "鱼鳞",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let a = new Attack();
            let u = new Util();
            let attrValue = 50;//防御属性提升
            let value = 35;//策略伤害降低
            
            let work = true;
            for(let i in self.ally){
                let tgt = self.ally[i];
                if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'q') work = false;
            }
            if(work){
                let res = a.SelectTarget(self,2,true,false,3,true,false,this);
                for(let i in res){
                    let tgt = res[i];
                    if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'b'){
                        let block = new AttrBlock(self,this,'防御',attrValue,99,false);
                        block.Init(tgt,true,2);
                    }
                    if(u.TransferArms(tgt.up_arms[tgt.choseArms]) == 'g'){
                        let block = new DamageBlock(self,this,'谋略',false,-value,false,false,99,0);
                        block.Init(tgt,null,true,2);
                    }
                }
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【鱼鳞】不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //反击之策
    {
        id : 36,
        name : "反击之策",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 75;//触发几率
            let damageValue = 100;
            
            let res = a.SelectTarget(self,3,true,false,2,true,false,this);
            for(let i in res){
                let block = new CounterAttackBlock(self,this,'反击',damageValue,100,3,0);
                block.Init(res[i],true,2);
            }
            let action = function(){
                for(let i in res){
                    for(let j in res[i].COUNTER_ATTACK){
                        let tgt = res[i].COUNTER_ATTACK[j];
                        if(tgt.tactics == that){
                            let realOdd = q.GetMoraleOdd(self,odd);
                            let rand = Math.floor(Math.random()*100);
                            if(rand < realOdd){
                                tgt.waitTime = 0;
                                let r = new Report();
                                r.InitTwoTargetReport(1,self,res[i],`【反击之策】令`,`获得反击能力`);
                            }
                            else{
                                tgt.waitTime = 99;
                                let r = new Report();
                                r.InitTwoTargetReport(1,self,res[i],`【反击之策】对`,`没有生效，生效几率为${realOdd}%`);
                            }
                        }
                    }
                }
            }
            let block = new ExecuteSysBlock(self,this,'回合开始',action,3);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //穷追猛打
    {
        id : 37,
        name : "穷追猛打",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 60;
            
            let res = a.SelectTarget(self,3,true,false,2,true,true,this);
            let action = function(){
                if(self.driver.turn >= 4){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,this.beExecuted,`【穷追猛打】令`,`获得连击`);
                        //打开连击的阀门
                        for(let i in this.beExecuted.STATUS){
                            if(this.beExecuted.STATUS[i].tactics == that){
                                this.beExecuted.STATUS[i].waitTime = 0;
                            }
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,this.beExecuted,`【穷追猛打】对`,`没有生效，生效几率为${realOdd}%`);
                        //关闭连击的阀门
                        for(let i in this.beExecuted.STATUS){
                            if(this.beExecuted.STATUS[i].tactics == that){
                                this.beExecuted.STATUS[i].waitTime = 99;
                            }
                        }
                    }
                }
            }
            //施加连击
            for(let i in res){
                let block = new StatusBlock(self,this,'连击',7,4);
                let work =  block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],'连击(预备)效果已施加');
                    let block = new ExecuteBlock(self,this,"行动开始",action,7);
                    block.Init(res[i]);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //长兵方阵
    {
        id : 38,
        name : "长兵方阵",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 75;//触发几率
            let damageValue = 60;
            
            let res = a.SelectTarget(self,3,true,false,2,true,false,this);
            for(let i in res){
                let block = new CounterAttackBlock(self,this,'分兵',damageValue,100,3,0);
                block.Init(res[i],true,2);
            }
            let action = function(){
                let target = this.beExecuted;
                for(let j in target.COUNTER_ATTACK){
                    let tgt = target.COUNTER_ATTACK[j];
                    if(tgt.tactics == that){
                        let realOdd = q.GetMoraleOdd(self,odd);
                        let rand = Math.floor(Math.random()*100);
                        if(rand < realOdd){
                            tgt.waitTime = 0;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【长兵方阵】令`,`获得分兵能力`);
                        }
                        else{
                            tgt.waitTime = 99;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【长兵方阵】对`,`没有生效，生效几率为${realOdd}%`);
                        }
                    }
                }
            }
            for(let i in res){
                let block = new ExecuteBlock(self,this,'行动开始',action,3);
                block.Init(res[i],false);
            }
            this.STATISTICS.times += 1;
        }
    },
    //措手不及
    {
        id : 39,
        name : "措手不及",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 80;
            
            let res = a.SelectTarget(self,3,false,true,2,false,true,this);
            let action = function(){
                if(self.driver.turn >= 4){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,this.beExecuted,`【措手不及】令`,`陷入怯战`);
                        //打开连击的阀门
                        for(let i in this.beExecuted.STATUS){
                            if(this.beExecuted.STATUS[i].tactics == that){
                                this.beExecuted.STATUS[i].waitTime = 0;
                            }
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,this.beExecuted,`【措手不及】对`,`没有生效，生效几率为${realOdd}%`);
                        //关闭连击的阀门
                        for(let i in this.beExecuted.STATUS){
                            if(this.beExecuted.STATUS[i].tactics == that){
                                this.beExecuted.STATUS[i].waitTime = 99;
                            }
                        }
                    }
                }
            }
            //施加连击
            for(let i in res){
                let block = new StatusBlock(self,this,'怯战',6,4);
                let work =  block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],'怯战(预备)效果已施加');
                    let block = new ExecuteBlock(self,this,"行动开始",action,6);
                    block.Init(res[i]);
                }
            }
            
            
            this.STATISTICS.times += 1;
        }
    },
    //援军秘策
    {
        id : 40,
        name : "援军秘策",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){            
            let a = new Attack();
            let cureValue = 103;
            let cureIndex = 0.825;
            let odd = 100;


            let res = a.SelectTarget(self,3,true,false,2,true,false,this);
            for(let i in res){
                let block = new CureStatusBlock(self,this,"休整",cureValue,cureIndex,99,4,odd);
                let work = block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`休整(预备)效果已施加`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    
]

export default commandB;