<template>
    <div class="battle_warrior_container">
        <div class="texture_branch">我的武将</div>
        <div class="close_btn" ref="close_btn1" @touchstart="$refs.close_btn1.classList.add('touch')"
        @touchend="$refs.close_btn1.classList.remove('touch')"
        @click="Close"></div>
        <div class="warrior_assemble_container">
            <div class="warriors_head" v-for="(w,id) in $store.state.AllWarriors" ref="warriors_head" :key="w.index"
            @touchstart="$refs.warriors_head[id].classList.add('click')"
            @touchend="$refs.warriors_head[id].classList.remove('click')" @click="ChoseThisWarrior(w)"
            v-show="w.name != '侍卫'">
                
                <!-- 武将阵营 -->
                <div class="bg_qun cover" v-if="w.camp == '群'"></div>
                <div class="bg_han cover" v-if="w.camp == '汉'"></div>
                <div class="bg_wei cover" v-if="w.camp == '魏'"></div>
                <div class="bg_shu cover" v-if="w.camp == '蜀'"></div>
                <div class="bg_wu cover" v-if="w.camp == '吴'"></div>
                <div class="bg_jin cover" v-if="w.camp == '晋'"></div>
                <img :src="w.img.head" alt="" class="warrior_head">
                <div class="name bg_qun" v-if="w.camp == '群'">{{w.name}}</div>
                <div class="name bg_han" v-if="w.camp == '汉'">{{w.name}}</div>
                <div class="name bg_wei" v-if="w.camp == '魏'">{{w.name}}</div>
                <div class="name bg_shu" v-if="w.camp == '蜀'">{{w.name}}</div>
                <div class="name bg_wu" v-if="w.camp == '吴'">{{w.name}}</div>
                <div class="name bg_jin" v-if="w.camp == '晋'">{{w.name}}</div>
                <!-- 武将标志 -->
                <div class="warrior_sign" v-if="w.id != -1">
                    <img src="../../assets/ui/ui (66).png" alt="" v-if="w.sign == 's2'" class="cover">
                    <img src="../../assets/ui/ui (67).png" alt="" v-if="w.sign == 's3'" class="cover">
                    <img src="../../assets/ui/ui (68).png" alt="" v-if="w.sign == 'sp'" class="cover">
                    <img src="../../assets/ui/ui (69).png" alt="" v-if="w.sign == 'xp'" class="cover">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'WarriorContainer',
    data(){
        return{

        }
    },
    methods:{
        Camp(camp,name){
            switch(camp){
                case "汉" : return name ? "bg_han name" : "background_loop bg_han cover";
                case "魏" : return name ? "bg_wei name" : "background_loop bg_wei cover";
                case "蜀" : return name ? "bg_shu name" : "background_loop bg_shu cover";
                case "吴" : return name ? "bg_wu name" : "background_loop bg_wu cover";
                case "群" : return name ? "bg_wei name" : "background_loop bg_qun cover";
                case "晋" : return name ? "bg_jin name" : "background_loop bg_jin cover";
            }
        },
        Close(){
            this.$bus.$emit('ShowWarriorContainer',true);
        },
        //选择这个武将
        ChoseThisWarrior(warriorItem){
            console.log(warriorItem);
            this.$store.state.BorderChess.currentWarriorItem.GetWarriorData(warriorItem);
            this.Close();
        }
    }
}
</script>

<style lang="less">
.battle_warrior_container{
    position: absolute;left: 0; top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
    background-size: cover;
    
    .texture_branch{
        left: 0; top: 15vw;
        width: 50vw;height: 10vw;
        line-height: 10vw;
        background: url('../../assets/background/bg (5).png')no-repeat;
        background-size: 100% 100%;
        font-size: 5vw;
        color: rgb(238, 238, 151);
        text-shadow: 0 0 0 6px black;
        text-indent: -20vw;
        font-family: 'FZLBJW';
    }
    .close_btn{
        right: 5vw; top: 15vw;
        width: 8vw;height: 8vw;
        background: url('../../assets/ui/ui (85).png')no-repeat;
        background-size: 100% 100%;
    }
    .close_btn.touch{
        transform: scale(.8);
    }
    .warrior_assemble_container{
        position: absolute; bottom: 5vh; left: 0;
        width: 100%;height: 80vh;
        overflow-y: scroll;overflow-x: hidden;
        .warriors_head{
            float: left;
            position: relative;
            margin: 6vw 0 2vw 3.4vw;
            width: 16vw;
            height: 16vw;
            .warrior_sign{
                position: absolute; left: .5vw; bottom: .5vw;
                width: 5.5vw; height: 5.5vw;
            }
            .warrior_head{
                position: absolute; left: 50%; top : 50%;
                transform : translate(-50%,-50%);
                width: 14vw;
                height: 14vw;
                transition: all .05s;
            }
            .warrior_head_bg_wu{
                background: darkred;
            }
            .warrior_head_bg_qun{
                background: rgb(108, 108, 108);
            }
            .warrior_head_bg_wei{
                background: #064584;
            }
            .warrior_head_bg_shu{
                background: rgb(20, 110, 7);
            }
            .warrior_head_bg_han{
                background: rgb(140, 5, 157);
            }
            .name{
                position: absolute; left: 0; bottom: -3vw;
                width: 100%;height: 4vw;
                line-height: 4vw;
                font-size: 2vw;
                color: rgb(230, 230, 230);
                background-size: 100% 100%;
                text-align: center;
            }
        }
        .warriors_head.click{
            transform: scale(.9);
        }
    }
    
}
</style>