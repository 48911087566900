import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';
import MakeDamageBlock from '../block/execute6.js';
let passiveA = [
    //三军齐出
    {
        id : 2,
        name : "三军齐出",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 90;
            let damageValue = 70;

            let action  = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new CounterAttackBlock(self,that,'分兵',damageValue,100,0,0);
                    let work = block.Init(self,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,self,`【三军齐出】令`,`进入分兵状态`);
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【三军齐出】没有生效，生效几率为${realOdd}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【三军齐出】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //势无虚动
    {
        id : 3,
        name : "势无虚动",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let value = 40;//伤害提升
            let tempBlock = null;

            let tag = '势无虚动' + self.belong + self.position;
            let action  = function(){
                let block1 = new StatusBlock(self,that,'无视规避',0,0);
                let work1 = block1.Init(self,false);
                tempBlock = block1;
                if(work1){
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,self,`【势无虚动】令`,`无视规避状态`);
                }
                let avail = true;
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that && self.DAMAGE_VAR[i].isOnceAttack){
                        if(self.DAMAGE_VAR[i][tag] < 3){
                            self.DAMAGE_VAR[i].value += value;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,self,`【势无虚动】令`,`追击战法伤害提高${self.DAMAGE_VAR[i].value}%`);
                            self.DAMAGE_VAR[i][tag] += 1;
                        }
                        avail = false;
                    }
                }
                if(avail){
                    
                    let block = new DamageBlock(self,that,'追击',true,value,true,false,99,0);
                    let work = block.Init(self,null,false);
                    block[tag] = 1;
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,self,`【势无虚动】令`,`追击战法伤害提高${value}%`);
                    }
                }
                
            }
            let afterMakeDamage = function(){
                u.clearBlock(self,self.STATUS,tempBlock,true,3);
            }
            let block = new ExecuteBlock(self,this,'试图发动追击战法',action,99);
            block.Init(self,false);
            let block1 = new MakeDamageBlock(self,this,afterMakeDamage,99);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【势无虚动】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //甚陷不惧
    {
        id : 4,
        name : "甚陷不惧",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd_plus = 50;

            let tag = '甚陷不惧' + self.belong + self.position;
            let action = function(){
                //判断是否符合触发条件
                if(self.hp < 0.9 * q.GetMaxHp(self) && self[tag] == 0
                || self.hp < 0.7 * q.GetMaxHp(self) && self[tag] == 1
                || self.hp < 0.3 * q.GetMaxHp(self) && self[tag] == 2){
                    let r = new Report();
                    let txt = "";
                    if(self[tag] == 0) txt = '兵力低于90%';
                    else if(self[tag] == 1) txt = '兵力低于70%';
                    else if(self[tag] == 2) txt = '兵力低于30%';
                    r.InitExecuteReport(2,self,txt);
                    if(self.TacticsArr[0].type == '主动' && self.TacticsArr[0].isDamageMaker){
                        let block = new OddBlock(self,that,self.TacticsArr[0],odd_plus,0,0,0);
                        let work = block.Init(self,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,self,`【甚陷不惧】令`,`【${self.TacticsArr[0].name}】发动几率提高${odd_plus}%`);
                        }
                    }
                    self[tag] += 1;
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action,99);
            block1.Init(self,false);
            self[tag] = 0;
            let r = new Report();
            r.InitExecuteReport(2,self,`【甚陷不惧】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //兵无常势
    {
        id : 5,
        name : "兵无常势",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 250;
            let cureIndex = 0;
            let value = 50;//属性提升
            let value2 = 40;

            let action  = function(){
                let rand = Math.floor(Math.random()*3);
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【兵无常势】`);
                if(rand == 1){
                    let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                    a.GetCure(self,self,cure,that,true,null);
                }
                else if(rand == 2){
                    for(let i=0;i<3;i++){
                        let attrType = "";
                        switch(i){
                            case 0 : attrType = '攻击';break;
                            case 1 : attrType = '防御';break;
                            case 2 : attrType = '谋略';break;
                        }
                        let block = new AttrBlock(self,that,attrType,value,0,false);
                        block.Init(self,true,3);
                    }
                }
                else{
                    let block = new DamageBlock(self,that,'所有',false,-value2,false,false,0,0);
                    block.Init(self,null,true,3);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【兵无常势】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //擅兵不寡
    {
        id : 6,
        name : "擅兵不寡",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 30;
            let cureValue = 180;
            let cureIndex = 0;
            let cureValue1 = 300;
            let cureIndex1 = 0;

            let action  = function(){
                //立即回血
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【擅兵不寡】`);
                let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                a.GetCure(self,self,cure,that,true,null);
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let cure = c.CountCure(self,self,cureValue1,cureIndex1,that);
                    a.GetCure(self,self,cure,that,true,null);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【擅兵不寡】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //愈战愈勇
    {
        id : 7,
        name : "愈战愈勇",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 10;
            let action  = function(){
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        self.DAMAGE_VAR[i].value += value;
                        let r = new Report();
                        r.InitTwoTargetReport(1,self,self,`【愈战愈勇】令`,`攻击伤害提高${self.DAMAGE_VAR[i].value}%`);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            let block1 = new DamageBlock(self,that,'攻击',true,value,false,false,99,0);
            block1.Init(self,null,true,2);
            this.STATISTICS.times += 1;
        }
    },
    //步步为营
    {
        id : 8,
        name : "步步为营",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 11;//每层减伤效果
            if(self.driver.version == '平衡'){
                value = 10;
            }


            let action  = function(){
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        self.DAMAGE_VAR[i].value -= value;
                        let r = new Report();
                        r.InitTwoTargetReport(1,self,self,`【步步为营】令`,`受到${self.DAMAGE_VAR[i].damageType}伤害降低${Math.abs(self.DAMAGE_VAR[i].value)}%`);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            let block1 = new DamageBlock(self,that,'所有',false,-value,false,false,99,0);
            block1.Init(self,null,true,2);
            this.STATISTICS.times += 1;
        }
    },
    //健卒不殆
    {
        id : 9,
        name : "健卒不殆",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 50;
            let damageValue = 40;//反击伤害
            let value = 50;

            let getHurt = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let tgt = this.attacker;
                    let block = new DamageBlock(self,that,'所有',true,-value,true,false,0,0);
                    let work = block.Init(tgt,null,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,tgt,`【健卒不殆】令`,`所有伤害降低${value}%`);
                    }
                }
            }
            let block = new BeforeMakeDamageBlock(self,this,getHurt,99,false);
            block.Init(self);
            let block1 = new CounterAttackBlock(self,this,'反击',damageValue,100,99,0);
            block1.Init(self,true,2);
            let r = new Report();
            r.InitExecuteReport(2,self,`【健卒不殆】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //深谋远虑
    {
        id : 11,
        name : "深谋远虑",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 11;
            let action  = function(){
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        self.DAMAGE_VAR[i].value += value;
                        let r = new Report();
                        r.InitTwoTargetReport(1,self,self,`【深谋远虑】令`,`策略伤害提高${self.DAMAGE_VAR[i].value}%`);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            let block1 = new DamageBlock(self,that,'谋略',true,value,false,false,99,0);
            block1.Init(self,null,true,2);
            this.STATISTICS.times += 1;
        }
    },
    //胜负先征
    {
        id : 18,
        name : "胜负先征",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 75;
            let cureIndex = 0;
            let value = 40;

            let getHurt = function(){
                let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                let report = new Report();
                report.InitTwoTargetReport(3,self,self,`【胜负先征】令`,`恢复了${cure}兵力(${self.hp + cure})`,false);
                a.GetCure(self,self,cure,that,true,report);
            }
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【胜负先征】`);
                if(self.morale >= 100){
                    let block1 = new DamageBlock(self,that,'主动',true,value,false,false,0,0);
                    block1.Init(self,null,true,3);
                    let block2 = new DamageBlock(self,that,'追击',true,value,false,false,0,0);
                    block2.Init(self,null,true,3);
                }
                else{
                    let block = new AfterDamageBlock(self,this,getHurt,0,true,true,true,true,true,true,true);
                    block.Init(self,'胜负先征');
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【胜负先征】效果已施加`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【胜负先征】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //兵者诡道
    {
        id : 20,
        name : "兵者诡道",
        type : "被动",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,170,'谋略',1.75);
            let cureValue = 150;
            let cureIndex = 1.2;

            let tag = '兵者诡道' + self.belong + self.position;
            let action = function(){
                self[tag] += 1;
                if(self[tag] >= 3){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【兵者诡道】`);
                    let rand = Math.floor(Math.random()*3);
                    if(rand == 1){
                        let res = a.SelectTarget(self,5,false,true,1,false,true,that);
                        for(let i in res){
                            let damage = c.CountDamage(self,self,damageValue,'谋略',false,that,null);
                            a.GetHurt(self,res[i],damage,false,'谋略',that,true,null);
                        }
                    }
                    else if(rand == 2){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`规避下两次伤害`);
                        for(let j=0;j<2;j++){
                            let block = new DodgeBlock(self,that,99);
                            block.Init(self,false);
                        }
                    }
                    else{
                        let res = a.SelectTarget(self,3,true,false,1,false,false,that);
                        res.push(self);
                        for(let i in res){
                            let cure = c.CountCure(self,res[i],cureValue,cureIndex,that);
                            a.GetCure(self,res[i],cure,that,true,null);
                        }
                    }
                    self[tag] -= 3;
                }
            }
            let block1 = new ExecuteBlock(self,this,'试图发动追击战法',action,99);
            block1.Init(self,false);
            let block2 = new ExecuteBlock(self,this,'试图发动主动战法',action,99);
            block2.Init(self,false);
            self[tag] = 0;
            let r = new Report();
            r.InitExecuteReport(2,self,`【兵者诡道】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
]

export default passiveA;