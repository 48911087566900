<template>
    <div class="team_info_view">
        <!-- 背景黑板 -->
        <div class="edit_single_team_bg_mask cover"></div>
        <div class="edit_container">
            <!-- 队伍名称 -->
            <div class="team_name_tape" ref="team_name_tape">
                <div class="name">部队预览</div>
            </div>
            <div class="close_btn" ref="close_btn2" @touchstart="$refs.close_btn2.classList.add('click')" 
            @touchend="$refs.close_btn2.classList.remove('click')" @click="Close"></div>
            <!-- 队伍配置容器 -->
            <div class="team_exposition">
                <div class="warrior_card_ipt" v-for="(w,w_index) in currentTeam" :key="w.index">
                    <div class="warrior_card">
                        <img :src="w.img.body" alt="" class="cover" @click="PreUnloadWarrior(w)">
                        <img src="../../assets/ui/ui (60).png" alt="" class="cover" v-if="w.id != -1">
                        <div class="black_tape_big1"></div>
                        <ul class="star_list" v-if="w.id != -1">
                            <li v-for="s in w.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                            <li v-for="s in (5-w.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                        </ul>
                        <!-- 觉醒 -->
                        <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-if="w.isUp">
                        <img src="../../assets/ui/ui (84).png" alt="" class="up_mark" v-if="w.isUpWork">
                        <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-if="w.isUp && w.isUpWork">
                        <div class="cost_img" v-if="w.id != -1">{{w.cost}}</div>
                            
                        <div class="camp" v-if="w.camp == '蜀'"><img src="../../assets/ui/ui (75).png" alt="" class="cover"></div>
                        <div class="camp" v-if="w.camp == '吴'"><img src="../../assets/ui/ui (78).png" alt="" class="cover"></div>
                        <div class="camp" v-if="w.camp == '魏'"><img src="../../assets/ui/ui (77).png" alt="" class="cover"></div>
                        <div class="camp" v-if="w.camp == '群'"><img src="../../assets/ui/ui (72).png" alt="" class="cover"></div>
                        <div class="camp" v-if="w.camp == '汉'"><img src="../../assets/ui/ui (64).png" alt="" class="cover"></div>
                        <div class="camp" v-if="w.camp == '晋'"><img src="../../assets/ui/ui (150).png" alt="" class="cover"></div>

                        
                        <div class="name" >{{w.name}}</div>
                        <div class="level" v-if="w.id != -1">Lv.-</div>

                        <!-- 武将标志 -->
                        <div class="warrior_sign" v-if="w.id != -1">
                            <img src="../../assets/ui/ui (66).png" alt="" v-if="w.sign == 's2'" class="cover">
                            <img src="../../assets/ui/ui (67).png" alt="" v-if="w.sign == 's3'" class="cover">
                            <img src="../../assets/ui/ui (68).png" alt="" v-if="w.sign == 'sp'" class="cover">
                            <img src="../../assets/ui/ui (69).png" alt="" v-if="w.sign == 'xp'" class="cover">
                        </div>

                        <!-- 兵种 -->
                        <div class="arm" v-if="w.id != -1">
                            <img src="../../assets/ui/ui (81).png" alt="" v-if="!w.isUpArm" class="cover">
                            <img src="../../assets/ui/ui (82).png" alt="" v-if="w.isUpArms" class="cover">
                            <img src="../../assets/ui/ui (79).png" alt="" v-if="w.arms == '步' && !w.isUpArms" class="icon">
                            <img src="../../assets/ui/ui (70).png" alt="" v-if="w.arms == '骑' && !w.isUpArms" class="icon">
                            <img src="../../assets/ui/ui (62).png" alt="" v-if="w.arms == '弓' && !w.isUpArms" class="icon">

                            <img src="../../assets/ui/ui (71).png" alt="" v-if="w.arms == '骑' && w.isUpArms" class="icon">
                            <img src="../../assets/ui/ui (80).png" alt="" v-if="w.arms == '步' && w.isUpArms" class="icon">
                            <img src="../../assets/ui/ui (63).png" alt="" v-if="w.arms == '弓' && w.isUpArms" class="icon">
                            <div class="arm_num">{{w.range}}</div>
                        </div>
                        <div class="tactics_container" v-if="w.id != -1">
                            <div class="tacticsTrough">
                                <img src="../../assets/ui/ui (92).png" alt="" class="cover" v-if="w.mainTactics.type == 'command'">
                                <img src="../../assets/ui/ui (93).png" alt="" class="cover" v-if="w.mainTactics.type == 'active'">
                                <img src="../../assets/ui/ui (91).png" alt="" class="cover" v-if="w.mainTactics.type == 'passive'">
                                <img src="../../assets/ui/ui (94).png" alt="" class="cover" v-if="w.mainTactics.type == 'combo'">
                                <img src="../../assets/ui/ui (100).png" alt="" class="skill_frame" v-if="w.mainTactics.quality == 'A'">
                                <img src="../../assets/ui/ui (101).png" alt="" class="skill_frame" v-if="w.mainTactics.quality == 'S'">
                                <img src="../../assets/ui/ui (102).png" alt="" class="skill_frame" v-if="w.mainTactics.quality == 'B'">
                                <div class="name_s" v-if="w.mainTactics.quality == 'S'">{{w.mainTactics.name}}</div>
                                <div class="name_a" v-if="w.mainTactics.quality == 'A'">{{w.mainTactics.name}}</div>
                                <div class="name_b" v-if="w.mainTactics.quality == 'B'">{{w.mainTactics.name}}</div>
                                <div class="s_sign" v-if="w.mainTactics.quality == 'S'"></div>
                                <div class="a_sign" v-if="w.mainTactics.quality == 'A'"></div>
                                <div class="b_sign" v-if="w.mainTactics.quality == 'B'"></div>
                                <div class="cover" @click="SelectTactics(w,-1,w.mainTactics)" v-if="w.mainTactics.id != -1"></div>
                            </div>
                            <div class="tacticsTrough" v-for="(t,t_index) in w.tacticsArr" :key="t.index">
                                <img src="../../assets/ui/ui (92).png" alt="" class="cover" v-if="t.type == 'command'">
                                <img src="../../assets/ui/ui (93).png" alt="" class="cover" v-if="t.type == 'active'">
                                <img src="../../assets/ui/ui (91).png" alt="" class="cover" v-if="t.type == 'passive'">
                                <img src="../../assets/ui/ui (94).png" alt="" class="cover" v-if="t.type == 'combo'">
                                <img src="../../assets/ui/ui (100).png" alt="" class="skill_frame" v-if="t.quality == 'A' && t.sign != '典藏'">
                                <img src="../../assets/ui/ui (101).png" alt="" class="skill_frame" v-if="t.quality == 'S' && t.sign != '典藏'">
                                <img src="../../assets/ui/ui (102).png" alt="" class="skill_frame" v-if="t.quality == 'B' && t.sign != '典藏'">

                                <img src="../../assets/ui/ui (98).png" alt="" class="skill_frame" v-if="t.quality == 'S' && t.sign == '典藏'">
                                <img src="../../assets/ui/ui (99).png" alt="" class="skill_frame" v-if="t.quality == 'A' && t.sign == '典藏'">
                                <img src="../../assets/ui/ui (163).png" alt="" class="skill_frame" v-if="t.quality == 'B' && t.sign == '典藏'">
                                <div class="name_s" v-if="t.quality == 'S'">{{t.name}}</div>
                                <div class="name_a" v-if="t.quality == 'A'">{{t.name}}</div>
                                <div class="name_b" v-if="t.quality == 'B'">{{t.name}}</div>
                                <div class="s_sign" v-if="t.quality == 'S'"></div>
                                <div class="a_sign" v-if="t.quality == 'A'"></div>
                                <div class="b_sign" v-if="t.quality == 'B'"></div>
                                <img src="../../assets//ui/ui (103).png" alt="" class="cover" @click="SelectTactics(w,t_index,t)" v-if="t.id == -1">
                                <div class="cover" @click="SelectTactics(w,t_index,t)" v-if="t.id != -1"></div>
                            </div>
                            
                        </div>

                        <div class="cover" @click="ConfigWarrior(w,w_index)" v-if="w.id == -1"></div>
                        <div class="cover" @click="PreUnloadWarrior(w,w_index)" v-if="w.id != -1"></div>

                    </div>
                    <div class="warrior_position" v-show="w_index == 0">大 营</div>
                    <div class="warrior_position" v-show="w_index == 1">中 军</div>
                    <div class="warrior_position" v-show="w_index == 2">前 锋</div>
                </div>
                
                
            </div>
            <!-- 删除队伍按钮 -->
            <div class="delete_team_btn" ref="delete_team_btn" @touchstart="$refs.delete_team_btn.classList.add('click')" 
            @touchend="$refs.delete_team_btn.classList.remove('click')" @click="IsDeleteThisTeam()">删除队伍</div>

            <!-- 模拟对战按钮 -->
            <div class="simulateBattle_btn" ref="simulateBattle_btn" @touchstart="$refs.simulateBattle_btn.classList.add('click')" 
            @touchend="$refs.simulateBattle_btn.classList.remove('click')" @click="TurnToSimBattle">模拟对战</div>
        </div>


        <!-- 武将卡池 -->
        <div class="select_warrior_container" v-if="selectWarriorControl">
            <div class="texture_branch">我的武将</div>
            <div class="close_btn" ref="close_btn1" @touchstart="$refs.close_btn1.classList.add('touch')"
            @touchend="$refs.close_btn1.classList.remove('touch')"
            @click="selectWarriorControl = false"></div>
            <div class="warrior_assemble_container">
                <div class="warriors_head" v-for="(w,id) in $store.state.AllWarriors" ref="warriors_head" :key="w.index"
                @touchstart="$refs.warriors_head[id].classList.add('click')"
                @touchend="$refs.warriors_head[id].classList.remove('click')" @click="SelectThisWarrior(w)" v-show="ShowRule(w)">
                    
                    <!-- 武将阵营 -->
                    <div class="bg_qun cover" v-show="w.camp == '群'"></div>
                    <div class="bg_han cover" v-show="w.camp == '汉'"></div>
                    <div class="bg_wei cover" v-show="w.camp == '魏'"></div>
                    <div class="bg_shu cover" v-show="w.camp == '蜀'"></div>
                    <div class="bg_wu cover" v-show="w.camp == '吴'"></div>
                    <div class="bg_jin cover" v-show="w.camp == '晋'"></div>

                    

                    <img :src="w.img.head" alt="" class="warrior_head" v-if="imgLoad(id)">
                    <div class="name bg_qun" v-if="w.camp == '群'">{{w.name}}</div>
                    <div class="name bg_han" v-if="w.camp == '汉'">{{w.name}}</div>
                    <div class="name bg_wei" v-if="w.camp == '魏'">{{w.name}}</div>
                    <div class="name bg_shu" v-if="w.camp == '蜀'">{{w.name}}</div>
                    <div class="name bg_wu" v-if="w.camp == '吴'">{{w.name}}</div>
                    <div class="name bg_jin" v-if="w.camp == '晋'">{{w.name}}</div>
                    <ul class="star_list" v-if="imgLoad(id)">
                        <li v-for="s in w.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                        <li v-for="s in (5-w.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                    </ul>
                    <!-- 觉醒 -->
                    <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-if="w.isUp">
                    <img src="../../assets/ui/ui (84).png" alt="" class="up_mark" v-if="w.isUpWork">
                    <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-if="w.isUp && w.isUpWork">
                    <!-- 武将标志 -->
                    <div class="warrior_sign" v-if="w.id != -1 && imgLoad(id)">
                        <img src="../../assets/ui/ui (66).png" alt="" v-if="w.sign == 's2'" class="cover">
                        <img src="../../assets/ui/ui (67).png" alt="" v-if="w.sign == 's3'" class="cover">
                        <img src="../../assets/ui/ui (68).png" alt="" v-if="w.sign == 'sp'" class="cover">
                        <img src="../../assets/ui/ui (69).png" alt="" v-if="w.sign == 'xp'" class="cover">
                    </div>
                </div>
            </div>
        </div>

        <!-- 战法池 -->
        <div class="skill_panel" v-if="selectTacticsControl">
            <div class="bg_texture2">我 的 战 法</div>
            <img src="../../assets/ui/ui (85).png" alt="" ref="close_btn" class="close_btn" 
            @touchstart="$refs.close_btn.classList.add('click')"
            @touchend="$refs.close_btn.classList.remove('click')"
            @click="selectTacticsControl = false">

            <div class="skill_container">
                <!-- 指挥战法 -->
                <div class="title">
                    <div class="text">指挥战法</div>
                </div>
                <div class="command_skill_container">
                    <div class="skill_box" v-for="(c) in $store.state.Tactics.command" :key="c.index" @click="LoadThisTactics(c)"
                    v-show="TacticsShowRule(c)">
                        <img src="../../assets/ui/ui (92).png" alt="" class="skill_image">
                        <img src="../../assets/ui/ui (90).png" alt="" class="skill_frame" v-if="c.quality == 'S'">
                        <img src="../../assets/ui/ui (88).png" alt="" class="skill_frame" v-if="c.quality == 'A'">
                        <img src="../../assets/ui/ui (89).png" alt="" class="skill_frame" v-if="c.quality == 'B'">
                        <div class="name">{{c.name}}</div>
                    </div>
                </div>
                <!-- 主动战法 -->
                <div class="title">
                    <div class="text">主动战法</div>
                </div>
                <div class="active_skill_container">
                    <div class="skill_box" v-for="(c) in $store.state.Tactics.active" :key="c.index" @click="LoadThisTactics(c)"
                    v-show="TacticsShowRule(c)">
                        <img src="../../assets/ui/ui (93).png" alt="" class="skill_image">
                        <img src="../../assets/ui/ui (90).png" alt="" class="skill_frame" v-if="c.quality == 'S'">
                        <img src="../../assets/ui/ui (88).png" alt="" class="skill_frame" v-if="c.quality == 'A'">
                        <img src="../../assets/ui/ui (89).png" alt="" class="skill_frame" v-if="c.quality == 'B'">
                        <div class="name">{{c.name}}</div>
                    </div>
                </div>
                <!-- 追击战法 -->
                <div class="title">
                    <div class="text">追击战法</div>
                </div>
                <div class="combo_skill_container">
                    <div class="skill_box" v-for="(c) in $store.state.Tactics.combo" :key="c.index" @click="LoadThisTactics(c)"
                    v-show="TacticsShowRule(c)">
                        <img src="../../assets/ui/ui (94).png" alt="" class="skill_image">
                        <img src="../../assets/ui/ui (90).png" alt="" class="skill_frame" v-if="c.quality == 'S'">
                        <img src="../../assets/ui/ui (88).png" alt="" class="skill_frame" v-if="c.quality == 'A'">
                        <img src="../../assets/ui/ui (89).png" alt="" class="skill_frame" v-if="c.quality == 'B'">
                        <div class="name">{{c.name}}</div>
                    </div>
                </div>
                <!-- 被动战法 -->
                <div class="title">
                    <div class="text">被动战法</div>
                </div>
                <div class="passive_skill_container">
                    <div class="skill_box" v-for="(c) in $store.state.Tactics.passive" :key="c.index" @click="LoadThisTactics(c)"
                    v-show="TacticsShowRule(c)">
                        <img src="../../assets/ui/ui (91).png" alt="" class="skill_image">
                        <img src="../../assets/ui/ui (90).png" alt="" class="skill_frame" v-if="c.quality == 'S'">
                        <img src="../../assets/ui/ui (88).png" alt="" class="skill_frame" v-if="c.quality == 'A'">
                        <img src="../../assets/ui/ui (89).png" alt="" class="skill_frame" v-if="c.quality == 'B'">
                        <div class="name">{{c.name}}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 战法详情 -->
        <div class="tactics_detail" v-if="tacticsDetailControl">
            <div class="mask cover" @click="tacticsDetailControl = false"></div>
            <div class="center_box" v-if="currentTactics != null">
                <div class="title">
                    <div class="name">{{ currentTactics.name }}</div>
                </div>
                <div class="close_btn" ref="close_btn3" @touchstart="$refs.close_btn3.classList.add('click')" 
                @touchend="$refs.close_btn3.classList.remove('click')" @click="tacticsDetailControl = false"></div>

                <div class="describe_area">
                    <div class="text">{{ currentTactics.describe[0] }}</div>
                </div>
                <div class="unload_btn" ref="unload_btn" @touchstart="$refs.unload_btn.classList.add('click')" 
                @touchend="$refs.unload_btn.classList.remove('click')" v-if="currentTactics.name != currentWarrior.mainTactics.name"
                @click="Unload()">卸下战法</div>
            </div>
        </div>

        

        
    </div>
</template>

<script>
// import TactInfo from '../../components/container/TactInfo.vue';
import ConfigTeam from '../../util/configTeam';
import Team from '../../util/team';
export default {
    name : 'ConfigTeam',
    props:['currentTeam','currentTeamIndex'],
    data(){
        return{
            selectWarriorControl : false,
            selectTacticsControl : false,
            tacticsDetailControl : false,
            isUnloadWarrior : false,
            currentWarriorIdex : -1,
            currentWarrior : null,
            currentTactics : null,

            imgIndex : 0,
        }
    },
    methods:{
        //图片慢速加载
        imgLoad(id){
            if(this.imgIndex >= id) return true;
            else return false;
        },
        //战法显示规则
        TacticsShowRule(tactics){
            if(tactics.lock) return false;
            for(let i in this.$store.state.MyTeam){
                for(let j=0;j<this.$store.state.MyTeam[i].length;j++){
                    for(let k=0;k<this.$store.state.MyTeam[i][j].tacticsArr.length;k++){
                        if(this.$store.state.MyTeam[i][j].tacticsArr[k].name == tactics.name) return false;
                    }
                    
                }
            }
            return true;
        },
        //显示武将的规则
        ShowRule(warriorData){
            if(!warriorData.own) return false;
            for(let i in this.$store.state.MyTeam){
                for(let j=0;j<this.$store.state.MyTeam[i].length;j++){
                    //id相同的武将不能同时上场,sp武将不能和普通武将同时上场
                    if(this.$store.state.MyTeam[i][j].id == warriorData.id) return false;
                    //
                    else if(this.$store.state.MyTeam[i][j].name == warriorData.name){
                        if(Math.abs(this.$store.state.MyTeam[i][j].id - warriorData.id) == 5000){
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        //关闭
        Close(){
            this.$bus.$emit('CloseEditTeam')  
        },
        //配置武将
        ConfigWarrior(warriorData,w_index){
            this.imgIndex = 0;
            this.currentWarrior = warriorData;
            this.currentWarriorIdex = w_index;
            this.selectWarriorControl = true;
            setInterval(() => {
                this.imgIndex = 1000;
            }, 5);
        },
        SelectThisWarrior(w){
            let cfg = new ConfigTeam(this);
            cfg.PutInWarriorData(this.currentTeamIndex,this.currentWarriorIdex,w);
            this.selectWarriorControl = false;
        },
        //打开选择战法面板
        SelectTactics(warrior,t_index,t){
            this.currentWarrior = warrior;
            this.currentTactics = t;
            this.currentTacticsIndex = t_index;
            console.log(warrior,t_index,t)
            if(t.id == -1){
                this.selectTacticsControl = true;
            }
            else{
                this.tacticsDetailControl = true;
            }
        },
        //安装战法
        LoadThisTactics(tacticsData){
            //先判定典藏战法不可以装备两个
            for(let i in this.currentWarrior.tacticsArr){
                if(this.currentWarrior.tacticsArr[i].sign == '典藏' && tacticsData.sign =='典藏' ){
                    this.$bus.$emit('alert','每个武将只能携带一个典藏');
                    return;
                }
            }
            let cfg = new ConfigTeam(this);
            cfg.PutInTacticsData(this.currentWarrior,this.currentTacticsIndex,tacticsData);
            this.selectTacticsControl = false;
        },
        //卸载战法
        Unload(){
            let cfg = new ConfigTeam(this);
            cfg.UnloadTactics(this.currentWarrior,this.currentTacticsIndex);
            this.tacticsDetailControl = false;
        },
        PreUnloadWarrior(warriorData,wIndex){
            this.currentWarrior = warriorData;
            this.currentWarriorIdex = wIndex;
            let that = this;
            let f = function(){
                let cfg = new ConfigTeam(that);
                cfg.UnloadWarrior(that.currentTeamIndex,that.currentWarriorIdex);
                that.currentWarrior = null;
                that.$forceUpdate();
            }
            let params = {
                text : `确定移除【${warriorData.camp}-${warriorData.name}-${warriorData.arms}】？`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
        },
        UnloadWarrior(){
            let cfg = new ConfigTeam(this);
            cfg.UnloadWarrior(this.currentTeamIndex,this.currentWarriorIdex);
            this.currentWarrior = null;
            this.isUnloadWarrior = false;
        },
        //是否删除队伍
        IsDeleteThisTeam(){
            let that = this;
            let f = function(){
                let cfg = new ConfigTeam(that);
                cfg.DeleteOneTeam(that.currentTeamIndex);
                that.Close();
            }
            let params = {
                text : '确定删除该队伍？',
                callBack : f,
            }
            this.$store.commit('Alert',params);
        },
        //转到模拟对局
        TurnToSimBattle(){
            let that = this;
            //检查是否配置
            let targetTeam =  this.$store.state.BorderChess.BlueTeam.warriorArr;
            let t = new Team(this);
            for(let i in targetTeam){
                t.RemoveWarrior(targetTeam[i]);
                targetTeam[i].GetMyTeamWarriorData(this.currentTeam[i]);
                console.log('targetTeam[i]',targetTeam[i],this.currentTeam[i]);
                for(let j in this.currentTeam[i].tacticsArr){
                    if(this.currentTeam[i].tacticsArr[j].id != -1){
                        targetTeam[i].tacticsArr[j].LoadData(this.currentTeam[i].tacticsArr[j]);
                        console.log('targetTeam[i].tacticsArr[j]',targetTeam[i].tacticsArr[j]);
                    }
                }
            }
            
            this.$router.push({
                path : '/Battle'
            })
        }
    },
    
}
</script>

<style lang="less">
//编辑队伍
.team_info_view{
    position: absolute; left: 0; top : 0;
    width : 100vw; height : 100vh;
    z-index: 10;
    .edit_single_team_bg_mask{
        background: rgba(0, 0, 0, 0.679)
    }
    .edit_container{
        position: absolute; top: 50%;
        margin-top: -70vw;
        width: 100vw; height: 140vw;
        background: url('../../assets/background/bg (7).png')#393b3e bottom right no-repeat;
        background-size: cover;
        overflow: hidden;
        .close_btn{
            right: 5vw; top: 5vw;
            width: 7vw;height: 7vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: cover;
        }
        .close_btn.click{
            transform: scale(.9);
        }
        .team_name_tape{
            left: 0; top: 6vw;
            width: 60vw;height: 12vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
            .name{
                position: absolute; left: 0; top : 0;
                width : 100%; height : 100%;
                text-indent: -15vw;
                line-height: 10vw;
                color: rgb(254, 245, 208);
                letter-spacing: 1vw;
                font-size: 4.2vw;
                font-family: "FZLBJW";
            }
        }
        
        .team_exposition{
            position: absolute; left: 0; top: 20vw;
            width: 100vw;
            height: 40vw;
            .warrior_card_ipt{
                position: relative;float: left;
                width: 24vw;height: 36vw;
                background: url('../../assets/background/bg (24).png')no-repeat;
                background-size: 100% 100%;
                margin: 5vw 0 0 2vw;
                transition: all .1s;
                .warrior_card{
                    width: 24vw;height: 36vw;
                    opacity: 1;
                    margin: 0 0 0 0;
                    transform: scale(1);
                    .camp{
                        left: 2%; top: 2%;
                        width: 4vw;height: 4vw;
                    }
                    .warrior_sign{
                        width: 5vw;height: 5vw;
                    }
                    .up_mark{
                        position: absolute; right: -2.1vw; top: -2.1vw;
                        width: 5.4vw;
                        height: 5.4vw;
                        transition: none;
                    }
                    .cost_img{
                        position: absolute; left: 55%; bottom : 2%;
                        transform : translate(-50%,0);
                        width: 9vw;
                        height: 3vw;
                        font-size: 2vw;
                        line-height: 3.4vw;
                        text-align: left;
                        text-indent: 5.5vw;
                        background: url('../../assets/ui/ui (61).png') left  no-repeat;
                        background-size: 60% 80%;
                        color: rgb(203, 206, 162);
                            text-shadow: 0 0 1px black;
                    }
                    .star_list{
                        left: 1.5%; top: 1.2%;
                        width: 97%;height:3vw;
                        background:linear-gradient(to bottom,rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0));
                        li{
                            float: left;
                            position: relative;
                            width: 2vw;height: 2vw;
                            margin: .8vw 0 0 0;
                        }
                        li:nth-of-type(1){
                            margin: .8vw 0 0 11.5vw;
                        }
                    }
                    .name {
                        top: 3vw;
                        transform: scale(.7);
                    }
                    .level{
                        left: 1vw; bottom: .5vw;
                        width: 10vw;height: 3vw;
                        line-height: 3vw;
                        font-size: 2.5vw;
                        text-align: left;
                        font-weight: 500;
                        color: rgb(217, 221, 135);
                        text-shadow: 0 0 1px black;
                    }
                    .arm{
                        position: absolute; right: 1vw; bottom : .5vw;
                        width : 5.8vw; height : 5.8vw;
                        .icon{
                            position: absolute; left: 50%; top : 50%;
                            transform : translate(-50%,-50%);
                            width: 80%; height: 80%;
                        }
                        .arm_num{
                            position: absolute; left: 40%; top : 50%;
                            transform : translate(-50%,-50%);
                            width: 7vw;height: 5vw;
                            font-size: 2.5vw;
                            line-height: 6.9vw;
                            text-align: right;
                            color: rgb(203, 206, 162);
                            text-shadow: 0 0 1px black;
                        }
                    }
                    .tactics_container{
                        position: absolute; top: 36vw; left: 0;
                        width: 24vw; height: 60vw;
                        background: #00000041;
                        .tacticsTrough{
                            position: relative; float: left;
                            width: 12vw;
                            height: 12vw;
                            margin: 5vw 0 0 6vw;
                            .skill_frame{
                                position: absolute; left: -.2vw; top : -.2vw;
                                width: 12.6vw;
                                height: 12.6vw;
                                
                            }
                            
                            .name_a,.name_s,.name_b{
                                position: absolute; left: -1.1vw; bottom : -3.3vw;
                                width : 14.2vw; height : 3.2vw;
                                font-size: 2.8vw;
                                letter-spacing: .2vw;
                                color: white;
                                text-align: center;
                                line-height: 3.2vw;
                                font-family: "FZLBJW";
                            }
                            .name_s{
                                background: url('../../assets/ui/ui (113).png')no-repeat;
                                background-size: 100% 100%;
                            }
                            .name_a{
                                background: url('../../assets/ui/ui (114).png')no-repeat;
                                background-size: 100% 100%;
                            }
                            .name_b{
                                background: url('../../assets/ui/ui (115).png')no-repeat;
                                background-size: 100% 100%;
                            }
                            .s_sign,.a_sign,.b_sign{
                                position: absolute; left: 0; top: 0;
                                width: 4vw;
                                height: 4vw;
                                background: url('../../assets/ui/ui (95).png')no-repeat;
                                background-size: 100% 100%;
                            }
                            .a_sign{
                                background: url('../../assets/ui/ui (96).png')no-repeat;
                                background-size: 100% 100%;
                            }
                            .b_sign{
                                background: url('../../assets/ui/ui (97).png')no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
                .warrior_position{
                    position: absolute; top: -5.5vw; left: 0;
                    width: 100%;
                    height: 4vw;
                    // background: url('../assets/main/bg.png')rgb(75, 75, 75) no-repeat;
                    background: url('../../assets/ui/ui (133).png')center no-repeat;
                    background-size: 100% 170%;
                    text-align: center;
                    font-size: 2vw;
                    line-height: 4vw;
                    color: rgb(175, 175, 175);
                }
            }
            .warrior_card_ipt:nth-of-type(1){
                margin: 5vw 0 0 13vw;
            }
        }
        //战法板块
        .load_skill_part{
            position: absolute; left: 0; top: 45%;
            width: 100vw;
            height: 65vw;
            z-index: 1;
            // transition: all .3s ease-out;
            .load_warrior_tape{
                float: left;position: relative;
                margin: 3.5vw 0 0 0;
                transition: all .2s ;
                width: 100vw;
                height: 18vw;
                // background: url('../assets/sim_battle/sim-texture1.png')rgb(24, 17, 0) no-repeat;
                background:#232526;
                box-shadow: 0 0 1px black;
                background-size: 100% 100%;
                .warrior_head_img{
                    position: relative;
                    float: left;
                    width: 45vw;
                    height: 18vw;
                    background: rgb(56, 53, 50);
                    box-shadow: 0 0 1px black;
                    box-sizing: border-box;
                    margin: 0vw 2vw 0 0vw;
                    overflow: hidden;
                    img{
                        position: absolute; right:-20%; top:0;
                        height: 100%;
                        // height: 100%;
                        
                    }
                    .middle_name_tape_linear_han,.middle_name_tape_linear_wei,.middle_name_tape_linear_qun,
                    .middle_name_tape_linear_shu,
                    .middle_name_tape_linear_wu,
                    .middle_name_tape_linear_jin{
                        position: absolute; left: 0; bottom:0;
                        padding-top: 2vw;
                        // width: 28vw;
                        color: rgb(238, 228, 191);
                        text-align: left;
                        line-height: 2.5vw;
                        font-size: 2.1vw;
                        text-align: center;
                        // font-weight: bold;
                        // height: 4vw;
                        width : 4vw;
                        height:16vw;
                        background:linear-gradient(to bottom,rgb(52, 0, 71),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_shu{
                        background:linear-gradient(to bottom,rgb(0, 74, 19),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_wei{
                        background:linear-gradient(to bottom,rgb(0, 46, 93),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_qun{
                        background:linear-gradient(to bottom,rgb(27, 27, 27),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_wu{
                        background:linear-gradient(to bottom,rgb(116, 9, 9),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_jin{
                        background:linear-gradient(to bottom,rgb(0, 89, 88),rgba(0, 0, 0, 0));
                    }
                    .black_tape{
                        position: absolute; right: 0; top:0;
                        width: 100%;
                        height: 4vw;
                        background:linear-gradient(to bottom,rgb(34, 34, 34),rgba(255, 255, 255, 0));
                    }
                    .red_list_2{
                        position: absolute; right: 5vw; top:1.5vw;
                        width: 13vw;
                        height: 2.5vw;
                        .star2{
                            position: relative;
                            float: left;
                            width: 2.5vw;
                            height: 2.5vw;
                            img{
                                position: absolute; left: 0; bottom:0;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .arm4{
                        position: absolute; right: 0vw; bottom : 0vw;
                        width : 5vw; height : 5vw;
                        img{
                            position: absolute; left: 0; top : 0;
                            width : 100%; height : 100%;
                        }
                        .logo2{
                            position: absolute; left: 50%; top : 50%;
                            transform : translate(-50%,-50%);
                            width : 4vw; height : 4vw;
                        }
                        .arm_num1{
                            position: absolute; left: 70%; top : 60%;
                            transform : translate(-50%,-50%);
                            width: 2vw;
                            height: 2vw;
                            font-size: 2vw;
                            // text-shadow: 0 0 10px black;
                            text-align: right;
                            color: rgb(228, 231, 197);
                            text-shadow: 0 0 1px black;
                        }
                    }
                    .warrior_sign{
                        position: absolute; left: -1vw; bottom : 1vw;
                        width : 5vw; height : 5vw;
                    }
                    .up_mark1{
                        position: absolute; left: 40vw; top:.5vw;
                        width: 5vw;
                        height: 5vw;
                    }
                    
                }
                .skills{
                    position: relative;
                    float: left;
                    width: 14vw;
                    height: 14vw;
                    margin: .5vw 0 0 2vw;
                    transform: scale(.9);
                    background: url('../../assets/ui/ui (103).png')no-repeat;
                    background-size: cover;
                    border-radius: 8vw;
                    box-sizing: border-box;
                    transition: all .1s;
                    
                    
                    
                }
                .skills.click{
                    // opacity: .1;
                    box-shadow: 0 0 15px white;
                }
            }
        }
        .load_skill_part.active{
            position: absolute; left: 0; top: 20vw;
        }

        .delete_team_btn,.simulateBattle_btn{
            bottom: 5vw; left: 50%;
            width: 24vw;height: 6vw;
            margin: 0 0 0 -29vw;
            line-height: 6vw;
            background: url('../../assets/ui/ui (10).png')no-repeat;
            background-size: 100% 100%;
            color: black;
            font-weight: bold;
        }
        .delete_team_btn.click,.simulateBattle_btn.click{
            transform: scale(.8);
        }
        .simulateBattle_btn{
            margin: 0 0 0 5vw;
        }
    }

    .select_warrior_container{
        position: absolute;left: 0; top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
        background-size: cover;
        
        .texture_branch{
            left: 0; top: 15vw;
            width: 50vw;height: 10vw;
            line-height: 10vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
            font-size: 5vw;
            color: rgb(238, 238, 151);
            text-shadow: 0 0 0 6px black;
            text-indent: -20vw;
        }
        .close_btn{
            right: 5vw; top: 15vw;
            width: 8vw;height: 8vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: 100% 100%;
        }
        .close_btn.touch{
            transform: scale(.8);
        }
        .warrior_assemble_container{
            position: absolute; bottom: 5vh; left: 0;
            width: 100%;height: 80vh;
            overflow-y: scroll;overflow-x: hidden;
            .warriors_head{
                float: left;
                position: relative;
                margin: 6vw 0 2vw 3.4vw;
                width: 16vw;
                height: 16vw;
                .star_list{
                    position: absolute; left: .9vw; bottom: .9vw;
                    width: 14.2vw; height: 3vw;
                    background:linear-gradient(to bottom,rgba(0, 0, 0, 0),black);
                    li{
                        position: relative; float: left;
                        margin: 0 0 0 .2vw;
                        width: 2.4vw; height: 2.4vw;
                    }
                    li:nth-of-type(1){
                        margin: 0 0 0 .7vw;
                    }
                }
                .up_mark{
                    position: absolute; right: -2vw; top: -2vw;
                    width: 6vw; height: 6vw;
                }
                .warrior_sign{
                    position: absolute; left: 1vw; bottom: 3vw;
                    width: 5vw; height: 5vw;
                }
                .warrior_head{
                    position: absolute; left: 50%; top : 50%;
                    transform : translate(-50%,-50%);
                    width: 14vw;
                    height: 14vw;
                    transition: all .05s;
                }
                .warrior_head_bg_wu{
                    background: darkred;
                }
                .warrior_head_bg_qun{
                    background: rgb(108, 108, 108);
                }
                .warrior_head_bg_wei{
                    background: #064584;
                }
                .warrior_head_bg_shu{
                    background: rgb(20, 110, 7);
                }
                .warrior_head_bg_han{
                    background: rgb(140, 5, 157);
                }
                .name{
                    position: absolute; left: 0; bottom: -4.7vw;
                    width: 100%;height: 4vw;
                    line-height: 4vw;
                    font-size: 2vw;
                    color: rgb(230, 230, 230);
                    background-size: 100% 100%;
                    text-align: center;
                }
            }
            .warriors_head.click{
                transform: scale(.9);
            }
        }
        
    }
    
    //战法库
    .skill_panel{
        position: absolute; left: 0; top : 0;
        width : 100%; height : 100%;
        background: url('../../assets/background/bg (1).jpg') right bottom no-repeat;
        background-size: cover;
        z-index: 3;
        .bg_texture2{
            top: 10vh; left: 0;
            width: 50vw;height: 10vw;
            line-height: 10vw;
            text-indent: -10vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
            font-size: 6vw;
            font-family: "FZLBJW";
        }
        .close_btn{
            position: absolute; right: 5vw; top: 10vh;
            width: 8vw;
            height: 8vw;
            transition: all .1s;
        }
        .close_btn.click{
            transform: scale(.7);
        }
        .skill_container{
            position: absolute; left: 5vw; top : 15vh;
            width: 90vw;
            height: 70vh;
            overflow-y: scroll;
            overflow-x: hidden;
            z-index: 2;
            transition: all .2s;
            //战法图像
            .skill_image,.skill_mask{
                position: absolute; top: 0; left: 1.5vw;
                width: 12vw;
                height: 12vw;
            }
            .skill_frame{
                position: absolute; top: 0; left: 0;
                width: 100%;
                height: 100%;
            }
            //遮罩层
            .skill_mask{
                text-align: right;
                line-height: 12vw;
                font-size: 2vw;
                color: rgb(122, 122, 122);
                border-radius: 6vw;
                background: rgba(0, 0, 0, 0.8);
                box-shadow: 0 0 2vw rgb(64, 64, 64);
                transition: all .2s;
            }
            //标题
            .title{
                position: relative;
                width: 100%;
                height: 7vw;
                background: rgba(0, 0, 0, 0.396);
                line-height: 7vw;
                text-indent: 2vw;
                float: left;
                margin: 5vw 0 0 0;
            }
            //战法
            .command_skill_container,.active_skill_container,.combo_skill_container,.passive_skill_container{
                position: relative;
                width: 100%;
                .skill_box{
                    position: relative;
                    width: 15vw;
                    height: 15vw;
                    float: left;
                    margin: 2.5vw 0 0 2.5vw;
                    .name{
                        position: absolute; bottom: 0; left: 0;
                        width: 100%;
                        height: 4vw;
                        font-size: 2.8vw;
                        color: white;
                        text-align: center;
                        line-height: 4.3vw;
                        font-family: "FZLBJW";
                    }
                }
                

            }
            

        }
        .unload_skill_btn{
            position: absolute; left: 35vw; top : 90vh;
            width : 30vw; height : 8vw;
            text-align: center;
            line-height: 8vw;
            color: rgb(19, 19, 19);
            font-size: 2vw;
            font-weight: bold;
            background: url('../../assets/ui/ui (10).png')no-repeat;
            background-size: 100% 100%;
            transition: all .05s;
        }
        .unload_skill_btn.click{
            transform: scale(.8);
        }
    }
    //战法详情界面
    .tactics_detail{
        position: absolute; left: 0; top: 0;
        width: 100vw; height: 100vh;
        .mask{
            background: rgba(0, 0, 0, 0.679)
        }
        .close_btn{
            right: 5vw; top: 5vw;
            width: 7vw;height: 7vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: cover;
        }
        .close_btn.click{
            transform: scale(.9);
        }
        .center_box{
            position: absolute; left: 0; top: 50%;
            width: 100vw; height: 80vw;
            margin: -40vw 0 0 0;
            background: url('../../assets/background/bg\ \(7\).png')#393b3e bottom right no-repeat;
            background-size: cover;
            .title{
                left: 0; top: 5vw;
                width: 60vw;height: 12vw;
                background: url('../../assets/background/bg (5).png')no-repeat;
                background-size: 100% 100%;
                .name{
                    position: absolute; left: 0; top : 0;
                    width : 100%; height : 100%;
                    text-indent: -15vw;
                    line-height: 10vw;
                    color: rgb(254, 245, 208);
                    letter-spacing: 1vw;
                    font-weight: bold;
                    font-size: 4.2vw;
                    font-family: "FZLBJW";
                }
            }
            .describe_area{
                left: 10vw; top: 20vw;
                width: 80vw; height: 40vw;
                background: #00000033;
                .text{
                    left: 10vw; top: 5vw;
                    width: 60vw; height: 30vw;
                    overflow: scroll;
                }
            }
            .unload_btn{
                width: 24vw; height: 6vw;
                line-height: 6vw;
                text-align: center;
                color: black;
                font-weight: bold;
                left: 38vw; bottom: 8vw;
                background: url('../../assets/ui/ui (10).png')no-repeat;
                background-size: 100% 100%;
            }
            .unload_btn.click{
                transform: scale(.8);
            }
        }
    }
}
</style>