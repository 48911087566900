import Query from '../js/query.js'
import Count from '../js/count.js';
import Attack from '../js/attack.js';
import StatusBlock from '../block/status.js';
let comboA = [
    //疾击其后
    {
        id : 0,
        name : "疾击其后",
        type : "追击",
        odd : 35,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 70 + Math.floor(Math.random()*70);//伤害率
            
            for(let j=0;j<2;j++){
                let res = a.SelectTarget(self,5,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],value,"攻击",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                }
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //怯心夺志
    {
        id : 1,
        name : "怯心夺志",
        type : "追击",
        odd : 30,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 200;//伤害率
            
            let damage = c.CountDamage(self,target,value,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true,null);
            //施加犹豫
            let block = new StatusBlock(self,this,'犹豫',1,0);
            block.Init(target,true);
            
            
            this.STATISTICS.times += 1;
        }
    },
    //钝兵挫锐
    {
        id : 2,
        name : "钝兵挫锐",
        type : "追击",
        odd : 30,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 200;//伤害率
            
            let damage = c.CountDamage(self,target,value,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true,null);
            //施加怯战
            let block = new StatusBlock(self,this,'怯战',1,0);
            block.Init(target,true);
            
            
            this.STATISTICS.times += 1;
        }
    },
    //温酒斩将
    {
        id : 4,
        name : "温酒斩将",
        type : "追击",
        odd : 35,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let value = 200;//伤害率
            
            let damage = c.CountDamage(self,target,value,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true,null);
            this.STATISTICS.times += 1;
        }
    },
]

export default comboA;