import Report from "../js/report";
//控制战法的发动几率和是否跳过准备阶段
class OddBlock{
    constructor(applicator,tactics,workTactics,value,duration,waitTime,skipTime){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //获得发动几率变化战法
        this.workTactics = workTactics;
        //几率增减数值
        this.value = value;
        //持续时间
        this.duration = duration;
        //等待事件
        this.waitTime = waitTime;
        //跳过准备时间
        this.skipTime = skipTime == undefined ? 0 : skipTime;
        //是否为增益效果
        this.isBenefit = false;
    }
    Init(target,needReport = true,reportLevel = 3){
        if(this.value > 0 || this.skipTime > 0) this.isBenefit = true;
        //检测冲突
        for(let i in target.ODD_VAR){
            if(target.ODD_VAR[i].tactics.type == this.tactics.type && target.ODD_VAR[i].workTactics == this.workTactics){
                let r = new Report();
                if(this.skipTime == 0){
                    let txt = `已存在【${this.workTactics.name}】的发动几率${this.value > 0?"提高":"降低"}效果`
                    r.InitExecuteReport(3,target,txt)
                    return false;
                }
                else if(this.skipTime > 0 && target.ODD_VAR[i].skipTime > 0
                || this.skipTime < 0 && target.ODD_VAR[i].skipTime < 0){
                    let txt = `已存在【${this.workTactics.name}】的准备时间${this.skipTime > 0?"增加":"减少"}${Math.abs(this.skipTime)}回合效果`
                    r.InitExecuteReport(3,target,txt);
                    return false;
                }
            }
        }
        target.ODD_VAR.push(this);
        if(needReport){
            let r = new Report();
            r.InitExecuteReport(reportLevel,target,`【${this.workTactics.name}】的发动几率${this.value > 0?"提高":"降低"}${Math.abs(this.value)}%`)
        }
        return true;
    }
}

export default OddBlock;