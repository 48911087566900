//需要知道本次伤害的类型时使用该块
class BeforeMakeDamageBlock{
    constructor(applicator,tactics,execute,duration,isMakeDamage){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //持续时间
        this.duration = duration;
        //被执行人
        this.beExecuted = null;
        //是即将造成伤害还是即将受到删改
        this.isMakeDamage = isMakeDamage == undefined ? true : isMakeDamage;
        //本次即将造成什么类型伤害
        this.attacker = null;//本次伤害的攻击者
        this.damageType = "攻击";
        this.isCommonAttack = false;
        this.originTactics = null;
        this.statusDamageType = null;
        //执行函数
        this.execute = execute;
    }
    Init(target){
        this.beExecuted = target;
        target.BEFORE_MAKE_DAMAGE.push(this);
        return true;
    }
    GetDamageData(damageType,isCommonAttack,originTactics,statusDamageType){
        this.damageType = damageType;
        this.isCommonAttack = isCommonAttack;
        this.originTactics = originTactics;
        this.statusDamageType = statusDamageType;
    }
    Execute(attacker){
        this.attacker = attacker
        this.execute();
    }
}

export default BeforeMakeDamageBlock;