<template>
    <div class="main">
        <img src="../assets/background/bg (1).jpg" alt="" v-show="false">
        <img src="../assets/background/bg (5).jpg" alt="" v-show="false">
        <!-- logo -->
        <div class="logo"></div>
        <!-- 导航区 -->
        <div class="main_navigation">
            <ul>
                <!-- 我的武将板块 -->
                <li ref="my_warrior" @touchstart="$refs.my_warrior.classList.add('click')"
                @touchend="$refs.my_warrior.classList.remove('click')" @click="EnterMyWarrior">我的武将</li>
                <!-- 我的战法板块 -->
                <li ref="my_tactics" @touchstart="$refs.my_tactics.classList.add('click')"
                @touchend="$refs.my_tactics.classList.remove('click')" @click="EnterTactics">我的战法</li>
                <!-- 我的队伍板块 -->
                <li ref="my_team" @touchstart="$refs.my_team.classList.add('click')"
                @touchend="$refs.my_team.classList.remove('click')" @click="EnterMyTeam">我的部队</li>
                <!-- 对战模式 -->
                <li ref="mock_fight" @touchstart="$refs.mock_fight.classList.add('click')"
                @touchend="$refs.mock_fight.classList.remove('click')" @click="EnterSimBattle">模拟对局</li>
                <!-- 娱乐模式 -->
                <li ref="game" @touchstart="$refs.game.classList.add('click')"
                @touchend="$refs.game.classList.remove('click')">更多模式</li>
                <!-- 设置 -->
                <li ref="setting" @touchstart="$refs.setting.classList.add('click')"
                @touchend="$refs.setting.classList.remove('click')" @click="EnterSettings">设 置</li>
            </ul>
        </div>

        <!-- 招募武将入口 -->
        <!-- <div class="main_recruit" ref="main_recruit" @touchstart="$refs.main_recruit.classList.add('click')"
        @touchend="$refs.main_recruit.classList.remove('click')"
        >
            <div class="logo1"></div>
            <div class="text">招 募</div>
        </div> -->
        <!-- 配置入口 -->
        <!-- <div class="settings_entrance" ref="settings_entrance"
        @touchstart="$refs.settings_entrance.classList.add('click')"
        @touchend="$refs.settings_entrance.classList.remove('click')"
        ></div> -->
        <!-- 账号入口 -->
        <!-- <div class="account_btn" ref="account_btn" 
        @touchstart="$refs.account_btn.classList.add('click')"
        @touchend="$refs.account_btn.classList.remove('click')"
        @click="OpenOrCloseAccountPanel(true)"></div> -->

        
        


        <!-- 招募界面组件 -->
        <!-- <Recruit/> -->
        <!-- 我的武将界面组件 -->
        <!-- <MyWarrior :InfiniteBorderApp="InfiniteBorderApp" /> -->
        <!-- 我的战法组件 -->
        <!-- <Tactics v-show="show.tactics"/> -->
        <!-- 配置组件 -->
        <!-- <Settings v-show="show.settings"/> -->
        <!-- 模拟对战板块 -->
        <!-- <SimBattleVue v-show="show.battle"/> -->
        <!-- 账号管理 -->
        <!-- <AccountVue v-show="show.account" :InfiniteBorderApp="InfiniteBorderApp"/> -->
        
        <!-- 路由 -->
        <router-view/>

        <!-- 顶部导航栏 -->
        <!-- <transition name="down"> -->
        <div class="top_navigation" v-show="$route.path != '/home'">
            <div class="logo2">
                <div class="text">{{ PageTopic() }}</div>
            </div>
            <div class="back_home" ref="back_home" @touchstart="$refs.back_home.classList.add('click')"
            @touchend="$refs.back_home.classList.remove('click')"
            @click="Back"></div>
        </div>
        <!-- </transition> -->

        
        <!-- 提示面板 -->
        <Alert v-if="$store.state.AlertPanel.status"/>
        
    </div>
</template>

<script>
import Load from '../util/load.js';
import Save from '../util/save.js';
import Alert from './container/Alert.vue';
export default {
    name : 'Main',
    components:{
        Alert
    },
    data(){
        return{
            
        }
    },
    methods:{
        PageTopic(){
            switch(this.$route.path){
                case "/myWarriors" : return "武 将";
                case "/myTactics" : return "战 法";
                case "/myTeam" : return "队伍配置";
                case "/setting" : return "设 置";
                case "/Battle" : return "模拟对局";
                case "/ReportContainer" : return "战 报";
                case "/BattleResult" : return "战报详情";
                case "/Lineup" : return "阵 容";
            }
            return "";
        },
        //返回主界面
        Back(){
            if(this.$store.state.WarriorDirty || this.$store.state.TacticsDirty){
                let save = new Save(this);
                if(this.$store.state.WarriorDirty){
                    save.SaveWarriorData();
                    this.$bus.$emit('alert',`武将数据保存成功`);
                }
                else if(this.$store.state.TacticsDirty){
                    save.SaveTacticsData();                    
                    this.$bus.$emit('alert',`战法数据保存成功`);
                }
                //保存app数据
                save.SaveAppData();
                //重置脏位
                this.$store.state.WarriorDirty = false;
                this.$store.state.TacticsDirty = false;
            }
            this.$router.back();
            
        },
        //路由选择
        RouterSelect(){

        },
        //进入招募界面
        EnterRecruit(){
            this.$bus.$emit('EnterRecruit',true);
            this.current_interface = 'recruit';
        },
        //进入我的武将界面
        EnterMyWarrior(){
            this.$router.push({
                path : '/myWarriors'
            })
        },
        //进入我的队伍界面
        EnterMyTeam(){
            this.$router.push({
                path : '/myTeam'
            })
        },
        //进入战法界面
        EnterTactics(){
            this.$router.push({
                path : '/myTactics'
            })
        },
        //进入配置界面
        EnterSettings(){
            this.$router.push({
                path : '/setting'
            })
        },
        //进入模拟对战界面
        EnterSimBattle(){
            this.$router.push({
                path : '/Battle'
            })
        },
        

        
        
    },
    
    mounted(){
        //初始化
        let load = new Load(this);
        load.Init();
    }
}
</script>

<style lang="less">

.main{
    position: absolute; left: 0; top : 0;
    width: 100%; height: 100%;
    background: url('../assets/background/bg (2).jpg')center right no-repeat;
    background-size: cover;
    .logo{
        left: 50%; top : 25vh;
        transform : translate(-50%,-50%);
        width: 50vw;height: 17vw;
        background: url('../assets/background/logo.webp')no-repeat;
        background-size: 100% 100%;
    }
    .preloading{
        position: absolute; left: 120vw; top: 0;
        width: 10px;height: 10px;
    }

    //主导航
    .main_navigation{
        left: 0vw; bottom: 8vw;
        width: 40vw;height: 78vw;
        // background:red;
        ul{
            position:absolute; left: 0; top : 0;
            width : 100%; height : 100%;
            li{
                position: relative;
                float: left;
                margin: 3vw 0 0 0;
                width: 40vw;height: 10vw;
                background: linear-gradient(to right,black,rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0));
                line-height: 10vw;
                text-indent: 8vw;
                text-align: left;
                font-size: 3.5vw;
                background: url('../assets/ui/ui (140).png')center no-repeat;
                background-size: 100% 100%;
                color: white;
                font-family: "FZLBJW";
                font-weight: bold;
            }
            li.click{
                transform: none;
                color: yellow;
            }
        }
    }
    //武将卡
    .warrior_card{
        float: left;position: relative;
        width: 17vw;height: 25.5vw;
        margin: 3vw 0 0 2.4vw;
        background: rgb(0, 0, 0);
        box-shadow: 0 0 1px black;
        transition: all .3s;
        .name{
            position: absolute; left: 0; top: 5%;
            width: 20%;
            height: 60%;
            line-height: 3.8vw;
            font-size: 100%;
            transform: scale(.6);
        }
        .star_list{
            left: 1.5%; top: 1.2%;
            width: 97%;height:3vw;
            background:linear-gradient(to bottom,black,rgba(0, 0, 0, 0));
            li{
                float: left;
                position: relative;
                width: 1.6vw;height: 1.6vw;
                margin: .5vw 0 0 0;
            }
            li:nth-of-type(1){
                margin: .5vw 0 0 7vw;
            }
        }
        .camp{
            left: 1%; top: 1%;
            width: 3vw;height: 3vw;
        }
        .cost_img{
            position: absolute; left: 50%; bottom : 2%;
            transform : translate(-50%,0);
            width: 26%;
            height: 8%;
        }
        .arm{
            right: 2%; bottom: 2%;
            width: 4.5vw;
            height: 4.5vw;
            .icon{
                position: absolute; left: 50%; top : 50%;
                transform : translate(-50%,-50%);
                width: 87%;height: 87%;
            }
            .arm_num{
                position: absolute; left: auto; top : 0; right: .2vw;
                width : 100%; height : 100%;
                font-size: 2vw;
                text-align: right;
                line-height: 6vw;
                font-weight: 500;
                color: rgb(222, 222, 222);
            }
        }
        .up_mark{
            right: -1.9vw; top: -1.9vw;
            width: 4.5vw;height: 4.5vw;
        }
        .warrior_sign{
            left: 1%; bottom: 12%;
            width: 4vw;height: 4vw;
        }
        .black_mask{
            position: absolute; left: 0; top : 0;
            width : 100%; height : 100%;
            box-shadow: 0 0 5px black;
            background: url('../assets/ui/ui (110).png')rgba(0, 0, 0, 0.6) center no-repeat;
            background-size: 30% 10%;
        }
    }
    //顶部导航
    .top_navigation{
        position: absolute; left: 0; top: 0;
        width: 100vw;height: 12vh;
        // background:linear-gradient(to bottom right,black,rgb(86, 1, 1));
        // background: rgb(32, 31, 31);
        // transition: all .2s;
        // z-index: 2;
        .logo2{
            left: 0; bottom: 1vw;
            width: 52vw;height: 11vw;
            background: url('../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
            .text{
                width: 100%; height: 11vw;
                line-height: 11vw;
                font-size: 5.4vw;
                text-align: center;
                color: rgb(193, 193, 82);
                text-shadow: 0 0 0 2px black;
                text-indent: -15vw;
                transition: all .3s;
                font-family: 'FZLBJW';
            }
        }
        
        
        .back_home{
            right:7vw; bottom: 3vw;
            width: 7vw;
            height: 7vw;
            background: url('../assets/ui/ui\ \(85\).png')no-repeat;
            background-size: 100% 100%;
        }
        .back_home.click{
            transform: scale(.8);
        }
    
    }
    .down-enter,.down-leave-to{
        transform: translateY(-100%);
    }
    .down-enter-to,.down-leave{
        transform: translateY(0);
    }
    

    // 招募板块
    .main_recruit{
        right: 5vw; bottom: 8vw;
        width: 26vw; height: 26vw;
        background: url('../assets/ui/ui\ \(5\).png')no-repeat;
        background-size: 100% 100%;
        .logo1{
            position: absolute; left: 50%; top : 50%;
            transform : translate(-50%,-50%);
            width: 60%;
            height: 60%;
            background: url('../assets/ui/ui\ \(4\).png')no-repeat;
            background-size: 100% 100%;
        }
        .text{
            position: absolute; left: 50%; top : 80%;
            transform : translate(-50%,-50%);
            width: 16vw; height: 5vw;
            line-height: 5vw;
            opacity: .8;
            background: url('../assets/ui/ui\ \(6\).png')no-repeat;
            background-size: 100% 100%;
        }
    }
    .main_recruit.click{
        transform: scale(.9);
        .text{
            opacity: 1;
        }
    }

    //配置入口
    .settings_entrance,.account_btn{
        right: 5vw;top: 12vh;
        width: 8vw;height: 8vw;
        background: url('../assets/ui/ui (134).png')no-repeat;
        background-size: 100% 100%;
        box-shadow: 0 0 5px rgb(81, 81, 81);
    }
    .settings_entrance.click,.account_btn.click{
        transform: scale(.9);
    }
    .account_btn{
        margin: 13vw 0 0 0;
        background: url('../assets/ui/ui (135).png')no-repeat;
        background-size: 100% 100%;
    }
    
}
</style>