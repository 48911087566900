import Report from "../js/report";
//受到伤害后执行块
class GetContinueDamageExecuteBlock{
    constructor(applicator,tactics,execute,duration,getAdDmgExecute,getTactDmgExecute,getCommand,getActive,getCombo,getPassive){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //执行函数
        this.execute = execute;
        //被执行人
        this.beExecuted = null,
        //持续时间
        this.duration = duration;
        //受到攻击伤害执行
        this.getAdDmgExecute = getAdDmgExecute;
        //受到谋略伤害执行
        this.getTactDmgExecute = getTactDmgExecute;
        //受到战法伤害执行
        this.getTacticsDmgExecute = {
            command : getCommand,
            active : getActive,
            combo : getCombo,
            passive : getPassive
        };
        //伤害数值
        this.damageValue = 0;
        //伤害来自于
        this.damageFromWho = null;
        //伤害来源的战法
        this.damageFromTactics = null;
        //伤害的类型是谋略还是物理
        this.damageType = "攻击";
        //类型
        this.type = "";
        //是否是有害效果
        this.isAbnormal = true;
    }
    Init(target){
        this.beExecuted = target;
        target.AFTER_CONTINUE_DAMAGE.push(this);
        return true;
    }
    GetData(damageValue,damageFromWho,damageFromTactics,damageType){
        //获取伤害数值
        this.damageValue = damageValue;
        //获取伤害来源者
        this.damageFromWho = damageFromWho;
        //获取伤害来源战法
        this.damageFromTactics = damageFromTactics;
        //获取伤害类型
        this.damageType = damageType;

    }
    Execute(){
        
        this.execute();
    }
}

export default GetContinueDamageExecuteBlock;