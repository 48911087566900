<template>
    <div class="my_warrior">
        <div class="warrior_container" ref="warrior_container">
            <!-- 武将卡 -->
            <div class="warrior_card"  v-for="(w,index) in $store.state.AllWarriors" :key="w.index" @click="SelectThisWarrior(index)" v-show="SysFilterWarrior(w)">
                <img v-if="imgLoad(index,w) && $store.state.equipment == 'phone' " :src="w.img.preview" alt="" class="cover pre_img">
                <img v-if="imgLoad(index,w) && $store.state.equipment == 'computer' " :src="w.img.body" alt="" class="cover pre_img">
                <img v-if="imgLoad(index,w)" src="../../assets/ui/ui (60).png" alt="" class="cover" >
                <ul v-if="imgLoad(index,w)" class="star_list">
                    <li v-for="s in w.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                    <li v-for="s in (5-w.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                </ul>
                <!-- 觉醒 -->
                <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-if="w.isUp">
                <img src="../../assets/ui/ui (84).png" alt="" class="up_mark" v-if="w.isUpWork">
                <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-if="w.isUp && w.isUpWork">
                <!-- 兵种 -->
                <div class="arm" v-if="imgLoad(index,w)">
                    <img src="../../assets/ui/ui (81).png" alt="" v-if="!w.isUpArm" class="cover">
                    <img src="../../assets/ui/ui (82).png" alt="" v-if="w.isUpArms" class="cover">
                    <img src="../../assets/ui/ui (79).png" alt="" v-if="w.arms == '步' && !w.isUpArms" class="icon">
                    <img src="../../assets/ui/ui (70).png" alt="" v-if="w.arms == '骑' && !w.isUpArms" class="icon">
                    <img src="../../assets/ui/ui (62).png" alt="" v-if="w.arms == '弓' && !w.isUpArms" class="icon">

                    <img src="../../assets/ui/ui (71).png" alt="" v-if="w.arms == '骑' && w.isUpArms" class="icon">
                    <img src="../../assets/ui/ui (80).png" alt="" v-if="w.arms == '步' && w.isUpArms" class="icon">
                    <img src="../../assets/ui/ui (63).png" alt="" v-if="w.arms == '弓' && w.isUpArms" class="icon">
                    <div class="arm_num">{{w.range}}</div>
                </div>

                <div class="camp" v-if="w.camp == '蜀'"><img src="../../assets/ui/ui (75).png" alt="" class="cover"></div>
                <div class="camp" v-if="w.camp == '吴'"><img src="../../assets/ui/ui (78).png" alt="" class="cover"></div>
                <div class="camp" v-if="w.camp == '魏'"><img src="../../assets/ui/ui (77).png" alt="" class="cover"></div>
                <div class="camp" v-if="w.camp == '群'"><img src="../../assets/ui/ui (72).png" alt="" class="cover"></div>
                <div class="camp" v-if="w.camp == '汉'"><img src="../../assets/ui/ui (64).png" alt="" class="cover"></div>
                <div class="camp" v-if="w.camp == '晋'"><img src="../../assets/ui/ui (150).png" alt="" class="cover"></div>

                <img src="../../assets/ui/ui (61).png" alt="" class="cost_img" v-if="imgLoad(index,w)">
                <div class="name">{{w.name}}</div>
                <!-- 武将标志 -->
                <div class="warrior_sign" v-if="imgLoad(index,w)">
                    <img src="../../assets/ui/ui (66).png" alt="" v-if="w.sign == 's2'" class="cover">
                    <img src="../../assets/ui/ui (67).png" alt="" v-if="w.sign == 's3'" class="cover">
                    <img src="../../assets/ui/ui (68).png" alt="" v-if="w.sign == 'sp'" class="cover">
                    <img src="../../assets/ui/ui (69).png" alt="" v-if="w.sign == 'xp'" class="cover">
                </div>
                
                <div class="black_mask" v-if="!w.own"></div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="my_warrior_bottom">
            <div class="filter_warrior_panel_btn" ref="filter_warrior_panel_btn"
            @touchstart="$refs.filter_warrior_panel_btn.classList.add('click')"
            @touchend="$refs.filter_warrior_panel_btn.classList.remove('click')"
            @click="filter = true">筛 选</div>
        </div>



        <!-- 底部武将筛选板 -->
        <div class="filter_warrior_panel cover" v-show="filter">
            <div class="mask cover" @click="filter = false"></div>
            <div class="container">
                <div class="item">
                    <div class="text">阵营筛选</div>
                    <div class="filter_btn" @click="OperaFilterWarrior('camp','群')">群
                        <div class="current cover bg_qun" v-show="FILTER_TABLE.camp.value == '群'">群</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('camp','汉')">汉
                        <div class="current cover bg_han" v-show="FILTER_TABLE.camp.value == '汉'">汉</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('camp','魏')">魏
                        <div class="current cover bg_wei" v-show="FILTER_TABLE.camp.value == '魏'">魏</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('camp','蜀')">蜀
                        <div class="current cover bg_shu" v-show="FILTER_TABLE.camp.value == '蜀'">蜀</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('camp','吴')">吴
                        <div class="current cover bg_wu" v-show="FILTER_TABLE.camp.value == '吴'">吴</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('camp','晋')">晋
                        <div class="current cover bg_jin" v-show="FILTER_TABLE.camp.value == '晋'">晋</div>
                    </div>
                </div>
                <div class="item">
                    <div class="text">是否解锁</div>
                    <div class="filter_btn" @click="OperaFilterWarrior('own',true)">是
                        <div class="current cover bg_orange" v-show="FILTER_TABLE.own.value == true">是</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('own',false)">否
                        <div class="current cover bg_orange" v-show="FILTER_TABLE.own.value == false && FILTER_TABLE.own.status">否</div>
                    </div>
                </div>
                <div class="item">
                    <div class="text">是否进阶</div>
                    <div class="filter_btn long" @click="OperaFilterWarrior('up','tactics')">觉醒
                        <div class="current cover bg_orange" v-show="FILTER_TABLE.up.value == 'tactics'">觉醒</div>
                    </div>
                    <div class="filter_btn long" @click="OperaFilterWarrior('up','work')">深造
                        <div class="current cover bg_orange" v-show="FILTER_TABLE.up.value == 'work'">深造</div>

                    </div>
                    <div class="filter_btn long" @click="OperaFilterWarrior('up','arms')">兵种
                        <div class="current cover bg_orange" v-show="FILTER_TABLE.up.value == 'arms'">兵种</div>

                    </div>

                </div>
                <div class="item">
                    <div class="text">战法品质</div>
                    <div class="filter_btn" @click="OperaFilterWarrior('quality','S')">S
                        <div class="current cover bg_orange" v-show="FILTER_TABLE.quality.value == 'S'">S</div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('quality','A')">A
                    <div class="current cover bg_orange" v-show="FILTER_TABLE.quality.value == 'A'">A</div>
                        <div class="current"></div>
                    </div>
                    <div class="filter_btn" @click="OperaFilterWarrior('quality','B')">B
                    <div class="current cover bg_orange" v-show="FILTER_TABLE.quality.value == 'B'">B</div>
                        <div class="current"></div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 武将详情页 -->
        <WarriorInfo :currentWarrior="currentWarrior" v-if="openWarriorPanel"/>
    </div>
</template>

<script>
import WarriorInfo from '../../components/container/WarriorInfo.vue';
export default {
    name  :'MyWarrior',
    
    components:{
        WarriorInfo
    },
    data(){
        return{
            //默认是从0开始
            // currentWarrior : this.$store.state.AllWarriors[0],
            currentWarrior : null,
            imgIndex : -1,
            maxIndex : -1,
            //控制武将详情界面
            openWarriorPanel : false,
            //控制过滤面板的打开
            filter : false,
            //过滤表
            FILTER_TABLE:{
                //阵营
                camp:{
                    status : false,
                    value : '',
                },
                //是否拥有
                own : {
                    status : true,
                    value : true,
                },
                //进阶
                up : {
                    status : false,
                    value : '',
                },
                //战法品质
                quality : {
                    status : false,
                    value : '',
                }
            },


            lock : true,
        }
    },
    methods:{
        //点开武将详情界面
        SelectThisWarrior(index){
            this.currentWarrior = this.$store.state.AllWarriors[index];
            this.openWarriorPanel = true;
        },
        //图片慢速加载
        imgLoad(id,w){
            if(this.imgIndex >= id){
                if(this.lock){
                    return w.own ? true : false;
                }
                else return true;
            }
            else return false;
        },
        //过滤筛选武将
        SysFilterWarrior(w){
            if(this.flag){
                return false;
            }
            if(this.FILTER_TABLE.camp.status){
                //表示当前筛选为阵营
                if(w.camp != this.FILTER_TABLE.camp.value){
                    //不符合条件
                    return false;
                }
            }
            if(this.FILTER_TABLE.own.status){
                if(w.own != this.FILTER_TABLE.own.value){
                    return false;
                }
            }
            if(this.FILTER_TABLE.up.status){
                if(this.FILTER_TABLE.up.value == 'tactics'){
                    if(!w.isUp){
                        return false;
                    }
                }else if(this.FILTER_TABLE.up.value == 'work'){
                    if(!w.isUpWork){
                        return false;
                    }
                }else if(this.FILTER_TABLE.up.value == 'arms'){
                    if(!w.isUpArms){
                        return false;
                    }
                }
            }
            if(this.FILTER_TABLE.quality.status){
                if(w.tactics_quality != this.FILTER_TABLE.quality.value){
                    return false;
                }
            }
            return true;
        },
        //操作过滤条件
        OperaFilterWarrior(type,value){
            if(type == 'camp'){
                if(this.FILTER_TABLE.camp.value == value){
                    this.FILTER_TABLE.camp.status = false;
                    this.FILTER_TABLE.camp.value = '';
                }else{
                    this.FILTER_TABLE.camp.status = true;
                    this.FILTER_TABLE.camp.value = value;
                }
            }else if(type == 'own'){
                if(this.FILTER_TABLE.own.value == value){
                    this.FILTER_TABLE.own.status = false;
                    this.FILTER_TABLE.own.value = -1;
                }else{
                    this.FILTER_TABLE.own.status = true;
                    this.FILTER_TABLE.own.value = value;
                }
            }else if(type == 'up'){
                if(this.FILTER_TABLE.up.value == value){
                    this.FILTER_TABLE.up.status = false;
                    this.FILTER_TABLE.up.value = '';
                }else{
                    this.FILTER_TABLE.up.status = true;
                    this.FILTER_TABLE.up.value = value;
                }
            }else if(type == 'quality'){
                if(this.FILTER_TABLE.quality.value == value){
                    this.FILTER_TABLE.quality.status = false;
                    this.FILTER_TABLE.quality.value = '';
                }else{
                    this.FILTER_TABLE.quality.status = true;
                    this.FILTER_TABLE.quality.value = value;
                }
            }
            
        },
        //关闭武将详情界面
        CloseWarriorPanel(){
            this.openWarriorPanel = false;
        },
        //图片加载控制
        ImgLoadControl(){
            let count = 0;
            for(let i=0;i<this.$store.state.AllWarriors.length;i++){
                if(this.$store.state.AllWarriors[i].own && count <= 30){
                    this.maxIndex = i;
                    count ++;
                }
                if(count > 30) break;
            }
            this.imgIndex = this.maxIndex;
            let timer = setInterval(() => {
                this.imgIndex += 8;
                if(this.imgIndex > this.$store.state.AllWarriors.length){
                    clearInterval(timer); 
                    this.lock = false;
                }
            }, 10);
        }
    },
    mounted(){
        this.ImgLoadControl();
        this.$bus.$on('CloseWarriorPanel',this.CloseWarriorPanel);
    }
}
</script>

<style lang="less">
.my_warrior{
    position: absolute; left: 0; top : 0;
    width : 100%; height : 100%;
    background: url('../../assets/background/bg\ \(1\).jpg')right bottom no-repeat;
    background-size: cover;
    .warrior_container{
        left: 0; top: 15vh;
        width: 100vw;height: 68vh;
        padding: 0 0 2vh 0;
        overflow-y: scroll;
        overflow-x: hidden;
        .warrior_card{
            // background: transparent;
            .pre_img{
                animation: fadeInSlight .15s linear 1;
            }
        }
    }
    
    //底部
    .my_warrior_bottom{
        left: 0; bottom: 0;
        width: 100vw;
        height: 8vh;
        background: rgba(0, 0, 0, 0.4);
        //武将筛选按钮
        .filter_warrior_panel_btn{
            right: 5vw; bottom: 5vw;
            width: 20vw; height: 6vw;
            line-height: 6vw;
            color: rgb(213, 213, 213);
            background: url('../../assets/ui/ui (111).png')no-repeat;
            background-size: 100% 100%;
        }
        .filter_warrior_panel_btn.click{
            transform: scale(.8);
        }
    }
    //过滤面板
    .filter_warrior_panel{
        z-index: 1;
        .mask{
            background: rgba(0, 0, 0, 0.553);
        }
        .container{
            position: absolute; left: 0; bottom : 0;
            width: 100vw;height: 60vw;
            background: url('../../assets/background/bg\ \(25\).png')no-repeat;
            background-size: 100% 100%;
            animation: filter_warrior_panel .2s ease-out 1;
            @keyframes filter_warrior_panel {
               0%{
                  opacity: 0;
               }
               100%{
                  opacity: 1;
               }
            }
            .item{
                position: relative;
                width: 90vw;height:10vw; margin: 2vw 0 0 5vw;
                // background: #000;
                .text{
                    position: relative;float: left;
                    width: 20vw;height: 10vw;
                    line-height: 10vw;

                }
                .filter_btn{
                    position: relative; float: left;
                    width: 8vw;height: 6vw;
                    margin: 2vw 0 0 2vw;
                    background: rgb(54, 54, 54);
                    border-radius: 1vw;
                    line-height: 6vw;
                    .current{
                        color: white;
                        border-radius: 1vw;
                        line-height: 6vw;
                    }
                }
                .filter_btn.long{
                    width: 16vw;
                }
            }
            .item:nth-of-type(1){
                margin: 8vw 0 0 5vw;
            }
        }
    }


    
}
</style>