import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';

let activeS = [
    //三术奇谋
    {
        id : 0,
        name : "三术奇谋",
        type : "主动",
        odd : 50,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,178,'谋略',1.85);
            let attrValue = q.GetAdditionValue(self,18,'谋略',0.2);
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            for(let j=0;j<3;j++){
                let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
                let attrType = "";
                switch(j){
                    case 0 : attrType = '攻击';break;
                    case 1 : attrType = '谋略';break;
                    case 2 : attrType = '防御';break;
                }
                for(let i=0;i<res.length;i++){
                    let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                    let block = new AttrBlock(self,this,attrType,-attrValue,2,0);
                    block.Init(res[i],true,3);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //当阳桥
    {
        id : 1,
        name : "当阳桥",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let time = 1 + Math.floor(Math.random()*2);
            let plus_value = 10;//追加的增伤效果
            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            let res2 = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let block = new StatusBlock(self,this,'混乱',time,0);
                block.Init(res[i],true,3);
            }
            for(let i in res2){
                let block = new StatusBlock(self,this,'犹豫',2,2);
                let work = block.Init(res2[i],false,3);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(3,res2[i],`犹豫(预备)效果已施加`);
                }
            }
            for(let i in res2){
                let block = new StatusBlock(self,this,'怯战',3,3);
                let work = block.Init(res2[i],false,3);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(3,res2[i],`怯战(预备)效果已施加`);
                }
            }
            //追加
            if(self.name == '张飞'){
                let block = new DamageBlock(self,this,'主动',true,plus_value,false,false,2,0);
                block.Init(self,null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //三军之众
    {
        id : 2,
        name : "三军之众",
        type : "主动",
        odd : 35,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 151;
            let cureIndex = 1.575;

            let realRange = q.GetTacticsRange(self,this);
            for(let j=0;j<4;j++){
                let res = a.SelectTarget(self,realRange,true,false,3,true,true,this);
                //尽可能优先选择兵力最低的
                let low = 1000000;
                let lowest = null;
                for(let i in res){
                    if(res[i].hp < low){
                        let rand = Math.floor(Math.random()*100);
                        if(rand < 60){
                            lowest = res[i];
                            low = res[i].hp;
                        }
                    }
                }
                if(lowest != null){
                    let cure = c.CountCure(self,lowest,cureValue,cureIndex,this);
                    a.GetCure(self,lowest,cure,this,true,null);
                }
            }

            this.STATISTICS.times += 1;
        }
    },
    //河内世泽
    {
        id : 3,
        name : "河内世泽",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [1,2],
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            
            let realRange = q.GetTacticsRange(self,this);
            let rand = Math.floor(Math.random()*4);
            //落雷
            if(rand == 1){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【落雷】`);
                let damageValue1 = q.GetAdditionValue(self,148,'谋略',1.35);//落雷伤害率
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue1,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block = new StatusBlock(self,this,'混乱',1,0);
                    block.Init(res[i],true,3);
                }
            }
            //迷阵
            else if(rand == 2){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【迷阵】`);
                let damageValue2 = q.GetAdditionValue(self,155,'谋略',1.5);//迷阵伤害
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue2,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block = new StatusBlock(self,this,'暴走',1,0);
                    block.Init(res[i],true,3);
                }
            }
            //溃堤
            else if(rand == 3){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【溃堤】`);
                let damageValue = q.GetAdditionValue(self,79.8,'谋略',0.785);//溃堤伤害
                let attrValue = q.GetAdditionValue(self,10,'谋略',0.08);
                let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block = new AttrBlock(self,this,'攻击',-attrValue,2,false);
                    block.Init(res[i],true,3);
                }
            }
            //夹攻
            else{
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【夹攻】`);
                let damageValue = q.GetAdditionValue(self,89.6,'谋略',0.945);
                let targetNumber = 1 + Math.floor(Math.random()*2);
                let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
            }

            let rand1 = Math.floor(Math.random()*4);
            //伐谋
            if(rand1 == 1){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【伐谋】`);
                let damageValue = q.GetAdditionValue(self,209,'谋略',2.175);
                let value = q.GetAdditionValue(self,45,'谋略',0.25);
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block1 = new AttrBlock(self,this,'攻击',-value,2,false);
                    block1.Init(res[i],true,3);
                    let block2 = new AttrBlock(self,this,'谋略',-value,2,false);
                    block2.Init(res[i],true,3);
                }
            }
            //雀伏
            else if(rand1 == 2){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【雀伏】`);
                let damageValue = q.GetAdditionValue(self,165,'谋略',1.75);
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
            }
            //火辎
            else if(rand1 == 3){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【火辎】`);
                let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
                let damageValue = q.GetAdditionValue(self,75,'谋略',0.75);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block = new DamageStatus(self,this,'燃烧',damageValue,'谋略',1,0,true);
                    block.Init(res[i],true,3);
                }
            }
            //毒泉
            else{
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【毒泉】`);
                let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
                let damageValue = q.GetAdditionValue(self,85,'谋略',0.875);
                for(let i in res){
                    let block = new DamageStatus(self,this,'恐慌',damageValue,'谋略',2,0,true);
                    block.Init(res[i],true,3);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //浑水摸鱼
    {
        id : 4,
        name : "浑水摸鱼",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let block = new StatusBlock(self,this,'混乱',2,0);
                block.Init(res[i],true,3);
            }

            this.STATISTICS.times += 1;
        }
    },
    //一骑当千
    {
        id : 5,
        name : "一骑当千",
        type : "主动",
        odd : 30,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 280;
            if(self.driver.version == '平衡'){
                damageValue = 260;
            }
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //十面埋伏
    {
        id : 6,
        name : "十面埋伏",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,130,'谋略',1.375);//直接伤害率
            if(self.driver.version == '平衡'){
                damageValue = q.GetAdditionValue(self,140,'谋略',1.375);
            }
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let res2 = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res2){
                let block = new DamageBlock(self,this,'所有',true,-999,false,false,1,0);
                block.Init(res2[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //利兵谋胜
    {
        id : 7,
        name : "利兵谋胜",
        type : "主动",
        odd : 50,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,200,'谋略',2.25);
            let cureValue = 149;
            let cureIndex = 0.785;
            if(self.driver.version == '平衡'){
                cureValue = 130;
                cureIndex = 0.75;
            }
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let res2 = a.SelectTarget(self,realRange,true,false,1,false,true,this);
            res2.push(self);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res2){
                let cure = c.CountCure(self,res2[i],cureValue,cureIndex,this);
                a.GetCure(self,res2[i],cure,this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //妖术
    {
        id : 4,
        name : "妖术",
        type : "主动",
        odd : 50,
        range : 4,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let block = new StatusBlock(self,this,'暴走',2,0);
                block.Init(res[i],true,3);
            }

            this.STATISTICS.times += 1;
        }
    },
    //及锋而试
    {
        id : 52,
        name : "及锋而试",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 120;
            let damageAdd = 40;
            let moraleDecline = 10;

            let tag = '及锋而试' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let realDamage = self[tag] ? damageValue + self[tag] * damageAdd : damageValue;
                let damage = c.CountDamage(self,res[i],realDamage,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let value = moraleDecline;
                if(res[i].morale < value) value = res[i].morale;
                res[i].morale -= value;
                let r = new Report();
                r.InitExecuteReport(3,res[i],`士气降低${moraleDecline}(${res[i].morale})`);
            }
            if(self[tag]){
                self[tag]++;
            }
            else self[tag] = 1;
            let r= new Report();
            r.InitExecuteReport(3,self,`【及锋而试】伤害率提高${damageAdd * self[tag]}%`);
            this.STATISTICS.times += 1;
        }
    },
]

export default activeS;