<template>
    <div class="tactics_view">
        <!-- <Header/> -->
        <!-- 全部战法容器 -->
        <div class="tactics_container">
            <div class="part" v-for="(p,item,id) in $store.state.Tactics" :key="p.index">
                <div class="border"></div>
                <div class="topic">
                    {{ topic[id] }}
                </div>
                <div class="tactics_box" v-for="t in p" :key="t.index" @click="CheckOrUnlock(t)">
                    <img :src="TactImgUrl(t)" alt="" class="skill_image">
                    <img src="../../assets/ui/ui (100).png" class="skill_frame" v-if="t.quality == 'A' && t.sign != '典藏'">
                    <img src="../../assets/ui/ui (101).png" class="skill_frame" v-if="t.quality == 'S' && t.sign != '典藏'">
                    <img src="../../assets/ui/ui (102).png" class="skill_frame" v-if="t.quality == 'B' && t.sign != '典藏'">
                    <img src="../../assets/ui/ui (99).png" class="skill_frame" v-if="t.quality == 'A' && t.sign == '典藏'">
                    <img src="../../assets/ui/ui (98).png" class="skill_frame" v-if="t.quality == 'S' && t.sign == '典藏'">
                    <img src="../../assets/ui/ui (163).png" class="skill_frame" v-if="t.quality == 'B' && t.sign == '典藏'">
                    <div class="name">
                        <img src="../../assets/ui/ui (114).png" class="cover" v-if="t.quality == 'A'" alt="">
                        <img src="../../assets/ui/ui (113).png" class="cover" v-if="t.quality == 'S'" alt="">
                        <img src="../../assets/ui/ui (115).png" class="cover" v-if="t.quality == 'B'" alt="">
                        <div class="text">{{ t.name }}</div>
                    </div>
                    <!-- 未拥有的遮罩层 -->
                    <transition name="skill_mask_transition">
                    <div class="skill_mask" v-show="t.lock"></div>
                    </transition>
                    <img src="../../assets/ui/ui (95).png" class="quality_sign" v-if="t.quality == 'S'" alt="">
                    <img src="../../assets/ui/ui (96).png" class="quality_sign" v-if="t.quality == 'A'" alt="">
                    <img src="../../assets/ui/ui (97).png" class="quality_sign" v-if="t.quality == 'B'" alt="">
                </div>
            </div>
        </div>
        <!-- 控制是否解锁技能 -->
       <div class="unlock_skill_control">
           <div class="slide_block" ref="slide_block"></div>
           <div class="view_model" @click="ExchangeModel(false)">预览模式</div>
           <div class="unlock_model" @click="ExchangeModel(true)">解锁技能</div>
       </div>

       <!-- 战法详情控件 -->
       <TactInfo v-if="$store.state.CTPanel.status" :currentTact="currentTact"/>
    </div>
</template>

<script>
import TactInfo from '../../components/container/TactInfo.vue'
// import Save from '../../mixin/util/save';
export default {
    name : 'Tactics',
    components:{
        TactInfo
    },
    data(){
        return{
            topic : ['指挥战法',"主动战法","追击战法","被动战法"],
            isUnlock : false,
            currentTact : null,
            showInfo : false,
        }
    },
    methods:{
        //战法的图像
        TactImgUrl(t){
            switch(t.type){
                case "command" : return require('../../assets/ui/ui (92).png');
                case "active" : return require('../../assets/ui/ui (93).png');
                case "combo" : return require('../../assets/ui/ui (94).png');
                case "passive" : return require('../../assets/ui/ui (91).png');
            }
        },
        //查看战法详情或解锁战法
        async CheckOrUnlock(tact){
            this.currentTact = tact;
            if(!this.isUnlock) this.$store.state.CTPanel.status = true;
            else{
                this.currentTact.lock = !this.currentTact.lock;
                //开启脏位
                this.$store.state.TacticsDirty = true;
            }
        },
        //切换模式
        ExchangeModel(unlock){
            if(unlock) this.$refs.slide_block.classList.add('active');
            else this.$refs.slide_block.classList.remove('active');
            this.isUnlock = unlock;
        },
        //关闭战法信息面板
        CloseTacticsPanel(){
            this.showInfo = false;
        }
    },
    beforeDestroy(){
        //保存数据
        // let save = new Save(this);
        // save.SaveTacticsData();
        // save.SaveAppData();
    },
    mounted(){
        this.$bus.$on('CloseTacticsPanel',this.CloseTacticsPanel)
    }
}
</script>

<style>
@import url('./tactics.css');
</style>