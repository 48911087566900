<template>
    <div class="setting_page">

        <div class="setting_nav">
            <li v-for="(n,index) in navList" :key="n.index" @click="navIndex = index">
                <div class="current_bg" v-if="index == navIndex"></div>
                <div class="cover text">{{ n.name }}</div>
            </li>
        </div>


        <!-- 角色管理面板 -->
        <div class="account_manage" v-if="navIndex == 0">
            <div class="account_box" v-for="(u,index) in this.$store.state.App.userList" :key="u.index">
                <div class="current_mask cover" v-if="u.uuid == $store.state.User.uuid"></div>
                <div class="name">{{ u.name + (u.uuid == $store.state.User.uuid ? "（当前存档）" : "") }}</div>
                <div class="warrior_number">
                    <div class="title">武 将：</div>
                    <div class="text">{{u.WarriorsStorage.length}}</div>
                </div>
                <div class="tactics_number">
                    <div class="title">战 法：</div>
                    <div class="text">{{u.TacticsStorage.length}}</div>
                </div>
                <div class="adjust_btn" ref="adjust_btn" @touchstart="$refs.adjust_btn[index].classList.add('click')"
                @touchend="$refs.adjust_btn[index].classList.remove('click')"
                @click="AdjustAccount(index)">修 改</div>
            </div>
            <div class="add_account" ref="add_account"
            @touchstart="$refs.add_account.classList.add('click')"
            @touchend="$refs.add_account.classList.remove('click')" @click="AddAccount">
                添加新存档
            </div>
        </div>

        <!-- <div class="base_settings" v-if="navIndex == 1">
            <div class="btn" @click="Horizon">横屏</div>
        </div> -->


        <!-- 账户的详细面板 -->
        <div class="account_detail_panel" v-if="currentIndex != -1">
            <div class="mask cover" @click="currentIndex = -1"></div>
            <div class="container">
                <div class="title">存 档</div>
                <div class="close_btn" ref="close_btn"
                @touchstart="$refs.close_btn.classList.add('click')"
                @touchend="$refs.close_btn.classList.remove('click')"
                @click="currentIndex = -1"></div>
                <div class="name">
                    <div class="text">存档名称</div>
                    <input type="text" ref="name" :value="$store.state.App.userList[currentIndex].name">
                </div>
                <div class="describe">
                    <div class="text">存档介绍</div>
                    <input type="text" ref="describe" :value="$store.state.App.userList[currentIndex].describe">
                    <!-- <textarea>{{ $store.state.User.describe }}</textarea> -->
                </div>
                <div class="delete_btn" ref="delete_btn" 
                @touchstart="$refs.delete_btn.classList.add('click')"
                @touchend="$refs.delete_btn.classList.remove('click')"
                @click="DeleteAccount">删除存档</div>
                <div class="alter_btn" ref="alter_btn" 
                @touchstart="$refs.alter_btn.classList.add('click')"
                @touchend="$refs.alter_btn.classList.remove('click')"
                @click="AlterData">修 改</div>
                <div class="load_btn" ref="load_btn"
                @touchstart="$refs.load_btn.classList.add('click')"
                @touchend="$refs.load_btn.classList.remove('click')" 
                @click="LoadThisAccount">导入存档</div>
            </div>
        </div>

    </div>
</template>

<script>
import Load from '../../util/load';
import Save from '../../util/save';
export default {
    name  : 'Setting',
    data(){
        return{
            currentIndex : -1,//当前存档在数组中的索引
            navIndex : 0,
            navList : [
                {
                    name : '角色管理',
                    current : true,
                },
                {
                    name : "基础设置",
                    current : false,
                }
            ]
        }
    },
    methods:{
        //横屏
        Horizon(){
            this.$bus.$emit('horizon');
        },
        //添加用户
        AddAccount(){
            if(this.$store.state.App.userList.length >= 16){
                this.$bus.$emit('alert',"最多保存16个存档");
                return false;
            }
            let load = new Load(this);
            let res =  load.AddNewAccount();
            let save = new Save(this);
            save.SaveAppData();
            if(res){
                this.$bus.$emit('alert','存档添加成功');
            }
        },
        //调整存档
        AdjustAccount(index){
            this.currentIndex = index;
        },
        //删除存档
        DeleteAccount(){
            let that = this;
            if(this.$store.state.App.userList.length < 2){
                this.$bus.$emit('alert',"最少需要保留一个存档");
                return false;
            }
            else if(that.$store.state.App.userList[that.currentIndex].uuid == that.$store.state.App.current_user_uuid){
                this.$bus.$emit('alert',"不可删除当前存档");
                return false;
            }
            //真正删除存档
            let f = function(){
                //如果你要删除的账户是当前正在使用的账户
                // if(that.$store.state.App.userList[that.currentIndex].uuid == that.$store.state.App.current_user_uuid){
                //     that.$store.state.App.current_user_uuid = that.$store.state.App.userList[(that.currentIndex + 1)%that.$store.state.App.userList.length].uuid
                //     that.$store.state.User = that.$store.state.App.userList[(that.currentIndex + 1)%that.$store.state.App.userList.length];
                // }
                that.$store.state.App.userList.splice(that.currentIndex,1);
                that.currentIndex = -1;
                let save = new Save(that);
                save.SaveAppData();
                that.$bus.$emit('alert',"删除成功")
            }
            let params = {
                text : '确定删除这个存档吗？',
                callBack : f,
            }
            this.$store.commit('Alert',params);
        },
        //修改数据
        AlterData(){
            if(this.$refs.name.value.length > 8){
                this.$bus.$emit('alert',"名字不能超过8个字符");
                return false;
            }
            if(this.$refs.describe.value.length > 60){
                this.$bus.$emit('alert',"描述不能超过60个字符");
                return false;
            }
            this.$store.state.App.userList[this.currentIndex].name = this.$refs.name.value;
            this.$store.state.App.userList[this.currentIndex].describe = this.$refs.describe.value;
            this.$bus.$emit('alert',"修改成功")
        },
        //导入存档
        LoadThisAccount(){
            let that = this;
            let f = function(){
                that.$store.state.App.current_user_uuid = that.$store.state.App.userList[that.currentIndex].uuid;
                that.$store.state.User = that.$store.state.App.userList[that.currentIndex];
                let load = new Load(that);
                //更新切换后账号的武将战法数据
                load.InitAllWarriors();
                load.InitTactics();
                load.LoadWarriorData();
                load.LoadTacticsData();
                let save = new Save(that);
                save.SaveAppData();
                that.$router.push({
                    path : '/home'
                })
                that.currentIndex = -1;
            }
            let params = {
                text : `是否导入该存档【${this.$store.state.App.userList[this.currentIndex].name}】`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
        }
        
    },
    mounted(){
        console.log(this.$store.state.App);
    }
}
</script>

<style lang="less">
.setting_page{
    position: absolute; left: 0; top : 0;
    width : 100%; height : 100%;
    background: url('../../assets/background/bg\ \(1\).jpg')right bottom no-repeat;
    background-size: cover;
    .setting_nav{
        top: 12vh; left: 5vw;
        margin-top: 5vw;
        width: 90vw;height: 9vw;
        background: #00000044;
        li{
            position: relative; float: left;
            width: 30vw; height: 9vw;
            line-height: 9vw;
            .current_bg{
                width: 100%; height: 100%;
                background: url('../../assets/background/bg\ \(10\).png')right bottom no-repeat;
                background-size: 100% 100%;
            }
            .text{
                color: white;
            }
        }
    }
    .account_manage{
        top: 12vh; left: 5vw;
        margin-top: 18vw;
        width: 90vw;height: 70vh;
        overflow: scroll;
        background: #00000044;
        .account_box{
            position: relative; float: left;
            width: 90vw; height: 25vw;
            background: url('../../assets/background/bg\ \(16\).png')right bottom rgb(30, 30, 30) no-repeat;
            background-size: 100% auto;
            margin: 0 0 3vw 0;
            .current_mask{
                background: url('../../assets/background/bg\ \(16\).png')right bottom rgb(59, 59, 59) no-repeat;
                background-size: 100% auto;
            }
            .name{
                left: 5vw; top: 5vw;
                width: 50vw; height: 5vw;
                line-height: 5vw;
                text-align: left;
                color: wheat;
            }
            .warrior_number,.tactics_number{
                left: 5vw; top: auto; bottom: 3vw;
                width: 30vw; height: 8vw;
                .title{
                    width: 12vw; height: 8vw;
                    line-height: 8vw;
                    text-align: left;
                }
                .text{
                    text-align: left;
                    left: 12vw;
                    width: 12vw; height: 8vw;
                    line-height: 8vw;
                    color: rgb(255, 248, 234);
                }
            }
            .tactics_number{
                left: 30vw;
            }
            .delete_btn,.adjust_btn{
                left: auto; top: auto;
                right: 3vw; bottom: 3vw;
                width: 16vw; height: 5vw;
                line-height: 5vw; 
                color: black;
                font-weight: bold;
                background: rgb(219, 219, 219);
            }
            // .adjust_btn{
            //     right: 22vw;
            // }
            .delete_btn.click,.adjust_btn.click{
                transform: scale(.8);
            }
        }
        .add_account{
            position: relative;float: left;
            width: 90vw; height: 10vw;
            margin: 0 0 10vw 0;
            line-height: 10vw;
            color: white;
            background: url('../../assets/ui/ui\ \(170\).png')center no-repeat;
            background-size: 150% 100%;
            opacity: .7;
        }
        .add_account.click{
            transform: none;
            opacity: 1;
        }
    }
    .base_settings{
        top: 12vh; left: 5vw;
        margin-top: 18vw;
        width: 90vw;height: 60vh;
        overflow: scroll;
        background: #00000044;
        .btn{
            left: 0;top: 0;
            width: 16vw;
            height: 6vw;
            line-height: 6vw;
            color: black;
            background: white;
        }
    }
    .account_detail_panel{
        z-index: 11;
        position: fixed; left: 0; top: 0;
        width: 100%; height: 100%;
        .mask{
            background: #00000096;
        }
        .container{
            left: 0; top: 50%;
            width: 100vw; height: 80vw;
            transform: translateY(-50%);
            background: url('../../assets/background/bg (7).png')#393b3e bottom right no-repeat;
            background-size: cover;
            .title{
                left: 0; top: 6vw;
                width: 50vw;height: 10vw;
                background: url('../../assets/background/bg (5).png')no-repeat;
                background-size: 100% 100%;
                text-indent: 10vw;
                text-align: left;
                font-size: 4.2vw;
                font-family: "FZLBJW";
                line-height: 10vw;
            }
            .close_btn{
                left: auto; top: 6vw; right: 5vw;
                width: 7vw; height: 7vw;
                background: url('../../assets/ui/ui (85).png')no-repeat;
                background-size: cover;
            }
            .name,.describe{
                left: 5vw; top: 20vw;
                width: 90vw; height: 10vw;
                background: #0000003f;
                .text{
                    width: 20vw; line-height: 10vw;
                }
                input{
                    // font-size: 13px;
                    font-size: 2.8vw;
                    text-align: left;
                    left: 20vw; top: 1vw;
                    width: 60vw;height: 8vw;
                    outline: none;
                    border: none;
                    color: white;
                    background: #00000000;
                }
            }
            .describe{
                left: 5vw; top: 34vw;
            }
            .delete_btn,.alter_btn,.load_btn{
                left: 5vw; bottom: 5vw;
                width: 22vw; height: 6vw;
                line-height: 6vw;
                font-weight: bold;
                background: white;
                color: black;
            }
            .alter_btn{
                left: 30vw;
            }
            .load_btn{
                left: auto; right: 5vw;
            }
            .delete_btn.click,.alter_btn.click,.load_btn.click{
                transform: scale(.8);
            }
        }
    }
}
</style>