import AttrBlock from "../block/attr";
import StatusBlock from "../block/status";
import Attack from "../js/attack";
import Query from "../js/query";
import ExecuteBlock from '../block/execute.js';
import DamageStatus from "../block/damageStatus";
import Report from "../js/report.js";
import RangeBlock from "../block/range";
import CounterAttackBlock from "../block/counterAtk.js";
import Judge from "../js/judge.js";
import Count from "../js/count.js";
import DamageBlock from "../block/damage.js";
import Util from "../js/util.js";
import AfterDamageBlock from "../block/execute1.js";
let hanAArr = [
    //貂蝉
    {
        id : 10,
        name : "闭月",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value = q.GetAdditionValue(self,29,'谋略',0.15);
            if(self.driver.version == '平衡'){
                value = q.GetAdditionValue(self,35,'谋略',0.15);
            }

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let block = new StatusBlock(self,this,"暴走",2,0);
                block.Init(res[i]);
            }
            for(let i in res){
                let block = new AttrBlock(self,this,"防御",-value,2);
                block.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //灵帝
    {
        id : 20,
        name : "帝临回光",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let j = new Judge();
            let damageValue = q.GetAdditionValue(self,59,'谋略',0.5);
            let damageValue1 = 70;//分兵伤害
            if(self.driver.version == '平衡'){
                damageValue = q.GetAdditionValue(self,65,'谋略',0.5);
                damageValue1 = 75;//分兵伤害
            }

            //指定敌军群体
            let res = a.SelectTarget(self,5,false,true,2,false,true,this);
            let block3 = new StatusBlock(self,this,'无视规避',1,0);
            block3.Init(self,false);
            for(let i in res){
                let block = new DamageStatus(self,this,"恐慌",damageValue,"谋略",99,2,false);
                let work = block.Init(res[i],false);
                if(work){
                    let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,that,'恐慌');
                    let damageMake = function(){
                        let tgt = this.beExecuted;
                        if(self.driver.turn > 2 && tgt.alive){
                            let avail = false;
                            for(let j in tgt.SPECIAL_STATUS){
                                if(tgt.SPECIAL_STATUS[j].tactics == that) avail = true;
                            }
                            if(avail){
                                let r = new Report();
                                r.InitExecuteReport(2,res[i],`由于【帝临回光】的恐慌，损失了${damage}兵力(${res[i].hp - damage})`,false);
                                a.GetHurt(self,tgt,damage,false,'谋略',that,false,r,true);
                            }
                        }
                    }
                    let block = new ExecuteBlock(self,that,'行动开始',damageMake,99);
                    block.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`恐慌(预备)效果已施加`)
                }
                
            }
            let block1 = new CounterAttackBlock(self,that,'分兵',damageValue1,100,99,3);
            let work = block1.Init(self,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,self,`分兵(预备)效果已施加`)
            }
            let block2 = new RangeBlock(self,that,true,1,99,3);
            let work1 =  block2.Init(self,false);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,self,`提高攻击距离(预备)效果已施加`)
            }
            let action = function(){
                if(self.driver.turn == 3){
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`获得分兵效果`);
                    }
                    if(work1){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`攻击距离提高1(${q.GetRealAdRange(self)})`);
                    }
                }
            }
            let arr = [];
            for(let i in self.STATUS){
                if(self.STATUS[i].tactics != this.tactics && self.STATUS[i].type == '无视规避') arr.push(self.STATUS[i]);
            }
            self.STATUS = arr;
            let block = new ExecuteBlock(self,this,"行动开始",action,3);
            block.Init(self);
            this.STATISTICS.times += 1;
        }
    },
    //十常侍
    {
        id : 49,
        name : "乱政",
        type : "主动",
        odd : 120,
        range : 4,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value1 = q.GetAdditionValue(self,75,'谋略',0.95);//妖术,恐慌伤害率
            let value2 = q.GetAdditionValue(self,50,'谋略',0.75);//火攻伤害
            let value3 = 100;//动摇伤害率

            let realRange = q.GetTacticsRange(self,this);
            for(let j=0;j<3;j++){
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let seed = Math.floor(Math.random()*10);
                    let statusType = "";
                    if(seed == 0 || seed == 1){
                        switch(seed){
                            case 0 : statusType = "妖术";break
                            case 1 : statusType = "恐慌";break
                        }
                        let block = new DamageStatus(self,this,statusType,value1,"谋略",2,0,true);
                        block.Init(res[i],true);
                    }
                    else if(seed == 2){
                        let block = new DamageStatus(self,this,"火攻",value2,"谋略",2,0,true);
                        block.Init(res[i],true);
                    }
                    else if(seed == 3){
                        let block = new DamageStatus(self,this,"动摇",value3,"攻击",2,0,true);
                        block.Init(res[i],true);
                    }
                    if(seed == 4 || seed == 5 || seed == 6 || seed == 7 || seed == 8 || seed == 9){
                        switch(seed){
                            case 4 : statusType = '挑衅';break;
                            case 5 : statusType = '混乱';break;
                            case 6 : statusType = '暴走';break;
                            case 7 : statusType = '怯战';break;
                            case 8 : statusType = '犹豫';break;
                            case 9 : statusType = '围困';break;
                        }
                        let block = new StatusBlock(self,this,statusType,2,0);
                        block.Init(res[i],true);
                    }
                }
            }

            this.STATISTICS.times += 1;
        }
    },
    //朱儁
    {
        id : 73,
        name : "西乡武功",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = 191;
            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,true,this);
            for(let i in res){
                let action = function(){
                    if(self.driver.turn == 2){
                        let targetArr = a.SelectTarget(res[i],4,false,true,2,false,true,that);
                        let realDamageValue = q.GetAdditionValue(res[i],damageValue,'谋略',2.075);
                        let r = new Report();
                        r.InitExecuteReport(2,res[i],`执行【西乡武功】`)
                        for(let j in targetArr){
                            let damage = c.CountDamage(res[i],targetArr[j],realDamageValue,'谋略',false,that,null);
                            a.GetHurt(res[i],targetArr[j],damage,false,'谋略',that,true,null);
                        }
                        //下一次受到所有伤害大幅降低
                        let block = new DamageBlock(self,that,'所有',false,-999,true,false,99,0);
                        block.Init(res[i],null,true);
                    }
                }
                let block = new ExecuteBlock(self,this,'行动开始',action,2);
                let work1 =  block.Init(res[i],true);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`【西乡武功】效果已施加`)
                }
                //添加先手
                let status = new StatusBlock(self,this,'先手',2,0);
                let work2 = status.Init(res[i],false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`先手效果已施加`)
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //王允
    {
        id : 120,
        name : "连环计",
        type : "主动",
        odd : 30,
        range : 4,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            //伐谋伤害
            let damageValue = q.GetAdditionValue(self,209,'谋略',2.175);
            let value = q.GetAdditionValue(self,45,'谋略',0.25);
            //落雷
            let damageValue1 = q.GetAdditionValue(self,148,'谋略',1.35);
            //迷阵
            let damageValue2 = q.GetAdditionValue(self,155,'谋略',1.5);
            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            //执行伐谋
            let r = new Report();
            r.InitExecuteReport(3,self,`执行【伐谋】`);
            let combo = false;
            let combo1 = false;
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                if(q.GetAttr(res[i],'谋略') < q.GetAttr(self,'谋略')){
                    combo = true;
                }
                if(j.IsGetStatus(res[i],'暴走')){
                    combo1 = true;
                }
            }
            for(let i in res){
                let block1 = new AttrBlock(self,this,'攻击',-value,2,false);
                block1.Init(res[i],true,3);
                let block2 = new AttrBlock(self,this,'谋略',-value,2,false);
                block2.Init(res[i],true,3);
            }
            //执行迷阵
            if(combo){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【迷阵】`);
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue2,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block = new StatusBlock(self,this,'暴走',1,0);
                    block.Init(res[i],true,3);
                }
            }
            //执行落雷
            if(combo1){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【落雷】`);
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue1,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
                for(let i in res){
                    let block = new StatusBlock(self,this,'混乱',1,0);
                    block.Init(res[i],true,3);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //许劭
    {
        id : 132,
        name : "举抑臧否",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = q.GetAdditionValue(self,20,'谋略',0.18);
            let odd = 60;//触发几率
            
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【举抑臧否】`)
                let attrType = '';
                let rand = Math.floor(Math.random()*3);
                if(rand == 0) attrType = '攻击';
                if(rand == 1) attrType = '防御';
                if(rand == 2) attrType = '谋略';

                let low = 10000;
                let high = -10000;
                let lowest = null;
                let highest = null;
                let fri = a.SelectTarget(self,5,true,false,3,true,false,that);
                let en = a.SelectTarget(self,5,false,true,3,false,false,that);
                for(let i in fri){
                    let num = q.GetAttr(fri[i],attrType);
                    if(num > high){
                        high = num;
                        highest = fri[i];
                    }
                    else if(num == high){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            high = num;
                            highest = fri[i];
                        }
                    }

                }
                for(let i in en){
                    let num = q.GetAttr(en[i],attrType);
                    if(num < low){
                        low = num;
                        lowest = en[i];
                    }
                    else if(num == low){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            low = num;
                            lowest = en[i];
                        }
                    }
                }
                //目标敌人
                if(lowest != null){
                    let block = new AttrBlock(self,that,attrType,-value,1,false);
                    block.Init(lowest,true,3);
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let type = "";
                        let typeRand = Math.floor(Math.random()*3);
                        switch(typeRand){
                            case 0 : type = '犹豫';break;
                            case 1 : type = '怯战';break;
                            case 2 : type = '围困';break;
                        }
                        let block = new StatusBlock(self,that,type,1,0);
                        block.Init(lowest,true,3);
                    }
                }
                //目标友军
                if(highest != null){
                    let block = new AttrBlock(self,that,attrType,value,1,false);
                    block.Init(highest,true,3);
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let type = "";
                        let typeRand = Math.floor(Math.random()*3);
                        switch(typeRand){
                            case 0 : type = '先手';break;
                            case 1 : type = '洞察';break;
                            case 2 : type = '无视规避';break;
                        }
                        let block = new StatusBlock(self,that,type,1,0);
                        block.Init(highest,true,3);
                    }
                }

            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【举抑臧否】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //阴丽华
    {
        id : 136,
        name : "辞后定朝",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步','弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let u = new Util();
            let duration = 4;
            let odd = 90;//触发几率
            let value = q.GetAdditionValue(self,40,'谋略',0.14);

            let res = a.SelectTarget(self,5,true,false,3,true,false,this);
            for(let i in res){
                if(res[i].gender == '男'){
                    let block = new AttrBlock(self,this,'攻击',0,99,false,true);
                    let work = block.Init(res[i],false,2);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(2,res[i],`攻击属性提高(预备)效果已施加`);
                    }
                }
                else{
                    let block = new AttrBlock(self,this,'谋略',0,99,false,true);
                    let work = block.Init(res[i],false,2);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(2,res[i],`谋略属性提高(预备)效果已施加`);
                    }
                }
                let block = new AttrBlock(self,this,'防御',0,99,false,true);
                let work = block.Init(res[i],false,2);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`防御属性提高(预备)效果已施加`);
                }
            }
            let action = function(){
                if(self.driver.turn == 4){
                    for(let i in res){
                        for(let j in res[i].ATTR){
                            let tgt = res[i].ATTR[j];
                            if(tgt.tactics == that){
                                tgt.value += value;
                                let r = new Report();
                                r.InitTwoTargetReport(2,self,res[i],`【辞后定朝】令`,`${tgt.attrType}属性提高${value}(${q.GetAttr(res[i],`${tgt.attrType}`)})`);
                            }
                        }
                    }
                }
                else{
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let r = new Report();
                        r.InitExecuteReport(2,self,`执行【辞后定朝】`);
                        u.ClearStatus(self,that,true,3);
                        u.ClearStatus(self,that,false,3);
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(2,self,`【辞后定朝】没有生效，生效几率为${realOdd}%`);
                    }
                }
                
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,duration);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //卫子夫
    {
        id : 137,
        name : "持玺兴兵",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 200;
            let cureIndex = 1.75;
            let time = 3;//触发次数
            let attrValue = 30;//属性值
            if(self.driver.version == '平衡'){
                cureValue = 250;
                attrValue = 35;
            }

            let tag = '持玺兴兵' + self.belong + self.position;
            let res = a.SelectTarget(self,2,true,false,2,false,false,this);
            let getHurt = function(){
                let tgt = this.beExecuted;
                if(tgt.hp < q.GetMaxHp(tgt)*0.5 && self[tag] < time){
                    let cure = c.CountCure(self,tgt,cureValue,cureIndex,that);
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,tgt,`【持玺兴兵】令`,`恢复了${cure}兵力(${tgt.hp + cure})`,false);
                    a.GetCure(self,tgt,cure,that,true,r);
                    let block1 = new AttrBlock(self,that,'攻击',attrValue,99,true,true);
                    let work1 = block1.Init(tgt,false,3);
                    if(work1){
                        block1.InitTwoTargetReport(that,'攻击',self,tgt,true,3);
                    }
                    let block2 = new AttrBlock(self,that,'谋略',attrValue,99,true,true);
                    let work2 = block2.Init(tgt,false,3);
                    if(work2){
                        block2.InitTwoTargetReport(that,'谋略',self,tgt,true,3);
                    }
                    if(self.alive){
                        let block1 = new AttrBlock(self,that,'攻击',-attrValue,99,true,true);
                        let work1 = block1.Init(self,false,3);
                        if(work1){
                            block1.InitTwoTargetReport(that,'攻击',self,self,false,3);
                        }
                        let block2 = new AttrBlock(self,that,'谋略',-attrValue,99,true,true);
                        let work2 = block2.Init(self,false,3);
                        if(work2){
                            block2.InitTwoTargetReport(that,'谋略',self,self,false,3);
                        }
                    }
                    self[tag] += 1;
                }
            }
            for(let i in res){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(res[i],'持玺兴兵');
            }
            self[tag] = 0;//标记

            
            this.STATISTICS.times += 1;
        }
    },
]

export default hanAArr;