<template>
    <div class="c-tact_info_view">
        <div class="mask cover" @click="Close"></div>
        <div class="container">
            
            <div class="close_btn" ref="close_btn" 
            @touchstart="$refs.close_btn.classList.add('click')"
            @touchend="$refs.close_btn.classList.remove('click')" 
            @click="Close"></div>
            <div class="texture"></div>
            <div class="topic">战法详情</div>
            <div class="tactics">
                <div class="tactics_icon">
                    <img src="../../assets/ui/ui (91).png" alt="" class="cover" v-if="currentTact.type == 'passive'">
                    <img src="../../assets/ui/ui (92).png" alt="" class="cover" v-if="currentTact.type == 'command'">
                    <img src="../../assets/ui/ui (93).png" alt="" class="cover" v-if="currentTact.type == 'active'">
                    <img src="../../assets/ui/ui (94).png" alt="" class="cover" v-if="currentTact.type == 'combo'">

                    <img src="../../assets/ui/ui (89).png" alt="" class="icon" v-if="currentTact.quality == 'B'">
                    <img src="../../assets/ui/ui (88).png" alt="" class="icon" v-if="currentTact.quality == 'A'">
                    <img src="../../assets/ui/ui (90).png" alt="" class="icon" v-if="currentTact.quality == 'S'">
                    <div class="name">{{currentTact.name}}</div>
                </div>
                <div class="odds">发动几率 : {{ currentTact.odds }}% &nbsp;&nbsp;|&nbsp;&nbsp; 范围 : {{ currentTact.range }}</div>
                <div class="describe">{{currentTact.describe[0]}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'TactInfo',
    props : ['currentTact'],
    methods:{
        Close(){
            this.$store.state.CTPanel.status = false;
        }
    }
}
</script>

<style lang="less">
.c-tact_info_view{
    left: 0; top : 0;
    width : 100%; height : 100%;
    z-index: 1;
    .mask{
        background: radial-gradient(rgba(0, 0, 0, 0.5),rgb(0, 0, 0));
    }
    .container{
        left: 0; top: 50%;
        width: 100vw;height: 80vw;
        margin: -40vw 0 0 0;
        background: url('../../assets/background/bg\ \(7\).png')#393b3e bottom right no-repeat;
        background-size: cover;
        animation: warrior_info .2s ease 1;
        @keyframes warrior_info {
            0%{
                transform: scale(.7);
                opacity: 0;
            }
            100%{
                transform: scale(1);
                opacity: 1;
            }
        }
        .close_btn{
            left: auto;right: 3vw; top: 3vw;
            height: 8vw; width: 8vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: cover;
        }
        .close_btn.click{
            transform: scale(.8);
        }
        .texture{
            left: 0; top: 2vw; width: 50vw; height: 10vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
        }
        .topic{
            left: 5vw; top:4vw;
            width: 20vw;height: 6vw;
            line-height: 6vw;
            font-size: 4.5vw;
            font-family: "FZLBJW";
        }
        .tactics{
            left: 8vw;top: 18vw;
            width: 84vw;height: 49vw;
            background: rgba(0, 0, 0, 0.2);
            .tactics_icon{
                left: 5vw; top: 5vw;
                width: 15vw;
                height: 15vw;
                border-radius: 50%;
                .icon{
                    left: -2vw; top: -.3vw;
                    width: 18.9vw;
                    height: 18.9vw;
                }
                .name{
                    left: 0; top: 100%;
                    width: 15vw;height: 3vw;
                    line-height: 3vw;
                    font-size: 3.5vw;
                    font-family: "FZLBJW";
                    color: white;
                }
            }
            .odds{
                left: 28vw; top: 5vw;
                width: 48vw;height: 6vw;
                text-align: left;
                font-family: "微软雅黑";
                color: white;
            }
            .describe{
                left: 28vw; top: 15vw;
                width: 48vw;height: 28vw;
                text-align: left;
                overflow: scroll;
                font-family: "微软雅黑";
                color: white;
            }
            .describe::-webkit-scrollbar{
                display: none;
            }
        }
    }

}
</style>