import CureStatusBlock from "../block/cureStatus";
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import DodgeBlock from '../block/dodge.js'
import Report from "../js/report";
import Judge from "../js/judge.js";
import ExecuteBlock from "../block/execute.js";
import DamageBlock from "../block/damage.js";
import BeforeMakeDamageBlock from "../block/execute2.js";
import StatusBlock from "../block/status.js";
import Util from "../js/util.js";

let hanBArr = [
    //蔡文姬
    {
        id : 8,
        name : "胡笳离愁",
        type : "主动",
        odd : 35,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue1 = 157;//基础恢复率
            let cureValue2 = 206;//基础恢复率第二段休整
            let cureIndex1 = 1;//恢复率增长率
            let cureIndex2 = 1.875;//恢复率增长率
            if(self.driver.version == '平衡'){
                cureValue2 = 230;//基础恢复率第二段休整
            }

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,false,true,this);
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue1,cureIndex1,this);
                a.GetCure(self,res[i],cure,this,true);
                let cureBlock = new CureStatusBlock(self,this,"休整",cureValue2,cureIndex2,1,0,100);
                cureBlock.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //何太后
    {
        id : 11,
        name : "母仪浮梦",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let j = new Judge();
            let odd = 50;//触发几率
            let index = q.GetAdditionValue(self,30,'谋略',0.15);

            let realRange = q.GetTacticsRange(self,this);
            let friRes = a.SelectTarget(self,realRange,true,false,3,true,true,this);
            let res = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            //施加规避
            for(let i in friRes){
                let block = new DodgeBlock(self,this,99);
                block.Init(friRes[i],false);
                let r = new Report();
                r.InitExecuteReport(2,friRes[i],`规避(预备)效果已施加`);
            }
            let action1 = function(){
                if(this.damageType == '攻击'){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let target = this.beExecuted;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,'【母仪浮梦】令',`攻击伤害降低了${index}%`)
                        let block = new DamageBlock(self,this.tactics,"攻击",true,-index,true,false,0);
                        block.Init(target,null,false);
                    }
                }
            }
            let action2 = function(){
                if(this.damageType == '谋略'){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let target = this.beExecuted;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,'【母仪浮梦】令',`策略伤害降低了${index}%`)
                        let block = new DamageBlock(self,this.tactics,"谋略",true,-index,true,false,0);
                        block.Init(target,null,false);
                    }
                }
            }
            //施加减伤
            for(let i in res){
                //冲突检测
                if(j.IsExistDamageBlock(res[i],"指挥","攻击",true,false)){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`已存在攻击伤害降低效果`);
                }
                else{
                    let block = new BeforeMakeDamageBlock(self,this,action1,4);
                    block.Init(res[i]);
                }
                if(j.IsExistDamageBlock(res[i],"指挥","谋略",true,false)){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`已存在策略伤害降低效果`);
                }
                else{
                    let block = new BeforeMakeDamageBlock(self,this,action2,4);
                    block.Init(res[i]);
                }
            }


            this.STATISTICS.times += 1;
        }
    },
    //袁绍
    {
        id : 8,
        name : "四世三公",
        type : "主动",
        odd : 30,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageIndex = 150;//伤害率
            if(self.driver.version == '平衡'){
                damageIndex = 175;
            }

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,3,true,true,this);
            for(let i in res){
                let r = new Report();
                r.InitExecuteReport(3,res[i],`执行【四世三公】`);
                let targetArr = a.SelectTarget(res[i],5,false,true,realTargetNumber,false,true,this);
                if(targetArr.length > 0){
                    let damage = c.CountDamage(res[i],targetArr[0],damageIndex,"攻击",false,this,null);
                    a.GetHurt(res[i],targetArr[0],damage,false,"攻击",this,true,null);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //卢植
    {
        id : 22,
        name : "将倾之柱",
        type : "主动",
        odd : 30,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,85,'谋略',1.2);//伤害率
            let index = q.GetAdditionValue(self,49,'防御',0.12);//减伤

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true);
            }
            //让自己减伤
            let block1 = new DamageBlock(self,this,"攻击",false,-index,false,false,2);
            block1.Init(self,null);
            let block2 = new DamageBlock(self,this,"谋略",false,-index,false,false,2);
            block2.Init(self,null);
            this.STATISTICS.times += 1;
        }
    },
    //潘凤
    {
        id : 41,
        name : "上将潘凤",
        type : "主动",
        odd : 80,
        range : 3,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 355;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(1,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);

            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            //自己陷入混乱
            let block = new StatusBlock(self,this,"混乱",1,0);
            let work = block.Init(self,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(3,self,`下一回合陷入混乱`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //华佗
    {
        id : 43,
        name : "去疾",
        type : "主动",
        odd : 120,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 63;
            let cureIndex = 0.6;
            let maxCure = 1200;
            let maxCureIndex = 0;
            let odd = 35;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(2,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,false,true,this);
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                a.GetCure(self,res[i],cure,this,true);
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(3,self,`执行【去疾】`);
                    let cure = c.CountCure(self,res[i],maxCure,maxCureIndex,this);
                    a.GetCure(self,res[i],cure,this,true);
                }
            }


            this.STATISTICS.times += 1;
        }
    },
    //司马懿
    {
        id : 72,
        name : "冢虎",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [1,2],
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,150,'谋略',1.5);
            let targetNumber = 1 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,this,null);
                a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //王昭君
    {
        id : 135,
        name : "汉韵旷野",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let j = new Judge();
            let odd = 40;//触发几率
            let add_plus = 3;//几率提升
            let index = q.GetAdditionValue(self,30,'谋略',0.15);
            let duration = 99;//效果持续时间

            let tag = '汉韵旷野' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            let action1 = function(){
                if(this.damageType == '攻击'){
                    let realOdd = q.GetMoraleOdd(self,odd + this.beExecuted[tag]*add_plus);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let target = this.beExecuted;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,'【汉韵旷野】令',`攻击伤害降低了${index}%`)
                        let block = new DamageBlock(self,this.tactics,"攻击",true,-index,true,false,0);
                        block.Init(target,null,false);
                    }
                }
            }
            let action2 = function(){
                if(this.damageType == '谋略'){
                    let realOdd = q.GetMoraleOdd(self,odd + this.beExecuted[tag]*add_plus);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let target = this.beExecuted;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,'【汉韵旷野】令',`策略伤害降低了${index}%`)
                        let block = new DamageBlock(self,this.tactics,"谋略",true,-index,true,false,0);
                        block.Init(target,null,false);
                    }
                }
            }
            //添加标记
            for(let i in res){
                res[i][tag] = 0;
            }
            //施加减伤
            for(let i in res){
                //冲突检测
                if(j.IsExistDamageBlock(res[i],"指挥","攻击",true,false)){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`已存在攻击伤害降低效果`);
                }
                else{
                    let block = new BeforeMakeDamageBlock(self,this,action1,duration,true);
                    block.Init(res[i]);
                }
                if(j.IsExistDamageBlock(res[i],"指挥","谋略",true,false)){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`已存在策略伤害降低效果`);
                }
                else{
                    let block = new BeforeMakeDamageBlock(self,this,action2,duration,true);
                    block.Init(res[i]);
                }
            }
            let addOdd  = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【汉韵旷野】`);
                for(let i in res){
                    res[i].camp != '汉' ? res[i][tag] += 2 : res[i][tag] += 1;
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`【汉韵旷野】生效几率提升${res[i][tag] * add_plus}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',addOdd,duration);
            block.Init(self,false);

            this.STATISTICS.times += 1;
        }
    },
    
]

export default hanBArr;