<template>
    <div class="tactics_describe_panel">
        <div class="mask" @click="$bus.$emit('ShowTacticsInfo',false);"></div>
        <div class="container">
            <div class="close_btn" ref="close_btn" 
            @touchstart="$refs.close_btn.classList.add('click')"
            @touchend="$refs.close_btn.classList.remove('click')" 
            @click="$bus.$emit('ShowTacticsInfo',false);"></div>
            <div class="texture"></div>
            <div class="topic">战法详情</div>
            <div class="tactics">
                <div class="tactics_icon">
                    <img src="../../assets/ui/ui (91).png" alt="" class="cover" v-if="game.currentTacticsItem.type == 'passive'">
                    <img src="../../assets/ui/ui (92).png" alt="" class="cover" v-if="game.currentTacticsItem.type == 'command'">
                    <img src="../../assets/ui/ui (93).png" alt="" class="cover" v-if="game.currentTacticsItem.type == 'active'">
                    <img src="../../assets/ui/ui (94).png" alt="" class="cover" v-if="game.currentTacticsItem.type == 'combo'">

                    <img src="../../assets/ui/ui (89).png" alt="" class="icon" v-if="game.currentTacticsItem.quality == 'B'">
                    <img src="../../assets/ui/ui (88).png" alt="" class="icon" v-if="game.currentTacticsItem.quality == 'A'">
                    <img src="../../assets/ui/ui (90).png" alt="" class="icon" v-if="game.currentTacticsItem.quality == 'S'">
                    <div class="name">{{game.currentTacticsItem.name}}</div>
                </div>
                <div class="odds">发动几率 : {{ game.currentTacticsItem.odds == "--" ? 100 : game.currentTacticsItem.odds }}% &nbsp;&nbsp;|&nbsp;&nbsp; 范围 : {{ game.currentTacticsItem.range }}</div>
                <div class="describe">{{game.version == '默认' ? game.currentTacticsItem.describe[0] : game.currentTacticsItem.describe[1]}}</div>
            </div>
            <div class="unload_btn" ref="unload_btn"  @touchstart="$refs.unload_btn.classList.add('click')"
            @touchend="$refs.unload_btn.classList.remove('click')"  v-if="!game.control.isMainTactics"
            @click="UnloadTactics">卸下战法</div>
        </div>
    </div>
</template>

<script>
import Team from '../../util/team';

export default {
    name : 'TacticsDescribe',
    data(){
        return{
            game : this.$store.state.BorderChess,
        }
    },
    methods: {
        UnloadTactics(){
            let t = new Team();
            t.RemoveTactics(this.game.currentTacticsItem);
            this.$bus.$emit('ShowTacticsInfo',false);
        }
    },
}
</script>

<style lang="less">
.tactics_describe_panel{
    position: absolute;left: 0; top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    .mask{
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
        background: #0000008d;
    }
    .container{
        position: absolute; left: 0; top: 50%;
        width: 100vw; height: 84vw;
        margin: -42vw 0 0 0;
        background: url('../../assets/background/bg\ \(7\).png')#393b3e bottom right no-repeat;
        background-size: cover;
        animation: warrior_info .2s ease 1;
        @keyframes warrior_info {
            0%{
                transform: scale(.7);
                opacity: 0;
            }
            100%{
                transform: scale(1);
                opacity: 1;
            }
        }
        .close_btn{
            left: auto;right: 3vw; top: 3vw;
            height: 8vw; width: 8vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: cover;
        }
        .close_btn.click,.unload_btn.click{
            transform: scale(.8);
        }
        .texture{
            left: 0; top: 2vw; width: 50vw; height: 10vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
        }
        .topic{
            left: 5vw; top:4vw;
            width: 20vw;height: 6vw;
            line-height: 6vw;
            font-size: 4.5vw;
            font-family: "FZLBJW";
        }
        .tactics{
            left: 8vw;top: 18vw;
            width: 84vw;height: 49vw;
            background: rgba(0, 0, 0, 0.2);
            .tactics_icon{
                left: 5vw; top: 5vw;
                width: 15vw;
                height: 15vw;
                border-radius: 50%;
                .icon{
                    left: -2vw; top: -.3vw;
                    width: 18.9vw;
                    height: 18.9vw;
                }
                .name{
                    left: 0; top: 100%;
                    width: 15vw;height: 3vw;
                    line-height: 3vw;
                    font-size: 3.5vw;
                    font-family: "FZLBJW";
                    color: white;
                }
            }
            .odds{
                left: 28vw; top: 5vw;
                width: 48vw;height: 6vw;
                text-align: left;
                font-family: "微软雅黑";
                color: white;
            }
            .describe{
                left: 28vw; top: 15vw;
                width: 48vw;height: 28vw;
                text-align: left;
                overflow: scroll;
                font-family: "微软雅黑";
                color: white;
            }
            .describe::-webkit-scrollbar{
                display: none;
            }
        }
        .unload_btn{
            left: 50%; bottom: 6vw; top: auto;
            margin: 0 0 0 -12vw;
            width: 24vw; height: 6vw;
            background: white;
            color: black;
            line-height: 6vw;
            text-align: center;
            font-weight: bold;

        }
    }
}
</style>