//战法的释放前后执行函数块
class ActiveTacticsBlock{
    constructor(applicator,tactics,execute,duration,waitTime,isAfterExecute,tacticsType){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //是发动前调用还是发动后调用（默认发动后)
        this.isAfterExecute = isAfterExecute == undefined ? true : isAfterExecute;
        //持续时间
        this.duration = duration;
        //等待事件
        this.waitTime = waitTime
        //触发该效果的战法本体
        this.triggerTactics = null;
        //该块的持有者
        this.beExecuted = null;
        //执行函数
        this.execute = execute;
        //是发动什么类型战法后执行，默认主动
        this.tacticsType = tacticsType == undefined ? "主动" : tacticsType;
    }
    Init(target,prior = false){
        this.beExecuted = target;
        prior ? target.ACTIVE_TACTICS_EXECUTE.unshift(this) : target.ACTIVE_TACTICS_EXECUTE.push(this);
        return true;
    }
    Execute(triggerTactics){
        this.triggerTactics = triggerTactics;
        this.execute();
    }
}

export default ActiveTacticsBlock;