import Judge from "./judge";
import Query from "./query";
import Util from "./util";
import Report from "./report";
import Manage from "./manage";
//计算
class Count{
    constructor(){

    }
    
    //获取一个武将的属性
    GetAttr(target,attrType){
        let res = 0;
        //基础属性
        switch(attrType){
            case "攻击" : res = target.attr.ad;  break;
            case "谋略" : res = target.attr.tact; break;
            case "防御" : res = target.attr.de; break;
            case "速度" : res = target.attr.speed; break;
        }
        //读取属性堆加区的块
        for(let i in target.ATTR){
            if(target.ATTR[i].attrType == attrType){
                res += target.ATTR[i].value;
            }
        }
        return res;
    }
    AttackDamageIndex(attacker,damageType,isCommonAttack,originTactics){
        let res = 0;
        let count1 = [];
        //攻击方结算
        for(let i in attacker.DAMAGE_VAR){
            if(attacker.DAMAGE_VAR[i].isMakeDamage && attacker.DAMAGE_VAR[i].waitTime <= 0){
                //先检同一个一次性战法只可以被加载一次
                let unlock = true;
                if(attacker.DAMAGE_VAR[i].isOnceAttack){
                    for(let j in count1){
                        if(count1[j].tactics == attacker.DAMAGE_VAR[i].tactics) unlock = false;
                    }
                }
                if(unlock){
                    //伤害来源于战法
                    if(originTactics != null){
                        //指定战法增伤减伤
                        if(attacker.DAMAGE_VAR[i].tacticsItem != null && attacker.DAMAGE_VAR[i].tacticsItem == originTactics){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        //考虑常规攻击还是谋略加成，和主动还是追击战法加成
                        else if((attacker.DAMAGE_VAR[i].damageType == damageType || attacker.DAMAGE_VAR[i].damageType == originTactics.type) && attacker.DAMAGE_VAR[i].tacticsItem == null){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        else if(attacker.DAMAGE_VAR[i].damageType == '所有'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                    }
                    //伤害来源于普通攻击
                    else if(originTactics == null && isCommonAttack){
                        if(attacker.DAMAGE_VAR[i].damageType == damageType || attacker.DAMAGE_VAR[i].damageType == '普通攻击'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        else if(attacker.DAMAGE_VAR[i].damageType == '所有'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                    }
                    //伤害来源于反击或其他
                    else{
                        if(attacker.DAMAGE_VAR[i].damageType == damageType){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        else if(attacker.DAMAGE_VAR[i].damageType == '所有'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                    }
                    if(attacker.DAMAGE_VAR[i].isOnceAttack){
                        count1.push(attacker.DAMAGE_VAR[i]);
                    }
                }
            }
        }
        if(res < -90 && res >= - 666) res = 0.1;
        else if(res < -666) res = 0.09;//大幅减伤
        else res = 1 + res/100;
        // let r = new Report();
        // r.InitExecuteReport(3,attacker,`${res}`);
        return res;
    }
    //获取一个武将的增减伤指数
    GetIndexOfDamageVar(attacker,receiver,damageType,isCommonAttack,originTactics){
        let res = 0;
        let count1 = [];
        let count2 = [];
        //攻击方结算
        for(let i in attacker.DAMAGE_VAR){
            if(attacker.DAMAGE_VAR[i].isMakeDamage && attacker.DAMAGE_VAR[i].waitTime <= 0){
                //先检同一个一次性战法只可以被加载一次
                let unlock = true;
                if(attacker.DAMAGE_VAR[i].isOnceAttack){
                    for(let j in count1){
                        if(count1[j].tactics == attacker.DAMAGE_VAR[i].tactics) unlock = false;
                    }
                }
                if(unlock){
                    //伤害来源于战法
                    if(originTactics != null){
                        //指定战法增伤减伤
                        if(attacker.DAMAGE_VAR[i].tacticsItem != null && attacker.DAMAGE_VAR[i].tacticsItem == originTactics){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        //考虑常规攻击还是谋略加成，和主动还是追击战法加成
                        else if((attacker.DAMAGE_VAR[i].damageType == damageType || attacker.DAMAGE_VAR[i].damageType == originTactics.type) && attacker.DAMAGE_VAR[i].tacticsItem == null){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        else if(attacker.DAMAGE_VAR[i].damageType == '所有'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                    }
                    //伤害来源于普通攻击
                    else if(originTactics == null && isCommonAttack){
                        if(attacker.DAMAGE_VAR[i].damageType == damageType || attacker.DAMAGE_VAR[i].damageType == '普通攻击'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        else if(attacker.DAMAGE_VAR[i].damageType == '所有'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                    }
                    //伤害来源于反击或其他
                    else{
                        if(attacker.DAMAGE_VAR[i].damageType == damageType){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                        else if(attacker.DAMAGE_VAR[i].damageType == '所有'){
                            res += attacker.DAMAGE_VAR[i].value;
                        }
                    }
                    if(attacker.DAMAGE_VAR[i].isOnceAttack){
                        count1.push(attacker.DAMAGE_VAR[i]);
                    }
                }
            }
        }
        //防御方结算
        for(let i in receiver.DAMAGE_VAR){
            if(!receiver.DAMAGE_VAR[i].isMakeDamage && receiver.DAMAGE_VAR[i].waitTime <= 0){
                //先检同一个一次性战法只可以被加载一次
                let unlock = true;
                if(receiver.DAMAGE_VAR[i].isOnceAttack){
                    for(let j in count2){
                        if(count2[j].tactics == receiver.DAMAGE_VAR[i].tactics) unlock = false;
                    }
                }
                if(unlock){
                    //伤害来源于战法
                    if(originTactics != null){
                        //指定战法增伤减伤
                        if(receiver.DAMAGE_VAR[i].tacticsItem != null && receiver.DAMAGE_VAR[i].tacticsItem == originTactics){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                        //考虑常规攻击还是谋略加成，和主动还是追击战法加成
                        if((receiver.DAMAGE_VAR[i].damageType == damageType || receiver.DAMAGE_VAR[i].damageType == originTactics.type) && receiver.DAMAGE_VAR[i].tacticsItem == null){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                        else if(receiver.DAMAGE_VAR[i].damageType == '所有'){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                    }
                    //伤害来源于普通攻击
                    else if(originTactics == null && isCommonAttack){
                        if(receiver.DAMAGE_VAR[i].damageType == damageType || receiver.DAMAGE_VAR[i].damageType == '普通攻击'){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                        else if(receiver.DAMAGE_VAR[i].damageType == '所有'){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                    }
                    //伤害来源于反击或其他
                    else{
                        if(receiver.DAMAGE_VAR[i].damageType == damageType){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                        else if(receiver.DAMAGE_VAR[i].damageType == '所有'){
                            res += receiver.DAMAGE_VAR[i].value;
                        }
                    }
                    if(receiver.DAMAGE_VAR[i].isOnceAttack){
                        count2.push(receiver.DAMAGE_VAR[i]);
                    }
                }
                
            }
        }
        if(res < -90 && res >= - 666) res = 0.1;
        else if(res < -666) res = 0.09;//大幅减伤
        else res = 1 + res/100;
        // let r = new Report();
        // r.InitExecuteReport(3,attacker,`${res}`);
        return res;
    }
    //获取最终伤害或治疗量的调整
    GetFinalDamageOrCureValue(attacker,receiver,isDamageOrCure,value){
        if(isDamageOrCure != '伤害' && isDamageOrCure != '治疗'){
            console.log('this.isDamageOrCure字段出错',isDamageOrCure);
            return;
        }
        let res = value;
        let ratio = 0;
        let fix = 0;
        for(let i in attacker.DISCOUNT){
            if(attacker.DISCOUNT[i].isDamageOrCure == isDamageOrCure && attacker.DISCOUNT[i].output && attacker.DISCOUNT[i].isRatio){
                ratio += attacker.DISCOUNT[i].value;
            }
            else if(attacker.DISCOUNT[i].isDamageOrCure == isDamageOrCure && attacker.DISCOUNT[i].output && !attacker.DISCOUNT[i].isRatio){
                fix += attacker.DISCOUNT[i].value;
            }
        }
        for(let i in receiver.DISCOUNT){
            if(receiver.DISCOUNT[i].isDamageOrCure == isDamageOrCure && !receiver.DISCOUNT[i].output && receiver.DISCOUNT[i].isRatio){
                ratio += receiver.DISCOUNT[i].value;
            }
            else if(receiver.DISCOUNT[i].isDamageOrCure == isDamageOrCure && !receiver.DISCOUNT[i].output && !receiver.DISCOUNT[i].isRatio){
                fix += receiver.DISCOUNT[i].value;
            }
        }
        res += fix;
        if(ratio < -90) ratio = -90;
        res = Math.floor(res * (1+ratio/100));
        return res < 0 ? 0 : res;
    }
    //计算伤害
    CountDamage(attacker,receiver,value,damageType = "攻击",isCommonAttack = true,originTactics = null,statusDamageType = null){
        //originTactics表示伤害来源的战法
        //statusDamageType表示状态类型伤害
        let j = new Judge();
        let u = new Util();
        let m = new Manage();
        let q = new Query();
        //即将造成伤害之前调用执行函数
        for(let i in attacker.BEFORE_MAKE_DAMAGE){
            if(attacker.BEFORE_MAKE_DAMAGE[i].isMakeDamage){
                attacker.BEFORE_MAKE_DAMAGE[i].GetDamageData(damageType,isCommonAttack,originTactics,statusDamageType);
                attacker.BEFORE_MAKE_DAMAGE[i].Execute(attacker);
            }
        }
        //即将受到伤害之间调用执行函数
        for(let i in receiver.BEFORE_MAKE_DAMAGE){
            if(!receiver.BEFORE_MAKE_DAMAGE[i].isMakeDamage){
                receiver.BEFORE_MAKE_DAMAGE[i].GetDamageData(damageType,isCommonAttack,originTactics,statusDamageType);
                receiver.BEFORE_MAKE_DAMAGE[i].Execute(attacker);
            }
        }

        //基础权值
        let damage_num = 0;
        let attacker_ad = this.GetAttr(attacker,"攻击");
        let receiver_de = this.GetAttr(receiver,"防御");
        let attacker_tact = this.GetAttr(attacker,"谋略");
        let receiver_tact = this.GetAttr(receiver,"谋略");
        //攻防差
        let indexAd = attacker_ad - receiver_de * (1 - q.GetIgnoreValue(attacker,'防御'));
        if(indexAd > 0) indexAd = (3 - 500 / (250 + indexAd));
        else indexAd = (100 / (100 - indexAd));
        //谋略差
        let indexTact = receiver_tact * (1 - q.GetIgnoreValue(attacker,'谋略'));
        //增减伤数值
        let varValue = this.GetIndexOfDamageVar(attacker,receiver,damageType,isCommonAttack,originTactics);
        if(indexTact < 50) indexTact = 1;
        else{
            indexTact = (1 - (75 - 9375/(75 + receiver_tact))/100);
        }
        //兵力基础伤害
        let base = 0;
        if(damageType == '攻击'){
            if(statusDamageType == '动摇')
                base = 0.33 * (373 * attacker.hp) / (7700 + attacker.hp);
            else base = (373 * attacker.hp) / (7700 + attacker.hp);
        }
        else{
            if(statusDamageType == '燃烧' || statusDamageType == '妖术' || statusDamageType == '恐慌')
                base = 0.33 * (178 * attacker.hp) / (6459 + attacker.hp);
            else base = (178 * attacker.hp) / (6459 + attacker.hp);
        }
        //处于大幅降低情况下base伤害也降低
        if(varValue == 0.09){
            base *= varValue;
        }
        //策略/攻击基础伤害
        let plus = 0;
        if(damageType == '攻击'){
            if(statusDamageType == '动摇') plus = attacker_ad * 0.4;
            else plus = attacker_ad * 0.33;
        }
        else{
            if(statusDamageType == '燃烧' || statusDamageType == '妖术' || statusDamageType == '恐慌') plus = attacker_tact * 0.25;
            else plus = attacker_tact * 0.5 * indexTact;
        }
        plus = plus * varValue;
        //主要伤害
        let main = 0;
        if(damageType == '攻击'){
            main = (value/100) * (300 * attacker.hp) / (3500 + attacker.hp) * indexAd;
        }
        else{
            main = (value/100) * (300 * attacker.hp) / (3500 + attacker.hp) * indexTact;
        }
        main = main * varValue;
        //总伤害
        damage_num = base + plus + main;
        //标准伤害，攻击力：250 伤害率120 base:210 plus:89 main:370 总669
        //标准策略伤害，谋略：250 伤害率120 base:108 。。。
        // if(originTactics != null){
        //     console.log(`${attacker.name}`,Math.floor(base),Math.floor(plus),Math.floor(main),damage_num,indexTact,indexAd);
        // }
        //如果伤害大于目标当前兵力，则修改为等于当前兵力
        //兵种克制
        if(!j.IsGetStatus(attacker,"无视兵种")){
            let atk_arms = u.TransferArms(attacker.up_arms[attacker.choseArms]);
            let rec_arms = u.TransferArms(receiver.up_arms[receiver.choseArms]);
            //步兵打骑兵
            if(atk_arms == 'b'){
                if(rec_arms == 'q'){
                    if(attacker.up_arms[attacker.choseArms] != '长枪兵'){
                        if(receiver.up_arms[receiver.choseArms] == '重骑兵'){
                            damage_num = damage_num * 0.75;
                        }else{
                            damage_num = damage_num * 0.8;
                        }
                    }
                }else if(rec_arms == "g" && attacker.up_arms[attacker.choseArms] == '长枪兵'){
                    damage_num = damage_num * 0.8;
                }
            }
            //骑兵打弓兵
            else if(atk_arms == 'q'){
                if(rec_arms == 'g'){
                    if(attacker.up_arms[attacker.choseArms] == '重骑兵'){
                        if(receiver.up_arms[receiver.choseArms] == '弩兵'){
                            damage_num = damage_num * 0.7;
                        }else{
                            damage_num = damage_num * 0.75;
                        }
                    }else{
                        if(receiver.up_arms[receiver.choseArms] == '弩兵'){
                            damage_num = damage_num * 0.75;
                        }else{
                            damage_num = damage_num * 0.8;
                        }
                    }
                }
            }
            //弓兵打步兵
            else if(atk_arms == 'g'){
                if(rec_arms == 'b'){
                    if(attacker.up_arms[attacker.choseArms] == '弩兵'){
                        damage_num = damage_num * 0.75;
                    }else{
                        damage_num = damage_num * 0.8;
                    }
                }
            }
        }
        //设置伤害浮动因子,将最终伤害的1/15作为伤害浮动
        // let damage_float_factor = Math.floor(damage_num/20);
        // damage_num = Math.floor(damage_num + Math.random()*damage_float_factor);
        // damage_num = Math.floor(damage_num);
        if(damage_num > receiver.hp){
            damage_num = receiver.hp
        }
        let isDodge = j.IsDodge(receiver);
        if(isDodge && !j.IsGetStatus(attacker,"无视规避")){
            let reportLevel = 3
            let r = new Report();
            r.InitExecuteReport(reportLevel,receiver,`处于规避状态`);
            damage_num = 0;
            m.ManageDodgeBlock(receiver);
        }
        else if(j.IsGetStatus(attacker,"无视规避") && isDodge){
            let reportLevel = 0;
            if(isCommonAttack) reportLevel = 3;
            else reportLevel = 3;
            let r = new Report();
            r.InitExecuteReport(reportLevel,attacker,`无视规避，规避无效`);
        }
        damage_num = this.GetFinalDamageOrCureValue(attacker,receiver,'伤害',damage_num);
        //返回伤害
        if(damage_num < 0) damage_num = 0;
        return Math.floor(damage_num);
        
    }
    //计算治疗量
    CountCure(initiator,receiver,cureValue,cureIndex,tacticsItem){
        //initiator发起该恢复效果的武将
        //receiver是接受恢复效果的武将
        let q = new Query();
        let index = (q.GetAttr(initiator,"谋略") - 80) < 0 ? 0 : (q.GetAttr(initiator,"谋略") - 80);
        let base = cureValue + cureIndex * index;
        let cure = (300 * receiver.hp) / (3500 + receiver.hp);
        cure *= (base/100);
        //浮动5%
        // cure += cure * ((1 + Math.floor(Math.random()*5))/100);
        //最多只可以恢复失去兵力中80%的兵力
        let cure_max = (q.GetMaxHp(receiver) - receiver.hp) * 0.8;
        if(cure > cure_max){
            //如果质量量溢出
            cure = cure_max;
        }
        cure = this.GetFinalDamageOrCureValue(initiator,receiver,'治疗',cure);
        cure = Math.floor(Math.abs(cure));
        return cure;
    }
    
}


export default Count;