class ExecuteSysBlock{
    constructor(applicator,tactics,executeStage,execute,duration){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //什么时候执行
        this.executeStage = executeStage;
        //执行函数
        this.execute = execute;
        //持续时间
        this.duration = duration;
        //被执行人
        this.beExecuted = null;
    }
    Init(target,prior = false){
        this.beExecuted = target;
        prior ? target.driver.EXECUTE.unshift(this) : target.driver.EXECUTE.push(this);
        return true;
    }
    Executed(){
        this.execute();
    }
}

export default ExecuteSysBlock;