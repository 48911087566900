import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';
let passiveB = [
    //枭雄
    {
        id : 10,
        name : "枭雄",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let odd = 90;//触发几率

            let action = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new StatusBlock(self,that,'洞察',0,0);
                    block.Init(self,false);
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,self,`【枭雄】令`,`进入洞察状态`);
                    let block1 = new StatusBlock(self,that,'无法施法',0,0);
                    block1.Init(self,false,2);
                }
                else{
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,self,`【枭雄】对`,`没有生效，生效几率为${realOdd}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,4);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【枭雄】效果已施加`);
            //追加
            if(self.name == '曹操'){
                for(let i=0;i<4;i++){
                    let attrType = "";
                    let tgtAttr = 0;
                    switch(i){
                        case 0 : attrType = '攻击'; tgtAttr = self.attr.ad; break;
                        case 1 : attrType = '防御'; tgtAttr = self.attr.de; break;
                        case 2 : attrType = '谋略'; tgtAttr = self.attr.tact; break;
                        case 3 : attrType = '速度'; tgtAttr = self.attr.speed; break;
                    }
                    let value = Math.floor(0.1 * tgtAttr);
                    let block = new AttrBlock(self,this,attrType,value,99,false);
                    block.Init(self,true,2);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //谋定后动
    {
        id : 12,
        name : "谋定后动",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 100;//触发几率
            let value = 55;

            let action  = function(){
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【谋定后动】`);
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let res = a.SelectTarget(self,2,true,false,2,true,true,that);
                    for(let j in res){
                        for(let i=0;i<3;i++){
                            let attrType = "";
                            switch(i){
                                case 0 : attrType = '攻击'; break;
                                case 1 : attrType = '防御'; break;
                                case 2 : attrType = '谋略'; break;
                            }
                            let block = new AttrBlock(self,that,attrType,value,2,false);
                            block.Init(res[j],true,3);
                        }
                    }
                }
                if(this.triggerTactics.wait > 0){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let block = new StatusBlock(self,that,'洞察',2,0);
                        block.Init(self,true,3);
                    }
                }
            }
            let block = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【谋定后动】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //合众
    {
        id : 13,
        name : "合众",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let c = new Count();
            let a = new Attack();
            let cureValue = 260;
            let cureIndex = 0;

            let action = function(){
                if(self.driver.turn %2 == 0){
                    let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                    let r = new Report().InitTwoTargetReport(2,self,self,`【合众】令`,`恢复了${cure}兵力(${self.hp})`,false);
                    a.GetCure(self,self,cure,that,true,r);
                }
            }
            let block = new StatusBlock(self,this,'先手',99,0);
            block.Init(self,true,2);
            let block1 = new ExecuteBlock(self,this,'行动开始',action,99);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【合众】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //分险
    {
        id : 14,
        name : "分险",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let value = 32;
            let odd = 50;//触发减伤几率
            let value1 = 1;//攻击距离增加

            let block = new RangeBlock(self,this,true,value1,99,0);
            block.Init(self,true,2);
            let getHurt = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let tgt = this.attacker;
                    let block = new DamageBlock(self,that,'所有',true,-value,true,false,0,0);
                    let work = block.Init(tgt,null,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,tgt,`【分险】令`,`所有伤害降低${value}%`);
                    }
                }
            }
            let block1 = new BeforeMakeDamageBlock(self,this,getHurt,99,false);
            block1.Init(self);
            let r = new Report();
            r.InitExecuteReport(2,self,`【分险】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //百战精兵
    {
        id : 15,
        name : "百战精兵",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let value = 32;

            for(let i=0;i<4;i++){
                let attrType = "";
                switch(i){
                    case 0 : attrType = '攻击';break;
                    case 1 : attrType = '防御';break;
                    case 2 : attrType = '谋略';break;
                    case 3 : attrType = '速度';break;
                }
                let block = new AttrBlock(self,this,attrType,value,99,false);
                block.Init(self,true,2);
            }
            this.STATISTICS.times += 1;
        }
    },
    //回马
    {
        id : 16,
        name : "回马",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let damageValue = 60;//反击伤害

            let block1 = new CounterAttackBlock(self,this,'反击',damageValue,100,99,0);
            block1.Init(self,true,2);
            this.STATISTICS.times += 1;
        }
    },
    //青囊秘要
    {
        id : 17,
        name : "青囊秘要",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 150;
            let cureIndex = 0;

            let action  = function(){
                //立即回血
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【青囊秘要】`);
                let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                a.GetCure(self,self,cure,that,true,null);
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【青囊秘要】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
]

export default passiveB;