<template>
    <div class="lineup_panel">
        <div class="container">
            <div class="box" v-for="a,index in game.LineupContainer" :key="a.index" ref="box" 
            @touchstart="$refs.box[index].classList.add('click')"
            @touchend="$refs.box[index].classList.remove('click')"
            @click="ChoseThis(a)">
                <div class="warrior" v-for="w in a.warriorArr" :key="w.index">
                    <div class="head_img">
                        <div class="bg_wei cover" v-if="w.camp == '魏'"></div>
                        <div class="bg_shu cover" v-if="w.camp == '蜀'"></div>
                        <div class="bg_wu cover" v-if="w.camp == '吴'"></div>
                        <div class="bg_han cover" v-if="w.camp == '汉'"></div>
                        <div class="bg_qun cover" v-if="w.camp == '群'"></div>
                        <div class="bg_jin cover" v-if="w.camp == '晋'"></div>
                        <div class="img_box">
                            <img :src="require(`../../static/card_3/${w.originSkin}`)" alt="">
                        </div>
                        
                    </div>
                    <div class="skill_container">
                        <li v-for="s in w.tacticsArr" :key="s.index">
                            <div class="bg_command cover" v-if="s.type == 'command'"></div>
                            <div class="bg_active cover" v-if="s.type == 'active'"></div>
                            <div class="bg_combo cover" v-if="s.type == 'combo'"></div>
                            <div class="bg_passive cover" v-if="s.type == 'passive'"></div>
                            <div class="cover">{{ s.name }}</div>
                            <img src="../../assets/ui/ui (95).png" class="quality_sign" v-if="s.quality == 'S'" alt="">
                            <img src="../../assets/ui/ui (96).png" class="quality_sign" v-if="s.quality == 'A'" alt="">
                            <img src="../../assets/ui/ui (97).png" class="quality_sign" v-if="s.quality == 'B'" alt="">
                        </li>
                    </div>
                </div>
            </div>

        </div>
        <div class="detail_panel" v-if="display">
            <div class="mask" @click="display = false"></div>
            <div class="box" v-if="targetData != null">
                <div class="topic">
                    <div class="text">{{ targetData.name == "" ? "未命名" : targetData.name }}</div>
                </div>
                <div class="back_btn" ref="back_btn" @touchstart="$refs.back_btn.classList.add('click')"
                @touchend="$refs.back_btn.classList.remove('click')"
                @click="display = false"></div>
                <div class="warrior_box">
                    <li v-for="w in targetData.warriorArr" :key="w.index">
                        <img :src="require(`../../static/card_0/${w.originSkin}`)" class="cover" alt="">
                        <img src="../../assets/background/bg (37).png" class="cover" alt="">
                        <div class="tactics_box">
                            <div class="tactics" v-for="s in w.tacticsArr" :key="s.index">
                                <div class="bg_command cover" v-if="s.type == 'command'"></div>
                                <div class="bg_active cover" v-if="s.type == 'active'"></div>
                                <div class="bg_combo cover" v-if="s.type == 'combo'"></div>
                                <div class="bg_passive cover" v-if="s.type == 'passive'"></div>
                                <div class="cover">{{ s.name }}</div>
                                <img src="../../assets/ui/ui (95).png" class="quality_sign" v-if="s.quality == 'S'" alt="">
                                <img src="../../assets/ui/ui (96).png" class="quality_sign" v-if="s.quality == 'A'" alt="">
                                <img src="../../assets/ui/ui (97).png" class="quality_sign" v-if="s.quality == 'B'" alt="">
                            </div>
                        </div>
                    </li>
                </div>
                <div class="apply_btn_left" ref="apply_btn_left"
                @touchstart="$refs.apply_btn_left.classList.add('click')"
                @touchend="$refs.apply_btn_left.classList.remove('click')"
                @click="ApplyLineup(game.BlueTeam)">应用到我方</div>
                <div class="apply_btn_right" ref="apply_btn_right" @touchstart="$refs.apply_btn_right.classList.add('click')"
                @touchend="$refs.apply_btn_right.classList.remove('click')"
                @click="ApplyLineup(game.RedTeam)">应用到敌方</div>
                <div class="delete_btn" ref="delete_btn" @touchstart="$refs.delete_btn.classList.add('click')"
                @touchend="$refs.delete_btn.classList.remove('click')"
                @click="DeleteThis()">删 除</div>
            </div>
        </div>
        <div class="random_chose_left" ref="random_chose_left" 
        @touchstart="$refs.random_chose_left.classList.add('click')"
        @touchend="$refs.random_chose_left.classList.remove('click')"
        @click="RandomApplyLineup(game.BlueTeam)"
        >随机应用我方</div>
        <div class="random_chose_right" ref="random_chose_right"
        @touchstart="$refs.random_chose_right.classList.add('click')"
        @touchend="$refs.random_chose_right.classList.remove('click')"
        @click="RandomApplyLineup(game.RedTeam)"
        >随机应用敌方</div>
        <div class="clear_all_btn" ref="clear_all_btn" @click="ClearAll()"
        @touchstart="$refs.clear_all_btn.classList.add('click')"
        @touchend="$refs.clear_all_btn.classList.remove('click')"
        >清空阵容</div>
    </div>
</template>

<script>
export default {
    name : 'Lineup',
    data(){
        return{
            display : false,
            game : this.$store.state.BorderChess,

            targetData : null,
        }
    },
    methods:{
        ChoseThis(data){
            this.targetData = data;
            this.display = true;
        },
        //应用某个阵容
        ApplyLineup(whichTeam){
            for(let i in whichTeam.warriorArr){
                whichTeam.warriorArr[i].CopyData(this.targetData.warriorArr[i]);
            }
            this.$bus.$emit('alert','应用成功');
            this.display = false;
        },
        //随机应用阵容
        RandomApplyLineup(whichTeam){
            let that = this;
            if(this.game.LineupContainer.length == 0){
                this.$bus.$emit('alert','没有可选阵容');
                return;
            }
            let rand = Math.floor(Math.random()*this.game.LineupContainer.length);
            let tgt = this.game.LineupContainer[rand];
            let f = function(){
                for(let i in whichTeam.warriorArr){
                    whichTeam.warriorArr[i].CopyData(tgt.warriorArr[i]);
                }
                that.$bus.$emit('alert','应用成功');
                that.$router.back();
            }
            let params = {
                text : `确定随机应用阵容？`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
        },
        //删除某个阵容
        DeleteThis(){
            let that = this;
            if(that.game.LineupContainer.length == 0) return;
            let f = function(){
                for(let i in that.game.LineupContainer){
                    if(that.game.LineupContainer[i] == that.targetData){
                        that.display = false;
                        that.game.LineupContainer.splice(i,1);
                        that.$bus.$emit('alert','清除成功');
                        return;
                    }
                }
                that.$bus.$emit('alert','未找到清除对象');
            }
            let params = {
                text : `确定清除该阵容？`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
        },
        //清除所有阵容
        ClearAll(){
            let that = this;
            let f = function(){
                that.game.LineupContainer  = [];
                that.$bus.$emit('alert','清除成功');
            }
            let params = {
                text : `确定清除所有${that.game.LineupContainer.length}个阵容？`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
        }
    },
    mounted(){

    }

}
</script>

<style lang="less">
.lineup_panel{
    position: absolute;left: 0; top: 0;
    width: 100vw;
    height: 100vh;
    background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
    background-size: cover;
    .bg_command{
            background: url('../../assets/background/bg (44).png')#006000 bottom right no-repeat;
            background-size: cover;
        }
        .bg_active{
            background: url('../../assets/background/bg (44).png')#650000 bottom right no-repeat;
            background-size: cover;
        }
        .bg_combo{
            background: url('../../assets/background/bg (44).png')#6b0268 bottom right no-repeat;
            background-size: cover;
        }
        .bg_passive{
            background: url('../../assets/background/bg (44).png')#23008b bottom right no-repeat;
            background-size: cover;
        }
    .container{
        position: absolute; top: 15vh; left: 5vw;
        width: 90vw; height: 75vh;
        overflow: scroll;
        .box{
            position: relative;
            width: 90vw; height: 30vw;
            margin: 2vw 0 1vw 0;
            // background: hsla(0, 0%, 6%, 0.525);
            background: url('../../assets/ui/ui (174).png')rgba(24, 24, 24, 0.755) no-repeat;
            background-size: cover;
            .warrior{
                position: relative;float: left;
                width: 30vw; height: 30vw;
                .head_img{
                    position: absolute; left: 3vw; top: 3vw;
                    width: 24vw; height: 10vw;
                    .img_box{
                        position: absolute; left: 50%; top: 50%;
                        width: 22vw; height: 8vw;
                        transform: translate(-50%,-50%);
                        overflow: hidden;
                        img{
                            position: absolute; left: 50%; top: 50%;
                            height: 110%;
                            transform: translate(-50%,-50%);
                        }
                    }
                }
                .skill_container{
                    position: absolute; left: 3vw; top: 16vw;
                    width: 24vw; height: 12vw;
                    li{
                        position: relative; float: left;
                        margin: 0 0 2vw 0;
                        width: 24vw; height: 4.5vw;
                        
                        background: #000;
                        
                        color: white;
                        .quality_sign{
                            position: absolute; left: -1vw; top: -1vw;
                            width: 3vw; height: 3vw;
                        }
                        .cover{
                            line-height: 4vw;
                            font-size: 2.8vw;
                        }
                    }
                    
                }
            }
            
        }
        .box.click{
            opacity: 0.8;
        }
        
    }
    .detail_panel{
        position: absolute;left: 0; top: 0;
        width: 100vw; height: 100vh;
        z-index: 1;
        .mask{
            width: 100%; height: 100%;
            background: #0000008d;
        }
        .box{
            left: 0; top: 50%;
            width: 100vw; height: 100vw;
            margin: -50vw 0 0 0;
            background: url('../../assets/background/bg (7).png')#393b3e bottom right no-repeat;
            background-size: cover;
            .topic{
                top: 5vw;left: 0; bottom: auto;
                width: 52vw;height: 11vw;
                background: url('../../assets/background/bg (5).png')no-repeat;
                background-size: 100% 100%;
                .text{
                    width: 100%; height: 11vw;
                    line-height: 11vw;
                    font-size: 5.4vw;
                    text-align: center;
                    color: rgb(193, 193, 82);
                    text-shadow: 0 0 0 2px black;
                    text-indent: -15vw;
                    transition: all .3s;
                    font-family: 'FZLBJW';
                }
            }
            .back_btn{
                top: 6vw;left: auto; right: 5vw; bottom: auto;
                width: 7vw;
                height: 7vw;
                background: url('../../assets/ui/ui\ \(85\).png')no-repeat;
                background-size: 100% 100%;
            }
            .back_btn.click{
                transform: scale(.8);
            }
            .warrior_box{
                position: absolute; left: 15vw; top: 25vw;
                width: 70vw; height: 30vw;
                li{
                    position: relative;float: left;
                    width: 20vw; height: 30vw;
                    margin: 0 0 0 2.5vw;
                    .tactics_box{
                        position: absolute; left: 0; top: 32vw;
                        width: 20vw; height: 18vw;
                        .tactics{
                            position: relative; float: left;
                            margin: 0 0 2vw 0;
                            width: 20vw; height: 4.5vw;
                            box-shadow:0 0 1px #000;
                            color: white;
                            .quality_sign{
                                position: absolute; left: -1vw; top: -1vw;
                                width: 3vw; height: 3vw;
                            }
                            .cover{
                                line-height: 4vw;
                                font-size: 2.8vw;
                            }
                        }
                    }
                }
            }
            .apply_btn_left,.apply_btn_right,.delete_btn{
                position: absolute; left: 10vw; top: 80vw;
                width: 22vw; height: 6vw;
                line-height: 6vw;
                background: white;
                color: black;
                font-weight: bold;
            }
            .delete_btn{
                left: auto; right: 10vw;
            }
            .apply_btn_right{
                left: 39vw;
            }
            .apply_btn_left.click,.apply_btn_right.click,.delete_btn.click{
                transform: scale(.8);
            }
        }
    }
    .clear_all_btn,.random_chose_left,.random_chose_right{
        position: absolute; left: auto; right: 5vw; top: 90vh;
        margin: 5vw 0 0 0;
        width: 24vw; height: 6vw;
        line-height: 6vw;
        background: white;
        color: black;
        font-weight: bold;
    }
    .random_chose_left{
        left: 5vw; right: auto;
    }
    .random_chose_right{
        left: 35vw; right: auto;
    }
    .clear_all_btn.click,.random_chose_left.click,.random_chose_right.click{
        transform: scale(.8);
    }
}
</style>