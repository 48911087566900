

class FallIntoContinueDamageExecuteBlock{
    constructor(applicator,tactics,execute,duration){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //执行函数
        this.execute = execute;
        //被执行人
        this.beExecuted = null,
        //持续时间
        this.duration = duration;

        //状态类型(恐慌，妖术...)
        this.type = null;
        //伤害类型
        this.damageType = null;//或者是谋略，默认攻击
        //伤害率
        this.damageValue = 0;
        //施加该伤害的武将
        this.originWarrior = null;
        //该持续伤害的施加战法
        this.originTactics = null;
        //持续时间
        this.duration = 0;
    }
    Init(target){
        this.beExecuted = target;
        target.FALL_INTO_CONTINUE_DAMAGE.push(this);
    }
    GetData(type,damageType,damageValue,originWarrior,originTactics,duration){
        //状态类型(恐慌，妖术...)
        this.type = type;
        //伤害类型
        this.damageType = damageType;//或者是谋略，默认攻击
        //伤害率
        this.damageValue = damageValue;
        //施加该伤害的武将
        this.originWarrior = originWarrior;
        //该持续伤害的施加战法
        this.originTactics = originTactics;
        //持续时间
        this.duration = duration;
    }
    Execute(){
        this.execute();
    }
}
export default FallIntoContinueDamageExecuteBlock;