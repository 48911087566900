class Query{
    constructor(){

    }
    //
    //获取一个武将无视属性的值
    GetIgnoreValue(target,attrType){
        let res = 0;
        for(let i in target.IGNORE){
            if(target.IGNORE[i].ignoreAttrType == attrType){
                res += target.IGNORE[i].value;
            }
        }
        if(res > 90) res = 90;
        return res/100;
    }
    //获取一个战法的实际准备时间
    GetRealTacticsPrepareTime(target,targetTactics){
        let base = targetTactics.wait;
        for(let i in target.ODD_VAR){
            if(target.ODD_VAR[i].workTactics == targetTactics){
                base-= target.ODD_VAR[i].skipTime;
            }
        }
        if(base < 0) base = 0;
        return base;
    }
    //输入基础数值和受影响的属性和index值，返回加成值
    GetAdditionValue(target,baseValue,attrType,index){
        let res = this.GetAttr(target,attrType);
        res = res < 80 ? 0 : res-80;
        res = Math.floor(res*index + baseValue);
        return res;
    }
    //获取一个武将的最大兵力
    GetMaxHp(target){
        return target.level * 100 + 5000;
    }
    //输入几率，返回士气加成下的几率
    GetMoraleOdd(target,odd){
        let res = odd;
        //士气影响
        let addition = Math.abs(target.morale - 100);
        addition = addition / (100 + target.morale/2);
        if(target.morale < 100){
            res -= res * addition;
        }else{
            res += res * addition;
        }
        res = Math.floor(res);
        return res;
    }
    //获得武将谋战法的实际发动几率
    GetRealTacticsOdd(target,tacticsItem){
        let res = this.GetMoraleOdd(target,tacticsItem.odd);
        for(let i in target.ODD_VAR){
            if(target.ODD_VAR[i].workTactics == tacticsItem) res += target.ODD_VAR[i].value;
        }
        return res > 100 ? 100 : res;
    }
    //获得战法的指定个数
    GetRealTargetNumber(baseNumber,target,tacticsItem){
        let res = baseNumber;
        return res;
    }
    //获得一个武将的实际攻击范围
    GetRealAdRange(target){
        let res = target.ackRange;
        for(let i in target.RANGE){
            if(target.RANGE[i].isCommonAttack && target.RANGE[i].waitTime <= 0) res += target.RANGE[i].value;
        }
        return res;
    }
    //获取武将战法的范围
    GetTacticsRange(target,tacticsItem){
        let res = tacticsItem.range
        for(let i in target.RANGE){
            if(!target.RANGE[i].isCommonAttack && target.RANGE[i].waitTime <= 0) res += target.RANGE[i].value;
        }
        return res;
    }
    //获取一个武将的属性
    GetAttr(target,attrType){
        let res = 0;
        //基础属性
        switch(attrType){
            case "攻击" : res = target.attr.ad;  break;
            case "谋略" : res = target.attr.tact; break;
            case "防御" : res = target.attr.de; break;
            case "速度" : res = target.attr.speed; break;
        }
        //读取属性堆加区的块
        for(let i in target.ATTR){
            if(target.ATTR[i].attrType == attrType){
                res += target.ATTR[i].value;
            }
        }
        return res > 0 ? res : 0;
    }
}


export default Query;