import Report from "../js/report";

class IgnoreBlock{
    constructor(applicator,tactics,ignoreAttrType,value,duration,waitTime,workTactics){
        //施加该块的武将
        this.applicator = applicator;
        //施加该战法的类型
        this.tactics = tactics;
        //无视的属性
        this.ignoreAttrType = ignoreAttrType;
        //数值
        this.value = value;
        //持续时间
        this.duration = duration;
        //等待事件
        this.waitTime = waitTime;
        //是否作用特殊指定战法
        this.workTactics = workTactics == undefined ? null : workTactics;
    }
    Init(target,needReport = true,reportLevel = 3){
        //判断冲突
        for(let i in target.IGNORE){
            if(target.IGNORE[i].tactics.type == this.tactics.type && target.IGNORE[i].ignoreAttrType == this.ignoreAttrType){
                let r = new Report();
                r.InitExecuteReport(3,target,`已存在无视${this.ignoreAttrType}属性效果`);
                return false;
            }
        }
        target.IGNORE.push(this);
        if(needReport){
            let r = new Report();
            r.InitExecuteReport(reportLevel,target,`无视${this.value}%${this.ignoreAttrType}属性效果已施加`);
        }
        return true;
    }
}
export default IgnoreBlock;