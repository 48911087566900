import Query from '../js/query.js'
import Count from '../js/count.js';
import Attack from '../js/attack.js';
import AttrBlock from '../block/attr.js';
import DamageBlock from '../block/damage.js';
let comboB = [
    //掠敌之利
    {
        id : 3,
        name : "掠敌之利",
        type : "追击",
        odd : 35,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 170;//伤害率
            let attrValue = 36;//属性偷取
            
            let damage = c.CountDamage(self,target,value,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true,null);
            //属性偷取
            if(q.GetAttr(target,"攻击") < 36) attrValue = q.GetAttr(target,"攻击");
            let block1 = new AttrBlock(self,this,'攻击',attrValue,2);
            block1.Init(self,true);
            let block2 = new AttrBlock(self,this,'攻击',-attrValue,2);
            block2.Init(target,true);
            this.STATISTICS.times += 1;
        }
    },
    //文伐
    {
        id : 5,
        name : "文伐",
        type : "追击",
        odd : 40,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ["骑","步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 228 + Math.floor((q.GetAttr(self,"谋略")-80)*1.5);//伤害率
            let index = 20;//下一次策略伤害提升
            
            let damage = c.CountDamage(self,target,value,"谋略",false,this,null);
            a.GetHurt(self,target,damage,false,"谋略",this,true,null);
            //下一次策略伤害提升
            let block = new DamageBlock(self,this,'谋略',false,index,true,false,99,0);
            block.Init(target,null,true);
            this.STATISTICS.times += 1;
        }
    },
    //火积
    {
        id : 6,
        name : "火积",
        type : "追击",
        odd : 45,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ["弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 192 + Math.floor((q.GetAttr(self,"谋略")-80)*1.25);//伤害率
            
            let damage = c.CountDamage(self,target,value,"谋略",false,this,null);
            a.GetHurt(self,target,damage,false,"谋略",this,true,null);
            
            this.STATISTICS.times += 1;
        }
    },
    //破甲
    {
        id : 7,
        name : "破甲",
        type : "追击",
        odd : 30,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 165;//伤害率
            let attrValue = 25;//属性降低
            
            let damage = c.CountDamage(self,target,value,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true,null);
            //属性降低
            if(q.GetAttr(target,"防御") < attrValue) attrValue = q.GetAttr(target,"防御");
            let block = new AttrBlock(self,this,'防御',-attrValue,2);
            block.Init(target,true);
            this.STATISTICS.times += 1;
        }
    },
    //攻心
    {
        id : 8,
        name : "攻心",
        type : "追击",
        odd : 40,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑',"步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 106 + Math.floor((q.GetAttr(self,"谋略")-80)*1);//伤害率
            let cureValue = 50;
            let cureIndex = 0.2;
            
            let damage = c.CountDamage(self,target,value,"谋略",false,this,null);
            a.GetHurt(self,target,damage,false,"谋略",this,true,null);
            let cure = c.CountCure(self,self,cureValue,cureIndex,this);
            a.GetCure(self,self,cure,this,true);
            this.STATISTICS.times += 1;
        }
    },
    //扬威
    {
        id : 9,
        name : "扬威",
        type : "追击",
        odd : 35,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ["骑","步","弓"],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = 160;
            let index = 20;//下一次伤害提升
            
            let damage = c.CountDamage(self,target,value,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true,null);
            //下一次策略伤害提升
            let block = new DamageBlock(self,this,'攻击',true,index,true,false,99,0);
            block.Init(self,null,true);
            this.STATISTICS.times += 1;
        }
    },
]

export default comboB;