//造成伤害执行函数
class MakeDamageBlock{
    constructor(applicator,tactics,execute,duration){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //执行函数
        this.execute = execute;
        //持续时间
        this.duration = duration;
        //被执行人
        this.beExecuted = null,
        //伤害数值
        this.damageValue = 0;
        //伤害施加于谁
        this.damageToWho = null;
        //伤害来源的战法
        this.damageFromTactics = null;
        //伤害是否来源于普通攻击
        this.isCommonAttack = false;
        //伤害的类型是谋略还是物理
        this.damageType = "攻击";
        //类型
        this.type = "";
        //是否是有害效果
        this.isAbnormal = false;
    }
    Init(target,type){
        //冲突判断
        this.type = type;
        this.beExecuted = target;
        target.MAKE_DAMAGE.push(this);
        return true;
    }
    Execute(){
        this.execute();
    }
}
export default MakeDamageBlock;