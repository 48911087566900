import DamageBlock from "../block/damage";
import ExecuteBlock from '../block/execute.js';
import AfterDamageBlock from "../block/execute1.js";
import AttackExecuteBlock from "../block/execute4.js";
import CureStatusBlock from "../block/cureStatus";
import StatusBlock from '../block/status.js'
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import Report from "../js/report";
import ActiveTacticsBlock from "../block/execute3.js";

let commandS = [
    //桃园结义
    {
        id : 0,
        name : "桃园结义",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 160;
            let cureIndex = 0.875;
            let damageValue = q.GetAdditionValue(self,120,'谋略',1.5);

            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【桃园结义】`)
                if(self.driver.turn < 5){
                    //找兵力最低队友
                    let fri = a.SelectTarget(self,3,true,false,3,true,true,that);
                    let low = 100000;
                    let lowest = null;
                    for(let i in fri){
                        if(fri[i].hp < low){
                            low = fri[i].hp;
                            lowest = fri[i];
                        }
                        else if(fri[i].hp == low){
                            let rand = Math.floor(Math.random()*2);
                            if(rand == 1){
                                low = fri[i].hp;
                                lowest = fri[i];
                            }
                        }
                    }
                    if(lowest != null){
                        let cure = c.CountCure(self,lowest,cureValue,cureIndex,that);
                        a.GetCure(self,lowest,cure,that,true,null);
                    }
                }
                else{
                    let res = a.SelectTarget(self,3,false,true,3,false,true,that);
                    let low = 100000;
                    let lowest = null;
                    for(let i in res){
                        if(res[i].hp < low){
                            low = res[i].hp;
                            lowest = res[i];
                        }
                        else if(res[i].hp == low){
                            let rand = Math.floor(Math.random()*2);
                            if(rand == 1){
                                low = res[i].hp;
                                lowest = res[i];
                            }
                        }
                    }
                    if(lowest != null){
                        let damage = c.CountDamage(self,lowest,damageValue,'谋略',false,that,null);
                        a.GetHurt(self,lowest,damage,false,'谋略',that,true,null);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【桃园结义】效果已施加`);
            //追加
            let count = 1;
            for(let i in self.ally){
                if(self.ally[i] != self && self.ally[i].camp == self.camp) count += 1;
            }
            if(count == 3){
                let action1 = function(){
                    if(self.driver.turn > 4){
                        let target = null;
                        //前锋援护
                        if(self.driver.turn %2 != 0 && self.ally[2].alive){
                            target = self.ally[2];
                        }
                        //中军援护
                        if(self.driver.turn%2 == 0 && self.ally[1].alive){
                            target = self.ally[1];
                        }
                        if(target != null){
                            let res = a.SelectTarget(target,3,true,false,2,false,false,that);
                            for(let i in res){
                                let block = new StatusBlock(target,that,'援护',0,0);
                                let work = block.Init(res[i],false,3);
                                if(work){
                                    let r = new Report();
                                    r.InitTwoTargetReport(3,res[i],target,`受到`,`援护`);
                                }
                            }
                        }
                    }
                }
                let block = new ExecuteBlock(self,this,'行动开始',action1,99);
                block.Init(self,false);
                let r = new Report();
                r.InitExecuteReport(2,self,`【桃园结义】追加效果已施加`);
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【桃园结义】追加效果不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //避其锋芒
    {
        id : 1,
        name : "避其锋芒",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value = 30 + Math.floor((q.GetAttr(self,'谋略')-80) * 0.15);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,true,false,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",false,-value,false,false,3,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到攻击伤害降低${value}%`);
                }
                let block2 = new DamageBlock(self,this,"谋略",false,-value,false,false,3,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到策略伤害降低${value}%`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //大赏三军
    {
        id : 2,
        name : "大赏三军",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value = 30 + Math.floor((q.GetAttr(self,'谋略')-80) * 0.15);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,true,false,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",true,value,false,false,3,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`攻击伤害提高${value}%`);
                }
                let block2 = new DamageBlock(self,this,"谋略",true,value,false,false,3,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`策略伤害提高${value}%`);
                }
            }
            this.STATISTICS.times += 1;
        }
        
    },
    //无心恋战
    {
        id : 3,
        name : "无心恋战",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value = 30 + Math.floor((q.GetAttr(self,'谋略')-80) * 0.15);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,false,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",true,-value,false,false,3,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`攻击伤害降低${value}%`);
                }
                let block2 = new DamageBlock(self,this,"谋略",true,-value,false,false,3,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`策略伤害降低${value}%`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //神兵天降
    {
        id : 4,
        name : "神兵天降",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value = 30 + Math.floor((q.GetAttr(self,'谋略')-80) * 0.15);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,false,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",false,value,false,false,3,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到攻击伤害提高${value}%`);
                }
                let block2 = new DamageBlock(self,this,"谋略",false,value,false,false,3,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到策略伤害提高${value}%`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //反计之策
    {
        id : 9,
        name : "反计之策",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 100;//犹豫几率
            
            let res = a.SelectTarget(self,4,false,true,2,false,false,this);
            for(let i in res){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*2);
                if(rand < realOdd){
                    let block = new StatusBlock(self,this,'犹豫',1,0);
                    block.Init(res[i],true,2);
                }
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'主动',true,-999,false,false,3,0);
                block.Init(res[i],null,true,2);
            }
            this.STATISTICS.times += 1;
        }
    },
    //战必断金
    {
        id : 10,
        name : "战必断金",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 90;//怯战几率
            
            let action = function(){
                let target = this.beExecuted;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                for(let i in target.STATUS){
                    if(target.STATUS[i].tactics == that){
                        if(rand < realOdd){
                            target.STATUS[i].waitTime = 0;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【战必断金】令`,`陷入怯战`);
                        }
                        else{
                            target.STATUS[i].waitTime = 3;
                            let r = new Report();
                            r.InitExecuteReport(2,target,`【战必断金】没有生效，生效几率为${realOdd}%`);
                        }
                    }
                }
                
            }
            let res = a.SelectTarget(self,4,false,true,2,false,false,this);
            for(let i in res){
                let block = new StatusBlock(self,this,'怯战',3,3);
                let work = block.Init(res[i],false,2);
                if(work){
                    let block = new ExecuteBlock(self,this,'行动开始',action,3);
                    block.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`怯战(预备)效果已施加`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //攻其不备
    {
        id : 11,
        name : "攻其不备",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = Number(((q.GetAttr(self,'速度')-80)*0.04 + 5.2).toFixed(1));//增伤幅度;
            if(self.driver.version == '平衡'){
                value = Number(((q.GetAttr(self,'速度')-80)*0.025 + 6).toFixed(1));//增伤幅度;
            }
            
            let tag = '攻其不备' + self.belong + self.position;
            let getHurt = function(){
                let target = this.beExecuted;
                for(let i in target.DAMAGE_VAR){
                    if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i][tag] < 5){
                        target.DAMAGE_VAR[i].value += value;
                        target.DAMAGE_VAR[i].value = Number(target.DAMAGE_VAR[i].value.toFixed(1));
                        target.DAMAGE_VAR[i][tag] += 1;
                        let r = new Report();
                        let txt = `受到${target.DAMAGE_VAR[i].damageType}伤害提高${target.DAMAGE_VAR[i].value}%`;
                        r.InitTwoTargetReport(3,self,target,`【攻其不备】令`,txt);
                    }
                }
            }
            let res = a.SelectTarget(self,5,false,true,2,false,false,this);
            for(let i in res){
                let block = new DamageBlock(self,this,'所有',false,0,false,false,99,0);
                let work = block.Init(res[i],null,false);
                block.isBenefit = false;
                block[tag] = 0;
                if(work){
                    let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                    block.Init(res[i],'攻其不备');
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到伤害提高效果已施加`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //众谋不懈
    {
        id : 12,
        name : "众谋不懈",
        type : "指挥",
        odd : 100,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,194,'谋略',1.925);
            let odd = 40;//触发几率
            
            let action = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let res = a.SelectTarget(self,5,false,true,1,false,false,that);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,that,null);
                        let r = new Report().InitTwoTargetReport(2,self,res[i],`【众谋不懈】令`,`损失了${damage}兵力(${res[i].hp - damage})`);
                        a.GetHurt(self,res[i],damage,false,'谋略',that,false,r)
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【众谋不懈】没有生效，生效几率为${realOdd}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'试图发动主动战法',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【众谋不懈】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //不攻
    {
        id : 13,
        name : "不攻",
        type : "指挥",
        odd : 100,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,83,'谋略',0.725);
            let value = 25;

            //不能普通攻击
            let block1 = new StatusBlock(self,this,"无法普通攻击",99,0);
            block1.Init(self,false);
            //增伤
            let block2 = new DamageBlock(self,this,'谋略',true,value,false,false,99,0);
            block2.Init(self,null,true,2);
            let action = function(){
                let res = a.SelectTarget(self,5,false,true,1,false,false,that);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,that,null);
                    let r = new Report().InitTwoTargetReport(2,self,res[i],`【不攻】令`,`损失了${damage}兵力(${res[i].hp - damage})`);
                    a.GetHurt(self,res[i],damage,false,'谋略',that,false,r)
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【不攻】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //始计
    {
        id : 14,
        name : "始计",
        type : "指挥",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value1 = q.GetAdditionValue(self,20,'谋略',0.15);
            let value2 = q.GetAdditionValue(self,30,'谋略',0.25);
            
            let action  = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【始计】`);
                let block = new DamageBlock(self,that,'所有',true,value1,false,true,99,0);
                block.Init(self.ally[0],null,true,3);
                //敌军兵力最多的
                let max = -10000;
                let maxW = null;
                let res = a.SelectTarget(self,5,false,true,3,false,false,that);
                for(let i in res){
                    if(res[i].hp > max){
                        max = res[i].hp;
                        maxW = res[i];
                    }
                    else if(res[i].hp == max){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            max = res[i].hp;
                            maxW = res[i];
                        }
                    }
                }
                if(maxW != null){
                    let block = new DamageBlock(self,that,'所有',true,-value2,true,false,99,0);
                    block.Init(maxW,null,true,3);
                }
            }
            let getHurt = function(){
                let block = new StatusBlock(self,that,'洞察',1,0);
                let work = block.Init(self,false,3);
                if(work){
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【始计】令`,`进入洞察`);
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action,4);
            block1.Init(self,false);
            let block2 = new AfterDamageBlock(self,this,getHurt,4,true,true,true,true,true,true,true);
            block2.Init(self,'始计');
            let r = new Report();
            r.InitExecuteReport(2,self,`【始计】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //重整旗鼓
    {
        id : 31,
        name : "重整旗鼓",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){            
            let a = new Attack();
            let cureValue = 140;
            let cureIndex = 1.125;
            let odd = 100;


            let res = a.SelectTarget(self,3,true,false,2,true,false,this);
            for(let i in res){
                let block = new CureStatusBlock(self,this,"休整",cureValue,cureIndex,99,4,odd);
                let work = block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`休整(预备)效果已施加`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //正始之变
    {
        id : 43,
        name : "正始之变",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){       
            let that = this;     
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let time = 15;//触发次数
            let odd = 100;
            let cureValue = 300;
            let cureIndex = 0;

            //追加
            let plus = false;
            if(self.name == '司马懿' || self.name == '司马昭' || self.name == '司马师') plus = true;
            let tag = '正始之变' + self.belong + self.position;
            let tag1 = '正始之变1' + self.belong + self.position;
            let action = function(){
                //执行人
                let target = this.beExecuted;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,target,`【正始之变】令`,`【${this.triggerTactics.name}】再次发动`);
                    this.triggerTactics.execute(target);
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【赐剑长驱】没有生效，生效几率为${realOdd}%`)
                }
            }
            let getHurt = function(){
                self[tag] += 1;
                if(self[tag] >= time){
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【正始之变】效果已施加`);
                    self[tag1] = true;
                    self[tag] -= time;
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【正始之变】累计${self[tag]}次`)
                }
            }
            let action1 = function(){
                if(self[tag1]){
                    let r = new Report();
                    r.InitExecuteReport(3,self,`执行【正始之变】`);
                    let res = a.SelectTarget(self,5,false,true,2,false,true,that);
                    for(let i in res){
                        let block = new StatusBlock(self,that,'犹豫',1,0);
                        block.Init(res[i],true,3);
                    }
                    if(plus){
                        let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                        a.GetCure(self,self,cure,that,true,null);
                    }
                    let fri = a.SelectTarget(self,5,true,false,3,true,false,that);
                    for(let i in fri){
                        let target = fri[i];
                        let block = new ActiveTacticsBlock(self,this,action,1,0,true,'主动');
                        block.Init(target,false);
                        let r = new Report();
                        r.InitExecuteReport(3,target,`【正始之变】效果已施加`)
                    }
                    self[tag1] = false;
                }
            }
            for(let i in self.ally){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(self.ally[i],false);
            }
            self[tag] = 0;
            self[tag1] = false;
            let block = new ExecuteBlock(self,this,'行动开始',action1,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【正始之变】效果已施加`)
            if(plus){
                let r = new Report();
                r.InitExecuteReport(2,self,`【正始之变】追加效果已施加`)
            }
            this.STATISTICS.times += 1;
        }
    },
]
export default commandS;


