class TacticsItem{
    constructor(data){
        this.damage = data.damage;
        this.cure = data.cure;
        this.times = data.times;
    }
}
class Item{
    constructor(data){
        //武将的id
        this.id = data == undefined ? "" : data.id;
        //武将的图像
        this.img = data == undefined ? "" : data.img;
        //武将的名称
        this.name = data == undefined ? "" : data.name;
        //武将的阵营
        this.camp = data == undefined ? "" : data.camp;
        //普通杀伤
        this.commonDamage = data == undefined ? "" : data.STATISTICS.atk;
        //战法
        this.tacticsArr = [];
    }
    CopyData(data){
        //武将的id
        this.id = data.id;
        //武将的图像
        this.img = data.img;
        //武将的名称
        this.name = data.name;
        //武将的阵营
        this.camp = data.camp;
        //普通杀伤
        this.commonDamage = data.commonDamage;
        //战法
        this.tacticsArr = data.tacticsArr;
    }
}
class Statistics{
    constructor(entity){
        this.entity = entity;
    }
    Init(){
        this.entity.Statistics.allTurn = 0;
        this.entity.Statistics.dataMatrices = [];
    }
    //构造统计矩阵
    BuildDataMatrices(arr,driver){
        this.entity.Statistics.allTurn = driver.turn > 8 ? 8 : driver.turn;
        for(let i in arr){
            let team = [];
            for(let j in arr[i]){
                let item = new Item(arr[i][j]);
                for(let k in arr[i][j].TacticsArr){
                    let tactItem = new TacticsItem(arr[i][j].TacticsArr[k].STATISTICS);
                    item.tacticsArr.push(tactItem);
                }
                team.push(item);
            }
            this.entity.Statistics.dataMatrices.push(team);
        }
    }
    //复制统计矩阵
    CopyDataMatrices(Statistics){
        let res = {
            allTurn : Statistics.allTurn,
            dataMatrices : [],
        }
        for(let i in Statistics.dataMatrices){
            let arr = [];
            for(let j in Statistics.dataMatrices[i]){
                let newItem = new Item();
                newItem.CopyData(Statistics.dataMatrices[i][j]);
                arr.push(newItem);
            }
            res.dataMatrices.push(arr);
        }
        return res;
    }
}

export default Statistics;