import Query from "../js/query";
import Report from "../js/report";

class AttrBlock{
    constructor(applicator,tactics,attrType,value,duration,isRepeat,isBenefit){
        //施加该块的武将
        this.applicator = applicator;
        //施加该战法的类型
        this.tactics = tactics;
        //该块的作用的属性类型
        this.attrType = attrType;
        //数值
        this.value = value;
        //持续时间
        this.duration = duration;
        //是否增益
        this.isBenefit = isBenefit == undefined ? (this.value > 0 ? true : false) : isBenefit;
        //是否可以叠加，默认不可叠加
        this.isRepeat = isRepeat == undefined ? false : isRepeat;
        //被施加该效果的武将
        this.owner = null;
    }
    Init(target,needReport = true,reportLevel = 3){
        //needReport是否需要自动生成战
        this.owner = target;
        //是否叠加
        let work = true;
        if(this.isRepeat){
            for(let i in target.ATTR){
                if(target.ATTR[i].tactics == this.tactics && target.ATTR[i].attrType == this.attrType){
                    target.ATTR[i].value += this.value;
                    target.ATTR[i].duration = this.duration;//持续时间刷新
                    if(needReport){
                        let q = new Query();
                        let report = new Report();
                        let text = `${this.attrType}属性`;
                        if(target.ATTR[i].value > 0){
                            text += `提高${target.ATTR[i].value}(${q.GetAttr(target,this.attrType)})`;
                        }
                        else text += `降低${Math.abs(target.ATTR[i].value)}(${q.GetAttr(target,this.attrType)})`;
                        report.InitExecuteReport(reportLevel,target,text);
                    }
                    work = false;
                }
            }
        }
        
        if(work){
            //判断冲突
            let temp = [];
            for(let i in target.ATTR){
                //添加的属性值相同
                if(target.ATTR[i].attrType == this.attrType && this.tactics.type == target.ATTR[i].tactics.type){
                    //加属性
                    if(this.isBenefit && target.ATTR[i].isBenefit){
                        //更强不替换
                        if(this.value <= target.ATTR[i].value){
                            let report = new Report();
                            let str = `已存在更强的${target.ATTR[i].attrType}属性提升效果`;
                            report.InitExecuteReport(3,target,str);
                            return false;
                        }
                        else{
                            let r = new Report();
                            r.InitExecuteReport(3,target,`${target.ATTR[i].attrType}属性提升效果刷新了`)
                        }
                    }
                    //减属性
                    else if(!this.isBenefit && !target.ATTR[i].isBenefit){
                        //更强不替换
                        if(this.value >= target.ATTR[i].value){
                            let report = new Report();
                            let str = `已存在更强的${target.ATTR[i].attrType}属性降低效果`;
                            report.InitExecuteReport(3,target,str);
                            return false;
                        }
                        else{
                            let r = new Report();
                            r.InitExecuteReport(3,target,`${target.ATTR[i].attrType}属性降低效果刷新了`)
                        }
                    }
                    else temp.push(target.ATTR[i]);
                }
                else temp.push(target.ATTR[i]);
            }
            target.ATTR = temp;
            target.ATTR.push(this);
        } 
        if(needReport && work){
            let q = new Query();
            let report = new Report();
            let text = `${this.attrType}属性`;
            if(this.isBenefit){
                text += `提高${this.value}(${q.GetAttr(target,this.attrType)})`;
            }
            else text += `降低${Math.abs(this.value)}(${q.GetAttr(target,this.attrType)})`;
            report.InitExecuteReport(reportLevel,target,text);
        }
        return true;
    }
    InitTwoTargetReport(tactics,attrType,target1,target2,isBenefit,reportLevel = 3){
        let q = new Query();
        for(let i in target2.ATTR){
            if(target2.ATTR[i].tactics == tactics && target2.ATTR[i].attrType == attrType){
                let str = isBenefit ? "提高" : "降低";
                let attr = target2.ATTR[i].attrType;
                let r = new Report();
                let txt = `${attr}属性${str}${isBenefit ? target2.ATTR[i].value : Math.abs(target2.ATTR[i].value)}(${q.GetAttr(target2,attr)})`;
                r.InitTwoTargetReport(reportLevel,target1,target2,`【${tactics.name}】令`,txt,true);
            }
        }
    }
}

export default AttrBlock;