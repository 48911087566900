<template>
    <div class="c-warrior_info" ref="warrior_info" v-if="currentWarrior != null">
        <div class="mask cover" @click="Close"></div>
        <div class="container">
            <div class="black_area"></div>
            <!-- <div class="texture">武将详情</div> -->
            <div class="close_btn" ref="close_btn" 
            @touchstart="$refs.close_btn.classList.add('click')"
            @touchend="$refs.close_btn.classList.remove('click')" 
            @click="Close"></div>
            
            <div class="warrior_img" v-if="currentWarrior != null" >
                <img :src="currentWarrior.img.post" alt="" class="post">
                <div class="shadow"></div>
                <div class="camp">
                    <img :src="CampImgUrl(currentWarrior.camp)" class="cover" alt="">
                    <div class="name">
                        {{ currentWarrior.name }}
                    </div>
                </div>
                <div class="star_bar">
                    <li v-for="s in currentWarrior.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                    <li v-for="s in (5-currentWarrior.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                </div>
                <div class="sign" v-if="currentWarrior.sign != null" >
                    <img :src="SignImgUrl()" class="cover" alt="">
                </div>
                <div class="panel_nav">
                    <li v-for="(n,index) in navList" :key="n.index" @click="aspect = index">
                        <div class="current_img" v-if="index == aspect"></div>
                        <div class="text cover" v-if="index != aspect">{{ n }}</div>
                        <div class="text cover current" v-if="index == aspect">{{ n }}</div>
                        <div class="apart" v-if="index != navList.length-1"></div>
                    </li>
                </div>
            </div>
            
            <!-- 基本信息板块 -->
            <div class="adjust_panel" >
                <div class="line" v-if="currentWarrior.own && aspect == 0">
                    <div class="sub_red_btn" ref="sub_red_btn" @touchstart="$refs.sub_red_btn.classList.add('active')"
                    @touchend="$refs.sub_red_btn.classList.remove('active')"
                    @click="AdjustStar(false)">退阶</div>
                    <div class="add_red_btn" ref="add_red_btn" @touchstart="$refs.add_red_btn.classList.add('active')"
                    @touchend="$refs.add_red_btn.classList.remove('active')"
                    @click="AdjustStar(true)">进阶</div>
                    <div class="star_bar">
                        <li v-for="s in currentWarrior.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                    <li v-for="s in (5-currentWarrior.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                    </div>
                </div>
                <div class="line" v-if="currentWarrior.own && aspect == 0">
                    <div class="up_sign" v-if="currentWarrior.isUp || currentWarrior.isUpWork" >
                        <img :src="UpImgUrl()" class="cover" alt="">
                    </div>
                    <div class="info_bar">
                        <div class="text">统帅值 : {{ currentWarrior.cost }}</div>
                    </div>
                    <div class="up_work_btn" ref="up_work_btn" @touchstart="$refs.up_work_btn.classList.add('active')"
                    @touchend="$refs.up_work_btn.classList.remove('active')"
                    @click="UpWarriorOpera('upWork',!currentWarrior.isUpWork)">
                        {{ currentWarrior.isUpWork ? "取消深造" : "深造" }}
                    </div>
                    <div class="up_btn" ref="up_btn" @touchstart="$refs.up_btn.classList.add('active')"
                    @touchend="$refs.up_btn.classList.remove('active')"
                    @click="UpWarriorOpera('up',!currentWarrior.isUp)">
                        {{currentWarrior.isUp ? "取消觉醒" : "觉醒"}}
                    </div>
                </div>
                <div class="line" v-if="currentWarrior.own && aspect == 0">
                    <div class="arms_sign">
                        <img :src="ArmsImageUrl()" class="cover" alt="">
                    </div>
                    <div class="info_bar">
                        <div class="text">{{ currentWarrior.up_arms[0] }}/{{ currentWarrior.up_arms[1] }}</div>
                        <div class="text">攻击距离：{{ currentWarrior.range }}</div>
                    </div>
                    <div class="up_arms_btn" ref="up_arms_btn" @touchstart="$refs.up_arms_btn.classList.add('active')"
                    @touchend="$refs.up_arms_btn.classList.remove('active')"
                    @click="UpWarriorOpera('upArms',!currentWarrior.isUpArms)">
                        {{currentWarrior.isUpArms ? "取消进阶" : "进阶兵种"}}
                    </div>
                </div>
                <div class="main_tactics_panel" v-if="aspect == 0">
                    <div class="tactics_icon">
                        <img :src="TacticsImg(currentWarrior.tactics_type)" class="cover" alt="">

                        <img src="../../assets/ui/ui (89).png" alt="" class="frame" v-if="currentWarrior.tactics_quality == 'B'">
                        <img src="../../assets/ui/ui (88).png" alt="" class="frame" v-if="currentWarrior.tactics_quality == 'A'">
                        <img src="../../assets/ui/ui (90).png" alt="" class="frame" v-if="currentWarrior.tactics_quality == 'S'">
                        <div class="name">
                            {{ currentWarrior.tactics_name }}
                        </div>
                    </div>
                    <div class="tactics_describe">
                        {{ currentWarrior.tactics_describe[0] }}
                    </div>
                </div>
            </div>

            <!-- 属性板块 -->
            <div class="attr_box" v-if="aspect == 1">
                <div class="line" v-for="a in attrList" :key="a.index">
                    <div class="title">{{ a }}</div>
                    <div class="origin_attr">{{ GetWarriorAttr(a,true) }}</div>
                    <div class="grow_attr">+ {{ GetWarriorAttr(a,false) }}</div>
                    <div class="count_attr">满级 : {{CountAttr(a)}}</div>
                </div>
                <div class="line">
                    <div class="title">综合评分</div>
                    <div class="count_attr">{{ AttrRate() }}</div>
                </div>
            </div>

            <!-- 画像板块 -->
            <div class="skin_chose_panel" v-if="aspect == 2">
                <div class="skin_container">
                    <li v-for="(s,index) in currentWarrior.skin" :key="s.index" @click="ChoseSkin(index)">
                        <img :src="require(`../../static/card_0/${s}`)" class="cover" alt="">
                        <div class="skin_chose_frame" v-if="currentWarrior.skinChose == index"></div>
                        <div class="text" v-if="currentWarrior.skinChose == index">使用中</div>
                    </li>
                </div>
            </div>

            <!-- 解锁按钮 -->
            <div class="lock_btn" ref="lock_btn" @touchstart="$refs.lock_btn.classList.add('click')"
            @touchend="$refs.lock_btn.classList.remove('click')"  v-if="!currentWarrior.own && aspect == 0" @click="Lock(false)"></div>
            <!-- 上锁按钮 -->
            <div class="unlock_btn" ref="unlock_btn" @touchstart="$refs.unlock_btn.classList.add('click')"
            @touchend="$refs.unlock_btn.classList.remove('click')"   v-if="currentWarrior.own && aspect == 0" @click="Lock(true)"></div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'WarriorInfo',
    props:['currentWarrior'],
    data(){
        return{
            aspect : 0, 
            navList : [
                "详 情",
                "属 性",
                "画 像"
            ],
            attrList : [
                "攻击属性","防御属性","策略属性","速度属性","攻城属性"
            ]
        }
    },
    methods:{
        //
        AttrRate(){
            let score = 0;
            score += 0.25 * Math.floor(this.currentWarrior.attr.ad + 49 *  this.currentWarrior.grow.ad);
            score += 0.3 * Math.floor(this.currentWarrior.attr.de + 49 *  this.currentWarrior.grow.de);
            score += 0.25 * Math.floor(this.currentWarrior.attr.tact + 49 *  this.currentWarrior.grow.tact);
            score += 0.08 * Math.floor(this.currentWarrior.attr.speed + 49 *  this.currentWarrior.grow.speed);
            score += 0.2 * Math.floor(this.currentWarrior.attr.demolition + 49 *  this.currentWarrior.grow.demolition);
            if(this.currentWarrior.gender == '女') score += 15;
            return Math.floor(score);
        },
        //改变画像选择
        ChoseSkin(skinIndex){
            if(!this.currentWarrior.own){
                this.$bus.$emit('alert','请先解锁武将');
                return;
            }
            else if(this.currentWarrior.skinChose == skinIndex) return;
            this.currentWarrior.ChangePortrayal(skinIndex);
            this.$bus.$emit('alert','画像切换成功');
            //开启脏位
            this.$store.state.WarriorDirty = true;
        },
        //计算武将的满级属性
        CountAttr(type){
            switch(type){
                case "攻击属性" : return Math.floor(this.currentWarrior.attr.ad + 49 *  this.currentWarrior.grow.ad);
                case "防御属性" : return Math.floor(this.currentWarrior.attr.de + 49 * this.currentWarrior.grow.de);
                case "策略属性" : return Math.floor(this.currentWarrior.attr.tact + 49 * this.currentWarrior.grow.tact);
                case "速度属性" : return Math.floor(this.currentWarrior.attr.speed + 49 *this.currentWarrior.grow.speed);
                case "攻城属性" : return Math.floor(this.currentWarrior.attr.demolition + 49 * this.currentWarrior.grow.demolition);
            }
        },
        //获得武将的属性
        GetWarriorAttr(type,isOrigin){
            switch(type){
                case "攻击属性" : return isOrigin ? this.currentWarrior.attr.ad : this.currentWarrior.grow.ad;
                case "防御属性" : return isOrigin ? this.currentWarrior.attr.de : this.currentWarrior.grow.de;
                case "策略属性" : return isOrigin ? this.currentWarrior.attr.tact : this.currentWarrior.grow.tact;
                case "速度属性" : return isOrigin ? this.currentWarrior.attr.speed : this.currentWarrior.grow.speed;
                case "攻城属性" : return isOrigin ? this.currentWarrior.attr.demolition : this.currentWarrior.grow.demolition;
            }
        },
        //战法类型图标
        TacticsImg(tacticsType){
            switch(tacticsType){
                case "command" : return require('../../assets/ui/ui (92).png');
                case "active" : return require('../../assets/ui/ui (93).png');
                case "combo" : return require('../../assets/ui/ui (94).png');
                case "passive" : return require('../../assets/ui/ui (91).png');
            }
        },
        //兵种图标
        ArmsImageUrl(){
            if(this.currentWarrior.arms == '弓'){
                if(this.currentWarrior.isUpArms) return require('../../assets/ui/ui (63).png');
                else return require('../../assets/ui/ui (62).png');
            }
            else if(this.currentWarrior.arms == '步'){
                if(this.currentWarrior.isUpArms) return require('../../assets/ui/ui (80).png');
                else return require('../../assets/ui/ui (79).png');
            }
            else {
                if(this.currentWarrior.isUpArms) return require('../../assets/ui/ui (71).png');
                else return require('../../assets/ui/ui (70).png');
            }
        },
        //觉醒图标
        UpImgUrl(){
            if(this.currentWarrior.isUp && this.currentWarrior.isUpWork) return require('../../assets/ui/ui (73).png');
            else if(this.currentWarrior.isUp) return require('../../assets/ui/ui (65).png');
            else return require('../../assets/ui/ui (84).png');
        },
        //标记图标
        SignImgUrl(){
            switch(this.currentWarrior.sign){
                case 's2' : return require('../../assets/ui/ui (66).png');
                case 's3' : return require('../../assets/ui/ui (67).png');
                case 'sp' : return require('../../assets/ui/ui (68).png');
                case 'xp' : return require('../../assets/ui/ui (69).png');
            }
        },
        //阵营图标
        CampImgUrl(camp){
            switch(camp){
                case "汉" : return require('../../assets/ui/ui (159).png');
                case "群" : return require('../../assets/ui/ui (157).png');
                case "蜀" : return require('../../assets/ui/ui (160).png');
                case "吴" : return require('../../assets/ui/ui (161).png');
                case "魏" : return require('../../assets/ui/ui (158).png');
                case "晋" : return require('../../assets/ui/ui (162).png');
            }
        },
        //关闭本界面
        Close(){
            this.$bus.$emit('CloseWarriorPanel');
        },
        //调整红星
        AdjustStar(add){
            if(add && this.currentWarrior.red < 5) this.currentWarrior.red += 1;
            else if(!add && this.currentWarrior.red > 0) this.currentWarrior.red -= 1;

            //开启脏位
            this.$store.state.WarriorDirty = true;
        },
        //上锁解锁武将
        Lock(value){
            //开启脏位
            this.$store.state.WarriorDirty = true;
            //type为真表示上锁，反之表示解锁
            this.currentWarrior.own = !value;
            if(value){
                this.currentWarrior.isUp = false;
                this.currentWarrior.isUpWork = false;
                this.currentWarrior.isUpArms = false;
                this.currentWarrior.red = 0;
                this.Close();
            }
        },
        //武将觉醒，深造，进阶
        UpWarriorOpera(type,status){
            if(type == 'up') this.currentWarrior.isUp = status;
            else if(type == 'upWork') this.currentWarrior.isUpWork = status ;
            else  this.currentWarrior.isUpArms = status ;

            //开启脏位
            this.$store.state.WarriorDirty = true;
        }
        
    },

}
</script>

<style lang="less">
.c-warrior_info{
    position: absolute; left: 0; top : 0;
    width : 100%; height : 100%;
    z-index: 1;
    .close_btn{
        left: auto;right: 5vw; top: 8vh;
        height: 8vw; width: 8vw;
        background: url('../../assets/ui/ui (85).png')no-repeat;
        background-size: cover;
        z-index: 2;
    }
    .close_btn.click{
        transform: scale(.8);
    }
    .texture{
        left: 0; top: 8vh; width: 50vw; height: 10vw;
        background: url('../../assets/background/bg (5).png')no-repeat;
        background-size: 100% 100%;
        font-family: "FZLBJW";
        line-height: 10vw;
        font-size: 4vw;
        text-indent: -10vw;
        z-index: 2;
    }
    //武将容器
    .container{
        left: 0; top: 0;
        width: 100vw; height: 100vh;
        background: url('../../assets/background/bg\ \(1\).jpg')#393b3e bottom right no-repeat;
        background-size: cover;
        .black_area{
            z-index: 1;
            width: 100vw; height: 5.2vh;
            background: #000;
        }
        .warrior_img{
            position: absolute; left: 0; top: 5vh;
            width: 100vw; height: 50vh;
            overflow: hidden;
            .camp{
                left: 5vw; top: 7vw;
                width: 12vw; height: 39vw;
                .name{
                    left: 4vw; top: 60%;
                    width: 4vw; 
                    font-size: 4vw;
                    font-family: "FZLBJW";
                    color: #ffffff96;
                    line-height: 4.2vw;
                    transform: translate(0,-50%);
                }
            }
            .star_bar{
                left: 9vw; top: 50vw;
                width: 4vw; height: 25vw;
                li{
                    position: relative; float: left;
                    width: 4vw; height: 4vw;
                    margin: .5vw 0 0 0;
                }
            }
            .sign{
                left: 6vw; top: 76vw;
                width: 11vw; height: 11vw;
            }
            .post{
                // animation: box_float .3s ease-out 1;
                left: 55%; top: 50%;
                height: 120%;
                transform: translate(-50%,-50%); 
            }
            .shadow{
                // top: -10vh;
                top: -.5vw;
                width: 100vw; height: 10vh;
                background: linear-gradient(to bottom,rgb(0, 0, 0),rgba(0, 0, 0, 0));
            }
            .panel_nav{
                top: auto; bottom: 0;
                width: 100vw; height: 9vw;
                background: #000000b4;
                li{
                    position: relative; float: left;
                    width: 33vw; height: 100%;
                    .text{
                        line-height: 9vw;
                        font-size: 3.5vw;
                        font-family: "FZLBJW";
                        color: rgb(189, 189, 189);
                    }
                    .text.current{
                        color: white;
                    }
                    .apart{
                        left: 100%; top: 2vw;
                        width: .4vw; height: 5vw;
                        background: #747474;
                    }
                    .current_img{
                        top: auto; bottom: 0;
                        width: 100%; height: 4.5vw;
                        background: url('../../assets//background//bg\ \(10\).png')no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .adjust_panel{
            top: 55vh; 
            width: 100vw; height: 80vw;
            animation: box_float .3s ease-out 1;
            .line{
                position: relative;float: left;
                width: 90vw; height: 9vw;
                background: #0000003f;
                margin: 3vw 0 0 5vw;
                transition: all .1s;
                .up_sign,.arms_sign{
                    left: 5vw; top: .5vw;
                    width: 8vw; height: 8vw;
                }
                .info_bar{
                    left: 18vw; top: 2.5vw;
                    width: 52vw; height: 4vw;
                    .text{
                        position: relative;float: left;
                        width: 25vw; height: 100%;
                        line-height: 4vw;
                        text-align: center;
                        // color: rgb(210, 210, 210);
                        color: whitesmoke;
                        border-left: 1px solid rgb(210, 210, 210);
                    }
                }
                .star_bar{
                    left: 50%; top: 0;
                    width: 20vw; height: 4vw;
                    margin: 2.5vw 0 0 -10vw;
                    li{
                        position: relative; float: left;
                        width: 4vw; height: 4vw;
                        margin: .5vw 0 0 0;
                    }
                }
                .sub_red_btn,.add_red_btn,.up_arms_btn,.up_btn,.up_work_btn{
                    left: 5vw; top: 2.5vw;
                    width: 14vw; height: 4vw;
                    line-height: 4vw;
                    background: rgb(237, 237, 237);
                    color: black;
                    font-family: "FZLBJW";
                    font-weight: bold;
                    font-size: 3vw;
                    .text{
                        width: 100%; height: 100%;
                        line-height: 4vw;
                        background: rgb(237, 237, 237);
                        color: black;
                        font-family: "FZLBJW";
                        font-weight: bold;
                        font-size: 3vw;
                    }
                }
                .add_red_btn,.up_btn,.up_arms_btn{
                    left: auto; right: 5vw;
                }
                .sub_red_btn.active,.add_red_btn.active,.up_arms_btn.active,.up_btn.active,.up_work_btn.active{
                    transform: scale(.8);
                }
                .up_work_btn{
                    left: auto;
                    right: 24vw;
                }
            }
            .main_tactics_panel{
                position: relative;float: left;
                width: 90vw; height: 26vw;
                background: #0000003f;
                margin: 3vw 0 0 5vw;
                .tactics_icon{
                    left: 5vw; top: 5vw;
                    width: 14vw; height: 14vw;
                    .frame{
                        left:-1.8vw;top: -.5vw;
                        width: 17.8vw; height: 17.8vw;
                    }
                    .name{
                        left: 0; top: 13.8vw;
                        width: 100%; height: 3vw;
                        line-height: 3vw;
                        text-align: center;
                        font-family: "FZLBJW";
                        color: whitesmoke;
                    }
                }
                .tactics_describe{
                    left: 28vw; top: 5vw;
                    width: 55vw; height: 17vw;
                    text-align: left;
                    overflow: scroll;
                    color: whitesmoke;
                }
            }
        }
        .attr_box{
            animation: box_float .3s ease-out 1;
            top: 55vh; 
            width: 100vw; height: 80vw;
            .line{
                position: relative;float: left;
                width: 90vw; height: 9vw;
                background: #0000003f;
                margin: 3vw 0 0 5vw;
                .title{
                    top: 2vw;
                    width: 20vw; height: 5vw;
                    text-align: center;
                    line-height: 5vw;
                }
                .origin_attr,.grow_attr,.count_attr{
                    top: 2vw; left: 30vw;
                    width: 12vw; height: 5vw;
                    line-height: 5vw; 
                    text-align: center;
                    color: whitesmoke;
                }
                .grow_attr{
                    left: 42vw;
                    color: rgb(0, 201, 0);
                }
                .count_attr{
                    left: 60vw;width: 30vw;
                    color: rgb(255, 255, 181);
                }
            }
        }
        .skin_chose_panel{
            animation: box_float .3s ease-out 1;
            top: 55vh; 
            width: 100vw; height: 80vw;
            .skin_container{
                left: 5vw; top: 5vw;
                overflow: scroll;
                width: 90vw; height: 68vw;
                li{
                    position: relative; float: left;
                    width: 20vw; height: 30vw;
                    margin: 2vw 0 2vw 7.4vw;
                    overflow: none;
                    .skin_chose_frame{
                        width: 100%; height: 100%;
                        background: url('../../assets/ui/ui\ \(118\).png')no-repeat;
                        background-size: 100% 100%;
                        transform: scale(1.1);
                    }
                    .text{
                        left: 0; top: auto; bottom: -8vw;
                        width: 100%; height: 5vw;
                        line-height: 5vw;
                        font-family: "FZLBJW";
                        color: greenyellow;
                        text-align: center;
                    }
                }
            }
        }
        
        .lock_btn,.unlock_btn{
            top: auto; left: 50%; bottom: 5vh;
            width: 30vw;height: 6vw;
            animation: fadeIn .3s ease-out 1;
            margin: 0 0 0 -15vw;
            color: black;
            line-height: 6vw;
            font-weight: bold;
            box-shadow: 0 0 1px rgb(48, 48, 48);
            background: url('../../assets/ui/ui\ \(87\).png')rgb(220, 217, 217) center no-repeat;
            background-size: 30% 80%;
        }
        
        .unlock_btn{
            background: url('../../assets/ui/ui\ \(86\).png')rgb(220, 217, 217) center no-repeat;
            background-size: 30% 80%;
        }
        .lock_btn.click,.unlock_btn.click{
            transform: scale(.8);
        }


        .warrior_body_img{
            img{
                position: absolute; left: 8vw; top: 0;
                width: 52vw; height: 80vw;
            }
        }
    }
}
</style>