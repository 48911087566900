//普通攻击的释放前后执行函数块
class AttackExecuteBlock{
    constructor(applicator,tactics,execute,duration,waitTime,isAfterExecute,detailExecuteTime){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //是发动前调用还是发动后调用（默认发动后)
        this.isAfterExecute = isAfterExecute == undefined ? true : isAfterExecute;
        //具体实在攻击后的哪个操作后执行
        this.detailExecuteTime = detailExecuteTime == undefined ? "造成伤害后" : detailExecuteTime;
        //持续时间
        this.duration = duration;
        //等待事件
        this.waitTime = waitTime
        //普通攻击的对象
        this.attackTarget = null;
        //该块的持有者
        this.beExecuted = null;
        //执行函数
        this.execute = execute;
    }
    Init(target,prior = false){
        this.beExecuted = target;
        prior ? target.LAUNCH_ATTACK_EXECUTE.unshift(this) : target.LAUNCH_ATTACK_EXECUTE.push(this);
        return true;
    }
    Execute(attackTarget){
        this.attackTarget = attackTarget;
        this.execute();
    }
}

export default AttackExecuteBlock;