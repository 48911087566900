import { render, staticRenderFns } from "./WarriorContainer.vue?vue&type=template&id=59d223ad&"
import script from "./WarriorContainer.vue?vue&type=script&lang=js&"
export * from "./WarriorContainer.vue?vue&type=script&lang=js&"
import style0 from "./WarriorContainer.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports