import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import Util from '../js/util.js';
import RangeBlock from '../block/range.js';
import ExecuteSysBlock from '../block/executeSys.js';
let commandA = [
    //久战熟谋
    {
        id : 5,
        name : "久战熟谋",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = Number(((q.GetAttr(self,'谋略')-80)*0.025 + 5).toFixed(1));//增伤幅度

            let tag = '久战熟谋' + self.belong + self.position;
            let action = function(){
                let target = this.beExecuted;
                for(let i in target.DAMAGE_VAR){
                    if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i][tag] < 5){
                        target.DAMAGE_VAR[i].value += value;
                        target.DAMAGE_VAR[i].value = Number(target.DAMAGE_VAR[i].value.toFixed(1));
                        target.DAMAGE_VAR[i][tag] += 1;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【久战熟谋】令`,`谋略伤害提高${target.DAMAGE_VAR[i].value}%`);
                    }
                }
            }
            let res = a.SelectTarget(self,3,true,false,2,false,false,this);
            for(let i in res){
                let block = new DamageBlock(self,this,'谋略',true,0,false,false,99,0);
                let work = block.Init(res[i],null,false,2);
                if(work){
                    block[tag] = 0;
                    block.isBenefit = true;
                    let block1 = new AttackExecuteBlock(self,this,action,99,0,true);
                    block1.Init(res[i],false);
                    let block2 = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
                    block2.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`【久战熟谋】效果已施加`);
                }      
                
            }

            this.STATISTICS.times += 1;
        }
    },
    //鸟云山兵
    {
        id : 6,
        name : "鸟云山兵",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 30;
            let value = 40;//减伤幅度

            let tag = '鸟云山兵' + self.belong + self.position;
            let action = function(){
                let target = this.beExecuted;
                for(let i in target.DAMAGE_VAR){
                    if(target.DAMAGE_VAR[i].tactics == that){
                        let realOdd = q.GetMoraleOdd(self,target[tag]);
                        let rand = Math.floor(Math.random()*100);
                        if(rand < realOdd){
                            target.DAMAGE_VAR[i].value = -value;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【鸟云山兵】令`,`受到${target.DAMAGE_VAR[i].damageType}伤害降低${Math.abs(target.DAMAGE_VAR[i].value)}%`);
                        }
                        else if(target.DAMAGE_VAR[i].value != 0){
                            target.DAMAGE_VAR[i].value = 0;
                            let r = new Report();
                            r.InitExecuteReport(2,target,`来自【鸟云山兵】受到${target.DAMAGE_VAR[i].damageType}伤害降低${value}%效果消失了`)
                        }
                    }
                }
                target[tag] += 10;
            }
            let res = a.SelectTarget(self,3,true,false,2,true,false,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,'攻击',false,0,false,false,99,0);
                let work1 = block1.Init(res[i],null,false);
                block1.isBenefit = true;
                let block2 = new DamageBlock(self,this,'谋略',false,0,false,false,99,0);
                let work2 = block2.Init(res[i],null,false);
                block2.isBenefit = true;
                let block = new ExecuteBlock(self,this,'行动开始',action,99);
                block.Init(res[i],false);
                res[i][tag] = odd;
                if(work1 || work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`【鸟云山兵】效果已施加`);
                }
            }

            this.STATISTICS.times += 1;
        }
    },
    //磐阵擅守
    {
        id : 15,
        name : "磐阵擅守",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();

            let action = function(){
                //清除上一回合施加的减伤
                for(let i in self.ally){
                    let arr = [];
                    for(let j in self.ally[i].DAMAGE_VAR){
                        let target = self.ally[i].DAMAGE_VAR[j];
                        if(target.tactics != that) arr.push(target);
                        else console.log(456789);
                    }
                    self.ally[i].DAMAGE_VAR = arr;
                }
                let low = 1000000;
                let lowest = null;
                let res = a.SelectTarget(self,3,true,false,3,true,false,that);
                let target = res[Math.floor(Math.random()*res.length)];
                for(let i in res){
                    if(res[i].hp < low){
                        low = res[i].hp;
                        lowest = res[i];
                    }
                    else if(res[i].hp == low){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            low = res[i].hp;
                            lowest = res[i];
                        }
                    }
                }
                if(lowest != null){
                    let block = new DamageBlock(self,that,'所有',false,-999,true,false,1,0);
                    let work = block.Init(lowest,null,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(1,self,lowest,`【磐阵擅守】令`,`受到下次伤害大幅降低`);
                    }
                }
                let block = new DodgeBlock(self,that,99);
                block.Init(target,false,1);
                let r = new Report();
                r.InitTwoTargetReport(1,self,lowest,`【磐阵擅守】令`,`规避下一次伤害`);
            }
            let block = new ExecuteSysBlock(self,this,'回合开始',action,4);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【磐阵擅守】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //反间
    {
        id : 16,
        name : "反间",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 8;
            let odd = 70;

            let tag = '反间' + self.belong + self.position;
            let res = a.SelectTarget(self,4,false,true,2,false,false,this);
            for(let i in res){
                let block = new DamageBlock(self,this,'所有',true,0,false,false,99,0);
                let work = block.Init(res[i],null,false);
                if(work){
                    block[tag] = 0;
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`伤害降低效果已施加`);
                }
            }
            let action = function(){
                let work = false;
                for(let i in res){
                    if(res[i] == this.damageFromWho) work = true;
                }
                if(work){
                    let target = this.damageFromWho;
                    for(let i in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i][tag] < 5){
                            let realOdd = q.GetMoraleOdd(self,odd);
                            let rand = Math.floor(Math.random()*100);
                            if(rand < realOdd){
                                target.DAMAGE_VAR[i].value -= value;
                                let r = new Report();
                                let txt = `${target.DAMAGE_VAR[i].damageType}伤害降低${Math.abs(target.DAMAGE_VAR[i].value)}%`;
                                r.InitTwoTargetReport(3,self,target,`【反间】令`,txt);
                                target.DAMAGE_VAR[i][tag] += 1;
                            }
                            else{
                                let r = new Report();
                                r.InitExecuteReport(3,target,`【反间】没有生效，生效几率为${realOdd}%`);
                            }
                        }
                    }
                }
            }
            for(let i in self.ally){
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                block.Init(self.ally[i],'反间');
            }
            this.STATISTICS.times += 1;
        }
    },
    //单骑救主
    {
        id : 17,
        name : "单骑救主",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = q.GetAdditionValue(self,25,'防御',0.125);

            let tag = '单骑救主' + self.belong + self.position;
            let getHurt = function(){
                if(self[tag] > 0){
                    self[tag]-=1;
                    let res = a.SelectTarget(self,5,true,false,1,true,true,that);
                    for(let i in res){
                        let block = new DodgeBlock(self,that,99);
                        let work = block.Init(res[i],false,3);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【单骑救主】令`,`规避下一次伤害，还剩${self[tag]}次`);
                        }
                    }
                    let en = a.SelectTarget(self,5,false,true,1,false,true,that);
                    for(let i in en){
                        let block = new DamageBlock(self,that,'所有',true,-value,true,false,1,0);
                        let work = block.Init(en[i],null,false,3);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,en[i],`【单骑救主】令`,`所有伤害降低${value}%`);
                        }
                    }
                }
            }
            let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
            block.Init(self,'单骑救主');
            self[tag] = 7;
            let r = new Report();
            r.InitExecuteReport(2,self,`【单骑救主】效果已施加`);
            //追加
            if(self.name == '赵云'){
                let block = new StatusBlock(self,this,'免疫怯战',2,0);
                block.Init(self,false,2);
                let r = new Report();
                r.InitExecuteReport(2,self,`【单骑救主】追加效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //先驱突击
    {
        id : 18,
        name : "先驱突击",
        type : "指挥",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let value = 30;//攻击提升
            if(self.driver.version == '平衡'){
                value = 15;
            }

            let block1 = new AttrBlock(self,this,'攻击',value,99,false);
            block1.Init(self,true,2);
            let block2 = new StatusBlock(self,this,'先手',3,0);
            block2.Init(self,true,2);
            let block = new StatusBlock(self,this,'连击',3,0);
            block.Init(self,true,2);
            this.STATISTICS.times += 1;
        }
    },
    //一夫当关
    {
        id : 19,
        name : "一夫当关",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 80;//减伤幅度

            let res = a.SelectTarget(self,2,true,false,2,false,false,this);
            if(self.position == '2'){
                let block = new DamageBlock(self,this,'攻击',false,-value,false,false,2,0);
                block.Init(self,null,true,2);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'援护',2,0);
                block.Init(res[i],true,2);
            }
            this.STATISTICS.times += 1;
        }
    },
    //白刃
    {
        id : 20,
        name : "白刃",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let value = 35//减伤幅度;
            let attrValue = 45;//属性增加

            let fri = a.SelectTarget(self,2,true,false,3,true,false,this);
            let res = a.SelectTarget(self,5,false,true,3,false,false,this);
            let action = function(){
                let target = this.beExecuted;
                if(self.driver.turn == 4){
                    let block = new AttrBlock(self,that,'攻击',attrValue,3,0);
                    let work = block.Init(target,false);
                    if(work){
                        let r= new Report();
                        r.InitTwoTargetReport(2,self,target,`【白刃】令`,`攻击属性提高${attrValue}(${q.GetAttr(target,'攻击')})`);
                    }
                }
            }
            for(let i in fri){
                let arm = u.TransferArms(fri[i].up_arms[fri[i].choseArms]);
                if(arm != 'g'){
                    let block = new AttrBlock(self,this,'防御',attrValue,3,0);
                    block.Init(fri[i],true,2);
                    let block1 = new ExecuteBlock(self,that,'行动开始',action,5);
                    block1.Init(fri[i],false);
                }
            }
            for(let i in fri){
                let block = new DamageBlock(self,this,'谋略',true,-value,false,false,3,0);
                block.Init(fri[i],null,true,2);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'谋略',true,-value,false,false,3,0);
                block.Init(res[i],null,true,2);
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //戮力同心
    {
        id : 25,
        name : "戮力同心",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 40;//增伤幅度

            let tag = "戮力同心" + self.belong + self.position;
            let trigger = function(){
                let res = a.SelectTarget(self.ally[0],2,true,false,2,false,true,that);
                let r = new Report();
                r.InitExecuteReport(3,self.ally[0],`执行【戮力同心】`);
                for(let i in res){
                    let work1 = true;
                    let work2 = true;
                    for(let j in res[i].DAMAGE_VAR){
                        let tgt = res[i].DAMAGE_VAR[j];
                        if(tgt.tactics == that && tgt[tag] < 2){
                            tgt.value += value;
                            let r = new Report();
                            r.InitExecuteReport(3,res[i],`${tgt.damageType}伤害提高${tgt.value}%`);
                            tgt[tag] += 1;
                            if(tgt.damageType == '主动') work1 = false;
                            if(tgt.damageType == '追击') work2 = false;
                        }
                    }
                    if(work1){
                        let block1 = new DamageBlock(self,that,'主动',true,value,false,false,1,0);
                        block1.Init(res[i],null,true,3);
                        block1[tag] = 1;
                    }
                    if(work2){
                        let block2 = new DamageBlock(self,that,'追击',true,value,false,false,1,0);
                        block2.Init(res[i],null,true,3);
                        block2[tag] = 1;
                    }
                }
            }
            let block1 = new ActiveTacticsBlock(self,this,trigger,99,0,true,'主动');
            block1.Init(self.ally[0],false);
            let block2 = new ActiveTacticsBlock(self,this,trigger,99,0,true,'追击');
            block2.Init(self.ally[0],false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【戮力同心】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //合纵连横
    {
        id : 26,
        name : "合纵连横",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 40;//围困几率
            
            let work = true;
            let count = {};
            for(let i in self.ally){
                let camp = self.ally[i].camp;
                if(count[camp]){
                    work = false; break;
                }
                count[camp] = true;
            }
            let attack = function(){
                let tgt = this.attackTarget;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new StatusBlock(self,that,'围困',1,0);
                    let work = block.Init(tgt,false,3);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,tgt,`【合纵连横】令`,`不可回复兵力`)
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(3,tgt,`【合纵连横】没有生效，生效几率为${realOdd}%`);
                }
                
            }
            if(work){
                let res = a.SelectTarget(self,3,true,false,3,true,false,this);
                for(let i in res){
                    let block = new RangeBlock(self,this,false,1,99,0);
                    block.Init(res[i],true,2);
                    let block1 = new AttackExecuteBlock(self,this,attack,99,0,true);
                    block1.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`【合纵连横】效果已施加`);
                }
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【合纵连横】不满足条件`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //诱敌深入
    {
        id : 41,
        name : "诱敌深入",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 40;
            let damageValue = 136;

            let tag = '诱敌深入' + self.belong + self.position;
            let res = a.SelectTarget(self,5,true,false,3,true,false,this);
            let getHurt = function(){
                let rec = this.beExecuted;//受到伤害者
                let target = this.damageFromWho;//伤害来源
                if(rec[tag] && self.driver.turn >= 3){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){ 
                        let r = new Report();
                        r.InitExecuteReport(3,rec,`执行【诱敌深入】`);
                        let realDamage = damageValue + q.GetAdditionValue(rec,0,'谋略',1.7);
                        let damage = c.CountDamage(rec,target,realDamage,'谋略',false,that,null);
                        a.GetHurt(rec,target,damage,false,'谋略',that,true,null);
                    }
                    else{
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,rec,`【诱敌深入】对`,`没有生效，生效几率为${realOdd}%`);
                    }
                    rec[tag] = false;
                }
            }
            for(let i in res){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(res[i],'');
                res[i][tag] = true;
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【诱敌深入】效果已施加`);
            }
            let clear = function(){
                for(let i in res){
                    res[i][tag] = true;
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',clear,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //悬权而动
    {
        id : 42,
        name : "悬权而动",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let morale_add = 3;//士气提升
            let value = 30;//伤害提升

            let res = a.SelectTarget(self,3,true,false,2,false,false,this);
            let getHurt = function(){
                if(self.driver.turn < 3){
                    let tgt = this.damageFromWho;//伤害来源
                    let work = false;
                    for(let i in res){
                        if(res[i] == tgt){
                            work = true;
                        }
                    }
                    if(work && tgt.morale < 160){
                        tgt.morale += morale_add;//士气提升
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,tgt,`【悬权而动】令`,`士气提高${morale_add}(${tgt.morale})`);
                    }
                }
            }
            for(let i in self.enemy){
                let block = new AfterDamageBlock(self,this,getHurt,2,true,true,true,true,true,true,true);
                block.Init(self.enemy[i],'悬权而动');
            }
            for(let i in self.ally){
                let block = new AfterDamageBlock(self,this,getHurt,2,true,true,true,true,true,true,true);
                block.Init(self.ally[i],'悬权而动');
            }
            
            let action = function(){
                if(self.driver.turn == 3){
                    let high = -1000;
                    let highest = null;
                    for(let i in res){
                        if(res[i].alive && res[i].morale > high){
                            high = res[i].morale;
                            highest = res[i];
                        }
                        else if(res[i].alive && res[i].morale == high){
                            let rand = Math.floor(Math.random()*2);
                            if(rand == 1){
                                high = res[i].morale;
                                highest = res[i];
                            }
                        }
                    }
                    if(highest != null){
                        let block = new DamageBlock(self,that,'所有',true,value,false,false,99,0);
                        let work = block.Init(highest,null,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,highest,`【悬权而动】令`,`所有伤害提高${value}%`);
                        }
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,3);
            block.Init(self,false);
            
            this.STATISTICS.times += 1;
        }
    },
    //计险远近
    {
        id : 44,
        name : "计险远近",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let attrValue = 20;//属性提升
            let value = 50;//伤害降低
            let value1 = 15;//受到伤害增加

            let res = a.SelectTarget(self,5,true,false,3,true,false,this);
            let work = true;
            let count = {};
            for(let i in self.ally){
                let range = self.ally[i].ackRange;
                if(count[range]){
                    work = false;
                    break;
                }
                count[range] = true;
            }
            let action = function(){
                let tgt = this.beExecuted;
                let block = new DamageBlock(self,that,'所有',false,-value,true,false,1,0);
                let work = block.Init(tgt,null,false);
                if(work){
                    let r = new Report();
                    r.InitTwoTargetReport(1,self,tgt,`【计险远近】令`,`受到下一次所有伤害降低${value}%`)
                }
            }
            if(work){
                let block = new StatusBlock(self,this,'无视规避',99,0);
                block.Init(self.ally[0],true,2);
                for(let i=0;i<3;i++){
                    let attrType = "";
                    switch(i){
                        case 0 : attrType = '攻击';break;
                        case 1 : attrType = '防御';break;
                        case 2 : attrType = '谋略';break;
                    }
                    let block = new AttrBlock(self,this,attrType,attrValue,99,false);
                    block.Init(self.ally[1],true,2);
                }
                let block1 = new ExecuteBlock(self,this,'回合开始',action,99);
                block1.Init(self.ally[2],false);
                let r = new Report();
                r.InitExecuteReport(2,self.ally[2],`【计险远近】效果已施加`);
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【计险远近】不满足条件`);
            }
            let attack = function(){
                let target = this.attackTarget;//被攻击对象
                let work = true;
                for(let i in target.DAMAGE_VAR){
                    if(target.DAMAGE_VAR[i].tactics == that && !target.DAMAGE_VAR[i].isMakeDamage){
                        target.DAMAGE_VAR[i].value += value1;
                        let r = new Report();
                        let txt = `受到${target.DAMAGE_VAR[i].damageType}伤害提高${target.DAMAGE_VAR[i].value}%`;
                        r.InitTwoTargetReport(3,self,target,`【计险远近】令`,txt);
                        work = false;
                    }
                }
                if(work){
                    let block = new DamageBlock(self,that,'所有',false,value1,false,false,0,0);
                    block.Init(target,null,false);
                    let r = new Report();
                    let txt = `受到所有伤害提高${value1}%`
                    r.InitTwoTargetReport(3,self,target,`【计险远近】令`,txt);
                }
            }
            for(let i in res){
                let block = new AttackExecuteBlock(self,this,attack,99,0,true);
                block.Init(res[i],false);
            }
            this.STATISTICS.times += 1;
        }
    },
    //知己知彼
    {
        id : 45,
        name : "知己知彼",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 60;//触发几率
            let value = 8;//增伤幅度;
            
            let tag = '知己知彼' + self.belong + self.position;
            let res = a.SelectTarget(self,5,false,true,2,false,false,this);
            let fri = a.SelectTarget(self,5,true,false,2,true,false,this);
            let getHurt = function(){
                let target = this.beExecuted;
                let attacker = this.damageFromWho;//伤害来源
                let work1 = false;
                let work2 = false;
                for(let i in res){
                    if(res[i] == target) work1 = true;
                }
                for(let i in fri){
                    if(attacker == fri[i]) work2 = true;
                }
                if(work1){
                    for(let i in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i].damageType == this.damageType && target.DAMAGE_VAR[i][tag] < 5){
                            let realOdd = q.GetMoraleOdd(self,odd);
                            let rand = Math.floor(Math.random()*100);
                            if(rand < realOdd){
                                target.DAMAGE_VAR[i].value += value;
                                target.DAMAGE_VAR[i].value = Number(target.DAMAGE_VAR[i].value.toFixed(1));
                                target.DAMAGE_VAR[i][tag] += 1;
                                let r = new Report();
                                let txt = `受到${target.DAMAGE_VAR[i].damageType}伤害提高${target.DAMAGE_VAR[i].value}%`;
                                r.InitTwoTargetReport(3,self,target,`【知己知彼】令`,txt);
                            }
                            else{
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,target,`【知己知彼】对`,`没有生效，生效几率为${realOdd}%`);
                            }
                        }
                    }
                }
                if(work2){
                    for(let i in attacker.DAMAGE_VAR){
                        if(attacker.DAMAGE_VAR[i].tactics == that 
                        && attacker.DAMAGE_VAR[i].damageType == this.damageType 
                        && attacker.DAMAGE_VAR[i][tag] < 5){
                            let realOdd = q.GetMoraleOdd(self,odd);
                            let rand = Math.floor(Math.random()*100);
                            if(rand < realOdd){
                                attacker.DAMAGE_VAR[i].value += value;
                                attacker.DAMAGE_VAR[i].value = Number(attacker.DAMAGE_VAR[i].value.toFixed(1));
                                attacker.DAMAGE_VAR[i][tag] += 1;
                                let r = new Report();
                                let txt = `${attacker.DAMAGE_VAR[i].damageType}伤害提高${attacker.DAMAGE_VAR[i].value}%`;
                                r.InitTwoTargetReport(3,self,attacker,`【知己知彼】令`,txt);
                            }
                            else{
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,attacker,`【知己知彼】对`,`没有生效，生效几率为${realOdd}%`);
                            }
                        }
                    }
                    
                }
            }
            for(let i in res){
                let block1 = new DamageBlock(self,this,'攻击',false,0,false,false,99,0);
                let work1 = block1.Init(res[i],null,false);
                let block2 = new DamageBlock(self,this,'谋略',false,0,false,false,99,0);
                let work2 = block2.Init(res[i],null,false);
                block1.isBenefit = false;
                block2.isBenefit = false;
                block1[tag] = 0;
                block2[tag] = 0;
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到攻击伤害提高效果已施加`);
                }
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到策略伤害提高效果已施加`);
                }
            }
            for(let i in fri){
                let block1 = new DamageBlock(self,this,'攻击',true,0,false,false,99,0);
                let work1 = block1.Init(fri[i],null,false);
                let block2 = new DamageBlock(self,this,'谋略',true,0,false,false,99,0);
                let work2 = block2.Init(fri[i],null,false);
                block1.isBenefit = true;
                block2.isBenefit = true;
                block1[tag] = 0;
                block2[tag] = 0;
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,fri[i],`攻击伤害提高效果已施加`);
                }
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,fri[i],`策略伤害提高效果已施加`);
                }
            }
            for(let i in self.enemy){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(self.enemy[i],'知己知彼');
            }
            for(let i in self.ally){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(self.ally[i],'知己知彼');
            }
            this.STATISTICS.times += 1;
        }
    },
]

export default commandA;