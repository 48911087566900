import Judge from "./judge";
import Query from "./query";
import Report from "./report";

class Util{
    constructor(){

    }
    //从targetArr里清除某个块
    clearBlock(target,targetArr,block,needReport = true,reportLevel = 3){
        let arr = [];
        console.log("targetArr1",targetArr,target.STATUS);
        let clear = false;
        for(let i in targetArr){
            if(targetArr[i] != block) arr.push(targetArr[i]);
            else{
                clear = true;
                if(needReport){
                    let r = new Report();
                    if(targetArr == target.STATUS) r.InitExecuteReport(reportLevel,target,`来自【${block.tactics.name}】的${block.type}状态消失了`)
                }
                console.log('清除块');

            }
        }
        
        if(targetArr == target.STATUS) target.STATUS = arr;
        else{
            targetArr = arr;
        }
        console.log("targetArr2",targetArr,target.STATUS);
        return clear;
    }
    //清除异常或有益效果
    ClearStatus(target,tacticsItem,isBenefit,reportLevel = 3){
        let flag = false;
        //清除状态效果
        let arr1 = [];
        for(let i in target.STATUS){
            let clear = false;
            //大前提：增益减益相同
            if(target.STATUS[i].isBenefit == isBenefit){
                //主动或者战法施加的效果可以被主动，指挥，追击，被动战法消除
                if((target.STATUS[i].tactics.type == '主动' || target.STATUS[i].tactics.type == '追击') && (tacticsItem.type == '主动' || tacticsItem.type == '指挥' || tacticsItem.type == '被动' || tacticsItem.type == '追击')) clear = true;
                //指挥战法施加的异常效果只能被被动战法消除
                if(target.STATUS[i].tactics.type == '指挥' && (tacticsItem.type == '被动' || tacticsItem.type == '指挥')) clear = true;
            }
            if(clear){
                let r = new Report();
                r.InitExecuteReport(reportLevel,target,`${target.STATUS[i].type}效果被移除了`);
                flag = true;
            }
            else arr1.push(target.STATUS[i]);
        }
        target.STATUS = arr1;
        //清除属性效果
        let arr2 = [];
        for(let i in target.ATTR){
            let clear = false;
            if(isBenefit == target.ATTR[i].isBenefit){
                //主动或者战法施加的效果可以被主动，指挥，追击，被动战法消除
                if((target.ATTR[i].tactics.type == '主动' || target.ATTR[i].tactics.type == '追击') && (tacticsItem.type == '主动' || tacticsItem.type == '指挥' || tacticsItem.type == '被动' || tacticsItem.type == '追击')) clear = true;
                //指挥战法施加的异常效果只能被被动战法消除
                if(target.ATTR[i].tactics.type == '指挥' && (tacticsItem.type == '被动' || tacticsItem.type == '指挥')) clear = true;
            }
            if(clear){
                let report = new Report();
                let text = `${target.ATTR[i].attrType}属性`
                if(target.ATTR[i].isBenefit) text += "提高"
                else text += "降低"
                text += `(${Math.abs(target.ATTR[i].value)})效果被移除了`
                report.InitExecuteReport(reportLevel,target,text);
                flag = true;
            }
            else arr2.push(target.ATTR[i]);
        }
        target.ATTR = arr2;
        //清除增减伤效果
        let arr3 = [];
        for(let i in target.DAMAGE_VAR){
            let clear = false;
            if(target.DAMAGE_VAR[i].isBenefit == isBenefit){
                //主动或者战法施加的效果可以被主动，指挥，追击，被动战法消除
                if((target.DAMAGE_VAR[i].tactics.type == '主动' || target.DAMAGE_VAR[i].tactics.type == '追击') && (tacticsItem.type == '主动' || tacticsItem.type == '指挥' || tacticsItem.type == '被动' || tacticsItem.type == '追击')){
                    clear = true;
                }
                //指挥战法施加的异常效果只能被被动战法消除
                if(target.DAMAGE_VAR[i].tactics.type == '指挥' && (tacticsItem.type == '被动' || tacticsItem.type == '指挥')) clear = true;
            }
            if(clear){
                let report = new Report();
                let str1 = target.DAMAGE_VAR[i].isMakeDamage ? "" : "受到";
                let str2 = "";
                if(target.DAMAGE_VAR[i].damageType == '谋略') str2 = "策略伤害";
                else if(target.DAMAGE_VAR[i].damageType == '攻击') str2 = "攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '普通攻击') str2 = "普通攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '主动') str2 = "主动战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '追击') str2 = "追击战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '所有') str2 = "所有伤害";
                let str3 = "";
                if(target.DAMAGE_VAR[i].value < -666) str3 = "大幅降低";
                else if(target.DAMAGE_VAR[i].value > 0) str3 = "提高";
                else str3 = "降低";
                let str4 = ''
                if(target.DAMAGE_VAR[i].value >= -666) str4 = `${Math.abs(target.DAMAGE_VAR[i].value)}%`
                report.InitExecuteReport(reportLevel,target,`来自【${target.DAMAGE_VAR[i].tactics.name}】的${str1+str2+str3+str4}效果被移除了`);
                flag = true;
            }
            else arr3.push(target.DAMAGE_VAR[i]);
        }
        target.DAMAGE_VAR = arr3;
        //移除战法发动几率和准备时间的增减益
        let arr4 = [];
        for(let i in target.ODD_VAR){
            let clear = false;
            if(target.ODD_VAR[i].isBenefit == isBenefit){
                //主动或者战法施加的效果可以被主动，指挥，追击，被动战法消除
                if((target.ODD_VAR[i].tactics.type == '主动' || target.ODD_VAR[i].tactics.type == '追击') && (tacticsItem.type == '主动' || tacticsItem.type == '指挥' || tacticsItem.type == '被动' || tacticsItem.type == '追击')){
                    clear = true;
                }
                //指挥战法施加的异常效果只能被被动战法消除
                if(target.ODD_VAR[i].tactics.type == '指挥' && (tacticsItem.type == '被动' || tacticsItem.type == '指挥')) clear = true;
            }
            if(clear){
                if(target.ODD_VAR[i].value != 0){
                    let r = new Report();
                    let txt = `【${attrType.ODD_VAR[i].workTactics.name}】发动几率${target.ODD_VAR[i].value>0?"提高":"降低"}${Math.abs(target.ODD_VAR[i].value)}%被移除了`;
                    r.InitExecuteReport(reportLevel,target,txt);
                }
                if(target.ODD_VAR[i].skipTime > 0){
                    let r = new Report();
                    let txt = `【${attrType.ODD_VAR[i].workTactics.name}】跳过${target.ODD_VAR[i].skipTime}回合被移除了`;
                    r.InitExecuteReport(reportLevel,target,txt);
                }
                flag = true;
            }
            else arr4.push(target.ODD_VAR[i]);
        }
        //移除距离加成
        let arr5 = [];
        for(let i in target.RANGE){
            let clear = false;
            if(target.RANGE[i].isBenefit == isBenefit){
                //主动或者战法施加的效果可以被主动，指挥，追击，被动战法消除
                if((target.RANGE[i].tactics.type == '主动' || target.RANGE[i].tactics.type == '追击') && (tacticsItem.type == '主动' || tacticsItem.type == '指挥' || tacticsItem.type == '被动' || tacticsItem.type == '追击')){
                    clear = true;
                }
                //指挥战法施加的异常效果只能被被动战法消除
                if(target.RANGE[i].tactics.type == '指挥' && (tacticsItem.type == '被动' || tacticsItem.type == '指挥')) clear = true;
            }
            if(clear){
                let text = "";
                if(this.isCommonAttack) text = "攻击距离";
                else text = "战法距离";
                if(target.RANGE[i].value > 0){
                    text += `提升${target.RANGE[i].value}效果`;
                }
                else{
                    text += `降低${Math.abs(target.RANGE[i].value)}效果`;
                }
                text += "被移除了";
                let r = new Report();
                r.InitExecuteReport(reportLevel,target,text);
                flag = true;
            }
            else arr5.push(target.RANGE[i]);
        }
        target.RANGE = arr5;
        //移除伤害类型状态，全部移除
        let arr = [];
        for(let i in target.SPECIAL_STATUS){
            let clear = false;
            //主动或者战法施加的效果可以被主动，指挥，追击，被动战法消除
            if((target.SPECIAL_STATUS[i].tactics.type == '主动' || target.SPECIAL_STATUS[i].tactics.type == '追击') && (tacticsItem.type == '主动' || tacticsItem.type == '指挥' || tacticsItem.type == '被动' || tacticsItem.type == '追击')){
                clear = true;
            }
            //指挥战法施加的异常效果只能被被动战法消除
            if(target.SPECIAL_STATUS[i].tactics.type == '指挥' && (tacticsItem.type == '被动' || tacticsItem.type == '指挥')) clear = true;
            if(clear){
                let r = new Report();
                let txt = `${target.SPECIAL_STATUS[i].type}效果被移除了`;
                r.InitExecuteReport(reportLevel,target,txt);
                flag = true;
            }
            else{
                arr.push(target.SPECIAL_STATUS[i]);
            }
        }
        target.SPECIAL_STATUS = arr;
        return flag;
    }
    //翻译兵种
    TransferArms(armStr){
        if(armStr == "禁卫" || armStr == "长枪兵" || armStr == "重步兵" || armStr == "藤甲兵" || armStr == "蛮兵") return "b";
        else if(armStr == "长弓兵" || armStr == "弩兵" || armStr == "死士") return "g";
        else if(armStr == "轻骑兵" || armStr == "重骑兵" || armStr == "铁骑兵" || armStr == "弓骑兵" || armStr == "象兵") return "q";
    }
    //获取目标武将的阵营加成等级
    GetTargetCampAddition(target){
        let res = 0;
        let count = 0;
        for(let i in target.ally){
            if(target.ally[i].camp == target.camp) count ++;
        }
        switch(count){
            case 2 : res = 0.08;break;
            case 3 : res = 0.1;break;
        } 
        return {
            res : res,
            count : count
        }
    }
    //获取当前回合的需要执行的武将
    GetExecutor(allTeam){
        let q = new Query();
        let judge = new Judge()
        let res = null;
        let fast = -1000;
        //选出一个速度最快且本回合还没有执行的武将执行
        for(let i in allTeam){
            for(let j in allTeam[i]){
                if(allTeam[i][j].action && allTeam[i][j].alive){
                    let speed = q.GetAttr(allTeam[i][j],"速度");
                    if(judge.IsGetStatus(allTeam[i][j],"先手")) speed += 1000;
                    if(speed > fast){
                        res = allTeam[i][j];
                        fast = speed;
                    }
                    else if(speed == fast){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            res = allTeam[i][j];
                            fast = speed;
                        }
                    }
                }
            }
        }
        return res;
    }
}

export default Util;