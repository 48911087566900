import DamageBlock from "../block/damage";
import ExecuteBlock from "../block/execute";
import AfterDamageBlock from "../block/execute1";
import ActiveTacticsBlock from "../block/execute3";
import StatusBlock from "../block/status";
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import Report from "../js/report";
import AttrBlock from '../block/attr.js'
import CounterAttackBlock from "../block/counterAtk.js";
import Util from "../js/util";
import DamageStatus from "../block/damageStatus.js";
let qunBArr = [
    //董卓
    {
        id : 4,
        name : "逆谋",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let index = q.GetAdditionValue(self,15.6,'防御',0.09)//减伤值
            let cureIndex = 50;//吸血值
            if(self.driver.version == '平衡'){
                index = q.GetAdditionValue(self,18,'防御',0.1)
            }

            //减伤
            let block1 = new DamageBlock(self,this,"攻击",false,-index,false,false,99);
            block1.Init(self,null,false);
            let r1 = new Report();
            r1.InitExecuteReport(2,self,`受到攻击伤害降低${index}%`);
            let block2 = new DamageBlock(self,this,"谋略",false,-index,false,false,99);
            block2.Init(self,null,false);
            let r2 = new Report();
            r2.InitExecuteReport(2,self,`受到策略伤害降低${index}%`);


            let res = a.SelectTarget(self,5,false,true,3,false,true,this)
            let action = function(){
                if(this.damageFromWho == self){
                    let cure = this.damageValue * cureIndex/100;
                    //最多只可以恢复失去兵力中80%的兵力
                    let cure_max = (q.GetMaxHp(self) - self.hp) * 0.8;
                    if(cure > cure_max){
                        //如果质量量溢出
                        cure = cure_max;
                    }
                    cure = Math.floor(cure);
                    a.GetCure(self,self,cure,this.tactics,true);
                }
            }
            for(let i in res){
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                block.Init(res[i],"执行");
            }
            let r = new Report();
            r.InitExecuteReport(2,self,`吸血效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //颜良&文丑
    {
        id : 50,
        name : "二夫之勇",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = 140;
            let index1 = 50;//主动战法减伤
            let index2 = 25;//主动战法增伤
            if(self.driver.version == '平衡'){
                value = 150;
                index2 = 30;
            }

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],value,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                let block1 = new DamageBlock(self,this,"主动",true,-index1,false,false,2,0);
                block1.Init(res[i],null,true);
                let block2 = new DamageBlock(self,this,"主动",false,index2,false,false,2,0);
                block2.Init(res[i],null,true);
            }


            this.STATISTICS.times += 1;
        }
    },
    //孟获
    {
        id : 56,
        name : "南蛮强袭",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = 25 + Math.floor((q.GetAttr(self,'攻击')-80)*0.1);
            let cureValue = 800;
            let cureIndex = 0;

            let res = a.SelectTarget(self,2,true,false,1,false,false,this);
            res.push(self);
            let action = function(){
                let target = this.beExecuted;
                if(self.driver.turn == 3){
                    let cure = c.CountCure(self,target,cureValue,cureIndex,that);
                    let work =  a.GetCure(self,target,cure,that,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,target,`【南蛮强袭】令`,`恢复了${cure}兵力(${target.hp})`)
                    }
                }
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'攻击',true,value,false,false);
                let work =  block.Init(res[i],null,false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`攻击伤害提高${value}%`);
                }
                let block1 = new StatusBlock(self,this,"无视规避",3,0);
                let work1 =  block1.Init(res[i],false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],"无视规避效果已施加");
                }
            }
            for(let i in self.ally){
                if(self.ally[i].up_arms[self.ally[i].choseArms] == '象兵' || self.ally[i].up_arms[self.ally[i].choseArms] == '蛮兵' 
                || self.ally[i].up_arms[self.ally[i].choseArms] == '藤甲兵' || self.ally[i] == self){
                    let block = new ExecuteBlock(self,this,'行动开始',action,3);
                    block.Init(self.ally[i]);
                }
            }

            this.STATISTICS.times += 1;
        }
    },
    //兀突骨
    {
        id : 66,
        name : "藤甲突击",
        type : "被动",
        odd : 100,
        range : 2,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let damageValue = 100;//伤害率
            let value = 50;//减伤幅度
            
            //不受敌方指挥战法影响
            let block = new StatusBlock(self,this,`不受敌方指挥战法影响`,99,0);
            let work = block.Init(self,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,self,`不受敌方指挥战法影响`)
            }
            let action = function(){
                if(self['藤甲突击']==0){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【藤甲突击】`);
                    //伤害
                    let res = a.SelectTarget(self,4,false,true,2,false,true,that);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                        a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                    }
                    //减伤
                    for(let i in res){
                        let block = new DamageBlock(self,that,'所有',true,-value,true,false,2,0);
                        block.Init(res[i],null,true);
                    }
                    self['藤甲突击'] += 1;
                }
            }
            let action2 = function(){
                self['藤甲突击'] = 0;
            }
            let block1 = new ActiveTacticsBlock(self,this,action,99,0,true);
            block1.Init(self,false);
            let block2 = new ExecuteBlock(self,this,'回合开始',action2,99);
            self['藤甲突击'] = 0;
            let r = new Report();
            r.InitExecuteReport(2,self,`【藤甲突击】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //张宝
    {
        id : 74,
        name : "地公将军",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,136,'谋略',1.45);
            let value = 24;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            let includeSelf = false;
            //造成伤害，同时检查妖术
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,this,null);
                a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
                let block1= new AttrBlock(self,this,'防御',-value,2);
                block1.Init(res[i],true);
                let block2 = new AttrBlock(self,this,'谋略',-value,2);
                block2.Init(res[i],true);
                for(let j in res[i].SPECIAL_STATUS){
                    if(res[i].SPECIAL_STATUS[j].type == '妖术'){
                        console.log(9999);
                        includeSelf = true; 
                    }
                }
            }
            //附加属性
            let ally = a.SelectTarget(self,realRange,true,false,includeSelf ? 3 : 2,includeSelf,true,this);
            for(let i in ally){
                let block1= new AttrBlock(self,this,'防御',value,2);
                block1.Init(ally[i],true);
                let block2 = new AttrBlock(self,this,'谋略',value,2);
                block2.Init(ally[i],true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //张梁
    {
        id : 75,
        name : "人公将军",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = 75;//反击伤害率
            let attrValue = 60;//防御属性加成

            //属性提升
            for(let i in self.ally){
                let block = new AttrBlock(self,this,'防御',attrValue,4);
                let work = block.Init(self.ally[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,self.ally[i],`防御属性提高${attrValue}(${q.GetAttr(self.ally[i],'防御')})`);
                }
                //反击效果
                if(i != '0'){
                    let block = new CounterAttackBlock(self,this,'反击',damageValue,100,4,0);
                    let work = block.Init(self.ally[i],false);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(2,self.ally[i],`反击效果已施加`);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //轲比能
    {
        id : 77,
        name : "明法御狄",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let u = new Util();
            let value = q.GetAdditionValue(self,20,'速度',0.14);

            let action = function(){
                if(self.driver.turn == 2){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【明法御狄】`);
                    let target = self.ally[2];
                    if(target.alive){
                        u.ClearStatus(target,that,true,3);
                        u.ClearStatus(target,that,false,3);
                        //消除白衣渡江
                        for(let i in target.EXECUTE){
                            if(target.EXECUTE[i].tactics.name == '白衣渡江' && target.EXECUTE[i].duration > 0){
                                let r = new Report();
                                r.InitExecuteReport(3,target,`【白衣渡江】预备伤害被移除了`);
                                target.EXECUTE[i].duration-=99;
                            }
                        }
                    }
                }
                else if(self.driver.turn == 3){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【明法御狄】`);
                    let target = self.ally[1];
                    if(target.alive){
                        u.ClearStatus(target,that,false,3);
                        //消除白衣渡江
                        for(let i in target.EXECUTE){
                            if(target.EXECUTE[i].tactics.name == '白衣渡江' && target.EXECUTE[i].duration > 0){
                                let r = new Report();
                                r.InitExecuteReport(3,target,`【白衣渡江】预备伤害被移除了`);
                                target.EXECUTE[i].duration-=99;
                            }
                        }
                    }
                }
                else if(self.driver.turn == 4){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【明法御狄】`)
                    for(let i in self.ally){
                        let target = self.ally[i];
                        let block = new AttrBlock(self,that,'防御',value,99);
                        block.Init(target,true);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始前',action,99);
            block.Init(self,true);
            let r = new Report();
            r.InitExecuteReport(2,self,`【明法御狄】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //董白
    {
        id : 82,
        name : "未笄难言",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,108,'谋略',1.075);
            let value = 18;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let avail = true;
                for(let j in res[i].DAMAGE_VAR){
                    if(res[i].DAMAGE_VAR[j].tactics == this){
                        res[i].DAMAGE_VAR[j].value-=value;
                        let r = new Report();
                        if(res[i].DAMAGE_VAR[j].damageType == '攻击'){
                            r.InitExecuteReport(3,res[i],`攻击伤害降低${Math.abs(res[i].DAMAGE_VAR[j].value)}%`)
                        }
                        else{
                            r.InitExecuteReport(3,res[i],`策略伤害降低${Math.abs(res[i].DAMAGE_VAR[j].value)}%`)
                        }
                        avail = false;
                    }
                }
                if(avail){
                    let block1 = new DamageBlock(self,this,'攻击',true,-value,false,false,99,0);
                    block1.Init(res[i],null,true);
                    let block2 = new DamageBlock(self,this,'谋略',true,-value,false,false,99,0);
                    block2.Init(res[i],null,true);
                }
            }
            for(let i in res){
                let block = new DamageStatus(self,this,'妖术',damageValue,'谋略',2,0,true);
                block.Init(res[i],true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //吕姬
    {
        id : 84,
        name : "缚父临危",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = 210;
            let value = 30;

            let realRange = q.GetTacticsRange(self,this);
            let targetNumber = q.GetRealTargetNumber(2,self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            //打伤害
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,this,null);
                a.GetHurt(self,res[i],damage,false,'攻击',this,true,null);
            }
            //增伤
            let targetArr = [];
            let fri = a.SelectTarget(self,realRange,true,false,2,false,true,this);
            if(fri != []){
                let target = fri[0];
                let maxAd = q.GetAttr(target,'攻击');
                for(let i in fri){
                    let ad = q.GetAttr(fri[i],'攻击');
                    if(ad > maxAd){
                        target = fri[i];
                        maxAd = ad;
                    }
                    else if(ad == maxAd){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            target = fri[i];
                            maxAd = ad;
                        }
                    }
                }
                targetArr.push(target);
            }
            targetArr.push(self);
            for(let i in targetArr){
                let work = false;
                for(let j=0;j<2;j++){
                    let block = new DamageBlock(self,this,'攻击',true,value,true,false,99,0);
                    work = block.Init(targetArr[i],null,false);
                }
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(3,targetArr[i],`下两次攻击伤害提高${value}%`);
                }
            }
            //给吕布施加无视规避
            for(let i in self.ally){
                if(self.ally[i].name == '吕布'){
                    let block = new StatusBlock(self,this,'无视规避',1,0);
                    block.Init(self.ally[i],true);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //于吉
    {
        id : 87,
        name : "治众刑咒",
        type : "主动",
        odd : 40,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let cureValue = 159;
            let cureIndex = 1.55;
            let value1 = q.GetAdditionValue(self,31,'谋略',0.12);

            let realRange = q.GetTacticsRange(self,this);
            let fri = a.SelectTarget(self,realRange,true,false,2,false,true,this);
            for(let i in fri){
                let cure = c.CountCure(self,fri[i],cureValue,cureIndex,this);
                a.GetCure(self,fri[i],cure,this,true);
            }
            let res = a.SelectTarget(self,realRange+1,false,true,2,false,true,this);
            for(let i in res){
                let block = new DamageBlock(self,this,'攻击',true,-value1,false,false,2,0);
                block.Init(res[i],null,true);
            }
            
            this.STATISTICS.times += 1;
        }
    },
]
export default qunBArr;