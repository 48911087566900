import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import ExecuteSysBlock from '../block/executeSys.js';
let shuAArr = [
    //黄月英
    {
        id : 6,
        name : "匠心不竭",
        type : "指挥",
        odd : 100,
        range : 6,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let damage1 = 34;//恐慌伤害率
            let damage2 = 41;//燃烧伤害率
            let damage3 = 44;//妖术伤害率

            let block = new StatusBlock(self,this,'无视规避',1,0);
            block.Init(self,false);
            let res = a.SelectTarget(self,6,false,true,3,false,true,this);
            for(let i in res){
                let block1 = new DamageStatus(self,this,"恐慌",damage1,"谋略",99,0,false);
                let work1 =  block1.Init(res[i],false);
                if(work1){
                    let damage = c.CountDamage(self,res[i],damage1,'谋略',false,that,'恐慌');
                    let damageMake = function(){
                        let tgt = this.beExecuted;
                        if(self.driver.turn >= 0 && tgt.alive){
                            let avail = false;
                            for(let j in tgt.SPECIAL_STATUS){
                                if(tgt.SPECIAL_STATUS[j].tactics == that && tgt.SPECIAL_STATUS[j].type == '恐慌') avail = true;
                            }
                            if(avail){
                                let r = new Report();
                                r.InitExecuteReport(2,res[i],`由于【匠心不竭】的恐慌，损失了${damage}兵力(${res[i].hp - damage})`,false);
                                a.GetHurt(self,tgt,damage,false,'谋略',that,false,r,true);
                            }
                        }
                    }
                    let block = new ExecuteBlock(self,that,'行动开始',damageMake,99);
                    block.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`恐慌(预备)效果已施加`);
                }
                let block2 = new DamageStatus(self,this,"燃烧",damage2,"谋略",99,2,false);
                let work2 =  block2.Init(res[i],false);
                if(work2){
                    let damage = c.CountDamage(self,res[i],damage2,'谋略',false,that,'燃烧');
                    let damageMake = function(){
                        let tgt = this.beExecuted;
                        if(self.driver.turn >= 3 && tgt.alive){
                            let avail = false;
                            for(let j in tgt.SPECIAL_STATUS){
                                if(tgt.SPECIAL_STATUS[j].tactics == that && tgt.SPECIAL_STATUS[j].type == '燃烧') avail = true;
                            }
                            if(avail){
                                let r = new Report();
                                r.InitExecuteReport(2,res[i],`由于【匠心不竭】的燃烧，损失了${damage}兵力(${res[i].hp - damage})`,false);
                                a.GetHurt(self,tgt,damage,false,'谋略',that,false,r,true);
                            }
                        }
                    }
                    let block = new ExecuteBlock(self,that,'行动开始',damageMake,99);
                    block.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`燃烧(预备)效果已施加`);
                }
                let block3 = new DamageStatus(self,this,"妖术",damage3,"谋略",99,4,false);
                let work3 =  block3.Init(res[i],false);
                if(work3){
                    let damage = c.CountDamage(self,res[i],damage3,'谋略',false,that,'妖术');
                    let damageMake = function(){
                        let tgt = this.beExecuted;
                        if(self.driver.turn >= 5 && tgt.alive){
                            let avail = false;
                            for(let j in tgt.SPECIAL_STATUS){
                                if(tgt.SPECIAL_STATUS[j].tactics == that && tgt.SPECIAL_STATUS[j].type == '妖术') avail = true;
                            }
                            if(avail){
                                let r = new Report();
                                r.InitExecuteReport(2,res[i],`由于【匠心不竭】的妖术，损失了${damage}兵力(${res[i].hp - damage})`,false);
                                a.GetHurt(self,tgt,damage,false,'谋略',that,false,r,true);
                            }
                        }
                    }
                    let block = new ExecuteBlock(self,that,'行动开始',damageMake,99);
                    block.Init(res[i],false);
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`妖术(预备)效果已施加`);
                }
            }
            let arr = [];
            for(let i in self.STATUS){
                if(self.STATUS[i].tactics != this.tactics) arr.push(self.STATUS[i]);
            }
            self.STATUS = arr;
            this.STATISTICS.times += 1;
        }
    },
    //姜维
    {
        id : 16,
        name : "其徐如林",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let value = q.GetAdditionValue(self,29.2,'谋略',0.12);
            let odd = 50;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = self.morale > 100 ? 3 : 2;
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,self.morale > 100,true,this);
            for(let i in res){
                for(let j=0;j<4;j++){
                    let str = "";
                    switch(j){
                        case 0 : str = "攻击";break
                        case 1 : str = "防御";break
                        case 2 : str = "谋略";break
                        case 3 : str = "速度";break
                    }
                    let block = new AttrBlock(self,this,str,Math.floor(value),2);
                    block.Init(res[i],true);
                }
                for(let j=0;j<3;j++){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let block = new DodgeBlock(self,this,99);
                        block.Init(res[i]);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //马云禄
    {
        id : 25,
        name : "红颜铁骑",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let value = 30;
            if(self.driver.version == '平衡'){
                value = 35;
            }

            //增加攻击属性
            let attr = new AttrBlock(self,this,"攻击",value,99);
            let work1 =  attr.Init(self,false);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,self,`攻击属性提高了${value}(${q.GetAttr(self,"攻击")})`);
            }
            let block = new StatusBlock(self,this,"连击",99,0);
            let work2 =  block.Init(self,false);
            if(work2){
                let r = new Report();
                r.InitExecuteReport(2,self,`连击效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //张飞
    {
        id : 39,
        name : "长坂之吼",
        type : "主动",
        odd : 75,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑'],
        wait : 2,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 450;
            let targetNumber = 2 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            //附加上无视兵种
            let block = new StatusBlock(self,this,"无视兵种",0);
            block.Init(self);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            let arr = [];
            for(let i in self.STATUS){
                if(self.STATUS[i].tactics != this) arr.push(self.STATUS[i]);
                else{
                    let report = new Report();
                    report.InitExecuteReport(3,self,`来自【${self.STATUS[i].tactics.name}】的${self.STATUS[i].type}效果消失了`)
                }
            }
            self.STATUS = arr;
            
            
            this.STATISTICS.times += 1;
        }
    },
    //沙摩柯
    {
        id : 57,
        name : "落首箭",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 300;//第一段伤害率
            let damageValue2 = 180;//第二段伤害率
            let time = 1 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue1,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            //对大营发动攻击
            let target = self.enemy[0];
            let damage = c.CountDamage(self,target,damageValue2,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,'攻击',this,true,null);
            let block = new StatusBlock(self,this,'混乱',time,0);
            block.Init(target,true);
            this.STATISTICS.times += 1;
        }
    },
    //黄忠
    {
        id : 67,
        name : "定军扬威",
        type : "主动",
        odd : 120,
        range : 2,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 100;//伤害率
            let value = q.GetAdditionValue(self,25,'防御',0.1);
            let targetNumber = 1 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'挑衅',1,0);
                block.Init(res[i],true);
            }
            let block = new DamageBlock(self,this,'攻击',false,-value,false,false,0,0);
            block.Init(self,null,true);
            
            this.STATISTICS.times += 1;
        }
    },
    //徐庶
    {
        id : 60,
        name : "破阵强袭",
        type : "追击",
        odd : 120,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = q.GetAdditionValue(self,119,'谋略',1.15);//基础伤害率
            let addition_value = 5;//增伤率
            let odd = 40;//触发暴走几率
            if(self.driver.version == '平衡'){
                damageValue = q.GetAdditionValue(self,135,'谋略',1.22);
            }
            
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"谋略",false,this,null);
            a.GetHurt(self,target,damage,false,"谋略",this,true);
            
            //增伤
            let flag = true;
            for(let i in self.DAMAGE_VAR){
                if(self.DAMAGE_VAR[i].tactics == this){
                    if(self.DAMAGE_VAR[i].value < addition_value * 6){
                        self.DAMAGE_VAR[i].value += addition_value;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,self,`【破阵强袭】令`,`策略伤害提高${self.DAMAGE_VAR[i].value}%`)
                    }
                    flag=false;break
                }
            }
            if(flag){
                let block = new DamageBlock(self,this,'谋略',true,addition_value,false,false,99,0);
                let work = block.Init(self,null,false);
                if(work){
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【破阵强袭】令`,`策略伤害提高${addition_value}%`)
                }
            }
            //暴走
            let realOdd = q.GetMoraleOdd(self,odd);
            let rand = Math.floor(Math.random()*100);
            if(rand < realOdd){
                let block = new StatusBlock(self,this,'暴走',1,0);
                block.Init(target,true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //马岱
    {
        id : 89,
        name : "奉令护蜀",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that  = this;
            let q = new Query();
            let index1 = q.GetAdditionValue(self,35,'攻击',0.2);//攻击力加成
            let index2 = q.GetAdditionValue(self,20,'防御',0.03);

            let action = function(){
                let ad = false; let de = false;
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        if(self.DAMAGE_VAR[i].isMakeDamage){
                            if(self.DAMAGE_VAR[i].value < index1 * 5){
                                self.DAMAGE_VAR[i].value += index1;
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,self,`【奉令护蜀】令`,`攻击伤害提高${self.DAMAGE_VAR[i].value}%`);
                            }
                            ad = true;
                        }
                        else{
                            if(self.DAMAGE_VAR[i].value > -index2*5){
                                self.DAMAGE_VAR[i].value -= index2;
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,self,`【奉令护蜀】令`,`受到所有伤害降低${Math.abs(self.DAMAGE_VAR[i].value)}%`);

                            }
                            de = true;
                        }
                    }
                }
                if(!ad){
                    let block = new DamageBlock(self,this,'普通攻击',true,index1,false,false,99,0);
                    block.Init(self,null,false);
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【奉令护蜀】令`,`攻击伤害提高${index1}%`);
                }
                if(!de){
                    let block = new DamageBlock(self,that,'所有',false,-index2,true,false,99,0);
                    block.Init(self,null,false);
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【奉令护蜀】令`,`受到所有伤害降低${index2}%`);
                }
            }
            let action1 = function(){
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that && self.DAMAGE_VAR[i].isMakeDamage){
                        self.DAMAGE_VAR[i].value = 0;
                        let r = new Report();
                        r.InitExecuteReport(3,self,`来自【奉令护蜀】普通攻击伤害提高效果消失了`);
                    }
                }
            }
            for(let i in self.ally){
                if(self.ally[i] != self){
                    let block1 = new AttackExecuteBlock(self,this,action,99,0,true);
                    block1.Init(self.ally[i],false);
                    let block2 = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
                    block2.Init(self.ally[i],false);
                    let block3 = new ActiveTacticsBlock(self,this,action,99,0,true,'追击');
                    block3.Init(self.ally[i],false);
                }
            }
            let block1 = new DamageBlock(self,this,'普通攻击',true,0,false,false,99,0);
            let work = block1.Init(self,null,false);
            if(work){
                let block = new AttackExecuteBlock(self,this,action1,99,0,true);
                block.Init(self,false);
                let r = new Report();
                r.InitExecuteReport(2,self,`【奉令护蜀】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //魏延
    {
        id : 97,
        name : "奇兵拒北",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步','弓'],
        wait : 0,
        execute(self){
            let that  = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let odd = 30;//初始触发几率
            let additionOdd = 5;//叠加几率
            let damageValue1 = 180;//伤害率1
            let damageValue2 = 90;//伤害率2

            let action = function(){
                let realOdd = q.GetMoraleOdd(self,odd + self[`奇兵拒北${self.position}`]*additionOdd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【奇兵拒北】`);
                    let res = [];
                    if(self.enemy[1].alive) res.push(self.enemy[1]);
                    if(self.enemy[0].alive) res.push(self.enemy[0]);
                    for(let i in res){
                        let damage  = c.CountDamage(self,res[i],damageValue1,'攻击',false,that,null);
                        a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                    }
                    //找到我军速度最高
                    let highValue = -10000;
                    let highest = self;
                    let fri = a.SelectTarget(self,5,true,false,2,false,false,that);
                    for(let i in fri){
                        let tgt = fri[i];
                        if(q.GetAttr(tgt,'速度') > highValue){
                            highValue = q.GetAttr(tgt,'速度');
                            highest = tgt;
                        } 
                        else if(q.GetAttr(tgt,'速度') == highValue){
                            let rand = Math.floor(Math.random()*100);
                            if(rand == 1){s
                                highValue = q.GetAttr(tgt,'速度');
                                highest = tgt;
                            }
                        }
                    }
                    let newRes = [];
                    if(self.enemy[1].alive) newRes.push(self.enemy[1]);
                    if(self.enemy[0].alive) newRes.push(self.enemy[0]);
                    let r1 = new Report();
                    r1.InitExecuteReport(3,highest,`执行【奇兵拒北】`);
                    damageValue2 = damageValue2 + Math.floor(Math.random()*90)
                    for(let i in newRes){
                        let damage  = c.CountDamage(highest,newRes[i],damageValue2,'攻击',false,that,null);
                        a.GetHurt(highest,newRes[i],damage,false,'攻击',that,true,null);
                    }
                    self[`奇兵拒北${self.position}`] = 0;
                }
                else{
                    self[`奇兵拒北${self.position}`] += 1;
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【奇兵拒北】没有生效，当前生效几率为${realOdd + self[`奇兵拒北${self.position}`]*additionOdd}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,true);
            self[`奇兵拒北${self.position}`] = 0;//初始化标记
            let r = new Report();
            r.InitExecuteReport(2,self,`【奇兵拒北】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //关兴&张苞
    {
        id : 102,
        name : "将门有将",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that  = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let odd = 30;//基本概率
            let odd_addition = 10;//提升概率
            let damageValue = 100;//分兵伤害率

            let action = function(){
                for(let j=0;j<2;j++){
                    let realOdd = q.GetMoraleOdd(self,odd + odd_addition*(self.driver.turn-1));
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let type = j==0?"连击":"分兵";
                        let value = j==0?0:damageValue;
                        let block = j==0?new StatusBlock(self,that,'连击',0,0) :new CounterAttackBlock(self,that,type,value,100,0,0);
                        let work = j==0?block.Init(self,false):block.Init(self,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,self,`【将门有将】令`,`获得${type}`);
                        }
                    }
                    else{
                        let r = new Report();
                        let txt = j==0?'连击效果没有生效':'分兵效果没有生效';
                        r.InitExecuteReport(2,self,`【将门有将】${txt}，生效几率为${realOdd}%`);
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,true);
            let r = new Report();
            r.InitExecuteReport(2,self,`【将门有将】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //夏侯霸
    {
        id : 106,
        name : "虎步陷阵",
        type : "主动",
        odd : 120,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 120;//基础伤害率
            let addition = 15;//增伤幅度
            if(self.driver.version == '平衡'){
                damageIndex = 125;
            }

            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                a.GetHurt(self,res[i],damage,false,"攻击",this);
                let work = true;
                for(let j in res[i].DAMAGE_VAR){
                    if(res[i].DAMAGE_VAR[j].tactics == this){
                        res[i].DAMAGE_VAR[j].value += addition;
                        let r = new Report();
                        r.InitExecuteReport(3,res[i],`受到攻击伤害提高${res[i].DAMAGE_VAR[j].value}%`);
                        work = false;
                    }
                }
                if(work){
                    let block = new DamageBlock(self,this,'攻击',false,addition,false,false,99,0);
                    block.Init(res[i],null,true,3);
                }   
                
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //周仓
    {
        id : 118,
        name : "持刀从武",
        type : "被动",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that  = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            let odd = 50;//基本概率
            let damageValue = 120;//分兵伤害率
            let decline = 20;//伤害递减

            self['target'] = [];
            let getHurt = function(){
                let origin = this.damageFromWho;
                //伤害来源自我军大营
                if(origin == self.ally[0]){
                    self['target'].push(this.beExecuted);
                }
            }
            for(let i in self.enemy){
                let block = new AfterDamageBlock(self,that,getHurt,99,true,true,true,true,true,true,true);
                block.Init(self.enemy[i],'持刀从武')
            }
            let action = function(){
                //执行
                if(!j.IsGetStatus(self,'混乱')){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    //把处于攻击范围内的敌军筛选出来
                    let temp = [];
                    for(let i in self['target']){
                        if(j.IsInScale(self,self['target'][i],3)) temp.push(self['target'][i]);
                    }
                    self['target'] = temp;
                    if(rand < realOdd && self['target'].length > 0){
                        let r = new Report();
                        r.InitExecuteReport(2,self,`执行【持刀从武】`);
                        for(let i=0;i<3;i++){
                            let target = self['target'][Math.floor(Math.random()*self['target'].length)];
                            let realDamage = damageValue;
                            if(!j.IsGetStatus(target,'混乱') && !j.IsGetStatus(target,'怯战') && !j.IsGetStatus(target,'混乱')) realDamage-=decline;
                            let damage = c.CountDamage(self,target,realDamage,'攻击',false,that,null);
                            a.GetHurt(self,target,damage,false,'攻击',that,true,null);
                        }
                    }
                    else if(self['target'].length == 0){
                        let r = new Report();
                        r.InitExecuteReport(2,self,`【持刀从武】没有目标可以选择`);
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(2,self,`【持刀从武】没有生效，生效几率为${realOdd}%`)
                    }
                }
                self['target'] = [];
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,true);
            let r = new Report();
            r.InitExecuteReport(2,self,`【持刀从武】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //刘禅
    {
        id : 121,
        name : "赐剑长驱",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that  = this;
            let q = new Query();
            let odd = q.GetAdditionValue(self,40,'谋略',0.08);//触发几率
            let value = 50;//伤害降低量

            let tag = '赐剑长驱' + self.belong + self.position;
            let action = function(){
                //执行人
                let target = this.beExecuted;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,target,`【赐剑长驱】令`,`【${this.triggerTactics.name}】再次发动`);
                    //发动之前先挂减伤
                    let block1 = new DamageCureDiscountBlock(self,that,true,'伤害',-value,0,0,null);
                    block1.Init(target,false,3);
                    let block2 = new DamageCureDiscountBlock(self,that,true,'治疗',-value,0,0,null);
                    block2.Init(target,false,3);
                    this.triggerTactics.execute(target);
                    block1.Clear(target);
                    block2.Clear(target);
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【赐剑长驱】没有生效，生效几率为${realOdd}%`)
                }
                target[tag] = false;
            }
            for(let i in self.ally){
                let target = self.ally[i];
                if(target != self){
                    let block = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
                    block.Init(target,false);
                    target[tag] = true;
                    let r = new Report();
                    r.InitExecuteReport(2,target,`【赐剑长驱】效果已施加`)
                }
            }
            //不能普通攻击
            let block1 = new StatusBlock(self,this,"无法普通攻击",99,0);
            block1.Init(self,false);
            let block2 = new StatusBlock(self,this,"无法施法",99,0);
            block2.Init(self,false);
            //刷新标记
            let clearTag = function(){
                for(let i in self.ally){
                    let target = self.ally[i];
                    if(target != self){
                        target[tag] = true;
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',clearTag,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //马良
    {
        id : 144,
        name : "雅虑适时",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 5,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = 24 + Math.floor((q.GetAttr(self,'谋略')-80) * 0.1);
            let value1 = 15;//分担伤害的比例
            let action = function(){
                if(self.driver.turn == 3 || self.driver.turn == 5 || self.driver.turn == 7){
                    //设置标记
                    let tag = "雅虑适时" + self.belong;
                    let realTargetNumber = q.GetRealTargetNumber(that.targetNumber,self,that);
                    let res = a.SelectTarget(self,5,true,false,realTargetNumber,true,false,that);
                    let r = new Report();
                    r.InitExecuteReport(1,self,`执行【雅虑适时】`);
                    //施加减伤
                    for(let i in res){
                        let block1 = new DamageBlock(self,that,"攻击",false,-value,false,false,1,0);
                        let work1 =  block1.Init(res[i],null,false);
                        if(work1){
                            let r = new Report();
                            r.InitExecuteReport(2,res[i],`受到攻击伤害降低${value}%`);
                        }
                        let block2 = new DamageBlock(self,that,"谋略",false,-value,false,false,1,0);
                        let work2 =  block2.Init(res[i],null,false);
                        if(work2){
                            let r = new Report();
                            r.InitExecuteReport(2,res[i],`受到策略伤害降低${value}%`);
                        }
                    }
                    //施加分担伤害
                    for(let i in res){
                        let target = res[i];
                        //另外的队友挂上为其减伤的效果
                        let action1 = function(){
                            //如果伤害来源是这个战法，不进行分担
                            if(this.damageFromTactics == that || target[tag] != self.driver.turn){
                                console.log(555);
                                return;
                            }
                            //获取另外两个队友
                            let newRes = a.SelectTarget(target,5,true,false,realTargetNumber,true,false,that);
                            let fri = [];
                            for(let j in newRes){
                                if(newRes[j] != target) fri.push(newRes[j]);
                            }
                            //获取伤害值
                            let damage = this.damageValue;
                            //伤害值扣除减伤比例
                            let getNewHurtValue = Math.floor(value1/100 * damage);
                            damage = getNewHurtValue * fri.length;
                            if((target.hp + damage) < q.GetMaxHp(target)) target.hp += damage;//大营返还失去兵力
                            else target.hp = q.GetMaxHp(target);
                            for(let j in fri){
                                let report = new Report().InitTwoTargetReport(3,fri[j],target,`代替`,`损失了${getNewHurtValue}兵力(${fri[j].hp-getNewHurtValue})`);
                                a.GetHurt(this.damageFromWho,fri[j],getNewHurtValue,this.isCommonAttack,this.damageType,that,false,report);
                            }
                            // let report = new Report().InitTwoTargetReport(3,self,target,`代替`,`损失了${damage}兵力(${self.hp})`);
                            // a.GetHurt(this.damageFromWho,self,damage,this.isCommonAttack,this.damageType,this.damageFromTactics,false,report);
                        }
                        let block = new AfterDamageBlock(self,that,action1,1,true,true,true,true,true,true,true);
                        block.Init(target,'雅虑适时');
                        target[tag] = self.driver.turn;
                        let r = new Report();
                        r.InitExecuteReport(2,target,`进入同心状态`);

                    }
                }
            }
            let block = new ExecuteSysBlock(self,this,"回合开始",action,99);
            block.Init(self);
            this.STATISTICS.times += 1;
        }
    },
    //sp赵云
    {
        id : 5023,
        name : "银龙孤胆",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 80;//伤害率
            let addition = 7;

            let realRange = q.GetTacticsRange(self,this);
            for(let j=0;j<7;j++){
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                for(let i in res){
                    let realDamage = damageValue + addition * j;
                    let damage = c.CountDamage(self,res[i],realDamage,"攻击",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                }
            }
            
            this.STATISTICS.times += 1;
        }
    },
]

export default shuAArr;