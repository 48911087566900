import Report from "./report";
class Manage{
    constructor(){

    }
    //管理获得治疗后执行函数
    ManageGetCureExecuteBlock(target){
        let arr = [];
        let len = target.GET_CURE.length;
        for(let i=0;i<len;i++){
            if(!target.GET_CURE[i]['action']){
                target.GET_CURE[i]['action'] = true;
                if(target.GET_CURE[i].duration > 0){
                    arr.push(target.GET_CURE[i]);
                }
                target.GET_CURE[i].duration--;
            }
            else arr.push(target.GET_CURE[i]);
            if(target.GET_CURE.length != len){
                i=0;
                len = target.GET_CURE.length;
            }
        }
        //清空action
        for(let i in target.GET_CURE){
            target.GET_CURE[i]['action'] = false;
        }
        target.GET_CURE = arr;
    }
    //管理战法释放前后执行函数
    ManageTacticsExecutedBlock(target){
        let temp = [];
        for(let i in target.ACTIVE_TACTICS_EXECUTE){
            if(target.ACTIVE_TACTICS_EXECUTE[i].duration > 0) temp.push(target.ACTIVE_TACTICS_EXECUTE[i]);
            target.ACTIVE_TACTICS_EXECUTE[i].duration--;
        }
        target.ACTIVE_TACTICS_EXECUTE = temp;
    }
    //管理普通攻击后执行函数
    ManageAttackExecuteBlock(target){
        let temp = [];
        for(let i in target.LAUNCH_ATTACK_EXECUTE){
            if(target.LAUNCH_ATTACK_EXECUTE[i].duration > 0) temp.push(target.LAUNCH_ATTACK_EXECUTE[i]);
            target.LAUNCH_ATTACK_EXECUTE[i].duration--;
        }
        target.LAUNCH_ATTACK_EXECUTE = temp;
    }
    //管理无视属性
    ManageIgnoreBlock(target){
        let temp = [];
        for(let i in target.IGNORE){
            if(target.IGNORE.duration > 0){
                if(target.IGNORE[i],waitTime>0) target.IGNORE[i],waitTime--;
                temp.push(target.IGNORE[i]);
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,target,`来自【${target.IGNORE[i].tactics.name}】无视${target.IGNORE[i].value}%效果消失了`);
            }
            target.IGNORE[i].duration--;
        }
        target.IGNORE = temp;
    }
    //管理几率控制块
    ManageOddBlock(target){
        let temp = [];
        for(let i in target.ODD_VAR){
            if(target.ODD_VAR[i].duration > 0){
                temp.push(target.ODD_VAR[i]);
            }
            else if(target.ODD_VAR[i].value != 0){
                let r = new Report();
                let text=`【${target.ODD_VAR[i].workTactics.name}】发动几率${target.ODD_VAR[i].value > 0?"增加":"降低"}${Math.abs(target.ODD_VAR[i].value)}%效果消失了`;
                r.InitExecuteReport(2,target,text);
            }
            target.ODD_VAR[i].duration--;
        }
        target.ODD_VAR = temp;
    }
    //管理距离控制块
    ManageRangBlock(target){
        let temp = [];
        for(let i in target.RANGE){
            if(target.RANGE[i].duration > 0){
                if(target.RANGE[i].waitTime > 0){
                    target.RANGE[i].waitTime--;
                }
                temp.push(target.RANGE[i]);
            }
            else{
                let text = target.RANGE[i].isCommonAttack ? "攻击距离" : "战法距离" ;
                if(target.RANGE[i].isBenefit) text += "提高";
                else text += "降低";
                text += `(${Math.abs(target.RANGE[i].value)})效果消失了`
                let r = new Report();
                r.InitExecuteReport(2,target,text);
            }
            target.RANGE[i].duration--;
        }
        target.RANGE = temp;
    }
    //管理反击分兵块
    ManageCounterAttackBlock(target){
        let temp = [];
        for(let i in target.COUNTER_ATTACK){
            if(target.COUNTER_ATTACK[i].duration > 0){
                if(target.COUNTER_ATTACK[i].waitTime > 0){
                    target.COUNTER_ATTACK[i].waitTime--;
                }
                temp.push(target.COUNTER_ATTACK[i]);
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,target,`来自【${target.COUNTER_ATTACK[i].tactics.name}】的${target.COUNTER_ATTACK[i].type}效果消失了`);
            }
            target.COUNTER_ATTACK[i].duration--;
        }
        target.COUNTER_ATTACK = temp;
    }
    //管理规避块
    ManageDodgeBlock(target){
        target.DODGE.shift();
    }
    //管理准备战法
    ManageWaitTactics(target,tacticsItem){
        let temp = [];
        let res = {
            launch : null,
            wait : null,
        };
        for(let i in target.WaitTacticsArr){
            if(tacticsItem == target.WaitTacticsArr[i].tactics){
                target.WaitTacticsArr[i].waitTime--;
                //发动战法
                if(target.WaitTacticsArr[i].waitTime <= 0){
                    let r = new Report();
                    r.InitExecuteReport(2,target,`发动【${target.WaitTacticsArr[i].tactics.name}】!`);
                    target.WaitTacticsArr[i].tactics.execute(target);
                    //发动战法放入res，实现本回合发动的准备型战法不会再次准备
                    res.launch = target.WaitTacticsArr[i].tactics;
                }
                else{
                    res.wait = target.WaitTacticsArr[i].tactics;
                    temp.push(target.WaitTacticsArr[i]);
                }
            }
            else temp.push(target.WaitTacticsArr[i]);
        }
        target.WaitTacticsArr = temp;
        return res;
    }
    //管理状态类型治疗块的生命周期
    ManageCureStatusBlock(target){
        let temp = [];
        for(let i in target.CURE_STATUS){
            if(target.CURE_STATUS[i].duration > 0){
                if(target.CURE_STATUS[i].waitTime <= 0) target.CURE_STATUS[i].Execute(target);
                else target.CURE_STATUS[i].waitTime --;
                temp.push(target.CURE_STATUS[i]);
            }
            else{
                let r = new Report();
                let text = `来自【${target.CURE_STATUS[i].tactics.name}】的${target.CURE_STATUS[i].type}效果消失了`;
                r.InitExecuteReport(2,target,text);
            }
            target.CURE_STATUS[i].duration--;
        }
        target.CURE_STATUS = temp;
    }
    //管理状态类型伤害块的生命周期
    ManageDamageStatusBlock(target){
        let arr = [];
        let len = target.SPECIAL_STATUS.length;
        for(let i=0;i<len;i++){
            if(!target.SPECIAL_STATUS[i]['action']){
                target.SPECIAL_STATUS[i]['action'] = true;
                if(target.SPECIAL_STATUS[i].duration > 0){
                    if(target.SPECIAL_STATUS[i].waitTime <= 0){
                        if(target.SPECIAL_STATUS[i].isExecute){
                            target.SPECIAL_STATUS[i].Execute(target);
                        }
                    }
                    else target.SPECIAL_STATUS[i].waitTime -= 1;
                    arr.push(target.SPECIAL_STATUS[i]);
                }
                else{
                    let report = new Report();
                    let str = `来自【${target.SPECIAL_STATUS[i].tactics.name}】的${target.SPECIAL_STATUS[i].type}效果消失了`
                    report.InitExecuteReport(2,target,str);
                }
                target.SPECIAL_STATUS[i].duration --;
            }
            if(target.SPECIAL_STATUS.length != len){
                i=0;
                len = target.SPECIAL_STATUS.length;
            }
        }
        //清空action
        for(let i in target.SPECIAL_STATUS){
            target.SPECIAL_STATUS[i]['action'] = false;
        }
        target.SPECIAL_STATUS = arr;
    }
    //管理即将造成伤害之前调用执行函数块
    ManageBeforeMakeDamageBlock(target){
        let temp = [];
        for(let i in target.BEFORE_MAKE_DAMAGE){
            if(target.BEFORE_MAKE_DAMAGE[i].duration > 0) temp.push(target.BEFORE_MAKE_DAMAGE[i]);
            target.BEFORE_MAKE_DAMAGE[i].duration--;
        }
        target.BEFORE_MAKE_DAMAGE = temp;
    }
    //管理受到伤害后的调用的执行块
    ManageAfterGetDamagedBlock(target){
        //受到伤害后执行
        let temp = [];
        for(let i in target.AFTER_DAMAGE){
            if(target.AFTER_DAMAGE[i].duration > 0){
                temp.push(target.AFTER_DAMAGE[i]);
            }
            target.AFTER_DAMAGE[i].duration--;
        }
        target.AFTER_DAMAGE = temp;
        //造成伤害后执行
        let arr = [];
        for(let i in target.MAKE_DAMAGE){
            if(target.MAKE_DAMAGE[i].duration > 0){
                arr.push(target.MAKE_DAMAGE[i]);
            }
            target.MAKE_DAMAGE[i].duration--;
        }
        target.MAKE_DAMAGE = arr;
    }
    //管理系统回合开始执行块
    ManageExecuteSysBlock(target){
        let arr = [];
        for(let i in target.EXECUTE){
            if(target.EXECUTE[i].duration > 0){
                target.EXECUTE[i].Executed();
                arr.push(target.EXECUTE[i]);
            }
            target.EXECUTE[i].duration--;
        }
        target.EXECUTE = arr;
    }
    //管理执行块的生命周期
    ManageExecuteBlock(target,stage){
        let arr = [];
        let len = target.EXECUTE.length;
        for(let i=0;i<len;i++){
            if(target.EXECUTE[i].executeStage == stage && !target.EXECUTE[i]['action']){
                target.EXECUTE[i]['action'] = true;
                if(target.EXECUTE[i].duration > 0){
                    target.EXECUTE[i].execute(target.EXECUTE[i]);
                    arr.push(target.EXECUTE[i]);
                }
                target.EXECUTE[i].duration--;
            }
            else arr.push(target.EXECUTE[i]);
            if(target.EXECUTE.length != len){
                i=0;
                len = target.EXECUTE.length;
            }
        }
        //清空action
        for(let i in target.EXECUTE){
            target.EXECUTE[i]['action'] = false;
        }
        target.EXECUTE = arr;
    }
    //管理属性块的生命周期
    ManageAttrBlock(target){
        let arr = [];
        for(let i in target.ATTR){
            if(target.ATTR[i].duration > 0) arr.push(target.ATTR[i]);
            else{
                let report = new Report();
                let text = `${target.ATTR[i].attrType}属性`
                if(target.ATTR[i].isBenefit) text += "增益"
                else text += "降低"
                text += `(${Math.abs(target.ATTR[i].value)})效果消失了`
                report.InitExecuteReport(2,target,text);
            }
            target.ATTR[i].duration -= 1;
        }
        target.ATTR = arr;
    }
    //管理状态块的生命周期
    ManageStatusBlock(target){
        let arr = [];
        for(let i in target.STATUS){
            if(target.STATUS[i].duration > 0){
                if(target.STATUS[i].waitTime > 0) target.STATUS[i].waitTime--;
                arr.push(target.STATUS[i]);
            }
            else{
                if(target.STATUS[i].type != "无法普通攻击" || target.STATUS[i].type != "无法施法"){
                    let report = new Report();
                    report.InitExecuteReport(2,target,`来自【${target.STATUS[i].tactics.name}】的${target.STATUS[i].type}状态消失了`)
                }
            }
            target.STATUS[i].duration -= 1;
        }
        target.STATUS = arr;
    }
    //管理一次性战法群体目标的增减伤
    ManageOnceTacticsDamageBlock(target,isMakeDamage){
        let temp = [];
        for(let i in target.DAMAGE_VAR){
            if(target.DAMAGE_VAR[i].isOnceTactics && target.DAMAGE_VAR[i].isMakeDamage == isMakeDamage){
                let r = new Report();
                let str1 = target.DAMAGE_VAR[i].isMakeDamage ? "" : "受到";
                let str2 = "";
                if(target.DAMAGE_VAR[i].damageType == '谋略') str2 = "策略伤害";
                else if(target.DAMAGE_VAR[i].damageType == '攻击') str2 = "攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '普通攻击') str2 = "普通攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '主动') str2 = "主动战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '追击') str2 = "追击战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '所有') str2 = "所有伤害";
                let str3 = "";
                if(target.DAMAGE_VAR[i].value >=  666) str3 += "大幅提高";
                else if(target.DAMAGE_VAR[i].value >= 0  && target.DAMAGE_VAR[i].value < 666) str3 += '提高';
                else if(target.DAMAGE_VAR[i].value < -666) str3 += "大幅降低"
                else str3 += '降低';
                let str4 = "";
                if(target.DAMAGE_VAR[i].value >= -90) str4 = `${Math.abs(target.DAMAGE_VAR[i].value)}%`
                r.InitExecuteReport(3,target,`来自【${target.DAMAGE_VAR[i].tactics.name}】的${str1+str2+str3+str4}效果消失了`);
            }
            else temp.push(target.DAMAGE_VAR[i]);
        }
        target.DAMAGE_VAR = temp;
    }
    //管理一次性的增减伤控制块
    ManageOnceDamageBlock(target,damage,isCommonAttack,damageType,tacticsItem,isMakeDamage){
        let count = [];
        let temp = [];
        // && !(tacticsItem && target.DAMAGE_VAR[i].isOnceTactics)
        for(let i in target.DAMAGE_VAR){
            let typeControl = false;
            if(damageType == target.DAMAGE_VAR[i].damageType || target.DAMAGE_VAR[i].damageType == '所有'){
                typeControl = true;
            }
            //伤害来源为主动战法，
            else if(tacticsItem != null &&  tacticsItem.type == '主动' && target.DAMAGE_VAR[i].damageType == '主动') typeControl = true;
            //伤害来源为追击战法
            else if(tacticsItem != null &&  tacticsItem.type == '追击' && target.DAMAGE_VAR[i].damageType == '追击') typeControl = true;
            //伤害来源为普通攻击
            else if(tacticsItem == null && isCommonAttack && target.DAMAGE_VAR[i].damageType == '普通攻击') typeControl = true;
            if(target.DAMAGE_VAR[i].isOnceAttack && typeControl
            &&  target.DAMAGE_VAR[i].isMakeDamage == isMakeDamage
            && !(tacticsItem && target.DAMAGE_VAR[i].isOnceTactics)){
                //出自一个战法的一次性加成块只结算一个
                let flag = false;
                for(let j in count){
                    if(count[j].tactics == target.DAMAGE_VAR[i].tactics){
                        flag = true; break;
                    }
                }
                if(flag) temp.push(target.DAMAGE_VAR[i]);
                //消耗掉一次性块
                else{
                    let r = new Report();
                    let str1 = target.DAMAGE_VAR[i].isMakeDamage ? "" : "受到";
                    let str2 = "";
                    if(target.DAMAGE_VAR[i].damageType == '谋略') str2 = "策略伤害";
                    else if(target.DAMAGE_VAR[i].damageType == '攻击') str2 = "攻击伤害";
                    else if(target.DAMAGE_VAR[i].damageType == '普通攻击') str2 = "普通攻击伤害";
                    else if(target.DAMAGE_VAR[i].damageType == '主动') str2 = "主动战法伤害";
                    else if(target.DAMAGE_VAR[i].damageType == '追击') str2 = "追击战法伤害";
                    else if(target.DAMAGE_VAR[i].damageType == '所有') str2 = "所有伤害";
                    let str3 = "";
                    if(target.DAMAGE_VAR[i].value < -666) str3 = "大幅降低";
                    else if(target.DAMAGE_VAR[i].value > 0) str3 = "提高";
                    else str3 = "降低";
                    let str4 = "";
                    if(target.DAMAGE_VAR[i].value >= -666) str4 = `${Math.abs(target.DAMAGE_VAR[i].value)}%`
                    r.InitExecuteReport(3,target,`来自【${target.DAMAGE_VAR[i].tactics.name}】的${str1+str2+str3+str4}效果消失了`);
                    if(target.DAMAGE_VAR[i].isOnceAttack || target.DAMAGE_VAR[i].isOnceTactics) count.push(target.DAMAGE_VAR[i]);
                }
            }
            //不是一次性消耗战法保留
            else temp.push(target.DAMAGE_VAR[i]);
        }
        target.DAMAGE_VAR = temp;
    }
    //管理增减上控制块生命周期
    ManageDamageVarBlock(target){
        let arr = [];
        for(let i in target.DAMAGE_VAR){
            if(target.DAMAGE_VAR[i].duration > 0){
                if(target.DAMAGE_VAR[i].waitTime > 0) target.DAMAGE_VAR[i].waitTime--;
                arr.push(target.DAMAGE_VAR[i]);
            }
            else{
                let report = new Report();
                let str1 = target.DAMAGE_VAR[i].isMakeDamage ? "" : "受到";
                let str2 = "";
                if(target.DAMAGE_VAR[i].damageType == '谋略') str2 = "策略伤害";
                else if(target.DAMAGE_VAR[i].damageType == '攻击') str2 = "攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '普通攻击') str2 = "普通攻击伤害";
                else if(target.DAMAGE_VAR[i].damageType == '主动') str2 = "主动战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '追击') str2 = "追击战法伤害";
                else if(target.DAMAGE_VAR[i].damageType == '所有') str2 = "所有伤害";
                let str3 = "";
                if(target.DAMAGE_VAR[i].value < -666) str3 = "大幅降低";
                else if(target.DAMAGE_VAR[i].value > 0) str3 = "提高";
                else str3 = "降低";
                let str4 = ''
                if(target.DAMAGE_VAR[i].value >= -666) str4 = `${Math.abs(target.DAMAGE_VAR[i].value)}%`
                report.InitExecuteReport(2,target,`来自【${target.DAMAGE_VAR[i].tactics.name}】的${str1+str2+str3+str4}效果消失了`);
            }
            target.DAMAGE_VAR[i].duration -= 1;
        }
        target.DAMAGE_VAR = arr;
        //处理伤害和治疗数值调整
        let temp = [];
        for(let i in target.DISCOUNT){
            if(target.DISCOUNT[i].duration > 0){
                if(target.DISCOUNT[i].waitTime > 0) target.DISCOUNT[i].waitTime--;
                temp.push(target.DISCOUNT[i]);
            }
            target.DISCOUNT[i].duration--;
        }
        target.DISCOUNT = temp;
    }
}
export default Manage;