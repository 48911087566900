<template>
    <div class="battle_result_panel">
        <!-- <div class="close_btn" @click="Close"></div>
        <div class="texture_branch">
            <div class="text">
                战 报 详 情
            </div>
        </div> -->
        <div class="result_warrior_container" ref="result_warrior_container" v-if="$store.state.BorderChess.isShowBattleResult">
            <div class="report_image">
                
                <div class="team_container" v-for="t,t_index in 2" :key="t.index">
                    <div class="troops_groove_left" v-if="t_index == 0">
                        <div class="blood_blue cover" ref="blood_blue"></div>
                        <div class="troops_number_blue"> {{ $store.state.BorderChess.battleResult[t_index][0].hp + $store.state.BorderChess.battleResult[t_index][1].hp + $store.state.BorderChess.battleResult[t_index][2].hp }}/
                            <span style="color: gray;">{{ TotalHp($store.state.BorderChess.battleResult[t_index])}}</span>
                        </div>
                        
                    </div>
                    <div class="troops_groove_right" v-if="t_index == 1">
                        <div class="blood_red cover" ref="blood_red"></div>
                        <div class="troops_number_red">{{ $store.state.BorderChess.battleResult[t_index][0].hp + $store.state.BorderChess.battleResult[t_index][1].hp + $store.state.BorderChess.battleResult[t_index][2].hp }}/
                            <span style="color: gray;">{{TotalHp($store.state.BorderChess.battleResult[t_index])}}</span>
                        </div>
                    </div>
                    <div class="warrior_card" v-for="(w,w_index) in $store.state.BorderChess.battleResult[t_index]" :key="w.index">
                        <img :src="w.img.body" alt="" class="cover" v-if="w.alive">
                        <img :src="w.img.body" alt="" class="cover filter_gray" v-if="!w.alive">
                        <img src="../../assets/ui/ui (60).png" alt="" class="cover" >
                        <ul class="star_list" v-if="w.alive">
                            <li v-for="s in w.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                            <li v-for="s in (5-w.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                        </ul>
                        <ul class="star_list filter_gray" v-if="!w.alive">
                            <li v-for="s in w.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                            <li v-for="s in (5-w.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                        </ul>
                        <div class="camp" v-show="w.camp == '蜀'"><img src="../../assets/ui/ui (75).png" alt="" class="cover"></div>
                        <div class="camp" v-show="w.camp == '吴'"><img src="../../assets/ui/ui (78).png" alt="" class="cover"></div>
                        <div class="camp" v-show="w.camp == '魏'"><img src="../../assets/ui/ui (77).png" alt="" class="cover"></div>
                        <div class="camp" v-show="w.camp == '群'"><img src="../../assets/ui/ui (72).png" alt="" class="cover"></div>
                        <div class="camp" v-show="w.camp == '汉'"><img src="../../assets/ui/ui (64).png" alt="" class="cover"></div>
                        <div class="camp" v-show="w.camp == '晋'"><img src="../../assets/ui/ui (150).png" alt="" class="cover"></div>

                        <div class="hp_num">{{w.hp}}</div>
                        <div class="warrior_sign">
                            <img src="../../assets/ui/ui (66).png" alt="" v-if="w.sign == 's2'" class="cover">
                            <img src="../../assets/ui/ui (67).png" alt="" v-if="w.sign == 's3'" class="cover">
                            <img src="../../assets/ui/ui (68).png" alt="" v-if="w.sign == 'sp'" class="cover">
                            <img src="../../assets/ui/ui (69).png" alt="" v-if="w.sign == 'xp'" class="cover">
                        </div>
                        <div class="cost_img">{{w.cost}}</div>
                        <div class="level">Lv.<span>{{w.level}}</span></div>
                        <div class="name">{{w.name}}</div>
                        <div class="arm">
                            <img src="../../assets/ui/ui (82).png" alt="" class="cover">
                            <img :src="require(`../../assets/arms/${w.up_arms[w.choseArms]}.png`)" class="icon" alt="">
                            <div class="arm_num">{{w.ackRange}}</div>
                        </div>
                        <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-if="w.alive && w.red == 5">
                        <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-if="w.alive && w.red != 5">
                        <img src="../../assets/ui/ui (73).png" alt="" class="up_mark filter_gray" v-if="!w.alive && w.red == 5">
                        <img src="../../assets/ui/ui (73).png" alt="" class="up_mark filter_gray" v-if="!w.alive && w.red != 5">
                        <div class="warrior_position">
                            <span v-if="w_index == 0">大营</span>
                            <span v-if="w_index == 1">中军</span>
                            <span v-if="w_index == 2">前锋</span>
                        </div>
                    </div>
                    <div class="result_icon" v-if="t_index == 0">
                        <div class="bg_icon1"></div>
                        <div class="text_icon">
                            <img src="../../assets/ui/ui (119).png" alt="" class="cover" v-if="teams[0][0].hp != 0 && teams[1][0].hp == 0">
                            <img src="../../assets/ui/ui (120).png" alt="" class="cover" v-if="teams[0][0].hp != 0 && teams[1][0].hp != 0">
                            <img src="../../assets/ui/ui (131).png" alt="" class="cover" v-if="teams[0][0].hp == 0 && teams[1][0].hp != 0">
                        </div>
                    </div>
                </div>
                <div class="statistics_btn" ref="statistics_btn" @touchstart="$refs.statistics_btn.classList.add('click')"
                @touchend="$refs.statistics_btn.classList.remove('click')" @click="OpenStatisticsPanel">统&nbsp;&nbsp;&nbsp;计</div>
            </div>
            <div class="report_text" ref="report_text">

            </div>
        </div>
        
        

        


        <div class="navigation_lift_btn" ref="navigation_lift_btn"
        @touchstart="$refs.navigation_lift_btn.classList.add('click')"
        @touchend="$refs.navigation_lift_btn.classList.remove('click')"
        @click="NavigateList()"
        >{{TransferChineseWords(game.Statistics.allTurn)}}回合</div>

    
        <div class="navigation_panel" v-show="show.navigation">
            <div class="mask cover" @click="CloseNavigateList"></div>
            <div class="navigation_list">
                <li v-for="(l,index) in NAV_HEAP" :key="l.index" ref="navigation_list"
                @touchstart="$refs.navigation_list[index].classList.add('click')"
                @touchend="$refs.navigation_list[index].classList.remove('click')"
                @click="NavLift(index)">
                
                    <span v-if="index == 0"  >顶 部</span> 
                    <span v-if="index == 1"  >准备阶段</span> 
                    
                    <span v-if="index > 1 && index < 10 && index != NAV_HEAP.length-1">第{{TransferChineseWords(index - 1)}}回合</span>
                    <span v-if="index == NAV_HEAP.length-1">底 部</span>
                </li>
            </div>
        </div> 
   

        <!-- 统计面板 -->
        <Statistics v-if="this.$store.state.BorderChess.isShowBattleStatistics"/>
        

    </div>
</template>

<script>
import Report from './js/report.js';
import Statistics from './Statistics.vue';
export default {
    name :'BattleResult',
    components : {
        Statistics
    },
    data(){
        return{
            teams : this.$store.state.BorderChess.battleResult,
            game : this.$store.state.BorderChess,
            show : {
                statistics : false,
                navigation : false,
            },
            NAV_HEAP : [],
            view_height : window.innerHeight/100,//对应1vw的px
            view_width : window.innerWidth/100,//对应1vw的px
            
        }
    },
    beforeCreate(){

    },
    methods:{
        //获取队伍的原始总兵力
        TotalHp(team){
            let res = 0;
            for(let i in team){
                res += (5000 + team[i].level * 100);
            }
            return res;
        },
        //获得对应的战报层数
        GetReportHeight(){
            this.NAV_HEAP = [];
            for(let i=0;i<this.game.ReportHeap.length;i++){
                if(this.game.ReportHeap[i].type == '系统'){
                    this.NAV_HEAP.push(i);
                }
            }
            this.NAV_HEAP.push(0);
        },
        //导航电梯
        NavLift(position){
            if(position == this.NAV_HEAP.length - 1){
                this.$refs.result_warrior_container.scrollTo(0,1000000);
                this.CloseNavigateList();
            }
            else if(position == 0){
                this.$refs.result_warrior_container.scrollTo(0,0);
                this.CloseNavigateList();
            }
            else{
                let report = document.querySelectorAll('.report');
                let distance = report[this.NAV_HEAP[position-1]].offsetTop;
                let high = this.$store.state.equipment == 'phone' ? 150 : 32;
                distance += high * this.view_width;
                this.$refs.result_warrior_container.scrollTo(0,distance);
                this.CloseNavigateList();
            }
        },
        //回合导航
        NavigateList(){
            this.show.navigation = true;
            this.$refs.result_warrior_container.classList.add('filter');
        },
        CloseNavigateList(){
            this.show.navigation = false;
            this.$refs.result_warrior_container.classList.remove('filter');
        },
        //汉字
        TransferChineseWords(number){
            switch(number){
                case 1 : return "一";
                case 2 : return "二";
                case 3 : return "三";
                case 4 : return "四";
                case 5 : return "五";
                case 6 : return "六";
                case 7 : return "七";
                case 8 : return "八";
            }
        },
        LoadingTeamsData(){
            
        },
        //初始化血量
        LoadingBloodTape(){
            let blueTotal = this.TotalHp(this.teams[0]);
            let redTotal = this.TotalHp(this.teams[1]);
            let blue_w = Math.floor(((this.teams[0][0].hp + this.teams[0][1].hp + this.teams[0][2].hp) / blueTotal)*100);
            let red_w = Math.floor(((this.teams[1][0].hp + this.teams[1][1].hp + this.teams[1][2].hp) / redTotal)*100);
            if(blue_w < 0){
                blue_w = 0
            }else if(blue_w > 100){
                blue_w = 100;
            }
            if(red_w < 0){
                red_w = 0;
            }else if(red_w > 100){
                red_w = 100;
            }
            let size = this.$store.state.equipment == 'phone' ? 50 : 25;
            this.$refs.blood_blue[0].style.width = (blue_w/100) * size + 'vw';
            this.$refs.blood_red[0].style.width = (red_w/100) * size + 'vw';
        },
        //打开统计面板
        OpenStatisticsPanel(){
            this.$store.state.BorderChess.isShowBattleStatistics = true;
        }   
    },
    mounted(){
        setTimeout(() => {
            this.LoadingBloodTape();
        }, 100);
        //加载战报
        let report = new Report();
        report.BuildReport(this.$refs.report_text,this.$store.state.BorderChess.ReportHeap);
        this.GetReportHeight();
    }
}
</script>

<style lang="less">
.battle_result_panel{
    position: absolute;left: 0; top: 0;
    width: 100vw;
    height: 100vh;
    background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
    background-size: cover;
    //变灰
    .filter_gray{
        filter: grayscale(1);
    }
    //蓝红环
    .blue_loop,.red_loop{
        position: absolute; left: 50%; top : 50%;
        transform : translate(-50%,-50%);
        width: 112%;height: 112%;
        background: rgb(0, 162, 194);
        // border-radius: 50%;
    }
    .red_loop{
        background: rgb(194, 0, 0);
    }
    
    .texture_branch{
        left: 0; top: 12vh;
        margin: -12vw 0 0 0;
        width: 52vw;height: 11vw;
        background: url('../../assets/background/bg (5).png')no-repeat;
        background-size: 100% 100%;
        .text{
            width: 100%; height: 11vw;
                line-height: 11vw;
                font-size: 5.4vw;
                text-align: center;
                color: rgb(193, 193, 82);
                text-shadow: 0 0 0 2px black;
                text-indent: -15vw;
                transition: all .3s;
                font-family: 'FZLBJW';
        }
    }
    .close_btn{
        right:7vw; top: 12vh;
        margin: -11vw 0 0 0;
        width: 7vw;
        height: 7vw;
        background: url('../../assets/ui/ui\ \(85\).png')no-repeat;
        background-size: 100% 100%;
    }
    .close_btn.touch{
        transform: scale(.8);
    }

    //容器
    .result_warrior_container{
        left: 5vw; top: 15vh;
        width: 95vw;height: 80vh;
        // background: rgb(203, 203, 203);
        overflow-y: scroll;
        overflow-x: hidden;
        .report_image{
            position: relative;
            width: 100%;height: 150vw;
            // background: greenyellow;
            .team_container{
                float: left;
                position: relative;
                width: 76vw;height: 58vw;
                margin: 0 0 0 7vw;
                //兵力槽
                .troops_groove_left,.troops_groove_right{
                    left: 2.5vw; top: 5vw;
                    width: 50vw;height: 2vw;
                    box-shadow: 0 0 2px darkred;
                    background: #000;
                    .blood_blue{
                        left: auto;
                        right:0;top: 0;
                        width: 50vw;
                        transition: all .2s ease-out;
                        background: url('../../assets/background/bg (32).png')center no-repeat;
                        background-size: cover;
                    }
                    .blood_red{
                        left:0;top: 0;
                        width: 50vw;
                        transition: all .2s ease-out;
                        background: url('../../assets/background/bg (31).png')center no-repeat;
                        background-size: cover;
                    }
                    .troops_number_blue,.troops_number_red{
                        left: -25vw;bottom: -1vw;
                        width: 25vw;height: 5vw;
                        line-height: 6vw;
                        // background: #000;

                    }
                    .troops_number_red{
                        
                        left: 50vw;
                    }
                }
                .troops_groove_left{
                    // border: .5vw solid rgb(2, 42, 105);
                    box-shadow: 0 0 2px blue;
                    left: 23vw;
                }
                .warrior_card{
                    width: 22vw;height: 33vw;
                    margin: 17vw 0 0 2.5vw;
                    background: url('../../assets/background/bg (23).png')no-repeat;
                    background-size: cover;
                    .name{
                        position: absolute; left: 0; top: 5%;
                        width: 20%;
                        height: 60%;
                        line-height: 3.8vw;
                        font-size: 100%;
                        transform: scale(.6);
                    }
                    .hp_num{
                        left: 4.4vw; top: .8vw;
                        width: 10vw;height: 3vw;
                        text-align: left;
                        font-size: 1.8vw;
                        line-height: 3vw;
                        color: rgb(220, 220, 220);
                        // box-shadow:  0 0 1px black;
                    }
                    .star_list{
                        left: 1.5%; top: 1%;
                        width: 97%;height:4vw;
                        background:linear-gradient(to bottom,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0));
                        li{
                            float: left;
                            position: relative;
                            width: 2vw;height: 2vw;
                            margin: .8vw 0 0 0;
                        }
                        li:nth-of-type(1){
                            margin: .8vw 0 0 10.1vw;
                        }
                    }
                    .camp{
                        left: .5vw; top: .7vw;
                        width: 3.7vw;height: 3.7vw;
                        img{
                            position: absolute; left: 0; top : 0;
                            width : 100%; height : 100%;
                        }
                    }
                    .cost_img{
                        position: absolute; left: 54%; bottom : 1.3%;
                        transform : translate(-50%,0);
                        width: 9vw;
                        height: 3.1vw;
                        line-height: 3.3vw;
                        font-size: 2.3vw;
                        text-align: left;
                        text-indent: 5vw;
                        font-weight: 500;
                        background: url('../../assets/ui/ui (61).png') left center no-repeat;
                        background-size: 55% 70%;
                        color: rgb(203, 206, 162);
                        text-shadow: 0 0 1px black;
                    }
                    .level{
                        left: 1vw; bottom: .5vw;
                        width: 12vw;height: 3vw;
                        line-height: 3vw;
                        font-size: 2.3vw;
                        text-align: left;
                        font-weight: 500;
                        color: rgb(217, 221, 135);
                        text-shadow: 0 0 1px black;
                        span{
                            color:gainsboro;font-size: 1.9vw;
                        }
                    }
                    .arm{
                        right: 1%; bottom: 1%;
                        width: 6.7vw;
                        height: 6.7vw;
                        .icon{
                            position: absolute; left: 50%; top : 50%;
                            transform : translate(-50%,-50%);
                            width: 80%;height: 80%;
                        }
                        .arm_num{
                            position: absolute; left: 0; top : 0;
                            width : 90%; height : 100%;
                            font-size: 2.3vw;
                            text-align: right;
                            line-height: 10vw;
                            font-weight: 500;
                            color: rgb(222, 222, 222);
                        }
                    }
                    .up_mark{
                        right: -2.3vw; top: -2.3vw;
                        width: 5vw;height: 5vw;
                    }
                    .warrior_sign{
                        left: 1%; bottom: 12%;
                        width: 5vw;height: 5vw;
                    }
                    .death_mask{
                        position: absolute; left: 0; top : 0;
                        width : 100%; height : 100%;
                        background: rgba(0, 0, 0, 0.7);
                    }
                    .warrior_position{
                        position: absolute; left: 50%; top: -5vw;
                        margin: 0 0 0 -8vw;
                        width: 16vw;
                        height: 4vw;
                        line-height: 4vw;
                        font-size: 2vw;
                        // background: rgb(78, 78, 78);
                        background: url('../../assets/ui/ui (133).png')no-repeat;
                        background-size: 100% 100%;
                        color: rgb(180, 180, 180);
                    }
                }
            }
            .team_container:nth-of-type(2){
                margin: 20vw 0 0 7vw;
                .warrior_card{
                    float: right;position: relative;
                    margin: 17vw 2.5vw 0 0;
                }
            }
            .result_icon{
                float: left;
                margin: 5vw 0;
                width: 76vw;height: 20vw;position: relative;
                // background: url('../../assets/background/bg (8).png')black bottom center no-repeat;
                background: rgba(0, 0, 0, 0.2);
                background-size: 100% 120%;
                // margin: 0 0 0 8vw;
                .game_time{
                    left: 0;top: 0;
                    width: 20vw;height: 6vw;
                    line-height: 6vw;
                    color: rgb(190, 190, 190);
                }
                .text_icon{
                    position: absolute; left: 50%; top : 50%;
                    transform : translate(-50%,-50%);
                    width: 16vw;height: 10vw;
                    
                }
                .bg_icon1{
                    position: absolute; left: 50%; top : 50%;
                    transform : translate(-50%,-50%);
                    width: 18vw;height: 18vw;
                    background: url('../../assets/ui/ui (132).png')no-repeat;
                    background-size: 100% 100%;
                    opacity: .2;
                }
            }
            .statistics_btn{
                right: 9vw; bottom: 4vw;
                width: 24vw;height: 6vw;
                background: url('../../assets/ui/ui (10).png')no-repeat;
                background-size: 100% 100%;
                color: black;
                line-height: 6vw;
                
            }
            .statistics_btn.click{
                transform: scale(.8);
            }
        }
        .report_text{
            position: relative;
            margin: 0;
            width: 95vw;
            .report{
                position: relative;
                display: inline-block;
                margin-left: 1vw;
                width: 94vw;
                min-height: 6.5vw;
                line-height: 6.5vw;
                .left_padding{
                    position: relative;float: left;
                    width: 4vw;height: 6.5vw;
                }
                .left_padding1{
                    position: relative;float: left;
                    width: 3.5vw;height: 3.5vw;
                    margin: 1.5vw .5vw 0 -.3vw;
                    background: rgb(194, 194, 194);
                    transform: scale(.3);
                    border-radius: 50%;
                }
                .span_box{
                    position: relative;
                    width: 100%; height: 4vw;
                }
                .warrior_head{
                    position: relative;float: left;
                    width: 6vw;height: 4.5vw;
                    margin: 1vw 0 0 0;
                    .head_img{
                        position: absolute; left: 0; top: 0;
                        width: 100%; height: 100%;
                        overflow: hidden;
                        img{
                            position: absolute; left: 0; top: -.75vw;
                            width:  6vw; height: 6vw;
                        }
                    }
                }
                .text{
                    position: relative;
                    padding: 0 1vw;
                    height: 6.5vw;float: left;
                    font-size: 2.9vw;
                    color: white;
                    // background: #000;
                }
                .number{
                    position: relative;
                    margin: 0 -1vw;
                    height: 6.5vw;float: left;
                    font-size: 2.9vw;
                    color: rgb(255, 231, 171);
                }
                .number1{
                    position: relative;
                    margin: 0 -1vw;
                    height: 6.5vw;float: left;
                    font-size: 2.9vw;
                    color: rgb(203, 255, 183);
                }
                .sys_text{
                    width: 88vw;position: relative;height: 7vw;
                    line-height: 7vw;
                    background: rgba(0, 0, 0, 0.2);
                    letter-spacing: .5vw;
                }
            }
            // background: blueviolet;
        }
    }
    .result_warrior_container.filter{
        filter: blur(5px);
    }
    .navigation_lift_btn{
        position: fixed;
        right: 5vw; bottom: 5vh;
        padding: 5vw 1vw;
        font-size: 3vw;
        width: 4vw;height: 12vw;
        line-height: 4vw;
        opacity: 0.7;
        background: url('../../assets/ui/ui (145).png')no-repeat;
        background-size: 100% 100%;
        box-shadow: 0 0 3px black;
    }
    .navigation_lift_btn.click{
        transform: scale(.8);
    }

    .navigation_panel{
        position: fixed;
        left: 0; top: 0;
        width: 100vw;height: 100vh;
        
        .navigation_list{
            position: fixed;
            right: 5vw; bottom: 5vh;
            width: 28vw;
            // height: 83vw;
            background:rgba(93, 93, 93, 0.7);
            li{
                float: left;position: relative;
                width: 27vw;height: 7vw;
                line-height: 7vw;
                background: rgb(37, 36, 36);
                color: white;
                margin: .5vw 0 0 .5vw;
                
            }
            li.click{
                background: rgb(165, 162, 162);
            }
        }
    }
    
}
</style>