import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import Report from "../js/report";

class CureStatusBlock{
    constructor(applicator,tactics,type,cureValue,cureIndex,duration,waitTime,odd){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //类型,急救或者是休整
        this.type = type;
        //数值
        this.cureValue = cureValue;
        //增长率
        this.cureIndex = cureIndex;
        //持续时间
        this.duration = duration;
        //准备时间
        this.waitTime = waitTime;
        //触发几率
        this.odd = odd;
    }
    Init(target,needReport = true,reportLevel = 3){
        //检查冲突
        for(let i in target.CURE_STATUS){
            if(target.CURE_STATUS[i].type == this.type && target.CURE_STATUS[i].cureValue >= this.cureValue){
                let r = new Report();
                let text = target.CURE_STATUS[i].cureValue > this.cureValue ? `已存在更强的${target.CURE_STATUS[i].type}效果` : `已存在相同的${target.CURE_STATUS[i].type}效果`;
                r.InitExecuteReport(3,target,text);
                return false;
            }
        }
        target.CURE_STATUS.push(this);
        //战报输出
        if(needReport){
            let r = new Report();
            r.InitExecuteReport(reportLevel,target,`${this.type}效果已施加`);
        }
        return true;
    }
    Execute(target){
        let q = new Query();
        let realOdd = q.GetMoraleOdd(target,this.odd);
        let rand = Math.floor(Math.random()*100);
        if(rand < realOdd){
            let c = new Count();
            let a = new Attack();
            let cure = c.CountCure(this.applicator,target,this.cureValue,this.cureIndex,this.tactics);
            let work = a.GetCure(this.applicator,target,cure,this.tactics,false);
            if(work){
                let r = new Report();
                let text = `由于【${this.tactics.name}】的${this.type}，恢复了${cure}兵力(${target.hp})`;
                r.InitExecuteReport(2,target,text);
            }
        }
        else{
            let r = new Report();
            r.InitExecuteReport(2,target,`【${this.tactics.name}】的${this.type}效果没有生效`);

        }
    }
}
export default CureStatusBlock;