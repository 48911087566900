import Attack from "../js/attack";
import Count from "../js/count";
import StatusBlock from "../block/status";
import Query from "../js/query";
import DamageBlock from "../block/damage";
import Report from "../js/report";
import CureStatusBlock from "../block/cureStatus";
import OddBlock from "../block/odd";
import ExecuteBlock from "../block/execute";
import ActiveTacticsBlock from "../block/execute3";
import Util from "../js/util";
let jinBArr = [
    //王元姬
    {
        id : 128,
        name : "明慧通透",
        type : "主动",
        odd : 60,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let u = new Util();
            let cureValue = 168;
            let cureIndex = 2;
            let odd = 50;//洞察几率

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,1,false,true,this);
            for(let i in res){
                u.ClearStatus(res[i],this,false,3);
                let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                a.GetCure(self,res[i],cure,this,true);
                let rand = Math.floor(Math.random()*100);
                let realOdd = q.GetMoraleOdd(self,odd);
                if(rand < realOdd){
                    let block = new StatusBlock(self,this,'洞察',1,0);
                    block.Init(res[i],true,3);
                }
            }

            this.STATISTICS.times += 1;
        }
    },
]

export default jinBArr;