import Attack from '../js/attack.js';
import Query from '../js/query.js';
import AttrBlock from '../block/attr.js'
import ExecuteBlock from '../block/execute.js';
import Count from '../js/count.js';
import Report from '../js/report.js';
import Judge from '../js/judge.js';
import DamageStatus from '../block/damageStatus.js'
import AfterDamageBlock from '../block/execute1.js';
import DamageBlock from '../block/damage.js';
import CounterAttackBlock from '../block/counterAtk.js';
import Util from '../js/util.js';
import StatusBlock from '../block/status.js';
import OddBlock from '../block/odd.js';
let qunAArr = [
    //贾诩
    {
        id : 19,
        name : "算无遗策",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = q.GetAdditionValue(self,22,'谋略',0.09);
            let damageValue = q.GetAdditionValue(self,140,'谋略',1.375);;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(2,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let action = function(){
                let target = this.beExecuted;
                //目标身上存在该战法施加的妖术块才可以执行
                let avail = false;
                for(let i in target.SPECIAL_STATUS){
                    if(target.SPECIAL_STATUS[i].tactics == that){
                        avail = true; break;
                    }
                }
                if(avail){
                    let damage = c.CountDamage(self,target,damageValue,"谋略",false,this.tactics,"妖术");
                    a.GetHurt(self,target,damage,false,"谋略",this.tactics,false);
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,target,`【算无遗策】令`,`损失了${damage}兵力(${target.hp})`);
                }
                else{
                    //妖术被刷新意味着执行块应该也要被移除
                    let temp = [];
                    for(let i in target.EXECUTE){
                        if(target.EXECUTE[i].tactics != that) temp.push(target.EXECUTE[i]);
                    }
                    target.EXECUTE = temp;
                }
            }
            for(let i in res){
                //减属性
                for(let j=0;j<2;j++){
                    let attrType = "";
                    switch(j){
                        case 0 : attrType = "攻击";break;
                        case 1 : attrType = '谋略';break;
                    }
                    let block = new AttrBlock(self,this,attrType,-value,2);
                    block.Init(res[i]);
                }
                //放入诅咒
                let block = new DamageStatus(self,this,'妖术',damageValue,"谋略",2,0,false);
                let flag = block.Init(res[i],true);
                if(flag){
                    let block = new ExecuteBlock(self,this,"试图发动主动战法",action,2);
                    block.Init(res[i]);
                }
            }


            this.STATISTICS.times += 1;
        }
    },
    //张宁
    {
        id : 27,
        name : "黄天余音",
        type : "主动",
        odd : 120,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            let value = 26 + Math.floor((q.GetAttr(self,"谋略")-80)*0.2)

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(1,self,this);
            //选择敌军
            let en = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in en){
                let value = 26 + Math.floor((q.GetAttr(self,"谋略")-80)*0.15)
                for(let j=0;j<4;j++){
                    let attrType = "";
                    switch(j){
                        case 0 : attrType = "攻击";break;
                        case 1 : attrType = "防御";break;
                        case 2 : attrType = "谋略";break;
                        case 3 : attrType = "速度";break;
                    }
                    let block = new AttrBlock(self,this,attrType,-value,1);
                    block.Init(en[i]);
                }
            }
            //给自己加属性
            for(let i=0;i<4;i++){
                let attrType = "";
                switch(i){
                    case 0 : attrType = "攻击";break;
                    case 1 : attrType = "防御";break;
                    case 2 : attrType = "谋略";break;
                    case 3 : attrType = "速度";break;
                }
                let block = new AttrBlock(self,this,attrType,value,0);
                block.Init(self);
            }
            let fri = a.SelectTarget(self,realRange,true,false,realTargetNumber,false,true);
            for(let i in fri){
                let value = 26 + Math.floor((q.GetAttr(self,"谋略")-80)*0.15)
                for(let j=0;j<4;j++){
                    let attrType = "";
                    switch(j){
                        case 0 : attrType = "攻击";break;
                        case 1 : attrType = "防御";break;
                        case 2 : attrType = "谋略";break;
                        case 3 : attrType = "速度";break;
                    }
                    let block = new AttrBlock(self,this,attrType,value,1);
                    block.Init(fri[i]);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //张角
    {
        id : 30,
        name : "黄天当立",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑'],
        wait : 1,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            let damageValue = 180 + Math.floor((q.GetAttr(self,"谋略")-80) * 0.9);
            let cureValue = 60;
            let cureIndex = 0.5;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(3,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let action = function(){
                if(this.damageFromTactics != null && this.damageFromTactics == that){
                    let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                    let work = a.GetCure(self,self,cure,that,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,self,`【黄天当立】令`,`恢复了${cure}兵力(${self.hp})`)
                    }
                }
            }
            for(let i in res){
                let block = new DamageStatus(self,this,"妖术",damageValue,"谋略",2,0,true);
                let work =  block.Init(res[i]);
                if(work){
                    let block1 = new AfterDamageBlock(self,this,action,2,false,true,false,false,true,false,false);
                    block1.Init(res[i],"妖术");
                }
            }


            this.STATISTICS.times += 1;
        }
    },
    //祝融夫人
    {
        id : 58,
        name : "火兽冲锋",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 80;
            let damageValue = 160;
            let value2 = 160;
            let odd = 30;

            let block = new DamageBlock(self,this,'普通攻击',true,value,false,false,99,0);
            let work = block.Init(self,null,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,self,`普通攻击伤害提高${value}%`)
            }
            let action = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand  < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【火兽冲锋】`)
                    let res = a.SelectTarget(self,4,false,true,1,false,true,that);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                        a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                    }
                    let block = new DamageBlock(self,that,'普通攻击',true,value2,true,false,99,0);
                    block.Init(self,null,true);
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【火兽冲锋】没有生效，生效几率为${realOdd}%`)
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action,99);
            block1.Init(self);

            this.STATISTICS.times += 1;
        }
    },
    //木鹿大王
    {
        id : 61,
        name : "夔吼象踏",
        type : "被动",
        odd : 100,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 60;//分兵伤害率
            let damageValue = q.GetAdditionValue(self,70.2,'谋略',0.69);//策略攻击伤害率

            //分兵
            let block = new CounterAttackBlock(self,this,'分兵',value,100,99,0);
            let work = block.Init(self,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,self,`分兵效果已施加`)
            }
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【夔吼象踏】`);
                let res = a.SelectTarget(self,4,false,true,1,false,true,that);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,that,null);
                    a.GetHurt(self,res[i],damage,false,'谋略',that,true,null);
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action,99);
            block1.Init(self);
            let r = new Report();
            r.InitExecuteReport(2,self,`【夔吼象踏】效果已施加`)

            this.STATISTICS.times += 1;
        }
    },
    //张郃
    {
        id : 81,
        name : "兵行巧变",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 260;
            let damageValue2 = 200;
            let value1 = q.GetAdditionValue(self,40,'攻击',0.04);//第1段属性变化
            let value2 = q.GetAdditionValue(self,80,'攻击',0.06);//第2段属性变化

            let realRange = q.GetTacticsRange(self,this);
            let rand = Math.floor(Math.random()*2);
            if(rand == 1){
                let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue1,"攻击",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                }
                //属性下降
                for(let i in res){
                    let block1 = new AttrBlock(self,this,'攻击',-value1,2);
                    block1.Init(res[i],true);
                    let block2 = new AttrBlock(self,this,'防御',-value1,2);
                    block2.Init(res[i],true);
                    let block3 = new AttrBlock(self,this,'谋略',-value1,2);
                    block3.Init(res[i],true);
                }
                //我军群体加属性
                let fri = a.SelectTarget(self,realRange,true,false,2,true,true,this);
                for(let i in fri){
                    let block1 = new AttrBlock(self,this,'攻击',value1,2);
                    block1.Init(fri[i],true);
                    let block2 = new AttrBlock(self,this,'防御',value1,2);
                    block2.Init(fri[i],true);
                    let block3 = new AttrBlock(self,this,'谋略',value1,2);
                    block3.Init(fri[i],true);
                }
            }
            else{
                let res = a.SelectTarget(self,realRange,false,true,3,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue2,"攻击",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                }
                //属性下降
                for(let i in res){
                    let block1 = new AttrBlock(self,this,'攻击',-value2,2);
                    block1.Init(res[i],true);
                    let block2 = new AttrBlock(self,this,'防御',-value2,2);
                    block2.Init(res[i],true);
                    let block3 = new AttrBlock(self,this,'谋略',-value2,2);
                    block3.Init(res[i],true);
                }
            }            
            
            this.STATISTICS.times += 1;
        }
    },
    //李儒
    {
        id : 85,
        name : "计谕废立",
        type : "主动",
        odd : 30,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            let damageValue1 = q.GetAdditionValue(self,140,'谋略',1.3);
            let damageValue2 = q.GetAdditionValue(self,156,'谋略',1.45);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(1,self,this);
            for(let j=0;j<2;j++){
                let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue1,'谋略',false,this,null);
                    a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
                    //陷入恐慌
                    if(j == 0){
                        let block = new DamageStatus(self,this,'恐慌',damageValue2,'谋略',2,0,true);
                        block.Init(res[i],true);
                    }
                    else{
                        let block = new DamageStatus(self,this,'燃烧',damageValue2,'谋略',2,0,true);
                        block.Init(res[i],true);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //张绣
    {
        id : 95,
        name : "宣威再战",
        type : "追击",
        odd : 120,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let damageValue = 150;//基础伤害率
            
            if(self.driver.turn < 4){
                let damage = c.CountDamage(self,target,damageValue,'攻击',false,this,null);
                a.GetHurt(self,target,damage,false,'攻击',this,true,null);
            }
            else{
                let time = 1 + Math.floor(Math.random()*3);
                for(let j=0;j<time;j++){
                    let res = a.SelectTarget(self,5,false,true,1,false,true,this);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,this,null);
                        a.GetHurt(self,res[i],damage,false,'攻击',this,true,null);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //庞德
    {
        id : 99,
        name : "令明负榇",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let u = new Util();
            let value = 6;
            let damageValue = q.GetAdditionValue(self,50,'速度',0.07);
            
            let arr = [];
            for(let i in self.ally){
                let arm = u.TransferArms(self.ally[i].up_arms[self.ally[i].choseArms]);
                if(arm == 'b' || arm == 'q'){
                    arr.push(self.ally[i]);
                }
            }
            let res = [];//最终结果
            for(let i=0;i<2 && arr.length > 0;i++){
                let rand = Math.floor(Math.random()*arr.length);
                res.push(arr[rand]);
                arr.splice(rand,1);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'攻击',true,value,false,false,99,0);
                let work = block.Init(res[i],null,false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`攻击伤害提高效果已施加`);
                }
                let block1 = new CounterAttackBlock(self,this,'分兵',damageValue,100,99,4);
                let work1 =  block1.Init(res[i],false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`分兵(预备)效果已施加`);
                }
            }
            let action = function(){
                //刷新攻击伤害提高
                if(self.driver.turn < 4){
                    for(let i in res){
                        for(let j in res[i].DAMAGE_VAR){
                            if(res[i].DAMAGE_VAR[j].tactics == that){
                                res[i].DAMAGE_VAR[j].value += value;
                                let r = new Report();
                                r.InitTwoTargetReport(1,self,res[i],`【令明负榇】令`,`攻击伤害提高${res[i].DAMAGE_VAR[j].value}%`)
                            }
                        }
                    }
                }
                else if(self.driver.turn == 4){
                    for(let i in res){
                        let r = new Report();
                        r.InitTwoTargetReport(1,self,res[i],`【令明负榇】令`,`获得分兵能力`)
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //高顺
    {
        id : 104,
        name : "登锋陷阵",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let odd_plus = 50;//发动几率提升
            
            let tag = '登锋陷阵' + self.belong + self.position;
            let action = function(){
                //判断是否符合触发条件
                if(self.hp < 0.9 * q.GetMaxHp(self) && self[tag] == 0
                || self.hp < 0.7 * q.GetMaxHp(self) && self[tag] == 1
                || self.hp < 0.3 * q.GetMaxHp(self) && self[tag] == 2){
                    let r = new Report();
                    let txt = "";
                    if(self[tag] == 0) txt = '兵力低于90%';
                    else if(self[tag] == 1) txt = '兵力低于70%';
                    else if(self[tag] == 2) txt = '兵力低于30%';
                    r.InitExecuteReport(2,self,txt);
                    for(let i in self.TacticsArr){
                        if(self.TacticsArr[i].type == '主动' && self.TacticsArr[i].isDamageMaker){
                            let block = new OddBlock(self,that,self.TacticsArr[i],odd_plus,0,0,0);
                            let work = block.Init(self,false);
                            if(work){
                                let r = new Report();
                                r.InitTwoTargetReport(2,self,self,`【登锋陷阵】令`,`【${self.TacticsArr[i].name}】发动几率提高${odd_plus}%`);
                            }
                        }
                    }
                    self[tag] += 1;
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action,99);
            block1.Init(self,false);
            self[tag] = 0;
            let block = new StatusBlock(self,this,'洞察',99,0);
            block.Init(self,true,2);
            let r = new Report();
            r.InitExecuteReport(2,self,`【登锋陷阵】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //华雄
    {
        id : 107,
        name : "将出关西",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 250;
            let decline = 40;//伤害率递减
            let time = 2 + Math.floor(Math.random()*3);

            let realRange = q.GetTacticsRange(self,this);
            for(let j=0;j<time;j++){
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
                let realDamage = damageValue - j*decline;
                //施加无视规避
                if(j == 0){
                    let block = new StatusBlock(self,this,"无视规避",0);
                    block.Init(self);
                }
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],realDamage,'攻击',false,this,null);
                    a.GetHurt(self,res[i],damage,false,'攻击',this,true,null);
                    if(j == 0){
                        let arr = [];
                        for(let k in self.STATUS){
                            if(self.STATUS[k].tactics != this) arr.push(self.STATUS[k]);
                            else{
                                let report = new Report();
                                report.InitExecuteReport(3,self,`来自【${self.STATUS[k].tactics.name}】的${self.STATUS[k].type}效果消失了`)
                            }
                        }
                        self.STATUS = arr;
                        //施加围困
                        let block = new StatusBlock(self,this,'围困',2,0);
                        block.Init(res[i],true,3);
                    }
                    
                }
                
            }

            this.STATISTICS.times += 1;
        }
    },
    //袁绍
    {
        id : 113,
        name : "威震河朔",
        type : "主动",
        odd : 70,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 200;//基础伤害率
            let value = q.GetAdditionValue(self,20,'攻击',0.08);
            let decline = 10;//概率下降
            
            let tag = '威震河朔' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                a.GetHurt(self,res[i],damage,false,"攻击",this);
            }
            //单体技能伤害提高
            let fri = a.SelectTarget(self,realRange,true,false,1,false,true,this);
            fri.push(self);
            for(let i in fri){
                for(let j in fri[i].TacticsArr){
                    if(fri[i].TacticsArr[j].type == '主动' && fri[i].TacticsArr[j].isDamageMaker && fri[i].TacticsArr[j].targetNumber == 1){
                        let block = new DamageBlock(self,this,'主动',true,value,false,false,2,0);
                        block.Init(fri[i],fri[i].TacticsArr[j],true,3);
                    }
                }
            }
            //概率下降
            let work = true;
            for(let i in self.ODD_VAR){
                if(self.ODD_VAR[i].tactics == this && self[tag] < 7){
                    self.ODD_VAR[i].value -= decline;
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【威震河朔】发动几率降低${Math.abs(self.ODD_VAR[i].value)}%`);
                    work = false;
                    self[tag] += 1;
                }
            }
            if(work){
                let block = new OddBlock(self,this,this,-decline,99,0,0);
                block.Init(self,true,3);
                self[tag] = 1;
            }
            this.STATISTICS.times += 1;
        }
    },
    //sp甄洛
    {
        id : 5026,
        name : "幽兰洛神",
        type : "主动",
        odd : 120,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue1 = 105;//基础恢复率
            let cureIndex1 = 1.125;//恢复率增长率
            let value = q.GetAdditionValue(self,33,'谋略',0.15);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,false,true,this);
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue1,cureIndex1,this);
                a.GetCure(self,res[i],cure,this,true);
                let block = new DamageBlock(self,this,'普通攻击',false,-value,false,false,1,0);
                block.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
]

export default qunAArr;