<template>
    <div class="report_view">
        <div class="container">
            <div class="report" v-for="r,index in game.ReportContainer" ref="report" @touchstart="$refs.report[index].classList.add('click')"
            @touchend="$refs.report[index].classList.remove('click')" :key="r.index" @click="ChoseThis(r)">
                <div class="top">
                    <div class="time">{{ r.time }}</div>
                </div>
                <div class="warrior_img" v-for="w in r.battleResult" :key="w.index">
                    <div class="head_img" v-if="w[0].name != ''">
                        <img :src="w[0].img.banner" alt="" class="img1">
                    </div>

                    <ul class="star_list" v-if="w[0].name != ''">
                        <li v-for="s in w[0].red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                        <li v-for="s in (5-w[0].red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                    </ul>
                    <div class="black_tape black_tape_wu" v-show="w[0].id && w[0].camp == '吴'">{{w[0].name}}</div>
                    <div class="black_tape black_tape_wei" v-show="w[0].id && w[0].camp == '魏'">{{w[0].name}}</div>
                    <div class="black_tape black_tape_shu" v-show="w[0].id && w[0].camp == '蜀'">{{w[0].name}}</div>
                    <div class="black_tape black_tape_qun" v-show="w[0].id && w[0].camp == '群'">{{w[0].name}}</div>
                    <div class="black_tape black_tape_han" v-show="w[0].id && w[0].camp == '汉'">{{w[0].name}}</div>
                    <div class="black_tape black_tape_jin" v-show="w[0].id && w[0].camp == '晋'">{{w[0].name}}</div>
                    <div class="black_tape_bottom"></div>
                    <!-- 觉醒 -->
                    <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-if="w[0].red != 5">
                    <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-if="w[0].red == 5">
                    <!-- 兵种 -->
                    <div class="cost_img">{{w[0].cost}}</div>
                    <div class="level">Lv.<span>{{w[0].level}}</span></div>
                    <div class="arm">
                        <img src="../../assets/ui/ui (82).png" alt="" class="cover">
                        <img :src="require(`../../assets/arms/${w[0].up_arms[w[0].choseArms]}.png`)" class="icon" alt="">
                        <div class="arm_num">{{w[0].ackRange}}</div>
                    </div>
                </div>
                <div class="result_icon">
                    <div class="bg_icon1"></div>
                    <div class="text_icon">
                        <img src="../../assets/ui/ui (119).png" alt="" class="cover" v-if="r.battleResult[0][0].hp != 0 && r.battleResult[1][0].hp == 0">
                        <img src="../../assets/ui/ui (120).png" alt="" class="cover" v-if="r.battleResult[0][0].hp != 0 && r.battleResult[1][0].hp != 0">
                        <img src="../../assets/ui/ui (131).png" alt="" class="cover" v-if="r.battleResult[0][0].hp == 0 && r.battleResult[1][0].hp != 0">
                    </div>
                </div>
            </div>
        </div>
        <div class="clear_btn" ref="clear_btn" @touchstart="$refs.clear_btn.classList.add('click')"
        @touchend="$refs.clear_btn.classList.remove('click')"
        @click="ClearReport()">清空战报</div>
    </div>
</template>

<script>
import Statistics from './js/statistics'
export default {
    name : 'Report',
    data(){
        return{
            game : this.$store.state.BorderChess,
        }
    },
    methods:{
        ChoseThis(data){
            this.game.battleResult = data.battleResult;
            this.game.ReportHeap = data.ReportHeap;
            this.game.Statistics = data.Statistics;
            
            this.$router.push({
                path : '/BattleResult'
            })
        },
        //清空战报
        ClearReport(){
            let that = this;
            if(that.game.ReportContainer.length == 0) return;
            let f = function(){
                that.game.ReportContainer = [];
                that.$bus.$emit('alert','清除战报成功');
            }
            let params = {
                text : `确定清除所有${that.game.ReportContainer.length}封战报？`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
        }
    },
    mounted(){
    }
}
</script>

<style lang="less">
.report_view{
    position: absolute;left: 0; top: 0;
    width: 100vw;
    height: 100vh;
    background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
    background-size: cover;
    .container{
        position: absolute; left: 5vw; top: 15vh;
        width: 90vw; height: 75vh;
        overflow-y: scroll;
        overflow-x: hidden;
        .report{
            position: relative; 
            width: 90vw; height: 24vw;
            background: url('../../assets/ui/ui (172).png')rgba(45, 45, 45, 0.76) no-repeat;
            background-size: 100% 100%;
            margin: 2vw 0 1vw 0;
            .top{
                position: absolute; left: 0; top: 0;
                width: 100%; height: 4.5vw;
                background: #1a1a1ab3;
                .time{
                    left: auto; right: 0; top: 0;
                    width: 32vw; height: 4.5vw;
                    line-height: 4.5vw;
                    font-size: 2.5vw;
                    color: gray;
                }
            }
            .warrior_img{
                position: absolute;
                // float: left;
                left: 56vw;top: 7vw;
                // margin:14vw 0 0 7vw;
                width: 24vw;
                height: 14vw;
                box-sizing: border-box;
                // border: .3vw solid rgb(69, 68, 68);
                border: .3vw solid rgb(124, 0, 91);
                background: url('../../assets/background/bg\ \(21\).png')no-repeat;
                background-size: 100% 100%;
                box-shadow:  0 0 2px black;
                // overflow: hidden;
                .head_img{
                    // position: absolute; left: 0; top: 0;
                    position: relative;
                    width: 100%; height: 100%;
                    overflow: hidden;
                    .img1{
                        position: absolute;
                        height: 100%;
                        left: 50%; top: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
                
                .black_tape{
                    position: absolute; left: 0; top: 0;
                    width: 3vw;
                    height: 80%;
                    font-size: 2vw;
                    padding: 1vw 0 0 0;
                    line-height: 2.4vw;
                    text-align: center;
                    color: rgb(215, 203, 175);
                    background:linear-gradient(to bottom,rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0));
                }
                .black_tape_wu{
                    background:linear-gradient(to bottom,rgba(92, 0, 0, 0.9),rgba(92, 0, 0, 0.9),rgba(92, 0, 0, 0.1));
                }
                .black_tape_wei{
                    background:linear-gradient(to bottom,rgba(0, 41, 92, 0.9),rgba(0, 41, 92, 0.9),rgba(0, 41, 92, 0.1));
                }
                .black_tape_shu{
                    background:linear-gradient(to bottom,rgba(0, 90, 13, 0.9),rgba(0, 90, 13, 0.9),rgba(0, 90, 13, 0.1));
                }
                .black_tape_qun{
                    background:linear-gradient(to bottom,rgba(42, 42, 42, 0.9),rgba(40, 40, 40, 0.9),rgba(73, 73, 73, 0.1));
                }
                .black_tape_han{
                    background:linear-gradient(to bottom,rgba(92, 0, 74, 0.9),rgba(92, 0, 74, 0.9),rgba(92, 0, 74, 0));
                }
                .black_tape_jin{
                    background:linear-gradient(to bottom,rgba(0, 93, 88, 0.9),rgba(0, 93, 88, 0.9),rgba(1, 112, 107, 0.1));
                }
                .black_tape_bottom{
                    position: absolute; left: 0; bottom: 0;
                    width: 100%;
                    height: 3.5vw;
                    background:linear-gradient(to top,rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0));
                }
                .star_list{
                    right: .1vw; top: 0vw;
                    width: 99%;height:4vw;
                    background:linear-gradient(to bottom,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0));
                    li{
                        float: left;
                        position: relative;
                        width: 2vw;height: 2vw;
                        margin: .7vw 0 0 0;
                    }
                    li:nth-of-type(1){
                        margin: .7vw 0 0 12vw;
                    }
                }
                .up_mark{
                    position: absolute; right: -2vw; top: -2vw;
                    width: 4.3vw;
                    height: 4.3vw;
                    transition: none;
                }
                .cost_img{
                    position: absolute; left: 54%; bottom : 1.3%;
                    transform : translate(-50%,0);
                    width: 9vw;
                    height: 3.1vw;
                    line-height: 3.5vw;
                    font-size: 2.1vw;
                    text-align: left;
                    text-indent: 5vw;
                    font-weight: 500;
                    background: url('../../assets/ui/ui (61).png') left  no-repeat;
                    background-size: 60% 80%;
                    color: rgb(203, 206, 162);
                    text-shadow: 0 0 1px black;
                }
                .level{
                    left: 1vw; bottom: .5vw;
                    width: 12vw;height: 3vw;
                    line-height: 3vw;
                    font-size: 2.3vw;
                    text-align: left;
                    font-weight: 500;
                    color: rgb(217, 221, 135);
                    text-shadow: 0 0 1px black;
                    span{
                        color:gainsboro;font-size: 1.9vw;
                    }
                }
                .arm{
                    right: 1%; bottom: 1%;
                    width: 5vw;
                    height: 5vw;
                    .icon{
                        position: absolute; left: 50%; top : 50%;
                        transform : translate(-50%,-50%);
                        width: 80%;height: 80%;
                    }
                    .arm_num{
                        position: absolute; left: 0; top : 0;
                        width : 100%; height : 100%;
                        font-size: 2.1vw;
                        text-align: right;
                        line-height: 7.5vw;
                        font-weight: 500;
                        color: rgb(222, 222, 222);
                    }
                }
                .warrior_sign{
                    left: 1%; bottom: 12%;
                    width: 5vw;height: 5vw;
                }
            }
            .warrior_img:nth-of-type(2){
                left: 10vw;
            }
            .result_icon{
                position: absolute; left: 50%; top : 7vw;
                width: 14vw;height: 14vw;
                // background: url('../../assets/background/bg (8).png')black bottom center no-repeat;
                background-size: 100% 120%;
                transform : translate(-50%,0);
                .game_time{
                    left: 0;top: 0;
                    width: 20vw;height: 6vw;
                    line-height: 6vw;
                    color: rgb(190, 190, 190);
                }
                .text_icon{
                    position: absolute; left: 50%; top : 50%;
                    transform : translate(-50%,-50%);
                    width: 10vw;height: 6vw;
                    
                }
                .bg_icon1{
                    position: absolute; left: 50%; top : 50%;
                    transform : translate(-50%,-50%);
                    width: 13vw;height: 13vw;
                    background: url('../../assets/ui/ui (132).png')no-repeat;
                    background-size: 100% 100%;
                    opacity: .2;
                }
            }
            
        }
        .report.click{
            background: rgba(255, 255, 255, 0.388);
        }
    }
    .clear_btn{
        left: 50%; top: 90vh;
        margin: 5vw 0 0 -10vw;
        width: 20vw; height: 6vw;
        background: rgb(242, 242, 242);
        line-height: 6vw;
        color: black;
        font-weight: bold;
    }
    .clear_btn.click{
        transform: scale(.8);
    }
}
</style>