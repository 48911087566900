<template>
    <div class="tactics_container_battle">
        <div class="texture_branch">战法选择</div>
        <div class="close_btn" ref="close_btn1" @touchstart="$refs.close_btn1.classList.add('touch')"
        @touchend="$refs.close_btn1.classList.remove('touch')"
        @click="Close"></div>
        <div class="tactics_container">
            <div class="part" v-for="(p,item,id) in $store.state.Tactics" :key="p.index">
                <div class="border"></div>
                <div class="topic">
                    {{ topic[id] }}
                </div>
                <div class="tactics_box" v-for="t in p" :key="t.index" v-show="ShowTactics(t)" @click="LoadThisTactics(t)">
                    <img :src="TactImgUrl(t)" alt="" class="skill_image">
                    <img src="../../assets/ui/ui (100).png" class="skill_frame" v-if="t.quality == 'A' && t.sign != '典藏'">
                    <img src="../../assets/ui/ui (101).png" class="skill_frame" v-if="t.quality == 'S' && t.sign != '典藏'">
                    <img src="../../assets/ui/ui (102).png" class="skill_frame" v-if="t.quality == 'B' && t.sign != '典藏'">
                    <img src="../../assets/ui/ui (99).png" class="skill_frame" v-if="t.quality == 'A' && t.sign == '典藏'">
                    <img src="../../assets/ui/ui (98).png" class="skill_frame" v-if="t.quality == 'S' && t.sign == '典藏'">
                    <img src="../../assets/ui/ui (163).png" class="skill_frame" v-if="t.quality == 'B' && t.sign == '典藏'">
                    <div class="name">
                        <img src="../../assets/ui/ui (114).png" class="cover" v-if="t.quality == 'A'" alt="">
                        <img src="../../assets/ui/ui (113).png" class="cover" v-if="t.quality == 'S'" alt="">
                        <img src="../../assets/ui/ui (115).png" class="cover" v-if="t.quality == 'B'" alt="">
                        <div class="text">{{ t.name }}</div>
                    </div>
                    <img src="../../assets/ui/ui (95).png" class="quality_sign" v-if="t.quality == 'S'" alt="">
                    <img src="../../assets/ui/ui (96).png" class="quality_sign" v-if="t.quality == 'A'" alt="">
                    <img src="../../assets/ui/ui (97).png" class="quality_sign" v-if="t.quality == 'B'" alt="">

                    <!-- <transition name="skill_mask_transition">
                    <div class="skill_mask" v-show="t.lock"></div>
                    </transition> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'TacticsContainer',
    data(){
        return{
            game : this.$store.state.BorderChess,
            topic : ['指挥战法',"主动战法","追击战法","被动战法"],
            isUnlock : false,
            currentTact : null,
            showInfo : false,
        }
    },
    methods:{
        //战法是否显示
        ShowTactics(data){
            for(let i in this.game.currentTeam.warriorArr){
                for(let j in this.game.currentTeam.warriorArr[i].tacticsArr){
                    let tgt = this.game.currentTeam.warriorArr[i].tacticsArr[j];
                    if(tgt.name == data.name) return false;
                }
            }
            return true;
        },
        //战法的图像
        TactImgUrl(t){
            switch(t.type){
                case "command" : return require('../../assets/ui/ui (92).png');
                case "active" : return require('../../assets/ui/ui (93).png');
                case "combo" : return require('../../assets/ui/ui (94).png');
                case "passive" : return require('../../assets/ui/ui (91).png');
            }
        },
        LoadThisTactics(tact){
            this.game.currentTacticsItem.LoadData(tact);
            this.Close();
        },
        //关闭改页面
        Close(){
            this.$bus.$emit('ShowTacticsContainer',false);
        }
    }
}
</script>

<style lang="less">
.tactics_container_battle{
    position: absolute;left: 0; top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: url('../../assets/background/bg (1).jpg')right bottom no-repeat;
    background-size: cover;
    .texture_branch{
        left: 0; top: 15vw;
        width: 50vw;height: 10vw;
        line-height: 10vw;
        background: url('../../assets/background/bg (5).png')no-repeat;
        background-size: 100% 100%;
        font-size: 5vw;
        color: rgb(238, 238, 151);
        text-shadow: 0 0 0 6px black;
        text-indent: -20vw;
        font-family: 'FZLBJW';
    }
    .close_btn{
        right: 5vw; top: 15vw;
        width: 8vw;height: 8vw;
        background: url('../../assets/ui/ui (85).png')no-repeat;
        background-size: 100% 100%;
    }
    .close_btn.touch{
        transform: scale(.8);
    }
    //全部战法容器
    .tactics_container{
        position: absolute; left: 50%; top : 50%;
        margin: -35vh 0 0 -45vw;
        width: 90vw;
        height: 70vh;
        overflow-y: scroll;
        overflow-x: hidden;
        animation: skill_emerge .2s ease-out 1;
        @keyframes skill_emerge{
            0%{
                opacity: 0;
                transform: translateY(5vw);
            }
            100%{
                opacity: 1;
                transform: translateY(0);
            }
        }
        .part{
            position: relative;
            width: 100%; 
            margin: 0vw 0 0 0;
            background: #000;
            .topic{
                position: relative;float: left;
                width: 100%; height: 7vw;
                text-align: left;
                text-indent: 5vw;
                line-height: 7vw;
                font-family: "微软雅黑";
                background: #00000038;
                margin: 5vw 0 5vw 0;
            }
            .border{
                position: absolute; left: 0; top: 0;
                width: 1vw;height: 100%;
            }
            .border:nth-of-type(1){
                background: green;
            }
            .border:nth-of-type(2){
                background: rgb(100, 0, 0);
            }
            .border:nth-of-type(3){
                background: rgb(55, 1, 54);
            }
            .border:nth-of-type(4){
                background: rgb(2, 5, 94);
            }
            .tactics_box{
                position: relative;
                width: 15vw;
                height: 15vw;
                float: left;
                margin: 0 0 2.5vw 2.5vw;
                // background: #000;
                .skill_frame{
                    position: absolute; top: -.2vw; left: 1.3vw;
                    width: 12.6vw;
                    height: 12.6vw;
                }
                .quality_sign{
                    left: .9vw; top: .5vw;
                    width: 3.7vw; height: 3.7vw;
                }
                .name{
                    position: absolute; bottom: -.3vw; left: .5vw; top: auto;
                    width: 14vw;
                    height: 3.3vw;
                    font-size: 2.8vw;
                    color: rgb(255, 255, 255);
                    text-align: center;
                    line-height: 4.1vw;
                    font-family: "FZLBJW";
                    .text{
                        position: absolute; top: 0; left: 0;
                        width: 100%; height: 100%;
                        line-height: 3.5vw;
                    }
                }
            }
        }
        //战法图像
        .skill_image,.skill_mask{
            position: absolute; top: 0; left: 1.5vw;
            width: 12vw;
            height: 12vw;
        }
        
        //遮罩层
        .skill_mask{
            text-align: right;
            line-height: 12vw;
            font-size: 2vw;
            color: rgb(122, 122, 122);
            border-radius: 6vw;
            background: rgba(0, 0, 0, 0.9);
            box-shadow: 0 0 2vw rgb(64, 64, 64);
            transition: all .2s;
        }
        .skill_mask_transition-enter{
            opacity: .9;
        }
        .skill_mask_transition-enter-to{
            opacity: 1;
        }
        .skill_mask_transition-leave{
            transform: scale(1);
            opacity: 1;
        }
        .skill_mask_transition-leave-to{
            transform: scale(1.4);
            opacity: 0;
        }
        //标题
        .title{
            position: relative;
            width: 100%;
            height: 7vw;
            background: rgba(0, 0, 0, 0.2);
            float: left;
            // text-indent: 3vw;
            margin: 5vw 0 0 0;
            .text{
                width: 20vw;
            }
        }
        
        

    }
}
</style>