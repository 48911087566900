import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import StatusBlock from "../block/status";
import OddBlock from "../block/odd";
import Report from "../js/report";
import DamageBlock from "../block/damage";
import DamageStatus from "../block/damageStatus";
import ExecuteBlock from "../block/execute";
import AfterDamageBlock from "../block/execute1";
import AttrBlock from '../block/attr.js'
import RangeBlock from "../block/range.js";
import Util from "../js/util.js";
import DodgeBlock from "../block/dodge.js";
let wuAArr = [
    //周瑜
    {
        id : 7,
        name : "玄武洰流",
        type : "主动",
        odd : 30,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let atk = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 150 + Math.floor((q.GetAttr(self,"谋略"))* 1.5);//基础伤害率
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = atk.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"谋略",false,this);
                atk.GetHurt(self,res[i],damage,false,"谋略",this);
            }
            for(let i in res){
                let sta = new StatusBlock(self,this,"怯战",2);
                sta.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //太史慈
    {
        id : 37,
        name : "方阵突击",
        type : "追击",
        odd : 30,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let damageValue = 200;//基础伤害率
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);
            
            //混乱
            let block = new StatusBlock(self,this,"混乱",1,0);
            block.Init(target,true);
            this.STATISTICS.times += 1;
        }
    },
    //甘宁
    {
        id : 42,
        name : "侵掠如火",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let value = 20;//
            let value2 = 30;
            //加发动几率
            for(let i in self.TacticsArr){
                if(self.TacticsArr[i] != this && self.TacticsArr[i].isDamageMaker){
                    let block = new OddBlock(self,this,self.TacticsArr[i],value,99,0,0);
                    let work = block.Init(self,false);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(2,self,`【${self.TacticsArr[i].name}】的发动几率增加${value}%`)
                    }
                    //加伤害
                    
                    let block1 = new DamageBlock(self,this,"攻击",true,value2,false,false,3);
                    let work1 = block1.Init(self,self.TacticsArr[i],false);
                    if(work1){
                        let r = new Report();
                        r.InitExecuteReport(2,self,`【${self.TacticsArr[i].name}】造成攻击伤害提高30%`)
                    }
                }
            }

            this.STATISTICS.times += 1;

        }
    },
    //诸葛恪
    {
        id : 55,
        name : "计定山越",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let q = new Query();
            let c = new Count();
            let a = new Attack();
            let value = 134 + Math.floor((q.GetAttr(self,"谋略")-80)*1.75);
            let cureValue = 98;
            let cureIndex = 0.6;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            let cureRes = a.SelectTarget(self,realRange,true,false,1,false,true,this);
            cureRes.push(self);
            //恐慌
            for(let i in res){
                let block = new DamageStatus(self,this,'恐慌',value,"谋略",2,0,true);
                block.Init(res[i],true);
                if(res[i].morale <= 100){
                    let block = new StatusBlock(self,this,'围困',2,0);
                    block.Init(res[i],true);
                }
            }
            //治疗
            for(let i in cureRes){
                let cure = c.CountCure(self,cureRes[i],cureValue,cureIndex,this);
                a.GetCure(self,cureRes[i],cure,this,true);
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //孙策
    {
        id : 62,
        name : "霸王渡江",
        type : "被动",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 40;//初始发动几率
            let odd_addition = 3;//发动几率提升
            let damageValue = 140;//伤害率

            let action = function(){
                let realOdd = q.GetMoraleOdd(self,(odd + odd_addition * self['霸王渡江']));
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【霸王渡江】`);
                    for(let j=0;j<3;j++){
                        let res = a.SelectTarget(self,5,false,true,1,false,true,that);
                        for(let i in res){
                            let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                            a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                        }
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【霸王渡江】没有生效，生效几率为${realOdd}%`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,true);
            //标记叠加层数
            self['霸王渡江'] = 0;
            let action1 = function(){
                //如果伤害来源是孙策
                if(this.damageFromWho == self && self['霸王渡江'] < 5){
                    self['霸王渡江'] += 1;
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【霸王渡江】生效几率提升${odd_addition}%`);
                }
            }
            for(let i in self.enemy){
                let target = self.enemy[i];
                let block = new AfterDamageBlock(self,this,action1,99,true,true,true,true,true,true,true);
                block.Init(target,'霸王渡江');
            }
            //无法施放主动战法
            let block1 = new StatusBlock(self,this,"无法施法",99);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`无法施放主动战法效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //周泰
    {
        id : 69,
        name : "疮痍累身",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let value = 84;//初始减伤幅度
            let value_decline = 7;
            let odd = 50;//触发几率
            let add_attr = 20;//属性提升

            //减伤效果
            let block1 = new DamageBlock(self,this,'攻击',false,-value,false,false,99,0);
            let work1 = block1.Init(self,null,false);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,self,`受到攻击伤害降低${value}%`);
            }
            let block2 = new DamageBlock(self,this,'谋略',false,-value,false,false,99,0);
            let work2 = block2.Init(self,null,false);
            if(work2){
                let r = new Report();
                r.InitExecuteReport(2,self,`受到策略伤害降低${value}%`);
            }
            //被打加属性和减效果
            let action = function(){
                if(this.damageType == '攻击' && block1.value < 0){
                    block1.value += value_decline;
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【疮痍累身】令`,`受到攻击伤害降低${Math.abs(block1.value)}%`)
                }
                else if(this.damageType == '谋略' && block2.value < 0){
                    block2.value += value_decline;
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【疮痍累身】令`,`受到策略伤害降低${Math.abs(block2.value)}%`)
                }
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    for(let i in self.ATTR){
                        if(self.ATTR[i].tactics == that){
                            self.ATTR[i].value += add_attr;
                            let r = new Report();
                            r.InitExecuteReport(3,self,`${self.ATTR[i].attrType}属性提高${self.ATTR[i].value}(${q.GetAttr(self,self.ATTR[i].attrType)})`);
                        }
                    }
                }
            }
            let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
            block.Init(self,'疮痍累身')
            for(let i=0;i<3;i++){
                let attrType = "";
                switch(i){
                    case 0 : attrType = '攻击';break;
                    case 1 : attrType = '防御';break;
                    case 2 : attrType = '谋略';break;
                }
                let block = new AttrBlock(self,this,attrType,0,99);
                block.Init(self,false);
                let r = new Report();
                r.InitExecuteReport(2,self,`${attrType}属性提升效果已施加`);
            }
            //援护效果
            if(self.position != "0"){
                for(let i in self.ally){
                    if(self.ally[i] != self){
                        let block = new StatusBlock(self,this,'援护',2,0);
                        block.Init(self.ally[i],false);
                    }
                }
                let r = new Report();
                r.InitExecuteReport(2,self,`援护效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //小乔＆大乔
    {
        id : 71,
        name : "双艳",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let odd = 100;//触发几率

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(2,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let realOdd = q.GetMoraleOdd(self,odd);
            let rand = Math.floor(Math.random()*100);
            for(let i in res){
                let block = new StatusBlock(self,this,'暴走',2,0);
                block.Init(res[i],true);
                if(rand < realOdd){
                    let block = new RangeBlock(self,this,true,-1,2,0);
                    block.Init(res[i],true);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //凌统
    {
        id : 90,
        name : "国士无双",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = q.GetAdditionValue(self,25,'攻击',0.12);
            let odd = 90;
            if(self.driver.version == '平衡'){
                value = q.GetAdditionValue(self,40,'攻击',0.12);
            }

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,false,this);
            let action = function(){
                let target = this.beExecuted;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new StatusBlock(self,that,'洞察',0,0);
                    let work =  block.Init(target,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,target,`【国士无双】令`,`进入洞察`);
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,target,`【国士无双】没有生效，生效几率为${realOdd}%`);
                }
            }
            let block = new DamageBlock(self,this,'攻击',true,value,false,false,3,0);
            block.Init(self,null,true,2);
            for(let i in res){
                let block = new ExecuteBlock(self,this,'行动开始',action,3);
                block.Init(res[i],true);
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【国士无双】效果已施加`)
            }

            this.STATISTICS.times += 1;
        }
    },
    //孙鲁班
    {
        id : 110,
        name : "全主诿异",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let value = q.GetAdditionValue(self,20,'谋略',0.2);//伤害增加率
            let damageValue = q.GetAdditionValue(self,197,'谋略',1.75);//直接伤害
            let targetNumber = 1 + Math.floor(Math.random()*2);//目标数
            if(self.driver.version == '平衡'){
                value = q.GetAdditionValue(self,25,'谋略',0.2);//伤害增加率
            }

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            //提高敌方异常状态的伤害
            for(let i in self.enemy){
                let target = self.enemy[i];
                for(let j in target.SPECIAL_STATUS){
                    if(target.SPECIAL_STATUS[j].tactics.type == '主动' && target.SPECIAL_STATUS[j].type == '妖术' || target.SPECIAL_STATUS[j].type == '燃烧' || target.SPECIAL_STATUS[j].type == '恐慌'){
                        target.SPECIAL_STATUS[j].damageValue = Math.floor(target.SPECIAL_STATUS[j].damageValue * (value/100 + 1));
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【全主诿异】令`,`${target.SPECIAL_STATUS[j].type}伤害提高${value}%`)
                    }
                }
            }
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,this,null);
                a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //程普
    {
        id : 111,
        name : "鏖兵卫主",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = q.GetAdditionValue(self,25,'防御',0.15);//防御属性提高
            let odd = 45;

            let tag = '鏖兵卫主' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,false,this);
            let action = function(){
                let target = this.beExecuted;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let block = new AttrBlock(self,that,'防御',value,0);
                    let work = block.Init(target,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,target,`【鏖兵卫主】令`,`防御属性提高${value}(${q.GetAttr(target,'防御')})`);
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,target,`【鏖兵卫主】没有生效，生效几率为${realOdd}%`);
                }
            }
            //行动减伤
            for(let i in res){
                let block = new ExecuteBlock(self,this,'行动开始',action,99);
                block.Init(res[i],true);
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【鏖兵卫主】效果已施加`)
            }
            //援护
            if(self.position != '0'){
                let target = self.ally[0];
                let move = function(){
                    if(target.hp < 0.9 * q.GetMaxHp(target) && self[tag] == 0
                    || target.hp < 0.7 * q.GetMaxHp(target) && self[tag] == 1
                    || target.hp < 0.3 * q.GetMaxHp(target) && self[tag] == 2){
                        let r = new Report();
                        let txt = "";
                        if(self[tag] == 0) txt = '兵力低于90%';
                        else if(self[tag] == 1) txt = '兵力低于70%';
                        else if(self[tag] == 2) txt = '兵力低于30%';
                        r.InitTwoTargetReport(2,target,self,txt,`执行【鏖兵卫主】援护`);
                        for(let i in self.ally){
                            if(self.ally[i].alive && self.ally[i] != self){
                                let block = new StatusBlock(self,that,'援护',1,0);
                                block.Init(self.ally[i],true,3);
                            }
                        }
                        //受到伤害大幅降低
                        for(let i=0;i<2;i++){
                            let block = new DamageBlock(self,that,'所有',false,-999,true,false,99,0);
                            block.Init(self,null,false);
                        }
                        let r1 = new Report();
                        r1.InitExecuteReport(3,self,`下两次受到伤害大幅降低`);
                        self[tag] += 1;
                    }
                }
                let block = new ExecuteBlock(self,this,'行动开始',move,99);
                block.Init(self,false);
                self[tag] = 0;
                let r = new Report();
                r.InitExecuteReport(2,self,`援护效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //蒋钦
    {
        id : 114,
        name : "怒浪伐敌",
        type : "追击",
        odd : 40,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let damageValue = 220;//基础伤害率
            let odd = 10;//发动几率提高

            let tag = '怒浪伐敌' + self.belong + self.position;
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);
            
            let work = true;
            for(let i in self.ODD_VAR){
                if(self.ODD_VAR[i].tactics == this && self[tag] < 3){
                    self.ODD_VAR[i].value += odd;
                    work = false;
                    self[tag] += 1;
                    let r = new Report();
                    r.InitExecuteReport(3,self,`【怒浪伐敌】发动几率提高${self.ODD_VAR[i].value}%`)
                }
            }
            if(work){
                let block = new OddBlock(self,this,this,odd,99,0,0);
                block.Init(self,true,3);
                self[tag] = 1;
            }
            this.STATISTICS.times += 1;
        }
    },
    //丁奉
    {
        id : 140,
        name : "雪奋短兵",
        type : "被动",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let u = new Util();
            if(self.driver.version == '平衡'){
                let damageValue = 300;//基础伤害率
                let attrValue = 40;//属性增加
                let targetNumber = 1;//目标个数
                let odd = 40;//发动几率提高

                let action = function(){
                    let range = q.GetRealAdRange(self);
                    if(range <= 1){
                        let r = new Report();
                        r.InitExecuteReport(2,self,`执行【雪奋短兵】`);
                        let block1 = new StatusBlock(self,that,'无视规避',0,0);
                        let work1 = block1.Init(self,false);
                        if(work1){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,self,`【雪奋短兵】令`,`无视规避状态`);
                        }
                        let res = a.SelectTarget(self,range,false,true,targetNumber,false,true,that);
                        for(let i in res){
                            let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                            a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                        }
                        //消除无视规避
                        u.clearBlock(self,self.STATUS,block1);
                    }
                    else{
                        let rand = Math.floor(Math.random()*100);
                        if(rand < odd){
                            let block = new RangeBlock(self,that,true,-1,99,0);
                            block.Init(self,false,2,true);
                            let newRange = q.GetRealAdRange(self);
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,self,`【雪奋短兵】令`,`攻击距离降低1(${newRange})`);
                            let block1 = new AttrBlock(self,that,'攻击',attrValue,99,true,true);
                            block1.Init(self,true,2);
                        }
                        else{
                            let r = new Report();
                            r.InitExecuteReport(2,self,`【雪奋短兵】攻击距离降低没有生效`);
                        }
                    }
                }
                let block = new ExecuteBlock(self,this,'行动开始',action,99);
                block.Init(self,true);
                let r = new Report();
                r.InitExecuteReport(2,self,`【雪奋短兵】效果已施加`);
            }
            else{
                let dodgeOdd = 40;//规避几率
                let statusOdd = 70;//动摇几率
                let targetNumber = 2;//目标个数
                let damageValue = 60;//基础伤害率
                let damageValue2 = 120;//动摇伤害率
                let getHurt = function(){
                    let range = q.GetRealAdRange(self);
                    if(range > 1){
                        let realOdd1 = q.GetMoraleOdd(self,dodgeOdd);
                        let rand1 = Math.floor(Math.random()*100);
                        if(rand1 < realOdd1){
                            let block = new DodgeBlock(self,that,99);
                            block.Init(self,false);
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,self,`【雪奋短兵】令`,`规避下一次伤害`);
                        }
                    }
                }
                let action1 = function(){
                    let range = q.GetRealAdRange(self);
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【雪奋短兵】`);
                    let res = a.SelectTarget(self,range,false,true,targetNumber,false,true,that);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                        a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                    }
                    //正确的动摇几率
                    let realOdd = q.GetMoraleOdd(self,statusOdd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let temp = a.SelectTarget(self,range,false,true,targetNumber,false,true,that);
                        let res = [];
                        for(let i in self.enemy){
                            let lock = false;
                            for(let j=0;j<temp.length;j++){
                                if(temp[j] == self.enemy[i]) lock == true;
                            }
                            if(!lock) res.push(self.enemy[i]);
                        }
                        for(let i in res){
                            //施加动摇
                            let block = new DamageStatus(self,that,"动摇",damageValue2,"攻击",1,0,true);
                            block.Init(res[i],true);
                        }
                    }
                }
                let action2 = function(){
                    let range = q.GetRealAdRange(self);
                    if(range > 1){
                        let block = new RangeBlock(self,that,true,-1,99,0);
                        block.Init(self,false,2,true);
                        let newRange = q.GetRealAdRange(self);
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,self,`【雪奋短兵】令`,`攻击距离降低1(${newRange})`);
                    }   
                }
                let block = new ExecuteBlock(self,this,'行动结束',action2,99);
                block.Init(self,true);
                let block1 = new ExecuteBlock(self,this,'行动开始',action1,99);
                block1.Init(self,true);
                let block2 = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block2.Init(self,'雪奋短兵')
                let r = new Report();
                r.InitExecuteReport(2,self,`【雪奋短兵】规避效果已施加`);
            }
            
            
            this.STATISTICS.times += 1;
        }
    },
    //sp太史慈
    {
        id : 5037,
        name : "方阵掩杀",
        type : "追击",
        odd : 40,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let damageValue = 235;//基础伤害率
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);
            
            let block = new DamageBlock(self,this,'攻击',true,-999,true,false,99,0);
            block.Init(target,null,true,3);
            this.STATISTICS.times += 1;
        }
    },
]

export default wuAArr;