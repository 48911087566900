<template>
    <div class="battle_page">
        <ChessConfigVue/>
        <TitleTipVue/>

        <!-- 开始战斗按钮 -->
        <div class="begin_battle_btn" ref="begin_battle_btn" 
        @touchstart="$refs.begin_battle_btn.classList.add('click')"
        @touchend="$refs.begin_battle_btn.classList.remove('click')"
        @click="BeginFight">开始</div>
        <!-- 战报按钮 -->
        <div class="report_btn" ref="report_btn"
        @touchstart="$refs.report_btn.classList.add('click')"
        @touchend="$refs.report_btn.classList.remove('click')"
        @click="ReportPanel()"
        >战报</div>
        <!-- 阵容按钮 -->
        <div class="camp_btn" ref="camp_btn"
        @touchstart="$refs.camp_btn.classList.add('click')"
        @touchend="$refs.camp_btn.classList.remove('click')"
        @click="OpenLineup()"
        >阵容</div>
        <!-- 武将池 -->
        <WarriorContainer v-if="isSelectWarrior"/>
        <!-- 武将面板 -->
        <WarriorAdjust v-if="isShowWarriorInfo"/>
        <!-- 战法面板 -->
        <TacticsContainer v-if="isSelectTactics" />
        <!-- 战法描述面板 -->
        <TacticsDescribe v-if="isShowTacticsDescribe"/>
        
    </div>
</template>

<script>
import Driver from './js/driver.js';

import ChessConfigVue from './ChessConfig.vue';
import TitleTipVue from './TitleTip.vue';
import WarriorAdjust from './WarriorAdjust.vue';
import WarriorContainer from './WarriorContainer.vue';
import TacticsContainer from './TacticsContainer.vue';
import TacticsDescribe from './TacticsDescribe.vue';
export default {
    name : 'Battle',
    components:{
        ChessConfigVue,
        TitleTipVue,
        WarriorAdjust,
        WarriorContainer,
        TacticsContainer,
        TacticsDescribe,
    },
    data(){
        return{
            isSelectWarrior : false,
            isSelectTactics : false,
            isShowTacticsDescribe : false,
            isShowWarriorInfo : false,
            isShowReport : false,

            tacticsItem : null,
            game : this.$store.state.BorderChess,
        }
    },
    methods:{
        //打开战报板块
        ReportPanel(){
            this.$router.push({
                path : '/ReportContainer'
            })
        },
        //打开阵容面板
        OpenLineup(){
            this.$router.push({
                path : '/Lineup'
            })
        },
        ShowWarriorContainer(close){
            this.isSelectWarrior = !close;
        },
        ShowTacticsContainer(show){
            this.isSelectTactics = show;
        },
        ShowTacticsInfo(show){
            this.isShowTacticsDescribe = show;
        },
        ShowWarriorAdjustPanel(show){
            this.isShowWarriorInfo = show;
            this.$forceUpdate();
        },
        //开始战斗按钮
        BeginFight(){
            //检查
            let avail = true;
            for(let i in this.game.BlueTeam.warriorArr){
                if(this.game.BlueTeam.warriorArr[i].id == -1) avail = false;
            }
            for(let i in this.game.RedTeam.warriorArr){
                if(this.game.RedTeam.warriorArr[i].id == -1) avail = false;
            }
            if(avail){
                let driver = new Driver(this.$store.state.BorderChess);
                driver.Main();
                this.$router.push({
                    path : '/BattleResult'
                })
            }
            else{
                this.$bus.$emit('alert','武将没有配置完整');
            }
        }
    },
    mounted(){
        this.$bus.$on('ShowWarriorContainer',this.ShowWarriorContainer);
        this.$bus.$on('ShowTacticsContainer',this.ShowTacticsContainer);
        this.$bus.$on('ShowTacticsInfo',this.ShowTacticsInfo);
        this.$bus.$on('ShowWarriorAdjustPanel',this.ShowWarriorAdjustPanel);
        this.game.currentTeam = this.game.BlueTeam;
    }
}
</script>

<style lang="less">
.battle_page{
    width: 100vw; height: 100vh;
    background: url('../../assets/background/bg (1).jpg') right bottom no-repeat;
    background-size: cover;
    .begin_battle_btn{
        position: absolute; left: 35vw; bottom:10vh; top: auto;
        width: 30vw;height: 30vw;
        background: url('../../assets/ui/ui (112).png')no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 30vw;
        font-size: 6vw;
        color: rgb(255, 253, 230);
        font-family: 'FZLBJW';
        // font-weight: bold;
    }
    .report_btn,.camp_btn{
        position: absolute; left: 15vw; bottom:10vh; top: auto;
        margin-bottom: -10vw;
        width: 25vw; height: 25vw;
        background: url('../../assets/ui/ui (146).png')no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 42vw;
        color: rgb(202, 180, 39);
        text-shadow: 0 0 5px black;
        font-size: 6vw;
        font-family: 'FZLBJW';
        // font-weight: bold;
    }
    .camp_btn{
        background: url('../../assets/ui/ui (167).png')no-repeat;
        background-size: 100% 100%;
        left: 60vw;
    }
    .begin_battle_btn.click,.report_btn.click,.camp_btn.click{
        transform: scale(.8);
    }
}
</style>