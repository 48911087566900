import Query from "./query";

//判断类
class Judge{
    constructor(){

    }
    //判断谋一方大营是否阵亡
    IsGameOver(whichTeam){
        if(!whichTeam[0].alive) return true;
        return false;
    }
    //判断一个武将是否在x范围之内
    IsInScale(impactor,target,x){
        let dis = x;
        for(let i = Number(impactor.position) + 1;i< 3;i++){
            if(impactor.ally[i].alive){
                dis -= 1;
            }
        }
        for(let i = 2;i>=0;i--){
            if(target.ally[i].alive && target.ally[i] != target) dis--;
            else if(target.ally[i] == target && dis > 0) return true;
        }
        return false;
    }
    //判断一个武将能否对另一个武将发动普通攻击
    IsCanAttackSomeone(attacker,receiver){
        let q = new Query();
        let dis = q.GetRealAdRange(attacker);
        for(let i = Number(attacker.position) + 1;i< 3;i++){
            if(attacker.ally[i].alive){
                dis -= 1;
            }
        }
        for(let i = 2;i>=0;i--){
            if(receiver.ally[i].alive && receiver.ally[i] != receiver) dis--;
            else if(receiver.ally[i] == receiver && dis > 0) return true;
        }
        return false;
    }
    //是否存在攻击距离效果
    IsExistRange(target,tacticsType,isBenefit){
        for(let i in target.RANGE){
            if(target.RANGE[i].tactics.type == tacticsType && isBenefit == target.RANGE[i].isBenefit){
                return true;
            }
        }
        return false;
    }
    //是否存在某个战法类型的造成某个伤害类型状态
    IsExistDamageStatus(target,tacticsType,statusType){
        for(let i in target.SPECIAL_STATUS){
            if(tacticsType == '所有' && target.SPECIAL_STATUS[i].type == statusType) return true;
            if(target.SPECIAL_STATUS[i].type == statusType && target.SPECIAL_STATUS[i].tactics.type == tacticsType) return true;
        }
        return false;
    }
    //是否存在某个战法类型的造成或者受到的增减伤块
    IsExistDamageBlock(target,tacticsType,damageType,isMakeDamage,isBenefit){
        for(let i in target.DAMAGE_VAR){
            if(target.DAMAGE_VAR[i].tactics.type == tacticsType
            && target.DAMAGE_VAR[i].damageType == damageType
            && target.DAMAGE_VAR[i].isMakeDamage == isMakeDamage
            && target.DAMAGE_VAR[i].isBenefit == isBenefit){
                return true;
            }
        }
        return false;
    }
    //是否存在规避
    IsDodge(target){
        if(target.DODGE.length > 0){
            return true;
        }
        return false;
    }
    //是否存在某种状态
    IsGetStatus(target,whichStatus){
        for(let i in target.STATUS){
            if(target.STATUS[i].type == whichStatus && target.STATUS[i].waitTime <= 0){
                return true;
            }
        }
        return false;
    }

}

export default Judge;