import Attack from "../js/attack";
import Count from "../js/count";
import StatusBlock from "../block/status";
import Query from "../js/query";
import DamageBlock from "../block/damage";
import Report from "../js/report";
import CureStatusBlock from "../block/cureStatus";
import OddBlock from "../block/odd";
import ExecuteBlock from "../block/execute";
import ActiveTacticsBlock from "../block/execute3";
import AfterDamageBlock from "../block/execute1";
import AttrBlock from "../block/attr";
import AttackExecuteBlock from "../block/execute4";
let qunSArr = [
    //马超
    {
        id : 5,
        name : "血溅黄砂",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let index = 120;
            if(self.driver.version == '平衡'){
                index = 100;
            }

            //加伤害
            let block = new DamageBlock(self,this,"攻击",true,index,false,false,99);
            block.Init(self,null,false);
            let report = new Report();
            report.InitExecuteReport(2,self,`造成攻击伤害提高${index}%`);
            //无法施放主动战法
            let block1 = new StatusBlock(self,this,"无法施法",99);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`无法施放主动战法效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //甄洛
    {
        id : 26,
        name : "洛水佳人",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let cureValue = 85;
            let cureIndex = 0.75;
            let odd = 75;


            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            for(let i in res){
                let block = new CureStatusBlock(self,this,"休整",cureValue,cureIndex,99,0,odd);
                let work = block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`休整(预备)效果已施加`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //吕布
    {
        id : 45,
        name : "辕门射戟",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let atk = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 120;//基础伤害率
            if(self.driver.version == '平衡'){
                damageIndex = 100;
            }
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            //附加上无视兵种
            let block = new StatusBlock(self,this,"无视兵种",0);
            block.Init(self);
            for(let j = 0 ;j < 2;j++){
                let res = atk.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                    atk.GetHurt(self,res[i],damage,false,"攻击",this);
                }
                //减伤
                if(j == 0){
                    for(let i in res){
                        let block = new DamageBlock(self,this,"攻击",true,-999,false,false,1);
                        block.Init(res[i])
                    }
                }
            }
            let arr = [];
            for(let i in self.STATUS){
                if(self.STATUS[i].tactics != this) arr.push(self.STATUS[i]);
                else{
                    let report = new Report();
                    report.InitExecuteReport(3,self,`来自【${self.STATUS[i].tactics.name}】的${self.STATUS[i].type}效果消失了`)
                }
            }
            self.STATUS = arr;
            
            this.STATISTICS.times += 1;
        }
    },
    //貂蝉
    {
        id : 31,
        name : "白楼独舞",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let value = q.GetAdditionValue(self,26,'谋略',0.2);//减伤幅度

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",true,-value,false,false,3);
                let block2 = new DamageBlock(self,this,"谋略",true,-value,false,false,3);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`攻击伤害降低${value}%`);
                }
                let work2 = block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`策略伤害降低${value}%`);
                }
            }
            //施加暴走
            for(let i in res){
                let block = new StatusBlock(self,this,"暴走",5,3);
                let work = block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`暴走(预备)效果已施加`)
                }
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //陈宫
    {
        id : 72,
        name : "迟智难酬",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,240,'谋略',2.75);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let fri = a.SelectTarget(self,realRange,true,false,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,this,null);
                a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
            }
            for(let i in fri){
                let block = new DamageBlock(self,this,'谋略',false,-999,true,false,99,0);
                block.Init(fri[i],null,true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //公孙瓒
    {
        id : 115,
        name : "动如雷震",
        type : "主动",
        odd : 35,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : [2,3],
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = q.GetAdditionValue(self,40,'速度',0.25);
            let targetNumber = 2 + Math.floor(Math.random()*2);
            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,targetNumber,true,true,this);
            for(let i in res){
                for(let j in res[i].TacticsArr){
                    if(res[i].TacticsArr[j].type == '追击'){
                        let odd = new OddBlock(self,this,res[i].TacticsArr[j],100,1,0,0);
                        odd.Init(res[i],true,3);
                    }
                }
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'追击',true,value,false,false,1,0);
                block.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //田丰
    {
        id : 117,
        name : "尽言直谏",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let value = 30;//主动战法增伤幅度
            let odd = 10;//几率提升

            let tag = '尽言直谏' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,false,false,this);
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【尽言直谏】`);
                let targetArr = [];
                //可以选择的战法数组
                for(let i in res){
                    for(let j in res[i].TacticsArr){
                        if(res[i].TacticsArr[j].type == '主动'){
                            targetArr.push({
                                warrior : res[i],
                                tactics : res[i].TacticsArr[j]
                            });
                        }
                    }
                }
                let final = [];
                for(let i=0;i<self[tag] && targetArr.length > 0;i++){
                    let rand = Math.floor(Math.random()*targetArr.length);
                    final.push(targetArr[rand]);
                    targetArr.splice(rand,1);
                }
                for(let i in final){
                    //检查冲突
                    let work1 = true;
                    let work2 = true;
                    for(let k in final[i].warrior.ODD_VAR){
                        let tgt = final[i].warrior.ODD_VAR[k]
                        if(tgt.tactics == that && tgt.duration == 0 && tgt.workTactics == final[i].tactics){
                            tgt.duration = 1;
                            let r = new Report();
                            r.InitExecuteReport(3,final[i].warrior,`【${final[i].tactics.name}】发动几率提高${tgt.value}%`);
                            work1 = false;
                        }
                    }
                    for(let k in final[i].warrior.DAMAGE_VAR){
                        let tgt = final[i].warrior.DAMAGE_VAR[k]
                        if(tgt.tactics == that && tgt.duration == 0 && tgt.tacticsItem == final[i].tactics){
                            tgt.duration = 1;
                            let r = new Report();
                            r.InitExecuteReport(3,final[i].warrior,`【${final[i].tactics.name}】伤害提高${tgt.value}%`);
                            work2 = false;
                        }
                    }
                    if(work1){
                        let block1 = new OddBlock(self,that,final[i].tactics,odd,1,0,0);
                        block1.Init(final[i].warrior,true,3);
                    }
                    if(work2){
                        let block2 = new DamageBlock(self,that,'主动',true,value,false,false,1,0);
                        block2.Init(final[i].warrior,final[i].tactics,true,3);
                    }
                }
                self[tag] = 2;
            }
            let trigger = function(){
                let target = this.beExecuted;
                for(let i in target.ODD_VAR){
                    if(target.ODD_VAR[i].tactics == that && target.ODD_VAR[i].workTactics == this.triggerTactics && self[tag] == 2){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`下阶段【尽言直谏】选择3个战法`);
                        self[tag] = 3;
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【尽言直谏】效果已施加`);
            for(let i in res){
                let block = new ActiveTacticsBlock(self,this,trigger,99,0,true,'主动');
                block.Init(res[i],false);
            }
            self[tag] = 2;
            this.STATISTICS.times += 1;
        }
    },
    //沮授
    {
        id : 133,
        name : "缓师徐持",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = q.GetAdditionValue(self,16,'谋略',0.12);
            
            let res = a.SelectTarget(self,5,false,true,3,false,false,this);
            let getHurt = function(){
                let target = this.beExecuted;
                if(!target.action){
                    for(let i in target.ATTR){
                        if(target.ATTR[i].tactics == that){
                            target.ATTR[i].value -= value;
                            let r = new Report();
                            let txt = `${target.ATTR[i].attrType}属性降低了${Math.abs(target.ATTR[i].value)}(${q.GetAttr(target,target.ATTR[i].attrType)})`;
                            r.InitTwoTargetReport(3,self,target,`【缓师徐持】令`,txt)
                        }
                    }
                }
            }
            for(let i in res){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(res[i],'缓师徐持');
                for(let j=0;j<4;j++){
                    let attrType = "";
                    switch(j){
                        case 0 : attrType = "攻击";break;
                        case 1 : attrType = "防御";break;
                        case 2 : attrType = "谋略";break;
                        case 3 : attrType = "速度";break;
                    }
                    let block = new AttrBlock(self,this,attrType,0,99,false);
                    block.isBenefit = false;
                    block.Init(res[i],false,3);
                    
                }
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【缓师徐持】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //马腾
    {
        id : 138,
        name : "伏波扬砂",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let duration = 99;//增伤持续回合数
            let value = q.GetAdditionValue(self,25,'攻击',0.12);//主动战法增伤幅度
            let index = 40;//每超过40%的增伤幅度
            let consume = 4;//每次消耗层数

            let tag = '伏波扬砂' + self.belong + self.position;
            let res = a.SelectTarget(self,5,true,false,3,true,false,this);
            let attack = function(){
                let tgt = this.beExecuted;
                let res = c.AttackDamageIndex(tgt,'攻击',true,null);
                if(res > 1) res -=1;
                res = Math.floor(res * 100 / index);
                self[tag] += res;
                let r = new Report();
                r.InitExecuteReport(3,self,`获得${res}层【扬砂】，共${self[tag]}层`)
            }
            //消耗扬砂
            let action = function(){
                if(self[tag] >= consume){
                    //先清除上一次提供的连击效果
                    let work = true;
                    for(let i in self.STATUS){
                        if(self.STATUS[i].tactics.type == '指挥' && self.STATUS[i].type == '连击'){
                            work = false;
                        }
                    }
                    if(work){
                        self[tag] -= consume;
                        let r = new Report();
                        r.InitExecuteReport(3,self,`消耗${consume}层【扬砂】获得连击效果，还剩${self[tag]}层`);
                        a.LaunchAttack(self);
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(3,self,`已存在连击效果`);
                    }
                    
                    
                }
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'普通攻击',true,value,false,false,duration,0);
                block.Init(res[i],null,true,2);
                if(res[i] != self){
                    let block1 = new AttackExecuteBlock(self,this,attack,99,0,false);
                    block1.Init(res[i],false);
                }
                else{
                    let block = new AttackExecuteBlock(self,this,action,99,0,true,'追击后');
                    block.Init(self,false);
                }
            }
            for(let i in res){
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【伏波扬砂】效果已施加`);
            }
            self[tag] = 0;
            this.STATISTICS.times += 1;
        }
    },
]

export default qunSArr;