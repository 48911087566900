import Attack from "../js/attack";
import Count from "../js/count";
import StatusBlock from "../block/status";
import Query from "../js/query";
import DamageBlock from "../block/damage";
import Report from "../js/report";
import CureStatusBlock from "../block/cureStatus";
import OddBlock from "../block/odd";
import ExecuteBlock from "../block/execute";
import ActiveTacticsBlock from "../block/execute3";
import BeforeMakeDamageBlock from "../block/execute2";
import AttrBlock from "../block/attr";
import GetCureExecuteBlock from "../block/execute5";
import AfterDamageBlock from "../block/execute1";
let jinAArr = [
    //卫瓘
    {
        id : 124,
        name : "持节镇西",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let ad = q.GetAdditionValue(self,22,'攻击',0.16);//属性加成
            let de = q.GetAdditionValue(self,22,'防御',0.16);//属性加成
            let tact = q.GetAdditionValue(self,22,'谋略',0.16);//属性加成
            let maxTime = 4;//叠加次数
            
            let tag_ad = '持节镇西' + '攻击' + self.belong + self.position;
            let tag_de = '持节镇西' + '防御' + self.belong + self.position;
            let tag_tact = '持节镇西' + '谋略' + self.belong + self.position;
            let getDamage = function(){
                let target = this.beExecuted;
                if(target[tag_de] < maxTime){
                    let block = new AttrBlock(self,that,'防御',de,0,true);
                    let work = block.Init(target,false,3);
                    if(work){
                        target[tag_de] += 1;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【持节镇西】令`,`防御属性提升${de * target[tag_de]}(${q.GetAttr(target,'防御')})`);
                    }
                }
            }
            let makeDamage = function(){
                let target = this.beExecuted;
                if(this.damageType == '攻击' && target[tag_ad] < maxTime){
                    let block = new AttrBlock(self,that,'攻击',ad,0,true);
                    let work = block.Init(target,false,3);
                    if(work){
                        target[tag_ad] += 1;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【持节镇西】令`,`攻击属性提升${ad * target[tag_ad]}(${q.GetAttr(target,'攻击')})`);
                    }
                }
                else if(this.damageType == '谋略' && target[tag_tact] < maxTime){
                    let block = new AttrBlock(self,that,'谋略',tact,0,true);
                    let work = block.Init(target,false,3);
                    if(work){
                        target[tag_tact] += 1;
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【持节镇西】令`,`谋略属性提升${tact * target[tag_tact]}(${q.GetAttr(target,'谋略')})`);
                    }
                }
            }
            let clear = function(){
                let tgt = this.beExecuted;
                tgt[tag_ad] = 0;
                tgt[tag_de] = 0;
                tgt[tag_tact] = 0;
            }
            for(let i in self.ally){
                let target = self.ally[i];
                //受到伤害
                let block1 = new BeforeMakeDamageBlock(self,this,getDamage,99,false);
                block1.Init(target);
                //造成伤害
                let block2 = new BeforeMakeDamageBlock(self,this,makeDamage,99,true);
                block2.Init(target);
                let block = new ExecuteBlock(self,this,'行动开始',clear,99);
                block.Init(target,false);
                //标记
                target[tag_ad] = 0;
                target[tag_de] = 0;
                target[tag_tact] = 0;
                let r = new Report();
                r.InitExecuteReport(2,target,`【持节镇西】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //贾充
    {
        id : 130,
        name : "赏顺伐逆",
        type : "被动",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let odd = 75;//触发几率
            let cureValue = 65;
            let cureIndex = 0.6;
            let damageValue = q.GetAdditionValue(self,180,'谋略',1.75);
            if(self.driver.version == '平衡'){
                cureValue = 80;
                cureIndex = 0.7;
            }
            
            let getCure = function(){
                if(this.cureTactics == that) return;
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let res = a.SelectTarget(self,5,true,false,2,false,true,that);
                    for(let i in res){
                        let cure = c.CountCure(self,res[i],cureValue,cureIndex,that);
                        let work = a.GetCure(self,res[i],cure,that,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,res[i],`【赏顺伐逆】令`,`恢复了${cure}兵力(${res[i].hp})`)
                        }
                    }
                }
            }
            let getHurt = function(){
                if(this.damageType == '谋略'){
                    let target = this.damageFromWho;
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let damage = c.CountDamage(self,target,damageValue,'谋略',false,that,null);
                        let report = new Report().InitTwoTargetReport(3,self,target,`【赏顺伐逆】令`,`损失了${damage}兵力(${target.hp - damage})`)
                        a.GetHurt(self,target,damage,false,'谋略',that,true,report);
                    }
                }
            }
            let block = new GetCureExecuteBlock(self,this,getCure,99);
            block.Init(self);
            let block1 = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
            block1.Init(self,'赏顺伐逆')
            this.STATISTICS.times += 1;
        }
    },
]

export default jinAArr;