import Attack from "../js/attack";
import Count from "../js/count";
import Report from "../js/report";
import StatusBlock from "./status";

class DamageStatus{
    constructor(applicator,tactics,type,damageValue,damageType,duration,waitTime,isExecute){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //状态类型
        this.type = type;
        //伤害率
        this.damageValue = damageValue
        //伤害类型,攻击伤害还是谋略伤害
        this.damageType = damageType;
        //持续时间
        this.duration = duration;
        //准备时间
        this.waitTime = waitTime == undefined ? 0 : waitTime;
        //是否执行效果还是单纯挂状态
        this.isExecute = isExecute == undefined ? true : isExecute;
    }
    Init(target,needReport = true,reportLevel = 3){
        //执行陷入持续伤害块
        for(let i in target.FALL_INTO_CONTINUE_DAMAGE){
            //获取数据信息
            target.FALL_INTO_CONTINUE_DAMAGE[i].GetData(this.type,this.damageType,this.damageValue,this.applicator,this.tactics,this.duration);
            //执行
            target.FALL_INTO_CONTINUE_DAMAGE[i].Execute();
        }
        // if(!target.alive) return false;
        //冲突判定
        let refresh = false;
        let temp = [];
        for(let i in target.SPECIAL_STATUS){
            if(target.SPECIAL_STATUS[i].tactics.type == this.tactics.type && target.SPECIAL_STATUS[i].type == this.type && target.SPECIAL_STATUS[i].damageValue >= this.damageValue){
                let r = new Report();
                let text = "";
                if(target.SPECIAL_STATUS[i].damageValue > this.damageValue) text = `已存在更强的${this.type}效果`
                else text = `已存在相同的${this.type}效果`
                r.InitExecuteReport(3,target,text);
                return false;
            }
            else if(target.SPECIAL_STATUS[i].tactics.type == this.tactics.type && target.SPECIAL_STATUS[i].type == this.type && target.SPECIAL_STATUS[i].damageValue < this.damageValue){
                let r = new Report();
                let text = `${this.type}效果刷新了`;
                r.InitExecuteReport(3,target,text);
                refresh = true;
            }
            else{
                temp.push(target.SPECIAL_STATUS[i]);
            }
        }
        target.SPECIAL_STATUS = temp;
        target.SPECIAL_STATUS.push(this);
        if(needReport){
            let r = new Report();
            r.InitExecuteReport(reportLevel,target,`${this.type}效果已施加`);
        }
        return true;
    }
    Execute(target){
        
        let c = new Count();
        let a = new Attack();
        let r = new Report();
        if(this.tactics.name == '匠心不竭' || this.tactics.name == '帝临回光'){
            let block = new StatusBlock(this.applicator,this.tactics,"无视规避",0);
            block.Init(this.applicator,false);
        }
        let damage = c.CountDamage(this.applicator,target,this.damageValue,this.damageType,false,this.tactics,this.type);
        let str = `由于【${this.tactics.name}】的${this.type}，损失了${damage}兵力(${target.hp - damage})`;
        let report = r.InitExecuteReport(2,target,str);
        a.GetHurt(this.applicator,target,damage,false,this.damageType,this.tactics,false,report,true);
        if(this.tactics.name == '匠心不竭' || this.tactics.name == '帝临回光'){
            let arr = [];
            for(let i in this.applicator.STATUS){
                if(this.applicator.STATUS[i].tactics != this.tactics) arr.push(this.applicator.STATUS[i]);
            }
            this.applicator.STATUS = arr;
        }
    }
}

export default DamageStatus;