class GetCureExecuteBlock{
    constructor(applicator,tactics,execute,duration){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //执行函数
        this.execute = execute;
        //持续时间
        this.duration = duration;
        //被执行人
        this.beExecuted = null;
        //治疗来源的战法
        this.cureTactics = null;
        //治疗施加者
        this.cureWarrior = null;
        //治疗量
        this.cureValue = 0;
    }
    Init(target){
        this.beExecuted = target;
        target.GET_CURE.push(this);
    }
    Execute(cureTactics,cureWarrior,cureValue){
        this.cureTactics = cureTactics;
        this.cureWarrior = cureWarrior;
        this.cureValue = cureValue;
        this.execute();
    }
}
export default GetCureExecuteBlock;