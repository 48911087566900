<template>
    <div class="title_tip">
        <div class="camp_tape">
            <div class="none cover bg"></div>
            <div class="bg_wei cover bg" v-if="ShowCamp('魏')"></div>
            <div class="bg_shu cover bg" v-if="ShowCamp('蜀')"></div>
            <div class="bg_wu cover bg" v-if="ShowCamp('吴')"></div>
            <div class="bg_qun cover bg" v-if="ShowCamp('群')"></div>
            <div class="bg_han cover bg" v-if="ShowCamp('汉')"></div>
            <div class="bg_jin cover bg" v-if="ShowCamp('晋')"></div>
            <div class="style"></div>
            <!-- 阵 -->
            <div class="box">阵
                <div class="bg_wei cover circle meet" v-if="ShowCamp('魏')">魏</div>
                <div class="bg_shu cover circle meet" v-if="ShowCamp('蜀')">蜀</div>
                <div class="bg_wu cover circle meet" v-if="ShowCamp('吴')">吴</div>
                <div class="bg_qun cover circle meet" v-if="ShowCamp('群')">群</div>
                <div class="bg_han cover circle meet" v-if="ShowCamp('汉')">汉</div>
                <div class="bg_jin cover circle meet" v-if="ShowCamp('晋')">晋</div>
            </div>
            <div class="box">
                号
                <div class="meet cover circle" v-if="ShowTrammel()">号</div>

            </div>
            <div class="box">
                兵
                <div class="meet cover circle" v-if="ShowArm('b')">兵</div>
                <div class="meet cover circle" v-if="ShowArm('g')">兵</div>
                <div class="meet cover circle" v-if="ShowArm('q')">兵</div>
                
            </div>
        </div>

        <!-- 清空队伍按钮 -->
        <div class="clear_teams_btn" ref="clear_teams_btn"
        @touchstart="$refs.clear_teams_btn.classList.add('click')"
        @touchend="$refs.clear_teams_btn.classList.remove('click')"
        @click="ClearTeamWarrior()"
        >清空队伍</div>
        <!-- 自动加点按钮 -->
        <div class="auto_addition_btn" ref="auto_addition_btn"
        @touchstart="$refs.auto_addition_btn.classList.add('click')"
        @touchend="$refs.auto_addition_btn.classList.remove('click')"
        @click="QuickAddAttr()">智能加点</div>
        <!-- 保存阵容 -->
        <div class="save_camp_btn" ref="save_camp_btn"
        @touchstart="$refs.save_camp_btn.classList.add('click')"
        @touchend="$refs.save_camp_btn.classList.remove('click')"
        @click="SaveTeamCamp()"
        >保存阵容</div>
        <!-- 切换版本 -->
        <div class="version_control_btn" ref="version_control_btn"
        @touchstart="$refs.version_control_btn.classList.add('click')"
        @touchend="$refs.version_control_btn.classList.remove('click')"
        @click="VersionControl()">
            {{ game.version + '模式' }} 
        </div>

        
    </div>
</template>

<script>
import Util from './js/util';
import trammels from '../../data/trammels.json';
import Team from '../../util/team';
import Save from '../../util/save';
export default {
    name : 'TitleTip',
    data(){
        return{
            show : {
                clear : false,
                auto : false,
                camp : false,
            },
            game : this.$store.state.BorderChess,
            camp_record:[],
            u : new Util(),
        }
    },
    methods:{
        //版本切换
        VersionControl(){
            let that = this;
            let f = function(){
                that.game.version = that.game.version == '平衡' ? "默认" : "平衡";
                that.$bus.$emit('alert','切换成功');
            }
            let params = {
                text : `是否切换到${that.game.version == '平衡' ? "默认" : "平衡"}模式，${that.game.version == '平衡' ? "默认模式下战法数值将更接近实际对局" : "平衡模式下战法数值将更平衡"}`,
                callBack : f,
            }
            this.$store.commit('Alert',params);
            
        },
        RemainPlus(target){
            let res = 0;
            res += target.red * 10 + Math.floor(target.level/10) * 10;
            res -= target.plus.ad;
            res -= target.plus.de;
            res -= target.plus.tact;
            res -= target.plus.speed;
            return res;
        },
        //显示是否存在阵营加成
        ShowCamp(camp){
            if(this.game.currentTeam.warriorArr){
                let tgtArr = this.game.currentTeam.warriorArr;
                let count = {};
                for(let i=0;i<tgtArr.length;i++){
                    if(count[tgtArr[i].camp]){
                        if(camp == tgtArr[i].camp){
                            return true;
                        }
                    }
                    count[tgtArr[i].camp] = 1;
                }
                return false;
            }
        },
        //显示是否存在兵种加成
        ShowArm(targetArm){
            let u = new Util();
            if(this.game.currentTeam.warriorArr){
                let tgtArr = this.game.currentTeam.warriorArr;
                let count = {};
                for(let i=0;i<tgtArr.length;i++){
                    let arm = u.TransferArms(tgtArr[i].up_arms[tgtArr[i].choseArms]);
                    if(count[arm]){
                        if(targetArm == arm){
                            return true;
                        }
                    }
                    count[arm] = 1;
                }
                return false;
            }
        },
        //显示是否存在称号加成
        ShowTrammel(){
            if(this.game.currentTeam.warriorArr){
                let tgtArr = this.game.currentTeam.warriorArr;
                for(let i in trammels){
                    let count = 0;
                    for(let k=0;k<tgtArr.length;k++){
                        for(let j=0;j<trammels[i].member.length;j++){
                            if(tgtArr[k].name == trammels[i].member[j].name && tgtArr[k].camp == trammels[i].member[j].camp){
                                count += 1;
                                break;
                            }
                            if(trammels[i].member.length-1 == j){
                                k = tgtArr.length;
                            }
                        }
                    }
                    if(count == 3){
                        return trammels[i];
                    }
                }
            }
        },
        //确定清理队伍
        ClearTeamWarrior(){
            let that = this;
            if(that.game.currentTeam.length == 0) return;
            let f = function(){
                let t = new Team(that);
                for(let i in that.game.currentTeam.warriorArr){
                    t.RemoveWarrior(that.game.currentTeam.warriorArr[i]);
                }
                that.$bus.$emit('alert','清除成功');
            }
            let params = {
                text : '确定清除该队伍？',
                callBack : f,
            }
            this.$store.commit('Alert',params);
        },
        //保存阵容到阵容目录里
        SaveTeamCamp(){
            for(let i in this.game.currentTeam.warriorArr){
                let tgt = this.game.currentTeam.warriorArr[i];
                if(tgt.id == -1){
                    this.$bus.$emit('alert','武将配置不全');
                    return
                } 
                else{
                    for(let j in tgt.tacticsArr){
                        if(tgt.tacticsArr[j].id == -1){
                            this.$bus.$emit('alert','战法配置不全');
                            return;
                        } 
                    }
                }
            }
            let that = this;
            let f = function(){
                let currentTime = new Date(); // 创建一个表示当前时间的Date对象
                let year = currentTime.getFullYear(); // 获取年份
                let month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（注意要加上1）
                let day = currentTime.getDate().toString().padStart(2, '0'); // 获取天数
                let hours = currentTime.getHours().toString().padStart(2, '0'); // 获取小时
                let minutes = currentTime.getMinutes().toString().padStart(2, '0'); // 获取分钟
                let seconds = currentTime.getSeconds().toString().padStart(2, '0'); // 获取秒数
                
                // 组合为完整的日期和时间字符串
                let timeString = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
                let item = {
                    name : '',
                    describe : '',
                    time : timeString,
                    warriorArr : new Team(that).ReturnNewTeam(that.game.currentTeam.warriorArr),
                }
                console.log('that.game.LineupContainer',that,that.game,this,that.game.LineupContainer);
                if(that.game.LineupContainer == undefined || that.game.LineupContainer == null){
                    that.game.LineupContainer = [];
                }
                that.game.LineupContainer.unshift(item);
                that.$bus.$emit('alert','添加成功');
                let save = new Save(that);
                save.SaveLineup();
            }
            let params = {
                text : '确定保存该阵容？',
                callBack : f,
            }
            this.$store.commit('Alert',params);

            
            // for(let i=0;i<3;i++){
            //     if(this.teams[this.SIGN_CAMP][i].name == ""){
            //         this.$bus.$emit('alert',"武将配置不完整");
            //         return;
            //     }
            //     else{
            //         if(this.teams[this.SIGN_CAMP][i].TACTICS_STACK.length < 3){
            //             this.$bus.$emit('alert',"战法配置不完整");
            //             return;
            //         }
            //     }
            // }
            // this.show.camp = true;
        },
        //初始属性
        RealAttr(attrType,target){
            let baseAttr = 0;
            switch(attrType){
                case 1 : baseAttr = target.attr.ad; break;
                case 2 : baseAttr = target.attr.de; break;
                case 3 : baseAttr = target.attr.tact; break;
                case 4 : baseAttr = target.attr.speed; break;
            }
            let number = 0;
            let addition = target.level -1;
            switch(attrType){
                case 1 : 
                    number = baseAttr + target.grow.ad * addition;
                    number += target.plus.ad;
                    break;
                case 2 :
                    number = baseAttr + target.grow.de * addition;
                    number += target.plus.de;
                    break;
                case 3 :
                    number = baseAttr + target.grow.tact * addition;
                    number += target.plus.tact;
                    break;
                case 4 :
                    number = baseAttr + target.grow.speed * addition;
                    number += target.plus.speed;
                    break;
            }
            return Math.floor(number);
        },
        //智能加点
        QuickAddAttr(){
            for(let i in this.game.currentTeam.warriorArr){
                let tgt = this.game.currentTeam.warriorArr[i];
                if(tgt.id == -1 || tgt.name == ""){
                    this.$bus.$emit('alert','先配置武将');
                    return;
                }
                else{
                    let remain = this.RemainPlus(tgt);
                    if(remain != 0){
                        //部分武将需要追速度
                        let needSpeed = false;
                        let speedValue = 0;
                        for(let j in this.game.currentTeam.warriorArr){
                            let w = this.game.currentTeam.warriorArr[j];
                            if(w.name == '马岱' && this.RealAttr(4,tgt) < this.RealAttr(4,w)){
                                speedValue = this.RealAttr(4,w) - this.RealAttr(4,tgt);
                                if(tgt.camp != w.camp) speedValue += 15;
                                else speedValue += 1;
                                needSpeed = true;
                            } 
                        }
                        for(let j in tgt.tacticsArr){
                            if(tgt.tacticsArr[j].name == '攻其不备'){
                                speedValue = Math.floor(remain*0.8);
                                needSpeed = true;
                            }
                        }
                        if(needSpeed){
                            let value = speedValue;
                            if(value < remain){
                                tgt.plus.speed += value;
                                remain -= value;
                            }
                            else{
                                tgt.plus.speed += remain;
                                remain = 0;
                            }
                        }
                        //防御性武将
                        if(tgt.attr.de > tgt.attr.ad && tgt.attr.de > tgt.attr.tact){
                            let value = Math.floor(remain*0.8)
                            tgt.plus.de += value;
                            remain-=value;
                        }
                        if(tgt.attr.ad > tgt.attr.tact) tgt.plus.ad += remain;
                        if(tgt.attr.tact > tgt.attr.ad) tgt.plus.tact += remain;
                    }
                    
                    
                }
            }
            this.$bus.$emit('alert','加点成功');
        }
    },
    mounted(){
        
    }

}
</script>

<style lang="less">
.title_tip{
    position: absolute; left: 0;top: 12vh;
    transform: translateY(83vw);
    width: 100vw;height: 15vw;
    background: url('../../assets/ui/ui (148).png')rgba(24, 26, 26, 0.73)center no-repeat;
    background-size: 250% 100%;
    // background: #09090983;
    .camp_tape{
        left: 2.5vw; top: 2.5vw;
        width: 30vw;height: 10vw;
        // left: 0; top: 0;
        // width: 40vw;height: 15vw;
        
        // background: rgb(227, 227, 227);
        // border-radius: 1vw;
        .bg{
            // border-radius: 2vw;
            box-shadow: 0 0 5px rgb(39, 38, 38);
        }
        .bg.none{
            background: rgb(33, 32, 32);
        }
        .style{
            position: absolute; left: 0; top: 0;
            width: 100%; height: 100%;
            background: url('../../assets/ui/ui\ \(173\).png')no-repeat;
            background-size: 100% 100%;
        }
        .box{
            float: left;position: relative;
            margin: 1.5vw 0 0 2vw;
            width: 7vw;height: 7vw;
            line-height: 6vw;
            border-radius: 50%;
            font-weight: bold;
            font-family: "FZLBJW";
            font-size: 4vw;
            border: .5vw solid rgb(124, 120, 120);
            box-sizing: border-box;
            color: rgb(170, 170, 170);
        
        .circle{
            border-radius: 50%;
            font-weight: bolder;
            font-size: 4vw;
        }
        .meet{
            left: -.5vw;top: -.5vw;
            color: rgb(227, 227, 227);
            border: .5vw solid rgb(221, 221, 221);
            // line-height: 8vw;
        }
    }
    }
    .clear_teams_btn,.auto_addition_btn,.save_camp_btn,.version_control_btn{
        right: 5vw; bottom: -10vw;left: auto; top: auto;
        height: 6vw;width: 20vw;
        line-height: 6vw;
        font-weight: bold;
        background: rgb(226, 226, 226);
        color: black;
    }
    .auto_addition_btn{
        right: 29vw;
    }
    .save_camp_btn{
        right: 77vw;
    }
    .version_control_btn{
        right: auto; left: 3vw;
        bottom: -20vw;
    }
    .clear_teams_btn.click,.auto_addition_btn.click,.save_camp_btn.click,.version_control_btn.click{
        transform: scale(.8);
    }
    .alert_panel{
        // z-index: 100;
        .mask{
            width: 100vw;height: 100vh;
            top: -12vh;
            margin: -83vw 0 0 0;
            
        }
    }
    
    
}
</style>