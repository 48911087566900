import Warrior from '../class/warrior.js';
import Tactics from '../class/tactics.js';
import Count from './count.js';
import Report from './report.js';
import Statistics from './statistics.js';

import weiSArr from '../warrior/wei_s.js';
import weiAArr from '../warrior/wei_a.js';
import qunSArr from '../warrior/qun_s.js';
import shuSArr from '../warrior/shu_s.js';
import wuSArr from '../warrior/wu_s.js';
import qunBArr from '../warrior/qun_b.js';
import shuAArr from '../warrior/shu_a.js';
import wuAArr from '../warrior/wu_a.js';
import hanBArr from '../warrior/han_b.js';
import hanAArr from '../warrior/han_a.js';
import wuBArr from '../warrior/wu_b.js';
import weiBArr from '../warrior/wei_b.js';
import qunAArr from '../warrior/qun_a.js';
import hanSArr from '../warrior/han_s.js';
import Util from './util.js';
import Query from './query.js';
import AttrBlock from '../block/attr.js';
import shuBArr from '../warrior/shu_b.js';
import activeA from '../tactics/active_a.js';
import commandS from '../tactics/command_s.js';
import activeS from '../tactics/active_s.js';
import commandA from '../tactics/command_a.js'
import commandB from '../tactics/command_b.js'
import Manage from './manage.js';
import comboS from '../tactics/combo_s.js';
import comboA from '../tactics/combo_a.js';
import comboB from '../tactics/combo_b.js'
import jinSArr from '../warrior/jin_s.js';
import jinAArr from '../warrior/jin_a.js';
import jinBArr from '../warrior/jin_b.js';
import activeB from '../tactics/active_b.js';
import passiveA from '../tactics/passive_a.js';
import passiveS from '../tactics/passive_s.js';
import passiveB from '../tactics/passive_b.js';

import trammels from '../../../data/trammels.json';
import DamageBlock from '../block/damage.js';
import Judge from './judge.js';
import StatusBlock from '../block/status.js';
import ExecuteBlock from '../block/execute.js';
import RangeBlock from '../block/range.js';
import CounterAttackBlock from '../block/counterAtk.js';
import DodgeBlock from '../block/dodge.js';
class Driver{
    constructor(entity){
        this.entity = entity;
        //版本
        this.version = entity.version;
        //队伍
        this.Team = [];
        //当前回合数
        this.turn = 1;
        //回合执行堆区
        this.EXECUTE = [];
        //战报堆
        this.ReportHeap = [];
        //计算
        this.Count = new Count();
    }
    //主函数
    Main(){
        //初始化
        this.Init();
        //对局前准备
        this.PrepareBattle();
        //开始进行对局
        this.Execute();
        //将战报写入战报板块
        this.PushReport();

    }
    //载入外部加成
    InitExternalAddition(target){
        //加入四大营的加成
        target.attr.ad += 20;
        target.attr.de += 20;
        target.attr.tact += 20;
        target.attr.speed += 20;
        target.hp += 5000;
        let report = new Report();
        report.InitExecuteReport(2,target,`四大营加成，全属性提高20`)
    }
    //翻译兵种
    TransferArms(armStr){
        if(armStr == "禁卫" || armStr == "长枪兵" || armStr == "重步兵" || armStr == "藤甲兵" || armStr == "蛮兵") return "b";
        else if(armStr == "长弓兵" || armStr == "弩兵" || armStr == "死士") return "g";
        else if(armStr == "轻骑兵" || armStr == "重骑兵" || armStr == "铁骑兵" || armStr == "弓骑兵" || armStr == "象兵") return "q";
        
    }
    //兵种加成载入
    InitArmsAddition(target,targetArr){
        //先看有没有构成加成条件
        let selfArms = this.TransferArms(target.up_arms[target.choseArms]);
        let addition = 0;
        let number = 0;
        for(let k = 0;k < 3; k++){
            let targetArms = this.TransferArms(targetArr[k].up_arms[targetArr[k].choseArms]);
            if(targetArms == selfArms){
                number ++;
            }
        }
        //符合加成
        if(number > 1){
            if(number == 2) addition = 0.055;
            else if(number == 3) addition = 0.1;
            let report = new Report();
            switch(selfArms){
                //步兵系
                case "b":
                    let addition_ad = Math.floor(this.Count.GetAttr(target,"攻击")*addition);
                    let addition_de = Math.floor(this.Count.GetAttr(target,"防御")*addition);
                    target.attr.ad += addition_ad;
                    target.attr.de += addition_de;
                    report.InitExecuteReport(2,target,`${number}步兵加成，攻击+${addition*100}%(${addition_ad})，防御+${addition*100}%(${addition_de})`)
                    break;
                case "g":
                    let addition_speed = Math.floor(this.Count.GetAttr(target,"速度")*addition);
                    let addition_de1 = Math.floor(this.Count.GetAttr(target,"防御")*addition);
                    target.attr.speed += addition_speed;
                    target.attr.de += addition_de1;
                    report.InitExecuteReport(2,target,`${number}弓兵加成，速度+${addition*100}%(${addition_speed})，防御+${addition*100}%(${addition_de1})`)
        
                    break;
                case "q":
                    let addition_speed1 = Math.floor(this.Count.GetAttr(target,"速度")*addition);
                    let addition_ad1 = Math.floor(this.Count.GetAttr(target,"攻击")*addition);
                    target.attr.speed += addition_speed1;
                    target.attr.ad += addition_ad1;
                    report.InitExecuteReport(2,target,`${number}骑兵加成，速度+${addition*100}%(${addition_speed1})，攻击+${addition*100}%(${addition_ad1})`)
                    break;
                    
            }
            
        }
    }
    //阵营加成
    InitCampAddition(target,originAd,originDe,originTact,originSpeed){
        let u = new Util();
        let index = u.GetTargetCampAddition(target).res;
        let count = u.GetTargetCampAddition(target).count;
        target.attr.ad += Math.floor(originAd*index);
        target.attr.de += Math.floor(originDe*index);
        target.attr.tact += Math.floor(originTact*index);
        target.attr.speed += Math.floor(originSpeed*index);
        if(count > 1){
            let report = new Report();
            report.InitExecuteReport(2,target,`${count}${target.camp}阵营加成，全属性提高${index*100}%`);
        }
    }
    //称号加成
    InitTrammelsAddition(target){
        for(let i in trammels){
            let count = 0;
            for(let k=0;k<target.ally.length;k++){
                for(let j=0;j<trammels[i].member.length;j++){
                    if(target.ally[k].name == trammels[i].member[j].name && target.ally[k].camp == trammels[i].member[j].camp){
                        count += 1;
                        break;
                    }
                    if(trammels[i].member.length-1 == j){
                        k = target.ally.length;
                    }
                }
            }
            if(count == 3){
                return trammels[i];
            }
        }
        return false;
    }
    //输出所有武将属性战报
    OutputWarriorAttr(){
        for(let i in this.Team){
            for(let j in this.Team[i]){
                let ad = this.Count.GetAttr(this.Team[i][j],"攻击");
                let de = this.Count.GetAttr(this.Team[i][j],"防御");
                let tact = this.Count.GetAttr(this.Team[i][j],"谋略");
                let speed = this.Count.GetAttr(this.Team[i][j],"速度");
                let report = new Report();
                report.InitExecuteReport(1,this.Team[i][j],` 攻击(${ad}) 防御(${de}) 策略(${tact}) 速度(${speed})`);
            }
        }
    }
    //装载战法
    LoadTactics(target,data){
        let targetMainArr = [];
        //加载主战法
        if(data.camp == '魏'){
            switch(data.tactics_quality){
                case "S": targetMainArr = weiSArr;  break;
                case "A": targetMainArr = weiAArr;  break;
                case "B": targetMainArr = weiBArr;  break;
            }
        }
        else if(data.camp == '群'){
            switch(data.tactics_quality){
                case "S" : targetMainArr = qunSArr;break;
                case "A" : targetMainArr = qunAArr;break;
                case "B" : targetMainArr = qunBArr;break;
            }
        }
        else if(data.camp == '蜀'){
            switch(data.tactics_quality){
                case "S" : targetMainArr = shuSArr;break;
                case "A" : targetMainArr = shuAArr;break;
                case "B" : targetMainArr = shuBArr;break;
            }
        }
        else if(data.camp == '吴'){
            switch(data.tactics_quality){
                case "S" : targetMainArr = wuSArr;break;
                case "A" : targetMainArr = wuAArr;break;
                case "B" : targetMainArr = wuBArr;break;
            }
        }
        else if(data.camp == '汉'){
            switch(data.tactics_quality){
                case "S" : targetMainArr = hanSArr;break;
                case "A" : targetMainArr = hanAArr;break;
                case "B" : targetMainArr = hanBArr;break;
            }
        }
        else if(data.camp == '晋'){
            switch(data.tactics_quality){
                case "S" : targetMainArr = jinSArr;break;
                case "A" : targetMainArr = jinAArr;break;
                case "B" : targetMainArr = jinBArr;break;
            }
        }
        for(let i in targetMainArr){
            if(targetMainArr[i].name == data.tactics_name){
                let t = new Tactics(target,targetMainArr[i]);
                target.TacticsArr.push(t);
                break;
            }
        }
        //安装学习战法
        for(let i in data.tacticsArr){
            let targetArr = [];
            if(data.tacticsArr[i].type == 'command'){
                switch(data.tacticsArr[i].quality){
                    case "S" : targetArr = commandS; break;
                    case "A" : targetArr = commandA; break;
                    case "B" : targetArr = commandB; break;
                }
            }
            else if(data.tacticsArr[i].type == 'active'){
                switch(data.tacticsArr[i].quality){
                    case "S" : targetArr = activeS;break;
                    case "A" : targetArr = activeA;break;
                    case "B" : targetArr = activeB;break;
                }
            }
            else if(data.tacticsArr[i].type == 'combo'){
                switch(data.tacticsArr[i].quality){
                    case "S" : targetArr = comboS;break;
                    case "A" : targetArr = comboA;break;
                    case "B" : targetArr = comboB;break;
                }
            }
            else if(data.tacticsArr[i].type == 'passive'){
                switch(data.tacticsArr[i].quality){
                    case "S" : targetArr = passiveS;break;
                    case "A" : targetArr = passiveA;break;
                    case "B" : targetArr = passiveB;break;
                }
            }
            for(let j in targetArr){
                if(targetArr[j].name == data.tacticsArr[i].name){
                    let t = new Tactics(target,targetArr[j]);
                    target.TacticsArr.push(t);
                    break;
                }
            }
        }
    }
    //装载武将的兵种效果
    InitArmSkill(target){
        let skill = {
            id : -1,
            name : "兵种特性",
            type : "兵种特性",
            odd : 100,
            range : 0,
            isDamageMaker : false,
            isControlMaker : false,
            isCureMaker : false,
            selectFri : false,
            selectSelf : false,
            selectEnemy : false,
            targetNumber : 0,
            arms : ['骑','步','弓'],
            wait : 0,
            execute(self){
                
            }
        }
        let arm = target.up_arms[target.choseArms];
        if(arm == '长枪兵'){
            skill.name = '长枪兵'
            let block = new DamageBlock(target,skill,'所有',true,8,false,false,99,0);
            let work = block.Init(target,null,false,2);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【长柄】效果已施加`);
            }
        }
        else if(arm == '禁卫'){
            skill.name = '禁卫'
            let block = new DamageBlock(target,skill,'所有',false,-6,false,false,99,0);
            let work = block.Init(target,null,false,2);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【铁意】效果已施加`);
            }
            let value = 8;
            if(self.position == '2'){
                value = 12;
            }
            let block1 = new DamageBlock(target,skill,'追击',false,-value,false,false,99,0);
            let work1 = block1.Init(target,null,false,2);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,target,`【护主】效果已施加`);
            }
        }
        else if(arm == '重步兵'){
            skill.name = '重步兵'
            let block = new AttrBlock(target,skill,'速度',-15,99,false);
            let work = block.Init(target,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【负重】效果已施加`);
            }
            let block1 = new DamageBlock(target,skill,'主动',false,-12,false,false,99,0);
            let work1 = block1.Init(target,null,false,2);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,target,`【披甲】效果已施加`);
            }
        }
        else if(arm == '蛮兵'){
            skill.name = '蛮兵'
            let block = new DamageBlock(target,skill,'所有',true,8,false,false,99,0);
            let work = block.Init(target,null,false,2);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【野战】效果已施加`);
            }
        }
        else if(arm == '藤甲兵'){
            skill.name = '惧火'
            let block = new DamageBlock(target,skill,'所有',false,-30,false,false,99,0);
            let work = block.Init(target,null,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【藤甲】效果已施加`);
            }
            let action = function(){
                let tgt = this.beExecuted;
                for(let i in tgt.SPECIAL_STATUS){
                    if(tgt.SPECIAL_STATUS[i].type == '燃烧' || tgt.SPECIAL_STATUS[i].type == '火攻'){
                        let block = new StatusBlock(target,skill,'暴走',0,0);
                        let work = block.Init(target,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,target,target,`【惧火】令`,`陷入暴走`);
                            break;
                        }
                    }
                }
            }
            let block1 = new ExecuteBlock(target,skill,'行动开始',action,99);
            block1.Init(target,false);
            let r = new Report();
            r.InitExecuteReport(2,target,`【惧火】效果已施加`);
        }
        else if(arm == '长弓兵'){
            skill.name = '先发'
            let block = new RangeBlock(target,skill,true,1,99,0);
            let work = block.Init(target,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【远攻】效果已施加`);
            }
            let block1 = new StatusBlock(target,skill,'先手',2,0);
            let work1 = block1.Init(target,false);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,target,`【先发】效果已施加`);
            }
        }
        else if(arm == '弩兵'){
            skill.name = '弩弓'
            let block = new DamageBlock(target,skill,'所有',true,8,false,false,99,0);
            let work = block.Init(target,null,false,2);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【弩弓】效果已施加`);
            }
        }
        else if(arm == '死士'){
            skill.name = '先发'
            let block = new RangeBlock(target,skill,true,-1,99,0);
            let work = block.Init(target,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,target,`【短兵】效果已施加`);
            }
            for(let i=0;i<3;i++){
                let attrType = ''
                switch(i){
                    case 0 : attrType = '攻击'; break;
                    case 1 : attrType = '防御'; break;
                    case 2 : attrType = '谋略'; break;
                }
                let block = new AttrBlock(target,skill,attrType,18,99,false);
                block.Init(target,false);
            }
            let r = new Report();
            r.InitExecuteReport(2,target,`【无畏】效果已施加`);
        }
        else if(arm == '轻骑兵'){
            skill.name = '冲阵';
            let block = new AttrBlock(target,skill,'速度',18,99,false);
            block.Init(target,false);
            let r = new Report();
            r.InitExecuteReport(2,target,`【轻装】效果已施加`);
            for(let i=0;i<4;i++){
                let block = new DamageBlock(target,skill,'所有',true,18,true,false,99,0);
                block.Init(target,null,false);
            }
            let r1 = new Report();
            r1.InitExecuteReport(2,target,`【冲阵】效果已施加`);
        }
        else if(arm == '铁骑兵'){
            skill.name = '不屈'
            let block1 = new DamageBlock(target,skill,'所有',false,-10,false,false,99,0);
            let work1 = block1.Init(target,null,false,2);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,target,`【不屈】效果已施加`);
            }
        }
        else if(arm == '象兵'){
            skill.name = '野性'
            let block = new StatusBlock(target,skill,'不受指挥战法影响',99,0);
            block.Init(target,false);
            let block1 = new DamageBlock(target,skill,'所有',false,-10,false,false,99,0);
            let work1 = block1.Init(target,null,false,2);
            if(work1){
                let r = new Report();
                r.InitExecuteReport(2,target,`【野性】效果已施加`);
            }
        }
        else if(arm == '重骑兵'){
            skill.name = '迎击'
            let block = new CounterAttackBlock(target,skill,'反击',75,100,2,0);
            block.Init(target,false);
            let r = new Report();
            r.InitExecuteReport(2,target,`【迎击】效果已施加`);
        }
        else if(arm == '弓骑兵'){
            skill.name = '骑射'
            let block = new RangeBlock(target,skill,false,1,99,0);
            block.Init(target,false);
            let r = new Report();
            r.InitExecuteReport(2,target,`【骑射】效果已施加`);
        }
    }
    //四大营额外效果施加
    InitExtraSpecial(targetTeam){
        for(let i=0;i<4;i++){
            let skill = {
                id : -1,
                name : "",
                type : "满级兵营",
                odd : 100,
                range : 0,
                isDamageMaker : false,
                isControlMaker : false,
                isCureMaker : false,
                selectFri : false,
                selectSelf : false,
                selectEnemy : false,
                targetNumber : 0,
                arms : ['骑','步','弓'],
                wait : 0,
                execute(self){
                    
                }
            }
            //疾风营
            if(i == 0){
                for(let j in targetTeam){
                    skill.name = '疾风营';
                    let block = new AttrBlock(targetTeam[j],skill,'速度',10,1,false);
                    block.Init(targetTeam[j],false,0);
                }
            }
            //铁壁营
            else if(i == 1){
                skill.name = '铁壁营';
                let rand = Math.floor(Math.random()*3);
                let tgt = targetTeam[rand];
                let block = new DodgeBlock(tgt,skill,1);
                block.Init(tgt,false);
            }
            //军机营
            else if(i == 2){
                skill.name = '军机营';
                let rand = Math.floor(Math.random()*3);
                let tgt = targetTeam[rand];
                let block = new StatusBlock(tgt,skill,'洞察',1,0);
                block.Init(tgt,false);
            }
            //尚武营
            else if(i == 3){
                skill.name = "尚武营";
                let rand = Math.floor(Math.random()*3);
                let tgt = targetTeam[rand];
                let block = new StatusBlock(tgt,skill,'无视兵种',1,0);
                block.Init(tgt,false);
            }
        }
    }
    //初始化
    Init(){
        //清空上一局战报
        this.entity.ReportHeap = [];
        //清空上一局统计
        let s = new Statistics(this.entity);
        s.Init();
        let u = new Util();
        let q = new Query();
        //导入武将基本数据
        let left = [];
        let right = [];
        for(let i in this.entity.BlueTeam.warriorArr){
            if(this.entity.BlueTeam.warriorArr[i].id != -1){
                let w = new Warrior(this.entity.BlueTeam.warriorArr[i],i,"blue",this.entity,this);
                this.LoadTactics(w,this.entity.BlueTeam.warriorArr[i])
                left.push(w);
            }
        }
        for(let i in this.entity.RedTeam.warriorArr){
            if(this.entity.RedTeam.warriorArr[i].id != -1){
                let w = new Warrior(this.entity.RedTeam.warriorArr[i],i,"red",this.entity,this);
                this.LoadTactics(w,this.entity.RedTeam.warriorArr[i]);
                right.push(w);
            }
        }
        this.Team.push(left);this.Team.push(right);
        //设置武将的友军数组索引和敌军数组索引
        for(let i in this.Team[0]){
            this.Team[0][i].ally = this.Team[0];
            this.Team[0][i].enemy = this.Team[1];
        }
        for(let i in this.Team[1]){
            this.Team[1][i].ally = this.Team[1];
            this.Team[1][i].enemy = this.Team[0];
        }

        //每个武将开始获取其加成
        for(let i in this.Team){
            let getTrammel = this.InitTrammelsAddition(this.Team[i][0]);
            for(let j in this.Team[i]){
                let report = new Report();
                report.InitExecuteReport(1,this.Team[i][j],"获得加成");
                //原始属性
                let ad = q.GetAttr(this.Team[i][j],"攻击");
                let de = q.GetAttr(this.Team[i][j],"防御");
                let tact = q.GetAttr(this.Team[i][j],"谋略");
                let speed = q.GetAttr(this.Team[i][j],"速度");
                //载入兵种
                this.InitArmsAddition(this.Team[i][j],this.Team[i]);
                //阵营加成
                this.InitCampAddition(this.Team[i][j],ad,de,tact,speed);
                //四大营加成
                this.InitExternalAddition(this.Team[i][j]);
                //称号加成
                if(getTrammel){
                    this.Team[i][j].attr.ad += getTrammel.plus.ad;
                    this.Team[i][j].attr.de += getTrammel.plus.de;
                    this.Team[i][j].attr.tact += getTrammel.plus.tact;
                    this.Team[i][j].attr.speed += getTrammel.plus.speed;
                    let r = new Report();
                    r.InitExecuteReport(2,this.Team[i][j],`获得${getTrammel.name}加成`);
                }
                //高级兵种特性
                this.InitArmSkill(this.Team[i][j]);
            }
        }
        //执行四大营额外效果
        for(let i in this.Team){
            this.InitExtraSpecial(this.Team[i]);
        }
        //输出武将的面板属性
        this.OutputWarriorAttr();
        //执行武将的被动战法
        for(let i in this.Team){
            for(let j in this.Team[i]){
                for(let k in this.Team[i][j].TacticsArr){
                    if(this.Team[i][j].TacticsArr[k].type == '被动'){
                        let report = new Report();
                        report.InitExecuteReport(1,this.Team[i][j],`执行【${this.Team[i][j].TacticsArr[k].name}】！`)
                        this.Team[i][j].TacticsArr[k].execute(this.Team[i][j]);
                    }
                }
            }
        }
    }
    
    //对局前准备,指挥战法执行阶段
    PrepareBattle(){
        let u = new Util();
        let r = new Report();
        r.InitSysMsg(this.entity.ReportHeap,"系统",`战前准备回合`);
        let i = 1;
        while(i<= 6){
            let executor = u.GetExecutor(this.Team);
            if(executor == null){
                console.log('wrong')
                return;
            }
            //对应武将执行其回合
            for(let j in executor.TacticsArr){
                if(executor.TacticsArr[j].type == '指挥'){
                    let report = new Report();
                    report.InitExecuteReport(1,executor,`执行【${executor.TacticsArr[j].name}】！`)
                    executor.TacticsArr[j].execute(executor);
                }
            }
            executor.action = false;
            i++;
        } 
        //重置武将的行动力
        for(let i in this.Team){
            for(let j in this.Team[i]){
                this.Team[i][j].action = true;
            }
        }  
    }
    //执行8回合对局
    Execute(){
        for(let i = 0;i<8;i++){
            let r = new Report();
            r.InitSysMsg(this.entity.ReportHeap,"系统",`第${i+1}回合`);
            let m = new Manage();
            m.ManageExecuteSysBlock(this);
            this.ExecuteOneTurn();
            if(!this.Team[0][0].alive){
                let r = new Report();
                r.InitExecuteReport(1,this.Team[0][0],`大营阵亡，对局结束`);
                break;
            }
            else if(!this.Team[1][0].alive){
                let r = new Report();
                r.InitExecuteReport(1,this.Team[1][0],`大营阵亡，对局结束`);
                break;
            }
            //本回合武将操作结束
            this.turn += 1;
            //重置武将的行动力
            for(let i in this.Team){
                for(let j in this.Team[i]){
                    this.Team[i][j].action = true;
                }
            }
        }
        //游戏结束
        this.entity.battleResult = this.Team;
        //统计
        let s = new Statistics(this.entity);
        s.BuildDataMatrices(this.Team,this);

        this.entity.isShowBattleResult = true;
        let r = new Report();
        r.InitSysMsg(this.entity.ReportHeap,"系统",`游戏结束`);

        console.log(this);
    }
    //执行一回合操作
    ExecuteOneTurn(){
        let u = new Util();
        let m = new Manage();
        //执行回合开始的执行函数
        let j = 1;
        while(j<= 6){
            let executor = u.GetExecutor(this.Team);
            if(executor == null){
                break;
            }
            //对应武将执行其回合
            m.ManageExecuteBlock(executor,'回合开始');
            executor.action = false;
            j++;
        } 
        //重置武将的行动力
        for(let i in this.Team){
            for(let j in this.Team[i]){
                this.Team[i][j].action = true;
            }
        }  
        //让所有武将执行他的回合
        let i = 1;
        while(i<= 6){
            let executor = u.GetExecutor(this.Team);
            if(executor == null) return;
            if(!this.Team[0][0].alive){
                return;
            }
            else if(!this.Team[1][0].alive){
                return;
            }
            //对应武将执行其回合
            executor.Execute();
            i++;
        }   
    }
    //将战报写入战报板块
    PushReport(){
        let currentTime = new Date(); // 创建一个表示当前时间的Date对象
        let year = currentTime.getFullYear(); // 获取年份
        let month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（注意要加上1）
        let day = currentTime.getDate().toString().padStart(2, '0'); // 获取天数
        let hours = currentTime.getHours().toString().padStart(2, '0'); // 获取小时
        let minutes = currentTime.getMinutes().toString().padStart(2, '0'); // 获取分钟
        let seconds = currentTime.getSeconds().toString().padStart(2, '0'); // 获取秒数
        
        // 组合为完整的日期和时间字符串
        let timeString = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        let s = new Statistics(this.entity);
        let sta = s.CopyDataMatrices(this.entity.Statistics);
        let item = {
            battleResult: this.entity.battleResult,
            ReportHeap : this.entity.ReportHeap,//战报堆
            //结果统计
            Statistics : sta,
            time : timeString,
        }
        this.entity.ReportContainer.unshift(item);
        if(this.entity.ReportContainer.length > 30){
            this.entity.ReportContainer.pop();
        }
    }





}

export default Driver;