import Report from "../js/report";

class CounterAttackBlock{
    constructor(applicator,tactics,type,damageValue,damageOdd,duration,waitTime){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //类型
        this.type = type;
        //伤害率
        this.damageValue = damageValue;
        //触发率
        this.damageOdd = damageOdd;
        //持续时间
        this.duration = duration;
        //等待时间
        this.waitTime = waitTime;
    }
    Init(target,needReport = true,reportLevel = 3){
        //冲突检测
        for(let i in target.COUNTER_ATTACK){
            if(target.COUNTER_ATTACK[i].type == this.type){
                let r = new Report();
                r.InitExecuteReport(3,target,`已存在${this.type}效果`);
                return false;
            }
        }
        target.COUNTER_ATTACK.push(this);
        if(needReport){
            let r = new Report();
            r.InitExecuteReport(reportLevel,target,`获得${this.type}能力`);
        }
        return true;
    }
}

export default CounterAttackBlock;