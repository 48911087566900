class Tactics{
    constructor(owner,data){
        //战法持有者
        this.owner = owner;
        //战法名称
        this.name = data.name;
        //战法类型
        this.type = data.type;
        //战法发动几率
        this.odd = data.odd;
        //战法范围
        this.range = data.range;
        //是否伤害型战法
        this.isDamageMaker = data.isDamageMaker;
        //是否控制型
        this.isControlMaker = data.isControlMaker
        //是否治疗形战法
        this.isCureMaker = data.isCureMaker;
        //指定友军
        this.selectFri = data.selectFri;
        //指定自己
        this.selectSelf = data.selectSelf;
        //指定敌军
        this.selectEnemy = data.selectEnemy;
        //作用目标数量
        this.targetNumber = data.targetNumber;
        //战法作用兵种
        this.arms = data.arms;
        //战法等待时间
        this.wait = data.wait;
        //统计
        this.STATISTICS = {
            damage : 0,
            cure : 0,
            times : 0,
        }
        //执行函数
        this.execute = data.execute;
    }
}
export default Tactics;