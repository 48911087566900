<template>
    <div class="team">
        <!-- <div class="close_btn" v-if="$store.state.equipment == 'computer'" @click="$router.back()"></div> -->
        <div class="team_container" ref="team_container">
            <div class="team_box" v-for="(t,t_index) in $store.state.MyTeam" :key="t.index">
                <!-- 武将卡 -->
                <div class="team_warrior" v-for="(w,index) in $store.state.MyTeam[t_index]" :key="w.index">
                    <div class="warrior_middle_img">
                        <img :src="w.img.banner" alt="" v-show="w.id" class="">
                    </div>
                    <ul class="star_list" v-show="w.name != ''">
                        <li v-for="s in w.red" :key="s.index"><img src="../../assets/ui/ui (74).png" alt="" class="cover"></li>
                        <li v-for="s in (5-w.red)" :key="s.index"><img src="../../assets/ui/ui (76).png" alt="" class="cover"></li>
                    </ul>
                    <div class="black_tape black_tape_wu" v-show="w.id && w.camp == '吴'">{{w.name}}</div>
                    <div class="black_tape black_tape_wei" v-show="w.id && w.camp == '魏'">{{w.name}}</div>
                    <div class="black_tape black_tape_shu" v-show="w.id && w.camp == '蜀'">{{w.name}}</div>
                    <div class="black_tape black_tape_qun" v-show="w.id && w.camp == '群'">{{w.name}}</div>
                    <div class="black_tape black_tape_han" v-show="w.id && w.camp == '汉'">{{w.name}}</div>
                    <div class="black_tape black_tape_jin" v-show="w.id && w.camp == '晋'">{{w.name}}</div>
                    <div class="black_tape_bottom"></div>
                    <!-- 觉醒 -->
                    <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-show="w.isUp">
                    <img src="../../assets/ui/ui (84).png" alt="" class="up_mark" v-show="w.isUpWork">
                    <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-show="w.isUp && w.isUpWork">
                    <!-- 兵种 -->
                    <div class="arm" v-if="w.id != -1">
                        <img src="../../assets/ui/ui (81).png" alt="" v-show="!w.isUpArm" class="cover">
                        <img src="../../assets/ui/ui (82).png" alt="" v-show="w.isUpArms" class="cover">
                        <img src="../../assets/ui/ui (79).png" alt="" v-show="w.arms == '步' && !w.isUpArms" class="icon">
                        <img src="../../assets/ui/ui (70).png" alt="" v-show="w.arms == '骑' && !w.isUpArms" class="icon">
                        <img src="../../assets/ui/ui (62).png" alt="" v-show="w.arms == '弓' && !w.isUpArms" class="icon">

                        <img src="../../assets/ui/ui (71).png" alt="" v-show="w.arms == '骑' && w.isUpArms" class="icon">
                        <img src="../../assets/ui/ui (80).png" alt="" v-show="w.arms == '步' && w.isUpArms" class="icon">
                        <img src="../../assets/ui/ui (63).png" alt="" v-show="w.arms == '弓' && w.isUpArms" class="icon">
                        <div class="arm_num">{{w.range}}</div>
                    </div>
                    <!-- 武将标志 -->
                    <div class="warrior_sign" v-if="w.id != -1">
                        <img src="../../assets/ui/ui (66).png" alt="" v-show="w.sign == 's2'" class="cover">
                        <img src="../../assets/ui/ui (67).png" alt="" v-show="w.sign == 's3'" class="cover">
                        <img src="../../assets/ui/ui (68).png" alt="" v-show="w.sign == 'sp'" class="cover">
                        <img src="../../assets/ui/ui (69).png" alt="" v-show="w.sign == 'xp'" class="cover">
                    </div>
                    <!-- <img src="../../assets/ui/ui (61).png" alt="" class="cost_img" v-show="w.id"> -->
                    <div class="position_sign">
                        <span v-if="index == 0">大营</span>
                        <span v-if="index == 1">中军</span>
                        <span v-if="index == 2">前锋</span>
                    </div>
                </div>
                <!-- 编辑队伍按钮 -->
                <div class="build_team_btn" ref="build_team_btn" @touchstart="$refs.build_team_btn[t_index].classList.add('click')" 
                @touchend="$refs.build_team_btn[t_index].classList.remove('click')"  @click="EditTeam(t_index)">
                    <div class="build_team_btn_img"></div>
                    <div class="build_team_btn_font">部队</div>
                </div>
            </div>
        </div>

        <!-- 添加队伍 -->
        <div class="add_team_btn" @touchstart='$refs.add_team_btn.classList.add("active")'  
        @touchend='$refs.add_team_btn.classList.remove("active")' ref="add_team_btn"
        @click="AddNewTeam()">
            <div class="text">{{ addTeamBtnStr }}</div>
        </div>
        <!-- 保存队伍数据按钮 -->
        <div class="save_team_data_btn" @touchstart='$refs.save_team_data_btn.classList.add("active")' ref="save_team_data_btn"  
        @touchend='$refs.save_team_data_btn.classList.remove("active")'
        @click="SaveData()">
            <div class="text">保存</div>
        </div>
        <!-- 请空队伍数据按钮 -->
        <div class="clear_team_data_btn" @touchstart='$refs.clear_team_data_btn.classList.add("active")' ref="clear_team_data_btn"  
        @touchend='$refs.clear_team_data_btn.classList.remove("active")'
        @click="ClearTeam()">
            <div class="text">{{ clearTeamStr }}</div>
        </div>


        <ConfigTeamVue :currentTeam="currentTeam" :currentTeamIndex="currentTeamIndex" v-show="configTeamShow"/>

    </div>
</template>

<script>
import ConfigTeamVue from './ConfigTeam.vue';
import ConfigTeam from '../../util/configTeam';
import Load from '../../util/load.js';
import Save from '../../util/save.js';
export default {
    name : 'MyTeam',
    components:{
        ConfigTeamVue
    },
    data(){
        return{
            //控制配置面板显示
            configTeamShow : false,
            currentTeam : [],
            currentTeamIndex : -1,

            tipStr : "",
            isAlert : false,

            addTeamBtnStr : "添加队伍",
            clearTeamStr : "清除队伍",
        }
    },
    methods:{
        EditTeam(index){
            //value = true表示打开
            this.currentTeamIndex = index;
            this.currentTeam = this.$store.state.MyTeam[index];
            this.configTeamShow = true;
        },
        CloseEditTeam(){
            this.configTeamShow = false;
        },
        //添加一个新队伍
        AddNewTeam(){
            let distance = (window.innerWidth/5) + 10;
            setTimeout(()=>{
                this.$refs.team_container.scrollTo(0,distance*(this.$store.state.MyTeam.length-5));
            },10)
            this.addTeamBtnStr = "添加中"
            let c = new ConfigTeam(this);
            c.ConfigOneTeam();
            setTimeout(() => {
                this.addTeamBtnStr = "添加队伍"
            }, 10);
        },
        //清除队伍
        ClearTeam(){
            let that = this;
            let f = function(){
                let c = new ConfigTeam(that);
                c.ClearTeamList();
                that.isAlert = false;
                that.clearTeamStr = "清除中"
                setTimeout(() => {
                    that.clearTeamStr = "清除队伍"
                    that.$bus.$emit('alert','清除队伍成功');
                }, 10);
            }
            let params = {
                text : '确定清除所有队伍？',
                callBack : f,
            }
            this.$store.commit('Alert',params);
            // this.isAlert = true;
        },
        SaveData(){
            let save = new Save(this);
            save.SaveMyTeam();
            this.$bus.$emit('alert','保存成功');
        }
    },
    async mounted() {
        this.$bus.$on('CloseEditTeam',this.CloseEditTeam);
        let load = new Load(this);
        await load.LoadMyTeam();
        this.clearTeamStr = "清除中"
        setTimeout(() => {
            this.clearTeamStr = "清除队伍"
        }, 5);
    },
}
</script>

<style  lang="less">
@import url('./team.less');
</style>