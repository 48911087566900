import CounterAttackBlock from "../block/counterAtk";
import ExecuteBlock from "../block/execute";
import StatusBlock from "../block/status";
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import Report from "../js/report";
import Util from "../js/util";
import DamageStatus from '../block/damageStatus.js'
import DamageBlock from '../block/damage.js'
import AfterDamageBlock from "../block/execute1.js";
import AttrBlock from "../block/attr.js";
let wuBArr = [
    //孙坚
    {
        id : 17,
        name : "遗志",
        type : "指挥",
        odd : 100,
        range : 6,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let c = new Count();
            let a = new Attack();
            let u = new Util();
            let cureValue = 10000;//治疗率
            let cureIndex = 0;
            let damageValue = 85;

            let target = self.ally[2];
            if(u.TransferArms(target.up_arms[target.choseArms]) == 'q'){
                let r = new Report();
                r.InitExecuteReport(2,target,`不满足【遗志】触发条件`);
                this.STATISTICS.times += 1;
                return false;
            }
            
            
            let r1 = new Report();
            r1.InitExecuteReport(2,target,`【遗志】效果已施加`);
            //施加反击效果
            let block1 = new CounterAttackBlock(self,that,"反击",damageValue,100,99,3);
            let avail = block1.Init(target,false);
            let r2 = new Report();
            r2.InitExecuteReport(2,target,`获得反击能力(预备)`);

            let action = function(){
                if(self.driver.turn == 3){
                    let cure = c.CountCure(self,target,cureValue,cureIndex,that);
                    let work = a.GetCure(self,target,cure,that,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,target,'【遗志】令',`恢复${cure}兵力(${target.hp})`);
                    }
                    //施加围困效果
                    let block2 = new StatusBlock(self,that,"围困",99);
                    let work1 =  block2.Init(target,false);
                    if(work1){
                        let r = new Report();
                        r.InitExecuteReport(2,target,`不可回复兵力`)
                    }
                    if(avail){
                        let r = new Report();
                        r.InitExecuteReport(2,target,`获得反击能力`)
                    }
                }
            }
            let block = new ExecuteBlock(self,this,"行动开始",action,3);
            block.Init(target);
            this.STATISTICS.times += 1;
        }
    },
    //周姬
    {
        id : 28,
        name : "复誓业火",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let c = new Count();
            let a = new Attack();
            let q = new Query();
            let damageValue = q.GetAdditionValue(self,114,"谋略",0.95);//直接火攻伤害
            let damageValue2 = q.GetAdditionValue(self,114,"谋略",0.95);//火攻伤害
            let index = q.GetAdditionValue(self,16,"谋略",0.06);//增伤数值

            let realRang = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRang,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,"火攻");
                a.GetHurt(self,res[i],damage,false,"谋略",this,true);
                let status = new DamageStatus(self,this,"火攻",damageValue2,"谋略",1,0);
                status.Init(res[i]);
                let block = new DamageBlock(self,this,"谋略",false,index,false,false,2);
                block.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //孙尚香
    {
        id : 29,
        name : "枭姬",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let c = new Count();
            let a = new Attack();
            let u = new Util();
            let q = new Query();
            let damageValue = 160;//第一段伤害
            let damageValue2 = 140;//第二段伤害

            let realRang = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(3,self,this);
            let res = a.SelectTarget(self,realRang,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true);
            }
            let r = new Report();
            r.InitExecuteReport(3,self,`再次发动攻击`);
            let rand = Math.floor(Math.random()*2);
            let res2 = a.SelectTarget(self,realRang,false,true,rand + 1,false,true,this);
            for(let i in res2){
                let damage = c.CountDamage(self,res2[i],damageValue2,"攻击",false,this,null);
                a.GetHurt(self,res2[i],damage,false,"攻击",this,true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //步皇后
    {
        id : 53,
        name : "中宫追玺",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let value = 60;

            let realRang = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRang,true,false,3,true,false,this);
            let action1 = function(){
                if(this.damageType == '攻击'){
                    let target = this.beExecuted;
                    for(let i in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i].damageType == '攻击' && target.DAMAGE_VAR[i].value < 0){
                            let index = value/5;
                            target.DAMAGE_VAR[i].value += index;
                            if(target.DAMAGE_VAR[i].value == 0){
                                let temp = [];
                                for(let j in target.AFTER_DAMAGE){
                                    if(target.AFTER_DAMAGE[j] != this) temp.push(target.AFTER_DAMAGE[j]);
                                    else{
                                        let r = new Report();
                                        r.InitTwoTargetReport(3,self,target,`【中宫追玺】令`,`受到攻击伤害已降低最低`);
                                    }
                                }
                                target.AFTER_DAMAGE = temp;
                            }
                            else{
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,target,`【中宫追玺】令`,`受到攻击伤害降低${Math.abs(target.DAMAGE_VAR[i].value)}%`);
                            }
                            break;
                        }
                    }
                }
            }
            let action2 = function(){
                if(this.damageType == '谋略'){
                    let target = this.beExecuted;
                    for(let i in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[i].tactics == that && target.DAMAGE_VAR[i].damageType == '谋略' && target.DAMAGE_VAR[i].value < 0){
                            let index = value/5;
                            target.DAMAGE_VAR[i].value += index;
                            if(target.DAMAGE_VAR[i].value == 0){
                                let temp = [];
                                for(let j in target.AFTER_DAMAGE){
                                    if(target.AFTER_DAMAGE[j] != this) temp.push(target.AFTER_DAMAGE[j]);
                                    else{
                                        let r = new Report();
                                        r.InitTwoTargetReport(3,self,target,`【中宫追玺】令`,`受到策略伤害已降低最低`);
                                    }
                                }
                                target.AFTER_DAMAGE = temp;
                            }
                            else{
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,target,`【中宫追玺】令`,`受到策略伤害降低${Math.abs(target.DAMAGE_VAR[i].value)}%`);
                            }
                            break;
                        }
                    }
                }
            }
            for(let i in res){
                let block1 = new DamageBlock(self,this,'攻击',false,-value,false,false,99,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到攻击伤害降低${value}%`);
                    let block = new AfterDamageBlock(self,this,action1,99,true,true,true,true,true,true,true);
                    block.Init(res[i],"中宫追玺1");
                }
                let block2 = new DamageBlock(self,this,'谋略',false,-value,false,false,99,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到策略伤害降低${value}%`);
                    let block = new AfterDamageBlock(self,this,action2,99,true,true,true,true,true,true,true);
                    block.Init(res[i],"中宫追玺2");
                }
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //张昭
    {
        id : 108,
        name : "竭忠尽智",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let c = new Count();
            let a = new Attack();
            let q = new Query();
            let value1 = q.GetAdditionValue(self,20,'谋略',0.1);
            let value2 = q.GetAdditionValue(self,30,'谋略',0.22);
            
            let realRang = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRang,true,false,2,true,true,this);
            //怯战
            let minAd = 100000;
            let minAdTarget = null;
            for(let i in self.ally){
                if(self.ally[i] != self && self.ally[i].alive){
                    let ad = q.GetAttr(self.ally[i],'攻击');
                    if(ad < minAd){
                        minAd = ad;
                        minAdTarget = self.ally[i];
                    }
                    else if(ad == minAd){
                        let rand = Math.floor(Math.random()*2);
                        if(rand == 1){
                            minAd = ad;
                            minAdTarget = self.ally[i];
                        }
                    }
                }
            }
            let fri = [self];
            if(minAdTarget != null) fri.push(minAdTarget);
            for(let i in fri){
                let block = new StatusBlock(this.execute,this,'怯战',1,0);
                block.Init(fri[i],true,3);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'攻击',false,-value1,false,false,2,0);
                block.Init(res[i],null,)
                for(let j=0;j<2;j++){
                    let block = new DamageBlock(self,this,'谋略',true,value2,true,false,99,0);
                    block.Init(res[i],null,false,3);
                }
                let r = new Report();
                r.InitExecuteReport(3,res[i],`下两次策略攻击伤害提高${value2}%`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //sp黄盖
    {
        id : 5094,
        name : "焚舟叩营",
        type : "追击",
        odd : 35,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self,target){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 210 + Math.floor(Math.random()*100);//基础伤害率
            let value = q.GetAttr(self,'攻击') * 0.5;
            //造成伤害
            let damage = c.CountDamage(self,target,damageValue,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);
            
            let block = new StatusBlock(self,this,"犹豫",2,0);
            block.Init(target,true);
            //攻击属性降低50%
            let block1 = new AttrBlock(self,this,"攻击",-value,0);
            block1.Init(self,true);
            this.STATISTICS.times += 1;
        }
    },
]

export default wuBArr;