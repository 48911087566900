import CureStatusBlock from "../block/cureStatus";
import DamageBlock from "../block/damage";
import DamageStatus from "../block/damageStatus";
import DodgeBlock from "../block/dodge";
import ExecuteBlock from "../block/execute";
import AfterDamageBlock from "../block/execute1";
import ActiveTacticsBlock from "../block/execute3";
import FallIntoContinueDamageExecuteBlock from "../block/fallIntoCtnDmgExec";
import GetContinueDamageExecuteBlock from "../block/getCtnDmgExec";
import StatusBlock from "../block/status";
import Attack from "../js/attack";
import Count from "../js/count";
import Judge from "../js/judge";
import Query from "../js/query";
import Report from "../js/report";
import Util from "../js/util";

let wuSArr = [
    //孙权
    {
        id : 3,
        name : "九锡黄龙",
        type : "主动",
        odd : 35,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let u = new Util();
            let q = new Query();
            let a = new Attack();
            let realRange = q.GetRealTacticsOdd(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,true,true,this);
            for(let i in res){
                let work = u.ClearStatus(res[i],this,false,3);
                if(!work){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`没有效果可以移除`);
                }
            }
            for(let i in res){
                for(let j=0;j<2;j++){
                    let block = new DodgeBlock(self,this,99);
                    block.Init(res[i]);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //吕蒙
    {
        id : 9,
        name : "白衣渡江",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageIndex = q.GetAdditionValue(self,215,'谋略',2.25);

            let realRange = q.GetRealTacticsOdd(self,this);
            //封住普通攻击
            let realTargetNumber1 = q.GetRealTargetNumber(2,self,this);
            let res1 = a.SelectTarget(self,realRange,false,true,realTargetNumber1,false,true,this);
            for(let i in res1){
                let block = new StatusBlock(self,this,"怯战",2);
                let work = block.Init(res1[i],false);
                if(work){
                    let report = new Report();
                    report.InitExecuteReport(2,res1[i],`怯战(预备)效果已施加`)
                }
            }
            //造成伤害
            let realTargetNumber2 = q.GetRealTargetNumber(3,self,this);
            let res2 = a.SelectTarget(self,realRange,false,true,realTargetNumber2,false,true,this);
            let block = new StatusBlock(self,this,'无视规避',1,0);
            block.Init(self,false);

            for(let i in res2){
                let damage = c.CountDamage(self,res2[i],damageIndex,"谋略",false,this);
                let action = function(){
                    if(self.driver.turn == 3 && this.beExecuted.alive){
                        let block = new StatusBlock(self,this.tactics,"无视规避",0);
                        block.Init(self,false);
                        let report = new Report().InitTwoTargetReport(2,self,res2[i],`【白衣渡江】令`,`损失了${damage}兵力(${res2[i].hp - damage})`,false)
                        a.GetHurt(self,res2[i],damage,false,"谋略",this.tactics,false,report);
                        let arr = [];
                        for(let i in self.STATUS){
                            if(self.STATUS[i].tactics != this.tactics) arr.push(self.STATUS[i]);
                        }
                        self.STATUS = arr;
                    }
                }
                let execute = new ExecuteBlock(self,this,"行动开始",action,3);
                execute.Init(res2[i],true);
                let report = new Report();
                report.InitExecuteReport(2,res2[i],`无视规避(预备)伤害已施加`)
            }
            let arr = [];
            for(let i in self.STATUS){
                if(self.STATUS[i].tactics != this.tactics) arr.push(self.STATUS[i]);
            }
            self.STATUS = arr;
            this.STATISTICS.times += 1;
        }
    },
    //陆逊
    {
        id : 46,
        name : "火势风威",
        type : "主动",
        odd : 40,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['步'],
        wait : 1,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let value = 111 + Math.floor((q.GetAttr(self,"谋略")-80)* 0.95);
            let value2 = 221 + Math.floor((q.GetAttr(self,"谋略")-80)* 2.45);
            let realRange = q.GetRealTacticsOdd(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let action = function(){
                if(that == this.damageFromTactics){
                    return;
                }
                let target = this.beExecuted;
                let damage = c.CountDamage(self,target,value2,"谋略",false,that,"燃烧");
                let report = new Report().InitTwoTargetReport(3,self,target,`【火势风威】令`,`损失了${damage}兵力(${target.hp-damage})`)
                a.GetHurt(self,target,damage,false,"谋略",that,false,report)
            
                //触发之后就移除执行函数
                let temp = [];
                for(let i in target.AFTER_DAMAGE){
                    if(target.AFTER_DAMAGE[i].tactics != that) temp.push(target.AFTER_DAMAGE[i]);
                    else{
                        let r = new Report();
                        r.InitExecuteReport(3,target,`【火势风威】燃烧效果消失了`)
                    }
                }
                target.AFTER_DAMAGE = temp;
            }
            for(let i in res){
                let damage = c.CountDamage(self,res[i],value,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                //引发燃烧效果
                let target = res[i];
                let temp = [];
                for(let i in target.AFTER_DAMAGE){
                    if(target.AFTER_DAMAGE[i].tactics != that) temp.push(target.AFTER_DAMAGE[i]);
                    else{
                        //先判断火势风威的燃烧还在不在
                        let damage = c.CountDamage(self,target,value2,"谋略",false,that,"燃烧");
                        let report = new Report().InitTwoTargetReport(3,self,target,`【火势风威】令`,`损失了${damage}兵力(${target.hp-damage})`)
                        a.GetHurt(self,target,damage,false,"谋略",that,false,report)
                        let r = new Report();
                        r.InitExecuteReport(3,target,`【火势风威】燃烧效果消失了`)
                    }
                }
                target.AFTER_DAMAGE = temp;
            }
            for(let i in res){
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                block.Init(res[i],"火势风威");
                let r = new Report();
                r.InitExecuteReport(3,res[i],`【火势风威】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //陆抗
    {
        id : 64,
        name : "西陵克晋",
        type : "指挥",
        odd : 100,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 50;
            let damageValue1 = 150;//基础攻击伤害率
            let damageValue2 = 150;//基础策略攻击伤害率
            let cureValue = 175;
            let cureIndex = 0;

            
            
            let action = function(){
                //选中攻击力最高的友军
                let highestAd = self;
                let highestTact = self;
                let maxAd = q.GetAttr(self,'攻击');
                let maxTact = q.GetAttr(self,'谋略');
                for(let i=0;i<3;i++){
                    if(self.ally[i].alive && q.GetAttr(self.ally[i],'攻击') > maxAd){
                        highestAd = self.ally[i];
                        maxAd = q.GetAttr(self.ally[i],'攻击');
                    }
                    if(self.ally[i].alive && q.GetAttr(self.ally[i],'谋略') > maxTact){
                        highestTact = self.ally[i];
                        maxTact = q.GetAttr(self.ally[i],'谋略');
                    }
                }
                let action1 = function(){
                    let realOdd = q.GetMoraleOdd(highestAd,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let r = new Report();
                        r.InitExecuteReport(2,highestAd,`执行【西陵克晋】`);
                        //造成攻击伤害
                        let res = a.SelectTarget(highestAd,4,false,true,1,false,true,that);
                        for(let i in res){
                            let damage = c.CountDamage(highestAd,res[i],damageValue1,'攻击',false,that,null);
                            a.GetHurt(highestAd,res[i],damage,false,'攻击',that,true,null);
                        }
                        //恢复兵力
                        let cure = c.CountCure(highestAd,highestAd,cureValue,cureIndex,that);
                        a.GetCure(highestAd,highestAd,cure,that,true);
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(2,highestAd,`【西陵克晋】没有生效，生效几率为${realOdd}%`);
                    }
                }
                let block1 = new ExecuteBlock(self,that,'行动开始',action1,1);
                block1.Init(highestAd);
                let action2 = function(){
                    let realOdd = q.GetMoraleOdd(highestTact,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let r = new Report();
                        r.InitExecuteReport(2,highestTact,`执行【西陵克晋】`);
                        //造成策略伤害
                        let res = a.SelectTarget(highestTact,4,false,true,1,false,true,that);
                        let realDamageValue = damageValue2 + Math.floor((q.GetAttr(highestTact,'谋略')-80)*1.25);
                        for(let i in res){
                            let damage = c.CountDamage(highestTact,res[i],realDamageValue,'谋略',false,that,null);
                            a.GetHurt(highestTact,res[i],damage,false,'谋略',that,true,null);
                        }
                        //恢复兵力
                        let cure = c.CountCure(highestTact,highestTact,cureValue,cureIndex,that);
                        a.GetCure(highestTact,highestTact,cure,that,true);
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(2,highestTact,`【西陵克晋】没有生效，生效几率为${realOdd}%`);
                    }
                }
                let block2 = new ExecuteBlock(self,that,'行动开始',action2,1);
                block2.Init(highestTact);
                let r1 = new Report();
                r1.InitExecuteReport(1,highestAd,`【西陵克晋】效果已施加`);
                let r2 = new Report();
                r2.InitExecuteReport(1,highestTact,`【西陵克晋】效果已施加`);

            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,true);
            let r = new Report();
            r.InitExecuteReport(2,self,`【西陵克晋】护主效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //黄盖
    {
        id : 94,
        name : "烈火焚舟",
        type : "追击",
        odd : 120,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = q.GetAdditionValue(self,150,'谋略',1.6);//基础伤害率
            let damageValue2 = q.GetAdditionValue(self,270,'谋略',2);//基础伤害率
            let value = 10;//士气减低值
            
            //检查是否存在烈火焚舟施加的燃烧效果
            let time = 0;
            let temp  = [];
            let tag = true;
            let isHigher = false;
            for(let i in target.SPECIAL_STATUS){
                if(target.SPECIAL_STATUS[i].tactics == this && target.SPECIAL_STATUS[i].duration > 0){
                    time = target.SPECIAL_STATUS[i].duration;
                    let r = new Report();
                    r.InitExecuteReport(3,target,`【烈火焚舟】燃烧效果被引发了！`);
                    tag = false;
                    isHigher = target.SPECIAL_STATUS[i]['higher'];
                }
                else if(target.SPECIAL_STATUS[i].tactics != this) temp.push(target.SPECIAL_STATUS[i]);
            }
            //引发剩余燃烧伤害
            for(let i=0;i<time;i++){
                if(isHigher){
                    let damage = c.CountDamage(self,target,damageValue2,'谋略',false,this,'燃烧');
                    a.GetHurt(self,target,damage,false,'谋略',this,true,null);
                }
                else{
                    let damage = c.CountDamage(self,target,damageValue,'谋略',false,this,'燃烧');
                    a.GetHurt(self,target,damage,false,'谋略',this,true,null);
                }
            }
            target.SPECIAL_STATUS = temp;
            //施加燃烧效果
            if(tag){
                let block = new DamageStatus(self,this,'燃烧',damageValue,'谋略',2,0,true);
                block['higher'] = false;
                block.Init(target,true);
            }
            else{
                let block = new DamageStatus(self,this,'燃烧',damageValue2,'谋略',1,0,true);
                block['higher'] = true;
                let work =  block.Init(target,false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(3,target,`更强的燃烧效果已施加`);
                }
            }
            //施加士气降低效果
            if(!tag){
                let res = a.GetDivideForceTarget(target);
                res.push(target);
                for(let i in res){
                    if(res[i].morale > 0){
                        res[i].morale -= value;
                        let r = new Report();
                        r.InitExecuteReport(3,res[i],`士气降低${value}(${res[i].morale})`);
                    }
                }   
            }
            
            
            this.STATISTICS.times += 1;
        }
    },
    //大乔
    {
        id : 96,
        name : "巧音唤蝶",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue1 = q.GetAdditionValue(self,176,'谋略',1.95);//直接伤害
            let damageValue2 = q.GetAdditionValue(self,86,'谋略',0.825);//燃烧伤害
            let cureValue1 = 161;//直接治疗
            let cureIndex1 = 1.325;
            let cureValue2 = 82;//休整治疗
            let cureIndex2 = 0.65;
            
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            let fri = a.SelectTarget(self,realRange,true,false,2,true,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue1,'谋略',false,this,null);
                a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
            }
            //燃烧效果
            for(let i in res){
                let maxHp = q.GetMaxHp(res[i]);
                if(res[i].hp > maxHp/2){
                    let block = new DamageStatus(self,this,'燃烧',damageValue2,'谋略',1,0,true);
                    block.Init(res[i],true);
                }
            }
            //治疗
            for(let i in fri){
                let cure = c.CountCure(self,fri[i],cureValue1,cureIndex1,this);
                a.GetCure(self,fri[i],cure,this,true);
            }
            //休整
            for(let i in fri){
                let maxHp = q.GetMaxHp(fri[i]);
                if(fri[i].hp < maxHp/2){
                    let block = new CureStatusBlock(self,this,'休整',cureValue2,cureIndex2,1,0,100);
                    block.Init(fri[i],true);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //小乔
    {
        id : 119,
        name : "鸾凤和鸣",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let cureValue = 85;
            let cureIndex = 0.85;

            let realRange = q.GetTacticsRange(self,this);
            let tag = '' + self.belong + self.position;
            let action = function(){
                if(self[tag]){
                    let res = a.SelectTarget(self,realRange,true,false,2,true,true,that);
                    let r = new Report();
                    r.InitExecuteReport(3,self,`执行【鸾凤和鸣】`);
                    for(let i in res){
                        let cure = c.CountCure(self,res[i],cureValue,cureIndex,that);
                        a.GetCure(self,res[i],cure,that,true);
                    }
                }
                self[tag] = false;
            }
            let block = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
            block.Init(self,false);
            let action1 = function(){
                let res = a.SelectTarget(self,5,false,true,1,false,true,that);
                for(let i in res){
                    let target = res[i];
                    for(let j=target.STATUS.length-1;j>=0;j--){
                        if((target.STATUS[j].tactics.type == '主动' || target.STATUS[j].tactics.type == '追击') && target.STATUS[j].type == '混乱' || target.STATUS[j].type == '暴走' || target.STATUS[j].type == '怯战'){
                            target.STATUS[j].duration += 1;
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【鸾凤和鸣】令`,`${target.STATUS[j].type}时间延长一回合`);
                            break;
                        }
                    }
                }
                self[tag] = true;
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action1,99);
            block1.Init(self,false);
            self[tag] = true;
            this.STATISTICS.times += 1;
        }
    },
    //鲁肃
    {
        id : 131,
        name : "同仇敌忾",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value1 = Number(((q.GetAttr(self,'谋略')-80)*0.01 + 2).toFixed(1));//减伤幅度
            let value2 = Number(((q.GetAttr(self,'谋略')-80)*0.01 + 2).toFixed(1));//增伤幅度

            let tag1 = '' + self.belong + self.position + 1;
            let tag2 = '' + self.belong + self.position + 2;
            let res = a.SelectTarget(self,3,true,false,3,true,false,this);
            let getHurt = function(){
                let target = this.beExecuted;
                let tgtArr = a.GetDivideForceTarget(target);
                for(let i in tgtArr){
                    //叠减伤
                    if(tgtArr[i][tag1] < 10){
                        for(let j in tgtArr[i].DAMAGE_VAR){
                            if(tgtArr[i].DAMAGE_VAR[j].tactics == that && !tgtArr[i].DAMAGE_VAR[j].isMakeDamage){
                                tgtArr[i].DAMAGE_VAR[j].value -= value1;
                                tgtArr[i].DAMAGE_VAR[j].value = Number(tgtArr[i].DAMAGE_VAR[j].value.toFixed(1));
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,tgtArr[i],`【同仇敌忾】令`,`受到${tgtArr[i].DAMAGE_VAR[j].damageType}伤害降低${Math.abs(tgtArr[i].DAMAGE_VAR[j].value)}%`);
                            }
                        }
                        tgtArr[i][tag1]+=1;
                    }
                    //叠增伤
                    if(tgtArr[i][tag2] < 10){
                        for(let j in tgtArr[i].DAMAGE_VAR){
                            if(tgtArr[i].DAMAGE_VAR[j].tactics == that && tgtArr[i].DAMAGE_VAR[j].isMakeDamage){
                                tgtArr[i].DAMAGE_VAR[j].value += value2;
                                tgtArr[i].DAMAGE_VAR[j].value = Number(tgtArr[i].DAMAGE_VAR[j].value.toFixed(1));
                                let r = new Report();
                                r.InitTwoTargetReport(3,self,tgtArr[i],`【同仇敌忾】令`,`主动战法伤害提高${tgtArr[i].DAMAGE_VAR[j].value}%`);
                            }
                        }
                        tgtArr[i][tag2]+=1;
                    }
                }
            }
            for(let i in res){
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(res[i],'同仇敌忾');
                res[i][tag1] = 0;
                res[i][tag2] = 0;
                let block1 = new DamageBlock(self,that,'所有',false,0,false,false,99,0);
                block1.isBenefit = true;
                block1.Init(res[i],null,true);
                let block2 = new DamageBlock(self,that,'主动',true,0,false,false,99,0);
                block2.isBenefit = true;
                block2.Init(res[i],null,false);
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【同仇敌忾】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    //周瑜
    {
        id : 134,
        name : "衔命建功",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            // let odd1 = 50;//触发几率
            // let odd2 = 50;//触发几率
            // let damageValue = q.GetAdditionValue(self,160,'谋略',1.6);

            


            let odd = 50;//触发几率
            let damageValue = q.GetAdditionValue(self,140,'谋略',1.25);
            
            for(let i in self.enemy){
                let tag = '衔命建功' + self.enemy[i].belong + self.enemy[i].position;
                let action = function(){
                    let tgt = this.beExecuted;
                    if(!tgt[tag]){
                        let realOdd = q.GetMoraleOdd(self,odd);
                        let rand = Math.floor(Math.random()*100);
                        if(rand < realOdd){
                            let damage = c.CountDamage(self,tgt,damageValue,'谋略',false,that,null);
                            let r = new Report().InitTwoTargetReport(3,self,tgt,'【衔命建功】令',`损失了${damage}兵力(${tgt.hp-damage})`)
                            a.GetHurt(self,tgt,damage,false,'谋略',that,false,r,false);
                        }
                        else{
                            let r = new Report();
                            r.InitExecuteReport(3,self,`【衔命建功】没有生效，生效几率为${realOdd}%`);
                        }
                        tgt[tag] = 1;
                    }
                }
                let fallInto = function(){
                    let tgt = this.beExecuted;
                    let newDmgValue = this.damageValue;//获取伤害率
                    let newDmgType = this.damageType;//获取伤害类型
                    let newType = this.type;
                    let dmgOrigin = this.originWarrior;//获取伤害的施加者（这个额外伤害的计算要算到施加者上，统计算到周瑜）;
                    let damage = c.CountDamage(dmgOrigin,tgt,newDmgValue,newDmgType,false,that,newType);
                    let r = new Report().InitTwoTargetReport(3,self,tgt,'【衔命建功】令',`损失了${damage}兵力(${tgt.hp-damage})`)
                    a.GetHurt(self,tgt,damage,false,newDmgType,that,false,r,false);
                }
                let clearTag = function(){
                    self.enemy[i][tag] = 0;
                }
                let block = new GetContinueDamageExecuteBlock(self,this,action,99,true,true,true,true,true,true);
                block.Init(self.enemy[i]);
                let block1 = new ExecuteBlock(self,this,'回合开始',clearTag,99);
                block1.Init(self.enemy[i],false);
                let block2 = new FallIntoContinueDamageExecuteBlock(self,this,fallInto,99);
                block2.Init(self.enemy[i]);
                let r = new Report();
                r.InitExecuteReport(2,self.enemy[i],`【衔命建功】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
]

export default wuSArr;