<template>
    <div class="warrior_adjust_cpt">
        <div class="mask cover"></div>
        <div class="container">
            <div class="close_btn" ref="close_btn" 
            @touchstart="$refs.close_btn.classList.add('click')"
            @touchend="$refs.close_btn.classList.remove('click')" 
            @click="Close"></div>
            <div class="topic">武将配置</div>
            <div class="warrior_img" v-if="$store.state.BorderChess.currentWarriorItem">
                <img :src="$store.state.BorderChess.currentWarriorItem.img.body" alt="" class="cover">
                <img src="../../assets/background/bg (37).png" alt="" class="cover">
            </div>
            <div class="star_bar">
                <ul class="red_list" v-show="$store.state.BorderChess.currentWarriorItem.name != ''">
                    <li v-for="s in $store.state.BorderChess.currentWarriorItem.red" :key="s.index" class="red"><img class="cover" src="../../assets/ui/ui (74).png" alt=""></li>
                    <li v-for="s in (5-$store.state.BorderChess.currentWarriorItem.red)" :key="s.index"><img class="cover" src="../../assets/ui/ui (76).png" alt=""></li>
                </ul>
                <div class="sub_btn" ref="sub_red_btn" @touchstart="$refs.sub_red_btn.classList.add('touch')"
                @touchend="$refs.sub_red_btn.classList.remove('touch')"
                @click="AdjustRed(false)"></div>
                <div class="add_btn" ref="add_red_btn" @touchstart="$refs.add_red_btn.classList.add('touch')"
                @touchend="$refs.add_red_btn.classList.remove('touch')"
                @click="AdjustRed(true)"></div>
            </div>
            <div class="arms_bar">
                <div class="title">兵种选择</div>
                <div class="arms_icon">
                    <li v-for="(a,a_index) in $store.state.BorderChess.currentWarriorItem.up_arms" :key="a.index" @click="$store.state.BorderChess.currentWarriorItem.choseArms = a_index">
                        <img src="../../assets/ui/ui (5).png" alt="" class="cover" v-if="a_index == $store.state.BorderChess.currentWarriorItem.choseArms">
                        <img src="../../assets/ui/ui (5).png" alt="" class="cover filter_gray" v-if="a_index != $store.state.BorderChess.currentWarriorItem.choseArms">
                        <img :src="require(`../../assets/arms/${$store.state.BorderChess.currentWarriorItem.up_arms[a_index]}.png`)" 
                        v-if="a_index == $store.state.BorderChess.currentWarriorItem.choseArms" alt="" class="cover">
                        <img :src="require(`../../assets/arms/${$store.state.BorderChess.currentWarriorItem.up_arms[a_index]}.png`)" 
                        v-if="a_index != $store.state.BorderChess.currentWarriorItem.choseArms" alt="" class="cover filter_gray">
                    </li>
                </div>
            </div>
            <div class="level">
                <div class="title">等级</div>
                <div class="sub_btn" ref="level_sub_btn" @touchstart="$refs.level_sub_btn.classList.add('touch')"
                @touchend="$refs.level_sub_btn.classList.remove('touch')"
                @click="AdjustLevel(false)">-</div>
                <div class="num">{{ $store.state.BorderChess.currentWarriorItem.level }}</div>
                <div class="add_btn" ref="level_add_btn" @touchstart="$refs.level_add_btn.classList.add('touch')"
                @touchend="$refs.level_add_btn.classList.remove('touch')"
                @click="AdjustLevel(true)">+</div>
            </div>
            <div class="attr" v-if="$store.state.BorderChess.currentWarriorItem">
                <li v-for="i in 4" :key="i.index">
                    <div class="title">
                        <img :src="IconImgUrl(i)" alt="" class="icon">
                        {{ TextTransform(i) }}
                    </div>
                    <div class="empty_btn" ref="attr_empty_btn" @touchstart="$refs.attr_empty_btn[i-1].classList.add('touch')"
                    @touchend="$refs.attr_empty_btn[i-1].classList.remove('touch')"
                    @click="AdjustAttr(false,true,i)">空</div>
                    <div class="sub_btn" ref="attr_sub_btn" @touchstart="LongTouchedAdjustStart($refs.attr_sub_btn[i-1],false,false,i)"
                    @touchend="LongTouchedAdjustEnd($refs.attr_sub_btn[i-1])" 
                    @click="AdjustAttr(false,false,i)">-</div>

                    <div class="number">{{ RealAttr(i)}} <span>{{ "(+" + PlusAttr(i) + ")" }}</span> </div>

                    <div class="add_btn" ref="attr_add_btn" @touchstart="LongTouchedAdjustStart($refs.attr_add_btn[i-1],true,false,i)"
                    @touchend="LongTouchedAdjustEnd($refs.attr_add_btn[i-1])" 
                    @click="AdjustAttr(true,false,i)">+</div>
                    <div class="full_btn" ref="attr_full_btn" @touchstart="$refs.attr_full_btn[i-1].classList.add('touch')"
                    @touchend="$refs.attr_full_btn[i-1].classList.remove('touch')"
                    @click="AdjustAttr(true,true,i)">满</div>
                </li>
            </div>
            <!-- 剩余加点 -->
            <div class="remain_plus">剩余点数 : {{ remain }}</div>
            <!-- 卸载武将 -->
            <div class="unload_warrior_btn" ref="unload_warrior_btn" @touchstart="$refs.unload_warrior_btn.classList.add('click')"
            @touchend="$refs.unload_warrior_btn.classList.remove('click')"
            @click="UnloadWarrior()">卸下武将</div>
        </div>
    </div>
</template>

<script>
import Team from '../../util/team.js';
export default {
    name : 'WarriorAdjust',
    data(){
        return{
            touchTimer : null,
            //长按时间的定时器控制
            interValTimer : null,

            remain : 0,
        }
    },
    methods:{
        //卸载武将
        UnloadWarrior(){
            let t = new Team();
            
            t.RemoveWarrior(this.$store.state.BorderChess.currentWarriorItem);
            this.Close();
        },
        //调整武将红度
        AdjustRed(isAdd){
            let target = this.$store.state.BorderChess.currentWarriorItem;
            if(isAdd) target.red < 5 ? target.red += 1 : null;
            else{
                if(this.remain < 10){
                    target.plus.ad = 0;
                    target.plus.de = 0;
                    target.plus.tact = 0;
                    target.plus.speed = 0;
                    this.$forceUpdate();
                }
                target.red > 0 ? target.red -= 1 : null;
            }
            this.remain = this.RemainPlus();
        },
        //调整武将等级
        AdjustLevel(isAdd){
            let target = this.$store.state.BorderChess.currentWarriorItem;
            if(isAdd) target.level < 50 ? target.level += 5 : null;
            else{
                if(this.remain < 10){
                    target.plus.ad = 0;
                    target.plus.de = 0;
                    target.plus.tact = 0;
                    target.plus.speed = 0;
                    this.$forceUpdate();
                }
                target.level > 5 ? target.level -= 5 : null;
            }
            this.remain = this.RemainPlus();
        },
        //剩余加点
        RemainPlus(){
            let res = 0;
            let target = this.$store.state.BorderChess.currentWarriorItem;
            res += target.red * 10 + Math.floor(target.level/10) * 10;
            res -= target.plus.ad;
            res -= target.plus.de;
            res -= target.plus.tact;
            res -= target.plus.speed;
            return res;
        },
        //长按调整加点
        LongTouchedAdjustStart(dom,isAdd,isFull,attrType){
            dom.classList.add('touch');
            clearTimeout(this.interValTimer);
            this.interValTimer = setTimeout(() => {
                this.touchTimer = setInterval(() => {
                    this.AdjustAttr(isAdd,isFull,attrType);
                }, 50);
            }, 500);
        },
        LongTouchedAdjustEnd(dom){
            dom.classList.remove('touch');
            clearTimeout(this.interValTimer);
            clearInterval(this.touchTimer);
        },
        //加点属性
        PlusAttr(attrType){
            let res = 0;
            switch(attrType){
                case 1:
                    res = this.$store.state.BorderChess.currentWarriorItem.plus.ad;
                    break;
                case 2:
                    res = this.$store.state.BorderChess.currentWarriorItem.plus.de;
                    break;
                case 3:
                    res = this.$store.state.BorderChess.currentWarriorItem.plus.tact;
                    break;
                case 4:
                    res = this.$store.state.BorderChess.currentWarriorItem.plus.speed;
                    break;
            }
            return res;
        },
        //初始属性
        RealAttr(attrType){
            let baseAttr = 0;
            switch(attrType){
                case 1 : baseAttr = this.$store.state.BorderChess.currentWarriorItem.attr.ad; break;
                case 2 : baseAttr = this.$store.state.BorderChess.currentWarriorItem.attr.de; break;
                case 3 : baseAttr = this.$store.state.BorderChess.currentWarriorItem.attr.tact; break;
                case 4 : baseAttr = this.$store.state.BorderChess.currentWarriorItem.attr.speed; break;
            }
            let number = 0;
            let addition = this.$store.state.BorderChess.currentWarriorItem.level -1;
            switch(attrType){
                case 1 : 
                    number = baseAttr + this.$store.state.BorderChess.currentWarriorItem.grow.ad * addition;
                    number += this.$store.state.BorderChess.currentWarriorItem.plus.ad;
                    break;
                case 2 :
                    number = baseAttr + this.$store.state.BorderChess.currentWarriorItem.grow.de * addition;
                    number += this.$store.state.BorderChess.currentWarriorItem.plus.de;
                    break;
                case 3 :
                    number = baseAttr + this.$store.state.BorderChess.currentWarriorItem.grow.tact * addition;
                    number += this.$store.state.BorderChess.currentWarriorItem.plus.tact;
                    break;
                case 4 :
                    number = baseAttr + this.$store.state.BorderChess.currentWarriorItem.grow.speed * addition;
                    number += this.$store.state.BorderChess.currentWarriorItem.plus.speed;
                    break;
            }
            return Math.floor(number);
        },
        IconImgUrl(keyWord){
            switch(keyWord){
                case 1 : return require('../../assets/ui/ui (152).png');
                case 2 : return require('../../assets/ui/ui (153).png');
                case 3 : return require('../../assets/ui/ui (155).png');
                case 4 : return require('../../assets/ui/ui (154).png');
            }
        },
        TextTransform(keyWord){
            switch(keyWord){
                case 1 : return "攻击";
                case 2 : return "防御";
                case 3 : return "谋略";
                case 4 : return "速度";
            }
        },
        //属性加点
        AdjustAttr(isAdd,isFull,attrType){
            this.remain = this.RemainPlus();
            let target = this.$store.state.BorderChess.currentWarriorItem.plus
            if(isFull){
                switch(attrType){
                    case 1 : 
                    if(isAdd) target.ad += this.remain;
                    else target.ad = 0;break;
                    case 2 : 
                    if(isAdd) target.de += this.remain;
                    else target.de = 0;break;
                    case 3 : 
                    if(isAdd) target.tact += this.remain;
                    else target.tact = 0;break;
                    case 4 : 
                    if(isAdd) target.speed += this.remain;
                    else target.speed = 0;break;
                }
            }   
            else{
                switch(attrType){
                    case 1 :
                        if(isAdd)  this.remain > 0 ? target.ad += 1 : null ;
                        else target.ad > 0 ? target.ad -= 1 : target.ad = 0;
                        break;
                    case 2 :
                        if(isAdd)   this.remain > 0 ?target.de += 1: null ;
                        else target.de > 0 ? target.de -= 1 : target.de = 0;
                        break;
                    case 3 :
                        if(isAdd)   this.remain > 0 ?target.tact += 1: null ;
                        else target.tact > 0 ? target.tact -= 1 : target.tact = 0;
                        break;
                    case 4 :
                        if(isAdd)   this.remain > 0 ?target.speed += 1: null ;
                        else target.speed > 0 ? target.speed -= 1 : target.speed = 0;
                        break;
                }
            }
            
            this.remain = this.RemainPlus();
            this.$forceUpdate();
        },
        Close(){
            this.$bus.$emit('ShowWarriorAdjustPanel',false)
        }
    },
    mounted(){
        this.remain = this.RemainPlus();
    }
}
</script>

<style lang="less">
.warrior_adjust_cpt{
    width: 100vw; height: 100vh;
    z-index: 11;
    .filter_gray{
        filter: grayscale(1);
    }
    .mask{
        background: radial-gradient(rgba(0, 0, 0, 0.5),rgb(0, 0, 0));
    }
    .sub_btn{
        background: url('../../assets/ui/ui\ \(128\).png')no-repeat;
        background-size: 100% 100%;
    }
    .add_btn{
        background: url('../../assets/ui/ui\ \(127\).png')no-repeat;
        background-size: 100% 100%;
    }
    .container{
        top: 50%;
        margin-top: -77.5vw;
        width: 100vw; height: 155vw;
        background: url('../../assets/background/bg (7).png')#393b3e bottom right no-repeat;
        background-size: cover;
        .star_bar,.arms_bar{
            top: 24vw; left: 40vw;
            width: 55vw; height: 8vw;
            background: #1414144b;
            .red_list{
                left: 50%; top: 2vw;
                width: 19vw; height: 4vw;
                margin: 0 0 0 -9.5vw;
                li{
                    position: relative; float: left;
                    width: 3.8vw; height: 3.8vw;
                }
            }
            .sub_btn,.add_btn{
                top: 1.5vw; left: 5vw;
                width: 5vw; height: 5vw;
                line-height: 3.4vw;
                font-weight: bold;
                font-size: 5vw;
            }
            .add_btn{
                left: auto; right: 5vw;
            }
            .sub_btn.touch,.add_btn.touch{
                transform: scale(.8);
            }
        }
        .arms_bar{
            top: 35vw;
            .title{
                width: 18vw;height: 8vw;
                line-height: 8vw;
                color: rgb(216, 216, 216);
                font-weight: bold;
                .icon{
                    top: 1.5vw; left: 1.5vw;
                    width: 5vw; height: 5vw;
                }
            }
            .arms_icon{
                left: 18vw; top: 0;
                width: 40vw; height: 8vw;
                li{
                    position: relative; float: left;
                    width: 8vw; height: 8vw;
                    margin: 0 0 0 2vw;
                    
                }
                li.filter_gray{
                    filter: grayscale(1);
                }
            }
        }
        .level{
            top: 72vw; left: 5vw;
            width: 90vw;height: 8vw;
            background: #1414144b;
            .title{
                width: 24vw;height: 8vw;
                line-height: 8vw;
                color: rgb(216, 216, 216);
                font-weight: bold;
                .icon{
                    top: 1.5vw; left: 1.5vw;
                    width: 5vw; height: 5vw;
                }
            }
            .sub_btn,.add_btn{
                top: 2vw;
                width: 8vw; height: 4vw;
                line-height: 3.4vw;
                font-weight: bold;
                font-size: 5vw;
                background: rgb(180, 180, 180);
                color: black;
            }
            .sub_btn{
                left: 35vw;
            }
            .add_btn{
                left: 65vw;
            }
            .num{
                left: 48.5vw;
                width: 10vw;height: 8vw;
                line-height: 8vw;
            }
            .sub_btn.touch,.add_btn.touch,.empty_btn.touch,.full_btn.touch{
                transform: scale(.8);
            }
            
        }
        .attr{
            top: 83vw; left: 5vw;
            width: 90vw; height: 35vw;
            li{
                position: relative;
                width: 100%; height: 8vw;
                margin: 0 0 3vw 0;
                background: #1414144b;
                .title{
                    width: 24vw;height: 8vw;
                    line-height: 8vw;
                    color: rgb(216, 216, 216);
                    font-weight: bold;
                    .icon{
                        top: 1.5vw; left: 1.5vw;
                        width: 5vw; height: 5vw;
                    }
                }
                .empty_btn,.full_btn{
                    top: 2vw;
                    width: 8vw; height: 4vw;
                    line-height: 4vw;
                    background: rgb(117, 117, 117);
                    color: rgb(255, 255, 255);
                }
                .empty_btn{
                    left: 22vw;
                }
                .full_btn{
                    left: 78vw;
                }
                .sub_btn,.add_btn{
                    top: 2vw;
                    width: 8vw; height: 4vw;
                    line-height: 3.4vw;
                    font-weight: bold;
                    font-size: 5vw;
                    background: rgb(180, 180, 180);
                    color: black;
                }
                .sub_btn{
                    left: 35vw;
                }
                .add_btn{
                    left: 65vw;
                }
                .number{
                    left: 46.5vw;
                    width: 16vw;height: 8vw;
                    line-height: 8vw;
                    text-align: center;
                    // background: red;
                    span{
                        color: rgb(12, 213, 12);
                    }
                }
                .sub_btn.touch,.add_btn.touch,.empty_btn.touch,.full_btn.touch{
                    transform: scale(.8);
                }
            }
            li:nth-of-type(1){
                margin: 1vw 0 3vw 0;
            }
        }
        .remain_plus{
            top: 128vw; left: 5vw;
            width: 90vw; height: 8vw;
            text-align: left;
            line-height: 8vw;
            text-indent: 5vw;
            color: rgb(216, 216, 216);
            font-weight: bold;
            background: #1414144b;
        }
        .unload_warrior_btn{
            top: auto; bottom: 5vw; left: 5vw;
            width: 20vw; height: 6vw;
            background: white;
            color: black;
            text-align: center;
            line-height: 6vw;
            font-weight: bold;
        }
        .close_btn{
            left: auto;right: 4vw; top: 6vw;
            height: 8vw; width: 8vw;
            background: url('../../assets/ui/ui (85).png')no-repeat;
            background-size: 100% 100%;
        }
        .close_btn.click,.unload_warrior_btn.click{
            transform: scale(.8);
        }
        .topic{
            font-family: 'FZLBJW';
            top: 5vw; width: 55vw; height: 12vw;
            background: url('../../assets/background/bg (5).png')no-repeat;
            background-size: 100% 100%;
            line-height: 12vw;
            font-size: 5vw;
            text-indent: -16vw;
        }
        .warrior_img{
            left: 5vw; top: 23vw;
            width: 30vw; height: 45vw;
            
        }
    }
}
</style>