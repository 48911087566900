import DamageBlock from "../block/damage";
import StatusBlock from "../block/status.js";
import AfterDamageBlock from "../block/execute1.js";
import Attack from '../js/attack.js';
import Count from '../js/count.js';
import Query from '../js/query.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import ExecuteBlock from "../block/execute.js";
import AttrBlock from "../block/attr.js";
import OddBlock from "../block/odd.js";
import ExecuteSysBlock from "../block/executeSys.js";
let shuSArr = [
    //刘备
    {
        id : 1,
        name : "皇裔流离",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let odd = 50;//触发几率
            let cureValue = 68;//基础恢复率
            let cureIndex = 0.6;//恢复率增长率
            
            let res = a.SelectTarget(self,3,true,false,3,true,true,this);
            for(let i in res){
                let action = function(){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let cure = c.CountCure(self,res[i],cureValue,cureIndex,that);
                        let report = new Report();
                        report.InitTwoTargetReport(3,self,res[i],`【皇裔流离】令`,`恢复了${cure}兵力(${res[i].hp + cure})`,false);
                        a.GetCure(self,res[i],cure,that,true,report);
                    }
                }
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                let work = block.Init(res[i],"急救");
                if(work){
                    let report = new Report();
                    report.InitExecuteReport(2,res[i],`急救效果已施加`);
                }
                
            }
            this.STATISTICS.times += 1;
        }
    },
    //关银屏
    {
        id : 40,
        name : "巾帼战阵",
        type : "主动",
        odd : 120,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let damageIndex = 120;//基础伤害率
            let addition = 40;//增伤幅度
            if(self.driver.version == '平衡'){
                addition = 25;
            }

            let atk = new Attack();
            let c = new Count();
            let q = new Query();
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = atk.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            //自己先增伤
            let block = new DamageBlock(self,this,"攻击",true,addition,false,false,0);
            block.Init(self);
            //不能普通攻击
            let block1 = new StatusBlock(self,this,"无法普通攻击",0,0);
            block1.Init(self,false);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                atk.GetHurt(self,res[i],damage,false,"攻击",this);
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //赵云
    {
        id : 23,
        name : "银龙冲阵",
        type : "主动",
        odd : 50,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageIndex = 150;//基础伤害率
            let addition = 20;//增伤幅度
            let ad = q.GetAttr(self,"攻击");
            addition += Math.floor((ad - 120)*0.18);
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            
            for(let j =0;j<2;j++){
                let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                    a.GetHurt(self,res[i],damage,false,"攻击",this);
                    if(j == 0){
                        let block1 = new DamageBlock(self,this,"攻击",false,addition,false,false,2);
                        block1.Init(res[i]);
                        let block2 = new DamageBlock(self,this,"谋略",false,addition,false,false,2);
                        block2.Init(res[i]);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //庞统
    {
        id : 48,
        name : "密谋定蜀",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let c = new Count();
            let a = new Attack();
            let value = 30 + Math.floor((q.GetAttr(self,"谋略")-80)*0.16);
            let value1 = 115 + Math.floor((q.GetAttr(self,"谋略")-80)*1.15);
            let value2 = 133 + Math.floor((q.GetAttr(self,"谋略")-80)*1.25);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",true,-value,false,false,2,0);
                block1.Init(res[i],null,true);
                let block2 = new DamageBlock(self,this,"谋略",true,-value,false,false,2,0);
                block2.Init(res[i],null,true);
            }
            let action = function(){
                let target = this.beExecuted;
                //目标身上存在该战法施加的妖术块才可以执行
                let avail = false;
                for(let i in target.SPECIAL_STATUS){
                    if(target.SPECIAL_STATUS[i].tactics == that){
                        avail = true; break;
                    }
                }
                if(avail){
                    let damage = c.CountDamage(self,target,value2,"谋略",false,this.tactics,"恐慌");
                    a.GetHurt(self,target,damage,false,"谋略",this.tactics,false);
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,target,`【密谋定蜀】令`,`损失了${damage}兵力(${target.hp})`);
                }
                else{
                    //恐慌被刷新意味着执行块应该也要被移除
                    let temp = [];
                    for(let i in target.EXECUTE){
                        if(target.EXECUTE[i].tactics != that) temp.push(target.EXECUTE[i]);
                    }
                    target.EXECUTE = temp;
                }
            }
            //恐慌
            for(let i in res){
                //放入诅咒
                let block = new DamageStatus(self,this,'恐慌',value1,"谋略",2,0,true);
                let flag = block.Init(res[i],true);
                if(flag){
                    let block = new ExecuteBlock(self,this,"试图发动追击战法",action,2);
                    block.Init(res[i]);
                }
            }

            
            
            this.STATISTICS.times += 1;
        }
    },
    //诸葛亮
    {
        id : 52,
        name : "明其虚实",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let c = new Count();
            let a = new Attack();
            let index = 6;
            

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,false,this);
            for(let i in res){
                let block = new StatusBlock(self,this,'犹豫',2,0);
                let work = block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`犹豫效果已施加`);
                }
                //属性降低
                let block1 = new AttrBlock(self,this,"谋略",0,99);
                let work1 =  block1.Init(res[i],false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`属性降低(预备)效果已施加`);
                }
            }
            let action = function(){
                for(let j in res){
                    let target = res[j];
                    for(let i in target.ATTR){
                        if(target.ATTR[i].tactics == that){
                            let value = Math.floor(target.attr.tact * index/100);
                            target.ATTR[i].value -= value;
                            let r = new Report();
                            let txt = `谋略属性降低${index*self.driver.turn}%(${Math.abs(target.ATTR[i].value)})(${q.GetAttr(target,"谋略")})`
                            r.InitTwoTargetReport(1,self,target,`【明其虚实】令`,txt);
                        }
                    }
                }
            }
            let block = new ExecuteSysBlock(self,this,"回合开始",action,99);
            block.Init(self);
            
            this.STATISTICS.times += 1;
        }
    },
    //法正
    {
        id : 78,
        name : "难知如阴",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let c = new Count();
            let a = new Attack();
            let index = 120;
            let odd = 75;//跳过准备时间的几率
            
            let fri = a.SelectTarget(self,3,true,false,2,false,true,that);
            for(let i in fri){
                if(fri[i].TacticsArr[0].type == '主动'){
                    let block = new OddBlock(self,this,fri[i].TacticsArr[0],0,99,0);
                    let work = block.Init(fri[i],false);
                    if(work){
                        let r = new Report();
                        r.InitExecuteReport(2,fri[i],`【${fri[i].TacticsArr[0].name}】发动几率提升(预备)已施加`)
                    }
                }
            }
            let action = function(){
                //刷新上回合提供的发动几率提升
                for(let i in fri){
                    for(let j in fri[i].ODD_VAR){
                        if(fri[i].ODD_VAR[j].tactics == that){
                            fri[i].ODD_VAR[j].value = 0;
                            fri[i].ODD_VAR[j].skipTime = 0;
                        }
                    }
                }
                if(self.driver.turn%2 != 0){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【难知如阴】`);
                    
                    let target = fri[Math.floor(Math.random()*fri.length)];
                    for(let i in target.ODD_VAR){
                        if(target.ODD_VAR[i].tactics == that){
                            target.ODD_VAR[i].value = index;
                            let r = new Report();
                            r.InitExecuteReport(3,target,`【${target.ODD_VAR[i].workTactics.name}】发动几率提升${index}%`)
                            if(target.ODD_VAR[i].workTactics.wait > 0){
                                let realOdd = q.GetMoraleOdd(self,odd);
                                let rand = Math.floor(Math.random()*100);
                                if(rand < realOdd){
                                    target.ODD_VAR[i].skipTime = 1;
                                    let r = new Report();
                                    r.InitExecuteReport(3,target,`【${target.ODD_VAR[i].workTactics.name}】跳过一回合准备`)
                                }
                            }
                        }
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            block.Init(self);
            
            this.STATISTICS.times += 1;
        }
    },
    //关羽
    {
        id : 88,
        name : "樊渊泅囚",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑'],
        wait : 1,
        execute(self){
            let atk = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 190;//基础伤害率
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = atk.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                atk.GetHurt(self,res[i],damage,false,"攻击",this);
            }
            for(let i in res){
                let sta = new StatusBlock(self,this,"犹豫",1);
                sta.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //严颜
    {
        id : 92,
        name : "断首何怒",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let odd = 60;//触发几率
            let index1 = q.GetAdditionValue(self,34,'防御',0.08);
            let index2 = 80;

            let getHurt = function(){
                let target = this.damageFromWho;//伤害来源
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                //让自己的减伤效果降低
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that && self.DAMAGE_VAR[i].value < 0){
                        self.DAMAGE_VAR[i].value += index2/4;
                        let r = new Report();
                        r.InitExecuteReport(3,self,`受到所有伤害降低${Math.abs(self.DAMAGE_VAR[i].value)}%`)
                    }
                }
                //让伤害来源者伤害降低
                if(rand < realOdd){
                    let block = new DamageBlock(self,that,'所有',true,-index1,true,false,99,0);
                    let work = block.Init(target,null,false,3);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,target,`【断首何怒】令`,`所有伤害降低${index1}%`);
                    }
                }
            }
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【断首何怒】`);
                let tag = true;
                //先找减伤块
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        self.DAMAGE_VAR[i].value = -index2;
                        tag = false;
                        let r = new Report();
                        r.InitExecuteReport(3,self,`受到所有伤害降低${index2}%`)
                    }
                }
                //没找到减伤快就重新添加
                if(tag){
                    let block = new DamageBlock(self,that,'所有',false,0,false,false,99,0);
                    block.Init(self,null,true,3);
                }
            }
            let block1 = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
            block1.Init(self,'断首何怒');
            let block = new ExecuteBlock(self,this,'行动开始',action,99);
            let work = block.Init(self,false);
            if(work){
                let block = new DamageBlock(self,that,'所有',false,0,false,false,99,0);
                block.Init(self,null,false);
                let r  = new Report();
                r.InitExecuteReport(2,self,`【断首何怒】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
    
]

export default shuSArr;