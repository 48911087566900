//引入路由
import VueRouter from 'vue-router'

//我的武将
import MyWarrior from '../pages/warrior/MyWarrior.vue';

//我的战法
import Tactics from '../pages/tactics/Tactics.vue';

//我的配将
import MyTeam from '../pages/team/MyTeam.vue';

//模拟对战
import Battle from '../pages/battle/Battle.vue';
import Report from '../pages/battle/Report.vue';
import BattleResult from '../pages/battle/BattleResult.vue';
import Lineup from '../pages/battle/Lineup.vue';

//设置面板
import Setting from '../pages/setting/Setting.vue';


export default new VueRouter({
    routes:[
        {path:"/",redirect:"/home"},
        {
            path : '/myWarriors',
            component : MyWarrior
        },
        {
            path : '/myTactics',
            component : Tactics
        },
        {
            path : '/myTeam',
            component : MyTeam,
        },
        //-----------------模拟板块-----------------
        {
            path : '/Battle',
            component : Battle
        },
        {
            path : '/ReportContainer',
            component : Report
        },
        {
            path : '/BattleResult',
            component : BattleResult,
        },
        {
            path : '/Lineup',
            component : Lineup,
        },
        //-----------------模拟板块-----------------
        {
            path : '/setting',
            component : Setting
        }
    ]
})