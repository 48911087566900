class ExecuteBlock{
    constructor(applicator,tactics,executeStage,execute,duration){
        //来自谁
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //什么时候执行
        this.executeStage = executeStage;
        //执行函数
        this.execute = execute;
        //持续时间
        this.duration = duration;
        //被执行人
        this.beExecuted = null;
    }
    Init(target,prior = false){
        if(this.executeStage != "回合开始" && this.executeStage != "行动开始" && this.executeStage != "行动结束" 
        && this.executeStage != "行动开始前" 
        && this.executeStage != "试图发动主动战法" && this.executeStage != "试图发动普通攻击"
        && this.executeStage != "试图发动追击战法"
        && this.executeStage != "生成伤害前"
        ){
            console.log('执行块的executeStage字段错误',this.executeStage);
            return false;
        }
        this.beExecuted = target;
        prior ? target.EXECUTE.unshift(this) : target.EXECUTE.push(this);
        return true;
    }
}

export default ExecuteBlock;