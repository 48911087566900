import Report from "../js/report";
import Attack from "../js/attack";
import Count from "../js/count";
import Query from "../js/query";
import StatusBlock from "../block/status";
import DamageBlock from "../block/damage";
import DamageStatus from "../block/damageStatus";
import AfterDamageBlock from "../block/execute1";
let shuBArr = [
    //诸葛亮
    {
        id : 35,
        name : "诸葛锦囊",
        type : "主动",
        odd : 35,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let value1 = q.GetAdditionValue(self,35,'谋略',0.22);//减伤幅度
            let value2 = q.GetAdditionValue(self,14,'谋略',0.1); //伤害提高幅度

            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(3,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,true,true,this);
            //施加减伤
            for(let i in res){
                let block = new DamageBlock(self,this,"谋略",false,-value1,false,false,2);
                block.Init(res[i]);
                let block1 = new DamageBlock(self,this,"攻击",true,value2,false,false,2);
                block1.Init(res[i]);
                let block2 = new DamageBlock(self,this,"谋略",true,value2,false,false,2);
                block2.Init(res[i]);
            }
            //施加先手给自己
            let block = new StatusBlock(self,this,"先手",2);
            block.Init(self);
            this.STATISTICS.times += 1;
        }
    },
    //张姬
    {
        id : 38,
        name : "将门虎女",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 190;
            let damageValue2 = 35;
            let odd = 75;//触发动摇几率

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(2,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let action = function(){
                if(that == this.damageFromTactics) return;
                let target = this.beExecuted;
                //先判断将门虎女的动摇还在不在
                let refresh = true;
                for(let i in target.SPECIAL_STATUS){
                    if(target.SPECIAL_STATUS[i].tactics == that) refresh = false;
                }
                if(!refresh){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let damage = c.CountDamage(self,target,damageValue2,"攻击",false,that,"动摇");
                        let work = a.GetHurt(self,target,damage,false,"攻击",that,false);
                        if(work){
                            let r = new Report();
                            r.InitExecuteReport(3,target,`由于【将门虎女】损失了${damage}兵力(${target.hp})`)
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitExecuteReport(3,target,`【将门虎女】没有生效，当前几率${realOdd}%`)
                    }
                }
                else{
                    //动摇被刷新意味着执行块应该也要被移除
                    let temp = [];
                    for(let i in target.AFTER_DAMAGE){
                        if(target.AFTER_DAMAGE[i].tactics != that) temp.push(target.AFTER_DAMAGE[i]);
                    }
                    target.AFTER_DAMAGE = temp;
                }
            }
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                //施加动摇
                let block = new DamageStatus(self,this,"动摇",damageValue2,"攻击",1,0,false);
                let work = block.Init(res[i],true);
                if(work){
                    let block1 = new AfterDamageBlock(self,this,action,1,true,true,true,true,true,true,true);
                    block1.Init(res[i],"将门虎女");
                }
            }
            this.STATISTICS.times += 1;
        }
    }
]

export default shuBArr;