import Vue from "vue";
import Vuex from 'vuex';

// import Warrior from '../mixin/structure/WarriorBattle'

//Vue实例使用Vuex
Vue.use(Vuex);

import Team  from '../util/team.js';
const store = new Vuex.Store({
    //公共数据存储区
    state(){
        return{
            //appId，也作为数据库的名称
            DATABASE_NAME : 'AssistingBorder App',

            ScreenWidth : window.innerWidth,
            ScreenHeight : window.innerHeight,

            //当前设备
            equipment : "phone",

            //当前用户
            User : null,

            //所有武将数据
            AllWarriors : [],
            //选择的武将
            currentWarrior : {
                
            },
            //所有战法信息容器
            Tactics : {
                command : [],
                active : [],
                combo : [],
                passive : [],
            },

            //应用数据
            App : null,
            //当前用户
            User : null,

            //界面脏位,表示是否需要启动存储
            WarriorDirty : false,
            TacticsDirty : false,


            //公用战法面板控件
            CTPanel : {
                status : false,
            },


            //提示面板控件
            AlertPanel:{
                status : false,//true表示打开
                text : "",//提示面板文字信息
                callBack: function(){},//挂载函数
            },


            //配将队伍
            MyTeams : [],

            //率土奕数据
            BorderChess : {
                version : "默认",
                AllWarriors : [],
                AllTactics : {
                    command : [],
                    active : [],
                    combo : [],
                    passive : [],
                },
                //蓝队
                BlueTeam : new Team(),
                //红队
                RedTeam : new Team(),
                //选中的队伍
                currentTeam : [],
                //选中的武将
                currentWarriorItem : null,
                //选中的战法槽
                currentTacticsItem : null,
                control : {
                    isMainTactics : false,
                },
                
                isShowBattleResult : false,
                isShowBattleStatistics : false,
                //对战结束后武将的结果
                battleResult:[
                    [],
                    []
                ],
                ReportHeap : [],//战报堆
                //结果统计
                Statistics : {
                    allTurn : 0,
                    //数据矩阵
                    dataMatrices : [],
                    

                },
                ReportContainer:[],//战报
                LineupContainer : [],//阵容
            }
        }
    },
    getters:{

    },
    //定义方法
    mutations:{
        CommitSimBattleResultTeams(state,arr){
            state.teams = arr;
        },
        Alert(state,params){
            state.AlertPanel.status = true;
            state.AlertPanel.text = params.text;
            state.AlertPanel.callBack = params.callBack;
        }
    },
    //操作异步
    actions:{

    },
    modules:{

    }
})


export default store;

