import localForage from "localforage";
class Save{
    constructor(vue){
        this.vue = vue;
    }
    //保存武将数据
    SaveWarriorData(){
        let resArr = [];
        for(let i in this.vue.$store.state.AllWarriors){
            if(this.vue.$store.state.AllWarriors[i].own){
                let item = {
                    id : this.vue.$store.state.AllWarriors[i].id,
                    camp : this.vue.$store.state.AllWarriors[i].camp,
                    name : this.vue.$store.state.AllWarriors[i].name,
                    arms : this.vue.$store.state.AllWarriors[i].arms,
                    red : this.vue.$store.state.AllWarriors[i].red,
                    isUp : this.vue.$store.state.AllWarriors[i].isUp,
                    isUpWork : this.vue.$store.state.AllWarriors[i].isUpWork,
                    isUpArms : this.vue.$store.state.AllWarriors[i].isUpArms,
                    skinChose : this.vue.$store.state.AllWarriors[i].skinChose,
                }
                resArr.push(item);
            }
        }
        this.vue.$store.state.User.WarriorsStorage = resArr;
    }
    //保存战法数据
    SaveTacticsData(){
        
        let tacticsStorage = [];
        for(let j = 0;j<4;j++){
            let tgtArr = [];
            switch(j){
                case 0 : tgtArr = this.vue.$store.state.Tactics.command;break;
                case 1 : tgtArr = this.vue.$store.state.Tactics.active;break;
                case 2 : tgtArr = this.vue.$store.state.Tactics.combo;break;
                case 3 : tgtArr = this.vue.$store.state.Tactics.passive;break;
            }
            for(let i in tgtArr){
                if(!tgtArr[i].lock){
                    let nameStr = tgtArr[i].name;
                    tacticsStorage.push(nameStr);
                }
            }
        }
        this.vue.$store.state.User.TacticsStorage = tacticsStorage;
    }
    //保存整个app数据
    SaveAppData(){
        localForage.setItem(this.vue.$store.state.DATABASE_NAME,this.vue.$store.state.App,(value)=>{
            console.log('target',this.vue.$store.state.App);
        }).then(function (value) {
            // 当值被存储后，可执行其他操作
            console.log(value);
        }).catch(function(err) {
            // 当出错时，此处代码运行
            console.log(err);
        });
    }
    //保存配将队伍
    SaveMyTeam(){
        let dataArr = [];
        for(let i in this.vue.$store.state.MyTeam){
            let oneTeam = [];
            for(let j = 0;j<3;j++){
                let newWarrior = {
                    id : this.vue.$store.state.MyTeam[i][j].id,
                    camp : this.vue.$store.state.MyTeam[i][j].camp,
                    name : this.vue.$store.state.MyTeam[i][j].name,
                    arms : this.vue.$store.state.MyTeam[i][j].arms,
                    tacticsArr : []
                }
                for(let k=0;k<2;k++){
                    //说明安装了战法
                    if(this.vue.$store.state.MyTeam[i][j].tacticsArr[k].id != -1){
                        let tactItem = {
                            name : this.vue.$store.state.MyTeam[i][j].tacticsArr[k].name,
                            type : this.vue.$store.state.MyTeam[i][j].tacticsArr[k].type
                        }
                        newWarrior.tacticsArr.push(tactItem)
                    }
                    else{
                        let tactItem = {
                            name : "",
                            type : "",
                        }
                        newWarrior.tacticsArr.push(tactItem);
                    }
                }
                oneTeam.push(newWarrior);
            }
            dataArr.push(oneTeam);
        }
        localForage.setItem(`${this.vue.$store.state.DATABASE_NAME}_TEAM`,dataArr,(value)=>{
            console.log("存储成功");
        }).then(function (value) {
            // 当值被存储后，可执行其他操作
            console.log(value);
        }).catch(function(err) {
            // 当出错时，此处代码运行
            console.log(err);
        });
    }
    //保存阵容数据
    SaveLineup(){
        let dataArr = this.vue.$store.state.BorderChess.LineupContainer;
        localForage.setItem(`${this.vue.$store.state.DATABASE_NAME}_Lineup`,dataArr,(value)=>{
            console.log("存储成功");
        }).then(function (value) {
            // 当值被存储后，可执行其他操作
            console.log(value);
        }).catch(function(err) {
            // 当出错时，此处代码运行
            console.log(err);
        });
    }
}
export default Save;