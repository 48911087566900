import Report from "../js/report";

class DodgeBlock{
    constructor(applicator,tactics,duration){
        //施加该块的武将
        this.applicator = applicator;
        //施加该效果的战法
        this.tactics = tactics;
        //施加持续时间
        this.duration = duration;
    }
    Init(target,needReport = true,reportLevel = 3){
        target.DODGE.push(this);
        if(needReport){
            let r = new Report();
            r.InitExecuteReport(reportLevel,target,`规避效果已施加`);
        }
        return true;
    }
}

export default DodgeBlock;