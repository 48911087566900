class Warrior{
    constructor(){
        this.id = -1;
        this.red = 0;
        this.name = "";
        this.camp = "";
        this.arms = "";
        this.cost = "-";
        this.range = "-";
        this.gender = "";
        this.up_arms = [];
        
        this.img = {
            head : "",
            body : require('../assets/background/bg (24).png'),
            preview : "",
            banner : ""
        }
        this.originSkin = "";
        this.mainTactics={
            name : '',
            odds : 0,
            quality : "",
            describe : '',
        }
        //属性
        this.attr = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
            demolition : 0,
        }
        this.grow = {
            ad : 0,
            de : 0,
            tact : 0,
            speed : 0,
            demolition : 0,
        }
        //标记
        this.sign = "";
        this.tacticsArr = [
            {
                id : -1,
                name : '',
                odds : 0,
                range : 0,
                type : '',
                quality : "",
                describe : '',
                sign : ""
            },
            {
                id : -1,
                name : '',
                odds : 0,
                range : 0,
                type : '',
                quality : "",
                describe : '',
                sign : ""
            }
        ]
        this.isUp = false;
        this.isUpWork = false;
        this.isUpArms = false;
    }
    GetData(data){
        this.id = data.id;
        this.name = data.name;
        this.camp = data.camp;
        this.cost = data.cost;
        this.arms = data.arms;
        this.red = data.red;
        this.range = data.range;
        this.gender = data.gender;
        this.up_arms = data.up_arms;
        this.img = {
            head : data.img.head,
            body : data.img.body,
            preview : data.img.preview,
            banner : data.img.banner
        }
        this.originSkin = data.originSkin;
        this.sign = data.sign
        this.mainTactics={
            name : data.tactics_name,
            odds : data.tactics_odds,
            type : data.tactics_type,
            quality : data.tactics_quality,
            describe : data.tactics_describe,
        }
        //属性
        this.attr = {
            ad : data.attr.ad,
            de : data.attr.de,
            tact : data.attr.tact,
            speed : data.attr.speed,
            demolition : data.attr.demolition,
        }
        this.grow = {
            ad : data.grow.ad,
            de : data.grow.de,
            tact : data.grow.tact,
            speed : data.grow.speed,
            demolition : data.grow.demolition,
        }
        this.isUp = data.isUp;
        this.isUpWork = data.isUpWork;
        this.isUpArms = data.isUpArms;
    }
    LoadTactics(tacticsData,index,tacticsType){
        this.tacticsArr[index].id = tacticsData.id;
        this.tacticsArr[index].odds = tacticsData.odds;
        this.tacticsArr[index].range = tacticsData.range;
        this.tacticsArr[index].name = tacticsData.name;
        this.tacticsArr[index].type = tacticsType;
        this.tacticsArr[index].quality = tacticsData.quality;
        this.tacticsArr[index].describe = tacticsData.describe;
        this.tacticsArr[index].sign = tacticsData.sign;
    }
}


class ConfigTeam{
    constructor(vue){
        this.vue = vue
    }
    Init(){
        this.vue.$store.state.MyTeam = [];
        this.ConfigOneTeam();
    }
    //构造一个空的队伍
    ConfigOneTeam(){
        let newTeam = [
            {} = new Warrior(),
            {} = new Warrior(),
            {} = new Warrior(),
        ]
        this.vue.$store.state.MyTeam.push(newTeam);
        return newTeam ; 
    }
    //放入一个武将
    PutInWarriorData(whichTeam,whichPosition,warriorData){
        this.vue.$store.state.MyTeam[whichTeam][whichPosition].GetData(warriorData);
    }
    //安装一个战法
    PutInTacticsData(warrior,tacticsIndex,tacticsData){
        warrior.tacticsArr[tacticsIndex].id = tacticsData.id;
        warrior.tacticsArr[tacticsIndex].name = tacticsData.name;
        warrior.tacticsArr[tacticsIndex].type = tacticsData.type;
        warrior.tacticsArr[tacticsIndex].range = tacticsData.range;
        warrior.tacticsArr[tacticsIndex].quality = tacticsData.quality;
        warrior.tacticsArr[tacticsIndex].odds = tacticsData.odds;
        warrior.tacticsArr[tacticsIndex].describe = tacticsData.describe;
        warrior.tacticsArr[tacticsIndex].sign = tacticsData.sign;
    }
    //卸载一个战法
    UnloadTactics(warriorData,whichTacticsIndex){
        warriorData.tacticsArr[whichTacticsIndex] = {
            id : -1,
            name : '',
            type : '',
            quality : "",
            describe : '',
        }
    }
    //卸载一个武将
    UnloadWarrior(whichTeam,whichPosition){
        this.vue.$store.state.MyTeam[whichTeam][whichPosition] = new Warrior();
    }
    //删除一支队伍
    DeleteOneTeam(teamIndex){
        this.vue.$store.state.MyTeam.splice(teamIndex,1);
    }
    //清理配将列表
    ClearTeamList(){
        this.Init();
    }
}

export default ConfigTeam;