import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';
import IgnoreBlock from '../block/ignore.js';
import MakeDamageBlock from '../block/execute6.js';
let passiveS = [
    //垒实迎击
    {
        id : 0,
        name : "垒实迎击",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 50;//触发几率
            let cureValue = 200;
            let cureIndex = 0;
            if(self.driver.version == '平衡'){
                cureValue = 180;
            }

            let begin = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【垒实迎击】`);
                    let res = a.SelectTarget(self,3,true,false,2,false,false,that);
                    for(let i in res){
                        let block = new StatusBlock(self,that,'援护',1,0);
                        block.Init(res[i],true,3);
                    }
                }
                else{
                    let r = new Report();
                    r.InitExecuteReport(2,self,`【垒实迎击】没有生效，生效几率为${realOdd}%`);
                }
            }
            let getHurt = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                    let r = new Report().InitTwoTargetReport(3,self,self,`【垒实迎击】令`,`恢复了${cure}兵力(${self.hp + cure})`,false)
                    a.GetCure(self,self,cure,that,true,r);
                }
                let realOdd1 = q.GetMoraleOdd(self,odd);
                let rand1 = Math.floor(Math.random()*100);
                if(rand1 < realOdd1){
                    let block = new DodgeBlock(self,that,99);
                    block.Init(self,false);
                    let r = new Report();
                    r.InitTwoTargetReport(3,self,self,`【垒实迎击】令`,`规避下一次伤害`);
                }
            }
            let block = new ExecuteBlock(self,this,'行动开始',begin,99);
            block.Init(self,false);
            let block1 = new AfterDamageBlock(self,this,getHurt,99,true,false,true,false,false,false,false);
            block1.Init(self,'垒实迎击')
            let r = new Report();
            r.InitExecuteReport(2,self,`【垒实迎击】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //击势
    {
        id : 1,
        name : "击势",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let odd = 65;//触发几率
            let value1 = 50;//增伤
            let value2 = 60;//无视防御

            let begin = function(){
                let realOdd1 = q.GetMoraleOdd(self,odd);
                let rand1 = Math.floor(Math.random()*100);
                if(rand1 < realOdd1){
                    let block = new DamageBlock(self,that,'攻击',true,value1,false,false,0,0);
                    let work = block.Init(self,null,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,self,`【击势】令`,`攻击伤害提高${value1}%`);
                    }
                }
                let realOdd2 = q.GetMoraleOdd(self,odd);
                let rand2 = Math.floor(Math.random()*100);
                if(rand2 < realOdd2){
                    let block = new IgnoreBlock(self,that,"防御",value2,0,0,null);
                    let work = block.Init(self,false);
                    if(work){
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,self,`【击势】令`,`无视${value2}%防御属性`)
                    }
                }
            }
            
            let block = new ExecuteBlock(self,this,'行动开始',begin,99);
            block.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【击势】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //百战无怯
    {
        id : 19,
        name : "百战无怯",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let value = 15;//减伤幅度
            let cureValue = 200;
            let cureIndex = 0;
            let maxTime = 4;//最多叠加层数
            if(self.driver.version == '平衡'){
                cureValue = 160;
            }


            if(self.position == '0'){
                let r = new Report();
                r.InitExecuteReport(2,self,`【百战无怯】不满足条件`);
            }
            else{
                let tag = '' + self.belong + self.position;
                let block1 = new DamageBlock(self,this,'攻击',false,-value*4,false,false,99,0);
                block1.Init(self,null,true,2);
                let block2 = new DamageBlock(self,this,'谋略',false,-value*4,false,false,99,0);
                block2.Init(self,null,true,2);
                self[tag] = 4;
                let getHurt = function(){
                    if(self[tag] > 0){
                        self[tag]-=1;
                        //恢复兵力
                        let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,self,`【百战无怯】令`,`恢复了${cure}兵力(${self.hp + cure})`,false);
                        a.GetCure(self,self,cure,that,true,r);
                        for(let i in self.DAMAGE_VAR){
                            if(self.DAMAGE_VAR[i].tactics == that){
                                self.DAMAGE_VAR[i].value += value;
                                let r = new Report();
                                let txt = `受到${self.DAMAGE_VAR[i].damageType}伤害降低${Math.abs(self.DAMAGE_VAR[i].value)}%`;
                                r.InitTwoTargetReport(3,self,self,`【百战无怯】令`,txt);
                            }
                        }
                    }
                }
                let block = new AfterDamageBlock(self,this,getHurt,99,true,true,true,true,true,true,true);
                block.Init(self,'百战无怯');
                let makeDamage = function(){
                    if(self[tag] < maxTime){
                        self[tag]+=1;
                        for(let i in self.DAMAGE_VAR){
                            if(self.DAMAGE_VAR[i].tactics == that){
                                self.DAMAGE_VAR[i].value -= value;
                                let r = new Report();
                                let txt = `受到${self.DAMAGE_VAR[i].damageType}伤害降低${Math.abs(self.DAMAGE_VAR[i].value)}%`;
                                r.InitTwoTargetReport(3,self,self,`【百战无怯】令`,txt);
                            }
                        }
                    }
                }
                let make = new MakeDamageBlock(self,this,makeDamage,99);
                make.Init(self,'百战无怯');
                let r = new Report();
                r.InitExecuteReport(2,self,`【百战无怯】效果已施加`);
            }
            this.STATISTICS.times += 1;
        }
    },
]

export default passiveS;