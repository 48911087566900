import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';
import MakeDamageBlock from '../block/execute6.js';
let activeB  = [
    //奇正之势
    {
        id : 26,
        name : "奇正之势",
        type : "主动",
        odd : 30,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 240;
            let damageValue2 = 220;

            let tag = '奇正之势' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let fri = a.SelectTarget(self,realRange,true,false,1,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue1,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            let highValue = -1000;
            let high = null;
            for(let i in fri){
                let attr = q.GetAttr(fri[i],'攻击');
                if(attr > highValue){
                    highValue = attr;
                    high = fri[i];
                }
                else if(attr == highValue){
                    let rand = Math.floor(Math.random()*100);
                    if(rand == 1){
                        highValue = attr;
                        high = fri[i];
                    }
                }
            }
            let action = function(){
                if(high[tag]){
                    let r = new Report();
                    r.InitExecuteReport(2,high,`执行【奇正之势】`);
                    let res = a.SelectTarget(high,realRange,false,true,1,false,true,that);
                    for(let i=0;i<res.length;i++){
                        let damage = c.CountDamage(high,res[i],damageValue2,"攻击",false,that,null);
                        a.GetHurt(high,res[i],damage,false,"攻击",that,true,null);
                    }
                    high[tag] = false;
                }
            }
            if(high != null){
                let block = new ExecuteBlock(self,this,'试图发动主动战法',action,1);
                block.Init(high,false);
                let r = new Report();
                r.InitExecuteReport(3,high,`【奇正之势】效果已施加`);
                high[tag] = true;

            }
            this.STATISTICS.times += 1;
        }
    },
    //飒沓如星
    {
        id : 27,
        name : "飒沓如星",
        type : "主动",
        odd : 40,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let value = q.GetAdditionValue(self,36,'谋略',0.18);
            let damageValue = 55;

            let tag = '飒沓如星' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let all = a.SelectTarget(self,realRange,true,false,3,true,true,this);
            let temp = [];
            for(let i in all){
                let arms = u.TransferArms(all[i].up_arms[all[i].choseArms]);
                if(arms == 'q') temp.push(all[i]);
            }
            let res = [];
            for(let i=0;i<2 && temp.length > 0;i++){
                let rand = Math.floor(Math.random()*temp.length);
                res.push(temp[rand]);
                temp.splice(rand,1);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'普通攻击',true,value,false,false,2,0);
                block.Init(res[i],null,true,3);
            }
            let attack = function(){
                let tgt = this.beExecuted;
                if(tgt[tag] > 0){
                    tgt[tag]--;
                }
                else if(tgt[tag] == 0){
                    //清除分兵
                    if(tgt[tag] < 0){
                        let arr = [];
                        for(let i in tgt.COUNTER_ATTACK){
                            if(tgt.COUNTER_ATTACK[i].tactics == that && tgt.COUNTER_ATTACK[i].type == '分兵'){
                                let r = new Report();
                                r.InitExecuteReport(3,tgt,`分兵效果消失了`);
                            }
                            else arr.push(tgt.COUNTER_ATTACK[i]);
                        }
                        tgt.COUNTER_ATTACK = arr;
                    }
                }
            }
            for(let i in res){
                let block = new CounterAttackBlock(self,this,'分兵',damageValue,100,2,0);
                let work = block.Init(res[i],false,3);
                if(work){
                    let block = new AttackExecuteBlock(self,this,attack,2,0);
                    block.Init(res[i],false);
                    res[i][tag] = 2;
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`下两次普通攻击进入分兵状态`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //铁戟金戈
    {
        id : 28,
        name : "铁戟金戈",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['步'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 330;
            let damageValue2 = 225;
            let targetNumber = 2 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let realDamage = targetNumber == 2 ? damageValue1 : damageValue2;
                let damage = c.CountDamage(self,res[i],realDamage,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            

            this.STATISTICS.times += 1;
        }
    },
    //并进
    {
        id : 29,
        name : "并进",
        type : "主动",
        odd : 40,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 100;

            let block = new CounterAttackBlock(self,this,'分兵',damageValue,100,0,0);
            block.Init(self,true,3);
            let block1 = new StatusBlock(self,this,'免疫怯战',0,0);
            block1.Init(self,true,3);
            this.STATISTICS.times += 1;
        }
    },
    //疾战
    {
        id : 30,
        name : "疾战",
        type : "主动",
        odd : 40,
        range : 1,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();

            let block = new StatusBlock(self,this,'连击',0,0);
            block.Init(self,true,3);
            let block1 = new StatusBlock(self,this,'免疫怯战',0,0);
            block1.Init(self,true,3);
            this.STATISTICS.times += 1;
        }
    },
    //闪击
    {
        id : 31,
        name : "闪击",
        type : "主动",
        odd : 50,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 50;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            let res1 = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in res1){
                let block = new DamageBlock(self,this,'攻击',true,-999,true,false,99,0);
                block.Init(res1[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //水淹七军
    {
        id : 34,
        name : "水淹七军",
        type : "主动",
        odd : 50,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2 ,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let damageValue = q.GetAdditionValue(self,205,'谋略',2.25);
            let value = q.GetAdditionValue(self,10,'谋略',0.08);//主动战法伤害降低

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                
            }
            for(let i in res){
                let block = new AttrBlock(self,this,'攻击',-value,2,false);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //斩铁
    {
        id : 38,
        name : "斩铁",
        type : "主动",
        odd : 25,
        range : 3,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 170;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'混乱',1,0);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //破魂
    {
        id : 39,
        name : "破魂",
        type : "主动",
        odd : 25,
        range : 3,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 180;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'暴走',1,0);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //箭岚
    {
        id : 40,
        name : "箭岚",
        type : "主动",
        odd : 30,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 170;
            let value = 45;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let block = new AttrBlock(self,this,'攻击',-value,2,false);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //养精蓄锐
    {
        id : 41,
        name : "养精蓄锐",
        type : "主动",
        odd : 45,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let cureValue = 122;
            let cureIndex = 1.15;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,false,true,this);
            for(let i in res){
                let block = new CureStatusBlock(self,this,'休整',cureValue,cureIndex,2,0,100);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //安抚军心
    {
        id : 42,
        name : "安抚军心",
        type : "主动",
        odd : 35,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let cureValue = 108;
            let cureIndex = 0.975;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,true,this);
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                a.GetCure(self,res[i],cure,this,true,null);
            }
            for(let i in res){
                u.ClearStatus(res[i],this,false,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //危崖困军
    {
        id : 43,
        name : "危崖困军",
        type : "主动",
        odd : 50,
        range : 2,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2 ,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,210,'谋略',2.3);
            let value = q.GetAdditionValue(self,7.2,'谋略',0.07);//主动战法伤害降低

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                
            }
            for(let i in res){
                let block = new AttrBlock(self,this,'防御',-value,2,false);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //合流
    {
        id : 44,
        name : "合流",
        type : "主动",
        odd : 40,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 131;
            let cureIndex = 1.125;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,1,false,true,this);
            res.push(self);
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                a.GetCure(self,res[i],cure,this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //连环
    {
        id : 45,
        name : "连环",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = q.GetAdditionValue(self,132,'谋略',1.4);
            let damageValue2 = q.GetAdditionValue(self,198,'谋略',2.1);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            //指定伤害
            let all = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            for(let i=0;i<all.length;i++){
                let damage = c.CountDamage(self,all[i],damageValue1,"谋略",false,this,null);
                a.GetHurt(self,all[i],damage,false,"谋略",this,true,null);
                break;
            }
            //随机伤害
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue2,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //声东击西
    {
        id : 46,
        name : "声东击西",
        type : "主动",
        odd : 50,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [1,2] ,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,231,'谋略',2.4);
            let targetNumber = 1 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                
            }
            this.STATISTICS.times += 1;
        }
    },
    //武锋
    {
        id : 47,
        name : "武锋",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 209;
            let value = q.GetAdditionValue(self,56,'速度',0.18);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let block1 = new AttrBlock(self,this,'攻击',-value,2,false);
                block1.Init(res[i],true,3);
                let block2 = new AttrBlock(self,this,'谋略',-value,2,false);
                block2.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
]

export default activeB;