<template>
  <!-- app应用 -->
  <div class="app" ref="app">
    <!-- 主组件 -->
    <Main/>

    

    <!-- <div class="click_animation" ref="clickImg" v-show="isClick"></div> -->
    
        <!-- 状态提示 -->
        <div class="message_alert" ref="message_alert" v-for="a in alert_arr" :key="a.id">
          <div class="bg cover"></div>
          <div class="text cover">{{a.msg}}</div>
        </div>
    <!-- </div> -->
    
  </div> 
</template>

<script>
import Main from './components/Main.vue'
export default {
  name : 'App',
  components : {
    Main,
  },
  data(){
    return{
      alert_arr : [],
    }
  },
  methods:{
    alertPanel(info){
        let alert_item = {
          id : Math.floor(Math.random()*9999),
          msg :info,
        }
        
        this.alert_arr.push(alert_item);
        setTimeout(()=>{
            if(this.alert_arr.length > 0){
              this.alert_arr.shift();
            }
        },1500)
        
    },
    Horizon(){
      this.$refs.app.classList.add('horizon');
    }
  },
  mounted(){
    //提示框的事件总线
    this.$bus.$on('alert',this.alertPanel);
    this.$bus.$on('horizon',this.Horizon);

    // this.Count();
    //对界面进行响应式
    if(window.innerWidth/window.innerHeight > 0.57){
      this.$refs.app.classList.add('auto');
      this.$refs.app.classList.add('hoverEvent');
    }
    if(window.innerWidth/window.innerHeight > 1.4){
      // this.$refs.app.classList.add('hoverEvent');
      this.$store.state.equipment = "computer";
    }
  }
}
</script>

<style lang="less">

.app{
  position: absolute; left: 0; top : 0;
  width : 100vw; height : 100vh;
  overflow: hidden;
  user-select: none;
  transition: all .2s ease-out;

  
  
  .circle{
    border-radius: 50%;
  }
  .bubble-enter,.bubble-leave-to{
    transform: scale(.8);
  }
  .bubble-enter-to,.bubble-leave{
    transform: scale(1);
  }
  .message_alert{
    position:absolute; left: 50%; bottom: 5vh;
    margin: 0 0 0 -20vw;
    width: 40vw;
    height: 9vw;
    line-height: 9vw;
    text-align: center;
    color: rgb(204, 204, 204);
    font-size: 3vw;
    overflow: hidden;
    z-index:100;
    transition: all .3s;
    animation: fadeInAndOut 1.5s linear 1;
    .bg{
      background: url('./assets/background/bg (33).png')no-repeat;
      background-size: 100% 100%;
    }
    .text{
      line-height: 9vw;
      text-align: center;
      color: rgb(204, 204, 204);
      font-size: 3vw;
    }
  }
  
  
  


  //颜色区
  //淡黄
  color: rgb(246, 245, 201);
}

@import url('./assets/css/auto.css');
@import url('./assets/css/hover.css');
</style>
