import Attack from "../js/attack";
import Count from "../js/count";
import StatusBlock from "../block/status";
import Query from "../js/query";
import DamageBlock from "../block/damage";
import Report from "../js/report";
import CureStatusBlock from "../block/cureStatus";
import OddBlock from "../block/odd";
import ExecuteBlock from "../block/execute";
import ActiveTacticsBlock from "../block/execute3";
import AttackExecuteBlock from "../block/execute4";
import AttrBlock from "../block/attr";
import Judge from "../js/judge";
import AfterDamageBlock from "../block/execute1";
import ExecuteSysBlock from '../block/executeSys.js';
let jinSArr = [
    //杜预
    {
        id : 122,
        name : "三军夺帅",
        type : "被动",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : false,
        targetNumber : 1,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue1 = 180;//攻击伤害率
            let damageValue2 = q.GetAdditionValue(self,100,'谋略',1);//谋略伤害率
            let addition = 10;//攻击属性提升
            let decline = 5;//谋略属性下降

            let action = function(){
                let rand = Math.floor(Math.random()*2);
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【三军夺帅】`);
                //攻击
                if(rand == 1){
                    let res = a.SelectTarget(self,5,false,true,1,false,true,that);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue1,'攻击',false,that,null);
                        a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                    }
                    let block = new AttrBlock(self,that,'攻击',addition,99,true);
                    block.Init(self,true,3);
                }
                else{
                    let res = a.SelectTarget(self,5,false,true,2,false,true,that);
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue2,'谋略',false,that,null);
                        a.GetHurt(self,res[i],damage,false,'谋略',that,true,null);
                    }
                    for(let i in res){
                        let block = new AttrBlock(self,that,'谋略',-decline,99,true);
                        block.Init(res[i],true,3);
                    }
                }
                
            }
            let block1 = new AttackExecuteBlock(self,this,action,99,0,true);
            block1.Init(self,false);
            let block2 = new ActiveTacticsBlock(self,this,action,99,0,true,'主动');
            block2.Init(self,false);
            let block3 = new ActiveTacticsBlock(self,this,action,99,0,true,'追击');
            block3.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【三军夺帅】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //司马炎
    {
        id : 123,
        name : "谋议宏图",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let value = q.GetAdditionValue(self,24,'谋略',0.2);
            let decline = Math.floor(value/8);
            let moraleAddition = 5;
            
            for(let i in self.ally){
                let block1 = new DamageBlock(self,this,'攻击',false,-value,false,false,99,0);
                block1.Init(self.ally[i],null,true,2);
                let block2 = new DamageBlock(self,this,'谋略',false,-value,false,false,99,0);
                block2.Init(self.ally[i],null,true,2);
            }
            let r = new Report();
            r.InitExecuteReport(2,self,`【谋议宏图】效果已施加`);
            let action = function(){
                let r = new Report();
                r.InitExecuteReport(1,self,`执行【谋议宏图】`);
                let res = a.SelectTarget(self,5,true,false,3,true,false,that);
                for(let i in res){
                    res[i].morale += moraleAddition;
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`士气提升${moraleAddition}(${res[i].morale})`)
                }
                for(let i in res){
                    let target = res[i];
                    for(let j in target.DAMAGE_VAR){
                        if(target.DAMAGE_VAR[j].tactics == that){
                            target.DAMAGE_VAR[j].value += decline;
                            let r = new Report();
                            r.InitExecuteReport(2,target,`受到${target.DAMAGE_VAR[j].damageType}伤害降低${Math.abs(target.DAMAGE_VAR[j].value)}%`)
                        }
                    }
                }
            }
            let block = new ExecuteSysBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //司马师
    {
        id : 125,
        name : "运筹决胜",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            let odd1 = 30;//发动几率1
            let odd2 = 50;//发动几率2
            let damageValue = q.GetAdditionValue(self,220,'谋略',1.25);
            
            let action = function(){
                let realOdd1 = q.GetMoraleOdd(self,odd1);
                let rand1 = Math.floor(Math.random()*100);
                let realOdd2 = q.GetMoraleOdd(self,odd2);
                let rand2 = Math.floor(Math.random()*100);
                if(rand1 < realOdd1){
                    let res = a.SelectTarget(self,5,false,true,1,false,true,that);
                    for(let i in res){
                        let block = new StatusBlock(self,that,'暴走',1,0);
                        let work =  block.Init(res[i],false,2);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,res[i],`【运筹决胜】令`,`陷入暴走`);
                        }
                    }
                }
                if(rand2 < realOdd2){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【运筹决胜】`)
                    let res = a.SelectTarget(self,5,false,true,3,false,true,that);
                    for(let i in res){
                        if(j.IsGetStatus(res[i],'混乱') || j.IsGetStatus(res[i],'暴走')){
                            let damage = c.CountDamage(self,res[i],damageValue,'谋略',false,that,null);
                            a.GetHurt(self,res[i],damage,false,'谋略',that,true,null);
                        }
                    }
                }
            }
            let block = new ExecuteBlock(self,this,"试图发动主动战法",action,99);
            block.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
    //司马昭
    {
        id : 126,
        name : "怀德畏威",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let damageValue1 = 160;
            let damageValue2 = q.GetAdditionValue(self,160,'谋略',1.95);
            
            let realRange = q.GetTacticsRange(self,this);
            //令谋略最低的友军发动攻击
            let min = 10000;
            let minWarrior = null;
            let fri = a.SelectTarget(self,realRange,true,false,2,false,true,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in fri){
                let targetAd = q.GetAttr(fri[i],'谋略');
                if(targetAd < min){
                    min = targetAd;
                    minWarrior = fri[i];
                }
                else if(targetAd == min){
                    let rand = Math.floor(Math.random()*2);
                    if(rand == 1){
                        min = targetAd;
                        minWarrior = fri[i];
                    }
                }
            }
            let target = null;
            if(minWarrior != null){
                let r = new Report();
                r.InitExecuteReport(3,minWarrior,`执行【怀德畏威】`)
                let en = a.SelectTarget(minWarrior,realRange,false,true,1,false,true,this);
                for(let i in en){
                    let damage = c.CountDamage(minWarrior,en[i],damageValue1,'攻击',false,this,null);
                    a.GetHurt(minWarrior,en[i],damage,false,'攻击',this,true,null);
                    target = en[i];
                }
            }
            //自己对敌军群体发动攻击
            let r = new Report();
            r.InitExecuteReport(3,self,`执行【怀德畏威】`)
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue2,'谋略',false,this,null);
                a.GetHurt(self,res[i],damage,false,'谋略',this,true,null);
                if(target == res[i]){
                    let block = new StatusBlock(self,this,'混乱',1,0);
                    block.Init(res[i],true,3);
                }
            }

            this.STATISTICS.times += 1;
        }
    },
    //文鸯
    {
        id : 127,
        name : "盛气横凌",
        type : "追击",
        odd : 50,
        range : 1,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self,target){
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 260;//基础伤害率
            let damageValue2 = 80 + Math.floor(Math.random()*80);//基础伤害率2
            if(self.driver.version == '平衡'){
                damageValue1 = 220;
            }

            //造成伤害
            let damage = c.CountDamage(self,target,damageValue1,"攻击",false,this,null);
            a.GetHurt(self,target,damage,false,"攻击",this,true);
            
            //混乱
            if(target.morale > 100){
                let block = new StatusBlock(self,this,"混乱",1,0);
                block.Init(target,true);
            }
            else{
                let damage = c.CountDamage(self,target,damageValue2,"攻击",false,this,null);
                a.GetHurt(self,target,damage,false,"攻击",this,true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //羊祜
    {
        id : 129,
        name : "潜谋远计",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let q = new Query();
            let c = new Count();
            let j = new Judge();
            let damageValue = q.GetAdditionValue(self,120,'谋略',1.5);
            let odd = 75;//几率
            let attrValue = 15;//属性提高
            let cureValue = 60;
            let cureIndex = 0.55;
            if(self.driver.version == '平衡'){
                damageValue = q.GetAdditionValue(self,125,'谋略',1.5);
                odd = 80;
            }

            let action = function(){
                if(self.driver.turn < 5){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let cure = c.CountCure(self,self,cureValue,cureIndex,that);
                        let r = new Report();
                        r.InitTwoTargetReport(3,self,self,`【潜谋远计】令`,`恢复了${cure}兵力(${self.hp + cure})`,false)
                        a.GetCure(self,self,cure,that,true,r);
                        //提升属性
                        let block = new AttrBlock(self,that,'谋略',attrValue,99,true);
                        block.Init(self,true,3);
                    }
                }
            }
            let makeDamage = function(){
                if(self.driver.turn > 4){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`执行【潜谋远计】`);
                    let res = a.SelectTarget(self,5,false,true,3,false,false,that);
                    for(let i in res){
                        let target = res[i];
                        if(q.GetAttr(target,'谋略') < q.GetAttr(self,'谋略')){
                            let realOdd = q.GetMoraleOdd(self,odd);
                            let rand = Math.floor(Math.random()*100);
                            if(rand < realOdd){
                                let damage = c.CountDamage(self,target,damageValue,'谋略',false,that,null);
                                a.GetHurt(self,target,damage,false,'谋略',that,true,null);
                            }
                            else{
                                let r = new Report();
                                r.InitExecuteReport(3,target,`【潜谋远计】没有生效，生效几率为${realOdd}%`);
                            }
                        }
                    }
                }
            }
            let block = new AfterDamageBlock(self,this,action,4,true,true,true,true,true,true,true);
            block.Init(self,'急救');
            let block1 = new ExecuteBlock(self,this,'行动开始',makeDamage,99);
            block1.Init(self,false);
            let r = new Report();
            r.InitExecuteReport(2,self,`【潜谋远计】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
]

export default jinSArr;