import Report from "../js/report";
import Attack from "../js/attack";
import Count from "../js/count";
import StatusBlock from "../block/status";
import Query from "../js/query";
import AttrBlock from "../block/attr";
import RangeBlock from "../block/range";
import ExecuteBlock from "../block/execute";
import AfterDamageBlock from "../block/execute1";
import Judge from "../js/judge";
import IgnoreBlock from "../block/ignore";
import DamageBlock from "../block/damage";
let weiSArr = [
    //曹操
    {
        id : 2,
        name : "魏武之世",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let q = new Query();
            let index = q.GetAdditionValue(self,15,'谋略',0.045);
            if(self.driver.version == '平衡'){
                index = q.GetAdditionValue(self,12,'谋略',0.044);
            }

            let res = a.SelectTarget(self,5,false,true,3,false,true,this);
            //减属性
            for(let i in res){
                for(let j=0;j<4;j++){
                    let str = "";
                    switch(j){
                        case 0 : str = "攻击"; break;
                        case 1 : str = "防御"; break;
                        case 2 : str = "谋略"; break;
                        case 3 : str = "速度"; break;
                    }
                    let value = Math.floor(q.GetAttr(res[i],str) * (index / 100));
                    let block = new AttrBlock(self,this,str,-value,99);
                    let work =  block.Init(res[i],false);
                    if(work){
                        let report = new Report();
                        report.InitExecuteReport(2,res[i],`的${str}属性降低了${index}%(${value})(${q.GetAttr(res[i],str)})`);
                    }
                }
            }
            let fri = a.SelectTarget(self,5,true,false,3,true,false,this);
            //加攻击距离
            for(let i in fri){
                let block = new RangeBlock(self,this,true,1,99,0);
                let work =  block.Init(fri[i],false);
                if(work){
                    let report = new Report();
                    report.InitExecuteReport(2,fri[i],`攻击距离提高1(${q.GetRealAdRange(fri[i])})`);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //荀彧
    {
        id : 12,
        name : "驱虎吞狼",
        type : "主动",
        odd : 30,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let atk = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = q.GetAdditionValue(self,153,'谋略',1.85);//基础伤害率
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = atk.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"谋略",false,this);
                atk.GetHurt(self,res[i],damage,false,"谋略",this);
            }
            for(let i in res){
                let sta = new StatusBlock(self,this,"围困",2);
                sta.Init(res[i]);
            }
            this.STATISTICS.times += 1;
        }
    },
    //张辽
    {
        id : 21,
        name : "其疾如风",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let index = Math.floor(41 + (q.GetAttr(self,"谋略")-80)*0.075);
            let odd = 70;//触发连击几率

            let res = a.SelectTarget(self,3,true,false,3,true,true,this);
            for(let i in res){
                let attrBlock = new AttrBlock(self,this,"速度",index,3);
                let work = attrBlock.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`速度属性提高了${index}(${q.GetAttr(res[i],"速度")})`);
                }
            }
            let action = function(){
                let realOdd = q.GetMoraleOdd(self,odd);
                let rand = Math.floor(Math.random()*100);
                if(rand < realOdd){
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,this.beExecuted,`【其疾如风】令`,`获得连击`);
                    //打开连击的阀门
                    for(let i in this.beExecuted.STATUS){
                        if(this.beExecuted.STATUS[i].tactics == that){
                            this.beExecuted.STATUS[i].waitTime = 0;
                        }
                    }
                }
                else{
                    let r = new Report();
                    r.InitTwoTargetReport(2,self,this.beExecuted,`【其疾如风】对`,`没有生效，生效几率为${realOdd}%`);
                    //关闭连击的阀门
                    for(let i in this.beExecuted.STATUS){
                        if(this.beExecuted.STATUS[i].tactics == that){
                            this.beExecuted.STATUS[i].waitTime = 3;
                        }
                    }
                }
            }
            //施加连击
            for(let i in res){
                let block = new StatusBlock(self,this,'连击',3,3);
                let work =  block.Init(res[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],'连击(预备)效果已施加');
                    let block = new ExecuteBlock(self,this,"行动开始",action,3);
                    block.Init(res[i]);
                }
            }

            this.STATISTICS.times += 1;
        }
    },
    //郭嘉
    {
        id : 47,
        name : "奇佐鬼谋",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : false,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let index = 22;
            
            let statusType = "";
            let rand = Math.floor(Math.random()*4);
            switch(rand){
                case 0 : statusType = '混乱';break
                case 1 : statusType = '暴走';break
                case 2 : statusType = '怯战';break
                case 3 : statusType = '犹豫';break
            }
            let realRange = q.GetTacticsRange(self,this);
            //我军
            let ally = a.SelectTarget(self,realRange,true,false,2,true,true,this);
            for(let i in ally){
                let attrValue = Math.floor(ally[i].attr.tact * index/100);
                let block = new AttrBlock(self,this,"谋略",attrValue,2);
                let work = block.Init(ally[i],false);
                if(work){
                    let r = new Report();
                    r.InitExecuteReport(3,ally[i],`谋略属性提高22%(${attrValue})(${q.GetAttr(ally[i],"谋略")})`)
                }
            }
            //敌军
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let block = new StatusBlock(self,this,statusType,2,0);
                block.Init(res[i],true);
            }
            this.STATISTICS.times += 1;
        }
    },
    //典韦
    {
        id : 65,
        name : "舍身卫主",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 40;//触发几率
            let damageValue = 100;//伤害率

            let action  = function(){
                let target = this.damageFromWho;
                let j = new Judge();
                //判断是否在2距离以内
                let avail = j.IsInScale(self,target,2);
                if(avail){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let damage = c.CountDamage(self,target,damageValue,'攻击',false,that,null);
                        let report = new Report().InitTwoTargetReport(3,self,target,`【舍身卫主】令`,`损失了${damage}兵力(${target.hp-damage})`);
                        a.GetHurt(self,target,damage,false,'攻击',that,false,report);
                    }
                }
            }
            let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
            block.Init(self,'舍身卫主');
            let r = new Report();
            r.InitExecuteReport(2,self,`【舍身卫主】反击效果已施加`);
            //援护
            if(self.position != '0'){
                let target = self.ally[0];
                let action1 = function(){
                    let damage = this.damageValue;
                    if((target.hp + damage) < q.GetMaxHp(target)) target.hp += damage;//大营返还失去兵力
                    else target.hp = q.GetMaxHp(target);
                    let report = new Report().InitTwoTargetReport(3,self,target,`代替`,`损失了${damage}兵力(${self.hp})`);
                    a.GetHurt(this.damageFromWho,self,damage,this.isCommonAttack,this.damageType,this.damageFromTactics,false,report);
                }
                let block = new AfterDamageBlock(self,this,action1,3,true,true,true,true,true,true,true);
                block.Init(target,'舍身卫主');
                let r = new Report();
                r.InitExecuteReport(2,self,`【舍身卫主】护主效果已施加`);
            }
            else{
                let r = new Report();
                r.InitExecuteReport(2,self,`【舍身卫主】护主效果不符合触发条件`);
            }

            
            this.STATISTICS.times += 1;
        }
    },
    //徐晃
    {
        id : 86,
        name : "统军畏慎",
        type : "指挥",
        odd : 100,
        range : 2,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let index1 = q.GetAdditionValue(self,25,'谋略',0.12);
            let index2 = 60;//无视防御谋略属性
            let odd1 = 80;//触发几率1
            let odd2 = 30;//触发几率2

            
            for(let i in self.ally){
                let target = self.ally[i];
                //施加效果
                let action = function(){
                    let realOdd1 = q.GetMoraleOdd(target,odd1) - (self.driver.turn-1)*10;
                    let realOdd2 = q.GetMoraleOdd(target,odd2) + (self.driver.turn-1)*10;
                    let rand1 = Math.floor(Math.random()*100);
                    let rand2 = Math.floor(Math.random()*100);
                    let attrType = "";
                    if(q.GetAttr(target,'攻击') > q.GetAttr(target,'谋略')) attrType = '攻击';
                    else attrType = '谋略';
                    //触发增伤
                    if(rand1 < realOdd1){
                        let block = new DamageBlock(self,that,attrType,true,index1,false,false,0,0);
                        let work = block.Init(target,null,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【统军畏慎】令`,`${attrType == '攻击'?"攻击":"策略"}伤害提高${index1}%`)
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,target,`【统军畏慎】${attrType == '攻击'?"攻击":"策略"}伤害提高对`,`没有生效`)
                    }
                    //触发无视防御谋略
                    if(rand2 < realOdd2){
                        let block = new IgnoreBlock(self,that,attrType == '攻击'?"防御":"谋略",index2,0,0,null);
                        let work = block.Init(target,false);
                        if(work){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,target,`【统军畏慎】令`,`无视${index2}%${attrType == '攻击'?"防御":"谋略"}属性`)
                        }
                    }
                    else{
                        let r = new Report();
                        r.InitTwoTargetReport(2,self,target,`【统军畏慎】无视${attrType == '攻击'?"防御":"谋略"}属性对`,`没有生效`)
                    }
                }
                let block = new ExecuteBlock(self,this,'行动开始',action,99);
                block.Init(self.ally[i],false);
            }

            this.STATISTICS.times += 1;
        }
    },
    //贾诩
    {
        id : 98,
        name : "谋谟帷幄",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 2,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let odd = 55;//发动几率
            let damageValue = q.GetAdditionValue(self,171,'谋略',1.825);
            let damageValue2 = q.GetAdditionValue(self,76,'谋略',0.7);


            
            let res = a.SelectTarget(self,5,true,false,2,true,false,this);
            for(let i in res){
                let tag = "谋谟帷幄" + res[i].belong + res[i].position;
                let action = function(){
                    if(!res[i][tag]){
                        let realOdd = q.GetMoraleOdd(self,odd);
                        let rand = Math.floor(Math.random()*100);
                        if(rand < realOdd){
                            let target = a.SelectTarget(res[i],5,false,true,1,false,false,that);
                            for(let j in target){
                                let damage = c.CountDamage(res[i],target[j],damageValue,'谋略',false,that,null);
                                let report = new Report().InitTwoTargetReport(2,self,target[j],`【谋谟帷幄】令`,`损失了${damage}兵力(${target[j].hp - damage})`)
                                a.GetHurt(res[i],target[j],damage,false,'谋略',that,false,report)
                            }
                        }
                        else{
                            let r = new Report();
                            r.InitExecuteReport(2,res[i],`【谋谟帷幄】没有生效，生效几率为${realOdd}%`);
                        }
                        res[i][tag] = 1;
                    }
                }
                let clearTag = function(){
                    res[i][tag] = 0;
                }
                let block = new ExecuteBlock(self,this,'试图发动主动战法',action,99);
                block.Init(res[i],false);
                let block1 = new ExecuteBlock(self,this,'行动开始',clearTag,99);
                block1.Init(res[i],false);
                let r = new Report();
                r.InitExecuteReport(2,res[i],`【谋谟帷幄】效果已施加`);
            }
            //大营中军额外效果
            let newRes = [self.ally[0],self.ally[1]];
            
            for(let i in newRes){
                let tag = "谋谟帷幄追加" + newRes[i].belong + newRes[i].position;
                let action1 = function(){
                    if(!newRes[i][tag]){
                        let target = this.beExecuted;
                        if(target.hp < q.GetMaxHp(target)*0.6){
                            let en = a.SelectTarget(target,5,false,true,1,false,false,that);
                            for(let j in en){
                                let damage = c.CountDamage(target,en[j],damageValue2,'谋略',false,that,null);
                                let report = new Report().InitTwoTargetReport(2,self,en[j],`【谋谟帷幄】追加效果令`,`损失了${damage}兵力(${en[j].hp - damage})`)
                                a.GetHurt(target,en[j],damage,false,'谋略',that,false,report)
                            }
                        }
                        newRes[i][tag] = 1;
                    }
                    
                }
                let clearTag = function(){
                    newRes[i][tag] = 0;
                }
                let block = new ExecuteBlock(self,this,'试图发动主动战法',action1,99);
                block.Init(newRes[i],false);
                let block1 = new ExecuteBlock(self,this,'行动开始',clearTag,99);
                block1.Init(newRes[i],false);
                let r = new Report();
                r.InitExecuteReport(2,newRes[i],`【谋谟帷幄】追加效果已施加`);
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //曹彰
    {
        id : 112,
        name : "五兵之烈",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageIndex = 280;//基础伤害率
            if(self.driver.version == '平衡'){
                damageIndex == 300;
                let index = 0;
                for(let i in self.DAMAGE_VAR){
                    if(!self.DAMAGE_VAR[i].isBenefit && self.DAMAGE_VAR[i].damageType == '主动'){
                        index = index - self.DAMAGE_VAR[i].value;
                    }
                }
                index = Math.abs(index);
                if(index > 0){
                    let block = new DamageBlock(self,this,'主动',true,index,false,false,0,0);
                    block.Init(self,this,true,3);
                }
            }
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageIndex,"攻击",false,this);
                a.GetHurt(self,res[i],damage,false,"攻击",this);
            }

            this.STATISTICS.times += 1;
        }
    },
    //乐进
    {
        id : 116,
        name : "奋疾先登",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let damageValue = 190;//基础伤害率
            let value = 5;
            let maxValue = 40;//增伤上限
            let decline = 20;//属性降低
            
            let realRange = q.GetTacticsRange(self,this);
            let tag = '奋疾先登' + self.belong + self.position;


            let block = new DamageBlock(self,this,'攻击',true,value,false,false,99,0);
            let work = block.Init(self,null,false);
            if(work){
                let r = new Report();
                r.InitExecuteReport(2,self,`【奋疾先登】攻击伤害提高效果已施加`);
            }
            //每次行动提高层数
            let action = function(){
                let time = 0;
                for(let i in self.ally){
                    if(self.ally[i].alive && q.GetAttr(self,'速度') > q.GetAttr(self.ally[i],'速度')) time++;
                }
                for(let i in self.enemy){
                    if(self.enemy[i].alive && q.GetAttr(self,'速度') > q.GetAttr(self.enemy[i],'速度')) time++;
                }
                for(let i in self.DAMAGE_VAR){
                    if(self.DAMAGE_VAR[i].tactics == that){
                        self.DAMAGE_VAR[i].value += time * value;
                        if(self.DAMAGE_VAR[i].value <= maxValue){
                            let r = new Report();
                            r.InitTwoTargetReport(2,self,self,`【奋疾先登】令`,`攻击伤害提高了${self.DAMAGE_VAR[i].value}%`);
                        }
                        else{
                            let r = new Report();
                            r.InitExecuteReport(2,self,`执行【奋疾先登】`)
                            let res = a.SelectTarget(self,realRange,false,true,2,false,true,that);
                            for(let j in res){
                                let damage = c.CountDamage(self,res[j],damageValue,'攻击',false,that,null);
                                a.GetHurt(self,res[j],damage,false,'攻击',that,true,null);
                            }
                            for(let j in res){
                                let con = true;
                                for(let k in res[j].ATTR){
                                    if(res[j].ATTR[k].tactics == that){
                                        res[j].ATTR[k].value -= decline;
                                        let r = new Report();
                                        r.InitExecuteReport(3,res[j],`速度属性降低${Math.abs(res[j].ATTR[k].value)}(${q.GetAttr(res[j],'速度')})`);
                                        con = false;
                                    }
                                }
                                if(con){
                                    let block = new AttrBlock(self,that,'速度',-decline,99);
                                    block.Init(res[j],true,3);
                                }   
                            }
                            self.DAMAGE_VAR[i].value = 0;
                        }
                    }
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',action,99);
            block1.Init(self,false);
            this.STATISTICS.times += 1;
        }
    },
]

export default weiSArr;