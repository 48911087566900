import Attack from '../js/attack.js';
import Report from '../js/report.js'
import DamageStatus from "../block/damageStatus.js";
import Query from '../js/query.js';
import Count from '../js/count.js';
import AttrBlock from '../block/attr.js';
import DodgeBlock from '../block/dodge.js';
import StatusBlock from '../block/status.js';
import DamageBlock from '../block/damage.js';
import AttackExecuteBlock from '../block/execute4.js';
import ActiveTacticsBlock from '../block/execute3.js';
import ExecuteBlock from '../block/execute.js';
import CounterAttackBlock from '../block/counterAtk.js';
import AfterDamageBlock from '../block/execute1.js';
import Judge from '../js/judge.js';
import DamageCureDiscountBlock from '../block/discount.js';
import OddBlock from '../block/odd.js';
import RangeBlock from "../block/range";
import Util from '../js/util.js';
import BeforeMakeDamageBlock from '../block/execute2.js';
import CureStatusBlock from '../block/cureStatus.js';
import MakeDamageBlock from '../block/execute6.js';

let activeA = [
    //极火佐攻
    {
        id : 9,
        name : "极火佐攻",
        type : "主动",
        odd : 50,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,200,'谋略',2.05);
            let value = q.GetAdditionValue(self,26,'谋略',0.1);
            
            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let block = new DamageStatus(self,this,'燃烧',damageValue,'谋略',1,0,true);
                block.Init(res[i],true,3);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'主动',false,value,true,false,1,0);
                block.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //绝水遏敌
    {
        id : 10,
        name : "绝水遏敌",
        type : "主动",
        odd : 50,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,230,'谋略',2.25);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'围困',1,0);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //鼎足江东
    {
        id : 11,
        name : "鼎足江东",
        type : "主动",
        odd : 35,
        range : 2,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let cureValue = 75;
            let cureIndex = 0.75;
            let value = q.GetAdditionValue(self,17,'攻击',0.11);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,true,false,2,true,true,this);
            //追加
            let plus = true;
            for(let i in self.ally){
                let arm = u.TransferArms(self.ally[i].up_arms[self.ally[i].choseArms]);
                if(self.ally[i] != self && arm != u.TransferArms(self.up_arms[self.choseArms])){
                    plus = false;
                }
            }
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                a.GetCure(self,res[i],cure,this,true,null);
            }
            if(plus){
                for(let i in res){
                    let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                    a.GetCure(self,res[i],cure,this,true,null);
                }
            }
            else{
                for(let i in res){
                    let block = new CureStatusBlock(self,this,'休整',cureValue,cureIndex,1,0,100);
                    block.Init(res[i],true,3);
                }
            }
            for(let i in res){
                let block1 = new DamageBlock(self,this,'攻击',true,value,false,false,2,0);
                block1.Init(res[i],null,true,3);
                let block2 = new DamageBlock(self,this,'谋略',true,value,false,false,2,0);
                block2.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //威震逍遥
    {
        id : 12,
        name : "威震逍遥",
        type : "主动",
        odd : 30,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 125;//基础伤害率
            let value = q.GetAdditionValue(self,24,'速度',0.1);

            let tag = '威震逍遥' + self.belong + self.position + Math.floor(Math.random()*1000);
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            let action = function(){
                let target = this.beExecuted;
                //检查是否还存在威震逍遥的动摇
                let work = false;
                for(let i in target.SPECIAL_STATUS){
                    if(target.SPECIAL_STATUS[i].tactics == that && target.SPECIAL_STATUS[i][tag] > 0){
                        target.SPECIAL_STATUS[i][tag]-=1;
                        work = true;//找到了
                    }
                }
                if(work){
                    let damage = c.CountDamage(self,target,damageValue,'攻击',false,that,'动摇');
                    let r = new Report().InitExecuteReport(3,target,`由于【威震逍遥】的动摇损失了${damage}兵力(${target.hp-damage})`);
                    a.GetHurt(self,target,damage,false,'攻击',that,false,r);
                }
            }
            for(let i in res){
                let block = new DamageStatus(self,this,'动摇',damageValue,'攻击',2,0,false);
                let work = block.Init(res[i],true,3);
                block[tag] = 2;
                if(work){
                    let block1 = new AttackExecuteBlock(self,this,action,2,0,true);
                    block1.Init(res[i],false);
                    let block2 = new AfterDamageBlock(self,this,action,2,true,false,true,false,false,false,false);
                    block2.Init(res[i],false);
                }
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'攻击',false,value,false,false,2,0);
                block.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //凤仪亭
    {
        id : 13,
        name : "凤仪亭",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let u = new Util();
            let c = new Count();
            let value = 20;
            let damageValue = 288;

            let tag = '凤仪亭' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            //追加
            let plus = true;
            for(let i in self.ally){
                let arm = u.TransferArms(self.ally[i].up_arms[self.ally[i].choseArms]);
                if(self.ally[i] != self && arm != u.TransferArms(self.up_arms[self.choseArms])){
                    plus = false;
                }
            }
            if(plus) value = 25;
            let block = new DamageBlock(self,this,'攻击',true,value,false,false,2,0);
            let work = block.Init(self,null,true,3);
            let makeDamage = function(){
                let r = new Report();
                r.InitExecuteReport(2,self,`执行【凤仪亭】`)
                let res = a.SelectTarget(self,realRange,false,true,1,false,true,that);
                for(let i in res){
                    let damage = c.CountDamage(self,res[i],damageValue,'攻击',false,that,null);
                    a.GetHurt(self,res[i],damage,false,'攻击',that,true,null);
                }
            }
            let block1 = new ExecuteBlock(self,this,'行动开始',makeDamage,1);
            block1.Init(self,false);
            let getHurt = function(){
                if(self[tag] < 2){
                    for(let i in self.DAMAGE_VAR){
                        if(self.DAMAGE_VAR[i].tactics == that){
                            self.DAMAGE_VAR[i].value += value;
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,self,`【凤仪亭】令`,`攻击伤害提高${self.DAMAGE_VAR[i].value}%`);
                        }
                    }
                    self[tag] += 1;
                }
            }
            if(work){
                let block2 = new AfterDamageBlock(self,this,getHurt,2,true,true,true,true,true,true,true);
                block2.Init(self,'凤仪亭');
                self[tag] = 0;
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //万箭齐发
    {
        id : 14,
        name : "万箭齐发",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 3,
        arms : ['弓'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 160;//直接伤害率
            if(self.driver.version == '平衡'){
                damageValue = 175;
            }

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                if(i == 0){
                    let block = new DamageBlock(self,this,'攻击',true,-999,false,false,1,0);
                    block.Init(res[i],null,true,3);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //汜水关
    {
        id : 15,
        name : "汜水关",
        type : "主动",
        odd : 30,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 180;
            let damageValue1 = 130;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            
            for(let i in res){
                //立即触发动摇
                for(let k in self.enemy){
                    let en = self.enemy[k];
                    let times = 0;
                    let arr = [];
                    for(let j in en.SPECIAL_STATUS){
                        let tgt = en.SPECIAL_STATUS[j];
                        if(tgt.tactics == this){
                            times = tgt.duration;
                        }
                        else arr.push(tgt);
                    }
                    en.SPECIAL_STATUS = arr;
                    if(times != 0){
                        let r = new Report();
                        r.InitExecuteReport(3,self,`立即引发剩余动摇效果`);
                    }
                    for(let p=0;p<times;p++){
                        let damage = c.CountDamage(self,en,damageValue1,"攻击",false,this,null);
                        a.GetHurt(self,en,damage,false,"攻击",this,true,null);
                    }
                    if(times != 0){
                        let r = new Report();
                        r.InitExecuteReport(3,en,`来自【汜水关】的动摇效果消失了`);
                    }
                }
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                //施加恐慌
                let block = new DamageStatus(self,this,'动摇',damageValue1,'攻击',3,0,true);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //掎角之势
    {
        id : 16,
        name : "掎角之势",
        type : "主动",
        odd : 40,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 180;
            let damageValue2 = q.GetAdditionValue(self,143,'谋略',1.2);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            for(let j=0;j<2;j++){
                let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
                if(j==0){
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue1,"攻击",false,this,null);
                        a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                    }
                }
                else{
                    for(let i in res){
                        let damage = c.CountDamage(self,res[i],damageValue2,"谋略",false,this,null);
                        a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //雄兵破敌
    {
        id : 17,
        name : "雄兵破敌",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步'],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 210;
            let attrValue = 65;
            if(self.driver.version == '平衡'){
                damageValue = 220;
            }

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            for(let i in res){
                let block1 = new AttrBlock(self,this,'防御',-attrValue,2,false);
                block1.Init(res[i],true,3);
                let block2 = new AttrBlock(self,this,'谋略',-attrValue,2,false);
                block2.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //道行险阻
    {
        id : 18,
        name : "道行险阻",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 150;
            let damageValue2 = q.GetAdditionValue(self,150,'谋略',1.2);
            let value1 = q.GetAdditionValue(self,50,'攻击',0.18);
            let value2 = q.GetAdditionValue(self,50,'谋略',0.18);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            let makeDamage = function(){
                let target = this.beExecuted;
                let damage1 = c.CountDamage(self,target,damageValue1,'攻击',false,that,null);
                let r1 = new Report().InitTwoTargetReport(2,self,target,`【道行险阻】令`,`损失了${damage1}兵力(${target.hp - damage1})`);
                a.GetHurt(self,target,damage1,false,'攻击',that,false,r1);
                let damage2 = c.CountDamage(self,target,damageValue2,'谋略',false,that,null);
                let r2 = new Report().InitTwoTargetReport(2,self,target,`【道行险阻】令`,`损失了${damage2}兵力(${target.hp - damage2})`);
                a.GetHurt(self,target,damage2,false,'谋略',that,false,r2);
            }
            for(let i in res){
                let block1 = new AttrBlock(self,this,'防御',-value1,1,false);
                block1.Init(res[i],true,3);
                let block2 = new AttrBlock(self,this,'谋略',-value2,1,false);
                block2.Init(res[i],true,3);
                let block = new ExecuteBlock(self,this,'行动开始',makeDamage,1);
                block.Init(res[i],false);
            }
            this.STATISTICS.times += 1;
        }
    },
    //折戟强攻
    {
        id : 19,
        name : "折戟强攻",
        type : "主动",
        odd : 30,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = 225;
            let value = q.GetAttr(self,'攻击') * 0.5;

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i=0;i<res.length;i++){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }
            //攻击属性降低50%
            let block = new AttrBlock(self,this,"攻击",-value,0);
            block.Init(self,true);
            this.STATISTICS.times += 1;
        }
    },
    //人中吕布
    {
        id : 20,
        name : "人中吕布",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let damageValue = 200;
            let targetNumber = 2 + Math.floor(Math.random()*2);
            let value = 50;//主动战法伤害降低

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            if(self.name == '吕布'){
                //附加上无视兵种
                let block = new StatusBlock(self,this,"无视兵种",0);
                block.Init(self);
            }
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                
            }
            if(self.name == '吕布'){
                let arr = [];
                for(let i in self.STATUS){
                    if(self.STATUS[i].tactics != this) arr.push(self.STATUS[i]);
                    else{
                        let report = new Report();
                        report.InitExecuteReport(3,self,`来自【${self.STATUS[i].tactics.name}】的${self.STATUS[i].type}效果消失了`)
                    }
                }
            }
            for(let i in res){
                let arm = u.TransferArms(res[i].up_arms[res[i].choseArms]);
                if(arm == 'b'){
                    let block = new StatusBlock(self,this,'围困',1,0);
                    block.Init(res[i],true,3);
                }
                if(arm == 'g'){
                    let block = new RangeBlock(self,this,true,-2,1,0);
                    block.Init(res[i],true,3);
                }
                if(arm == 'q'){
                    let block = new DamageBlock(self,this,'主动',true,-value,false,false,1,0);
                    block.Init(res[i],null,true,3);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //筹策绝道
    {
        id : 21,
        name : "筹策绝道",
        type : "主动",
        odd : 50,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2 ,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let damageValue = q.GetAdditionValue(self,250,'谋略',2.425);
            let value = q.GetAdditionValue(self,25,'谋略',0.15);//主动战法伤害降低

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                
            }
            for(let i in res){
                let block1 = new AttrBlock(self,this,'谋略',-value,2,false);
                block1.Init(res[i],true,3);
                let block2 = new AttrBlock(self,this,'速度',-value,2,false);
                block2.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //全军突击
    {
        id : 22,
        name : "全军突击",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let damageValue = 145;
            let value = q.GetAdditionValue(self,28,'谋略',0.1);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
                
            }
            //清状态
            let fri = a.SelectTarget(self,realRange,true,false,3,true,false,this);
            for(let i in fri){
                let arms = u.TransferArms(fri[i].up_arms[fri[i].choseArms]);
                if(arms == 'q' || arms == 'b'){
                    u.ClearStatus(fri[i],this,false,3);
                }
            }
            //增伤
            for(let i in fri){
                let arms = u.TransferArms(fri[i].up_arms[fri[i].choseArms]);
                if(arms == 'q' || arms == 'b'){
                    for(let j=0;j<2;j++){
                        let block = new DamageBlock(self,this,'所有',true,value,true,false,99,0);
                        block.Init(fri[i],null,j == 0,3);
                    }
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //兼弱攻昧
    {
        id : 23,
        name : "兼弱攻昧",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = 210;
            let damageValue2 = q.GetAdditionValue(self,169,'谋略',1.2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            let lowDe = 100000;
            let lowestDe = null; 
            let lowTact = 100000;
            let lowestTact = null; 
            for(let i in res){
                let attr = q.GetAttr(res[i],'防御');
                if(attr < lowDe){
                    lowDe = attr;
                    lowestDe = res[i];
                }
                else if(attr == lowDe){
                    let rand = Math.floor(Math.random()*100);
                    if(rand == 1){
                        lowDe = attr;
                        lowestDe = res[i];
                    }
                }
            }
            for(let i in res){
                let attr = q.GetAttr(res[i],'谋略');
                if(attr < lowTact){
                    lowTact = attr;
                    lowestTact = res[i];
                }
                else if(attr == lowTact){
                    let rand = Math.floor(Math.random()*100);
                    if(rand == 1){
                        lowTact = attr;
                        lowestTact = res[i];
                    }
                }
            }
            if(lowestDe != null){
                let damage = c.CountDamage(self,lowestDe,damageValue1,'攻击',false,this,null);
                a.GetHurt(self,lowestDe,damage,false,'攻击',this,true,null);
            }
            if(lowestTact != null){
                let damage = c.CountDamage(self,lowestTact,damageValue2,'谋略',false,this,null);
                a.GetHurt(self,lowestTact,damage,false,'谋略',this,true,null);
            }
            this.STATISTICS.times += 1;
        }
    },
    //风声鹤唳
    {
        id : 24,
        name : "风声鹤唳",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let damageValue = q.GetAdditionValue(self,130,'谋略',1.3);
            let value = q.GetAdditionValue(self,12,'谋略',0.075);//主动战法伤害降低
            let targetNumber = 2 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let block = new DamageStatus(self,this,'恐慌',damageValue,'谋略',2,0,true);
                block.Init(res[i],true,3);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'谋略',false,value,false,false,2,0);
                block.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //翁处还张
    {
        id : 25,
        name : "翁处还张",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue1 = q.GetAdditionValue(self,132,'谋略',1.4);
            let damageValue2 = q.GetAdditionValue(self,165,'谋略',1.8);
            let targetNumber = 2 + Math.floor(Math.random()*2);
            let targetNumber1 = 1 + Math.floor(Math.random()*2);

            let tag = '翁处还张' + self.belong + self.position;
            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue1,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            let res1 = a.SelectTarget(self,realRange,false,true,targetNumber1,false,true,this);
            let makeDamage = function(){
                let tgt = this.beExecuted;//攻击者自己
                if(tgt[tag]){
                    let damage = c.CountDamage(self,tgt,damageValue2,"谋略",false,that,null);
                    let r = new Report().InitTwoTargetReport(3,self,tgt,`【翁处还张】令`,`损失了${damage}兵力(${tgt.hp - damage})`,false);
                    a.GetHurt(self,tgt,damage,false,"谋略",that,false,r);
                    tgt[tag] = false;
                }
            }
            for(let i in res1){
                let block = new MakeDamageBlock(self,this,makeDamage,1);
                block.Init(res1[i],'翁处还张');
                res1[i][tag] = true;
                let r = new Report();
                r.InitExecuteReport(3,res1[i],`【翁处还张】效果已施加`);
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //伐谋
    {
        id : 32,
        name : "伐谋",
        type : "主动",
        odd : 40,
        range : 3,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,209,'谋略',2.175);
            let value = q.GetAdditionValue(self,45,'谋略',0.25);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res){
                let block1 = new AttrBlock(self,this,'攻击',-value,2,false);
                block1.Init(res[i],true,3);
                let block2 = new AttrBlock(self,this,'谋略',-value,2,false);
                block2.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //楚歌四起
    {
        id : 33,
        name : "楚歌四起",
        type : "主动",
        odd : 50,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let damageValue = q.GetAdditionValue(self,127,'谋略',1.275);
            let targetNumber = 2 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let block = new DamageStatus(self,this,'恐慌',damageValue,'谋略',2,0,true);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //焰焚箕轸
    {
        id : 35,
        name : "焰焚箕轸",
        type : "主动",
        odd : 50,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2 ,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,119,'谋略',1.3);
            let targetNumber = 2 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res){
                let block = new DamageStatus(self,this,'燃烧',damageValue,'谋略',1,0,true);
                block.Init(res[i],true,3);
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //落雷
    {
        id : 36,
        name : "落雷",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,148,'谋略',1.35);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'混乱',1,0);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //迷阵
    {
        id : 37,
        name : "迷阵",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let damageValue = q.GetAdditionValue(self,155,'谋略',1.5);

            let realRange = q.GetTacticsRange(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,false,true,realTargetNumber,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res){
                let block = new StatusBlock(self,this,'暴走',1,0);
                block.Init(res[i],true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //激水之疾
    {
        id : 48,
        name : "激水之疾",
        type : "主动",
        odd : 35,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2 ,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let u = new Util();
            let damageValue = q.GetAdditionValue(self,180,'谋略',1.8);
            let value = 20;

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue,"谋略",false,this,null);
                a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
            }
            for(let i in res){
                if(res[i].morale < self.morale){
                    let block = new AttrBlock(self,this,'谋略',-value,2,false);
                    block.Init(res[i],true,3);
                }
            }
            this.STATISTICS.times += 1;
        }
    },
    //奇术折冲
    {
        id : 49,
        name : "奇术折冲",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : [2,3],
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let damageValue = q.GetAdditionValue(self,116,'谋略',1.1);
            let value = q.GetAdditionValue(self,20,'谋略',0.12);//普通攻击伤害降低
            let targetNumber = 2 + Math.floor(Math.random()*2);

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,targetNumber,false,true,this);
            for(let i in res){
                let block = new DamageStatus(self,this,'妖术',damageValue,'谋略',2,0,true);
                block.Init(res[i],true,3);
            }
            for(let i in res){
                let block = new DamageBlock(self,this,'普通攻击',true,-value,false,false,2,0);
                block.Init(res[i],null,true,3);
            }
            this.STATISTICS.times += 1;
        }
    },
    //九变之利
    {
        id : 50,
        name : "九变之利",
        type : "主动",
        odd : 40,
        range : 4,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 2,
        arms : ['骑','步',"弓"],
        wait : 1,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let j = new Judge();
            let attrValue = 35;//属性降低
            let value = 40;;//受到伤害增加40%
            let damageValue1 = 90;//伤害率
            let damageValue2 = 180;//直接伤害率

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,2,false,true,this);
            for(let i in res){
                if(j.IsGetStatus(res[i],'混乱') || j.IsGetStatus(res[i],'怯战') || j.IsGetStatus(res[i],'犹豫')){
                    let block1 = new AttrBlock(self,this,'防御',-attrValue,2,false);
                    block1.Init(self,true,3);
                    let block2 = new AttrBlock(self,this,'谋略',-attrValue,2,false);
                    block2.Init(self,true,3);
                }
                else{
                    let block = new StatusBlock(self,this,'怯战',2,0);
                    block.Init(res[i],true,3);
                }
                if(res[i].SPECIAL_STATUS.length > 0){
                    let block1 = new DamageBlock(self,this,'攻击',false,value,false,false,2,0);
                    block1.Init(res[i],null,true,3);
                    let block2 = new DamageBlock(self,this,'谋略',false,value,false,false,2,0);
                    block2.Init(res[i],null,true,3);
                }
                else{
                    let block = new DamageStatus(self,this,'动摇',damageValue1,'攻击',2,0,true);
                    block.Init(res[i],true,3);
                }
            }
            for(let i in res){
                let damage = c.CountDamage(self,res[i],damageValue2,"攻击",false,this,null);
                a.GetHurt(self,res[i],damage,false,"攻击",this,true,null);
            }

            this.STATISTICS.times += 1;
        }
    },
    //望风而降
    {
        id : 51,
        name : "望风而降",
        type : "主动",
        odd : 40,
        range : 5,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let j = new Judge();
            let damageValue1 = q.GetAdditionValue(self,108,'谋略',1.5);//伤害率
            let damageValue2 = q.GetAdditionValue(self,98,'谋略',1.25);//直接伤害率

            let realRange = q.GetTacticsRange(self,this);
            let res = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in res){
                for(let j=0;j<2;j++){
                    let damage = c.CountDamage(self,res[i],damageValue1,"谋略",false,this,null);
                    a.GetHurt(self,res[i],damage,false,"谋略",this,true,null);
                }
            }
            for(let i in res){
                if(res[i].morale < self.morale){
                    let block = new DamageStatus(self,this,'恐慌',damageValue2,'谋略',1,0,true);
                    block.Init(res[i],true,3);
                }
            }
            
            this.STATISTICS.times += 1;
        }
    },
    //敛众定气
    {
        id : 53,
        name : "敛众定气",
        type : "主动",
        odd : 40,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['骑','步',"弓"],
        wait : 0,
        execute(self){
            let u = new Util();
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let cureValue = 85;
            let cureIndex = 0.8;


            let realRange = q.GetRealTacticsOdd(self,this);
            let realTargetNumber = q.GetRealTargetNumber(this.targetNumber,self,this);
            let res = a.SelectTarget(self,realRange,true,false,realTargetNumber,true,true,this);
            for(let i in res){
                let work = u.ClearStatus(res[i],this,false,3);
                if(!work){
                    let r = new Report();
                    r.InitExecuteReport(3,res[i],`没有效果可以移除`);
                }
            }
            for(let i in res){
                let cure = c.CountCure(self,res[i],cureValue,cureIndex,this);
                a.GetCure(self,res[i],cure,this,true,null);
            }
            
            this.STATISTICS.times += 1;
        }
    },
]

export default activeA;