import DamageBlock from "../block/damage";
import ExecuteBlock from "../block/execute";
import AfterDamageBlock from "../block/execute1";
import AttrBlock from '../block/attr.js'
import StatusBlock from "../block/status";
import Attack from "../js/attack";
import Count from "../js/count";
import Judge from "../js/judge";
import Query from "../js/query";
import Report from "../js/report";

let hanSArr = [
    //吕布
    {
        id : 24,
        name : "金吾飞将",
        type : "主动",
        odd : 35,
        range : 5,
        isDamageMaker : true,
        isControlMaker : true,
        isCureMaker : false,
        selectFri : false,
        selectSelf : false,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['骑'],
        wait : 0,
        execute(self){
            let q = new Query();
            let a = new Attack();
            let c = new Count();
            let j = new Judge();
            let damageValue = 275;

            let realRange = q.GetTacticsRange(self,this);
            let allTargets = a.SelectTarget(self,realRange,false,true,3,false,true,this);
            let res = [];
            for(let i in allTargets){
                if(j.IsGetStatus(allTargets[i],"混乱") || j.IsGetStatus(allTargets[i],"暴走")){
                    res.push(allTargets[i]);
                }
            }
            //存在处于混乱或暴走的目标
            if(res.length > 0){
                let target = res[Math.floor(Math.random()*res.length)];
                let r = new Report();
                r.InitExecuteReport(3,self,`执行【金吾飞将】`)
                let damage = c.CountDamage(self,target,damageValue,"攻击",false,this);
                a.GetHurt(self,target,damage,false,"攻击",this,true);
            }

            let target = a.SelectTarget(self,realRange,false,true,1,false,true,this);
            for(let i in target){
                let damage = c.CountDamage(self,target[i],damageValue,"攻击",false,this);
                a.GetHurt(self,target[i],damage,false,"攻击",this,true);
                let block = new StatusBlock(self,this,"混乱",2,0);
                block.Init(target[i]);
            }
            
            


            this.STATISTICS.times += 1;
        }
    },
    //董卓
    {
        id : 44,
        name : "酒池肉林",
        type : "指挥",
        odd : 100,
        range : 5,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let a = new Attack();
            let value = q.GetAdditionValue(self,28,'防御',0.225);
            let value2 = 999;
            let cureIndex = 35;
            
            let res = a.SelectTarget(self,4,true,false,3,true,true,this);
            let enemy = a.SelectTarget(self,5,false,true,3,false,false,that);
            for(let i in res){
                let block1 = new DamageBlock(self,this,"攻击",false,-value,false,false,2,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到攻击伤害降低${value}%`);
                }
                let block2 = new DamageBlock(self,this,"谋略",false,-value,false,false,2,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到策略伤害降低${value}%`);
                }
                //减伤
                let block3 = new DamageBlock(self,this,"攻击",true,-value2,false,false,3,3);
                let work3 = block3.Init(res[i],null,false);
                if(work3){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`造成攻击伤害大幅降低(预备)`);
                }
                let block4 = new DamageBlock(self,this,"谋略",true,-value2,false,false,3,3);
                let work4 = block4.Init(res[i],null,false);
                if(work4){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`造成策略伤害大幅降低(预备)`);
                }
            }
            let blood = function(){
                if(this.damageFromWho == self){
                    let cure = this.damageValue * cureIndex/100;
                    //最多只可以恢复失去兵力中80%的兵力
                    let cure_max = (q.GetMaxHp(self) - self.hp) * 0.8;
                    if(cure > cure_max){
                        //如果质量量溢出
                        cure = cure_max;
                    }
                    cure = Math.floor(cure);
                    a.GetCure(self,self,cure,this.tactics,true);
                }
            }
            let action = function(){
                if(self.driver.turn == 4){
                    let r = new Report();
                    r.InitExecuteReport(2,self,`获得吸血能力`);
                    for(let i in enemy){
                        let block = new AfterDamageBlock(self,that,blood,99,true,true,true,true,true,true,true);
                        block.Init(enemy[i],"酒池肉林");
                    }
                }
            }
            let block = new ExecuteBlock(self,this,"行动开始",action,5);
            block.Init(self);
            this.STATISTICS.times += 1;
        }
    },
    //张机
    {
        id : 60,
        name : "金匮要略",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : true,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let odd = 50;//触发几率
            let value = q.GetAdditionValue(self,20.4,'谋略',0.13);//减伤
            let cureValue = 80;//基础恢复率
            let cureIndex = 0.75;//恢复率增长率
            if(self.driver.version == '平衡'){
                cureValue = 60;
            }
            
            let res = self.ally;
            for(let i in res){
                let action = function(){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let cure = c.CountCure(self,res[i],cureValue,cureIndex,that);
                        let report = new Report();
                        // report.InitExecuteReport(3,res[i],`由于【金匮要略】恢复了${cure}兵力(${res[i].hp})`,false)
                        report.InitTwoTargetReport(3,self,res[i],`【金匮要略】令`,`恢复了${cure}兵力(${res[i].hp + cure})`,false)
                        a.GetCure(self,res[i],cure,that,true,report);
                    }
                }
                let block1 = new DamageBlock(self,this,"攻击",false,-value,false,false,3,0);
                let work1 =  block1.Init(res[i],null,false);
                if(work1){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到攻击伤害降低${value}%`);
                }
                let block2 = new DamageBlock(self,this,"谋略",false,-value,false,false,3,0);
                let work2 =  block2.Init(res[i],null,false);
                if(work2){
                    let r = new Report();
                    r.InitExecuteReport(2,res[i],`受到策略伤害降低${value}%`);
                }
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                let work = block.Init(res[i],"急救");
                if(work){
                    let report = new Report();
                    report.InitExecuteReport(2,res[i],`急救效果已施加`);
                }
            }
            let clearCure = function(){
                if(self.driver.turn == 4){
                    for(let i in res){
                        let temp = [];
                        for(let j in res[i].AFTER_DAMAGE){
                            if(res[i].AFTER_DAMAGE[j].tactics != that) temp.push(res[i].AFTER_DAMAGE[j]);
                            else{
                                console.log(123);
                            }
                        }
                        res[i].AFTER_DAMAGE = temp;
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',clearCure,4);
            block.Init(self);
            this.STATISTICS.times += 1;
        }
    },
    //皇甫嵩
    {
        id : 91,
        name : "京观垒冢",
        type : "被动",
        odd : 100,
        range : 1,
        isDamageMaker : true,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : true,
        targetNumber : 1,
        arms : ['步'],
        wait : 0,
        execute(self){
            let that = this;
            let a = new Attack();
            let c = new Count();
            let q = new Query();
            let odd = 70;//触发几率
            let damageValue1 = 200;
            let damageValue2 = q.GetAdditionValue(self,200,'谋略',1);
            if(self.driver.version == '平衡'){
                odd = 65;
            }
            
            let action = function(){
                let target = this.beExecuted;
                //伤害来自皇甫，且不是京观垒冢
                if(this.damageFromWho == self && this.damageFromTactics != that){
                    let realOdd = q.GetMoraleOdd(self,odd);
                    let rand = Math.floor(Math.random()*100);
                    if(rand < realOdd){
                        let rand1 = Math.floor(Math.random()*2);
                        if(rand1 == 1){
                            let damage = c.CountDamage(self,target,damageValue1,'攻击',false,that,null);
                            let report = new Report().InitTwoTargetReport(3,self,target,`【京观垒冢】令`,`损失了${damage}兵力(${target.hp-damage})`);
                            a.GetHurt(self,target,damage,false,'攻击',that,false,report);
                        }
                        else{
                            let damage = c.CountDamage(self,target,damageValue2,'谋略',false,that,null);
                            let report = new Report().InitTwoTargetReport(3,self,target,`【京观垒冢】令`,`损失了${damage}兵力(${target.hp-damage})`);
                            a.GetHurt(self,target,damage,false,'谋略',that,false,report);
                        }
                    }
                }
            }
            for(let i in self.enemy){
                let target = self.enemy[i];
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                block.Init(target,'京观垒冢');
            }
            let r = new Report();
            r.InitExecuteReport(2,self,`【京观垒冢】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
    //刘表
    {
        id : 105,
        name : "抚民励德",
        type : "指挥",
        odd : 100,
        range : 3,
        isDamageMaker : false,
        isControlMaker : false,
        isCureMaker : false,
        selectFri : true,
        selectSelf : true,
        selectEnemy : false,
        targetNumber : 3,
        arms : ['弓'],
        wait : 0,
        execute(self){
            let that = this;
            let q = new Query();
            let value = q.GetAdditionValue(self,80,'谋略',0.325);
            let decline = Math.floor(value/8);
            
            let tag = '抚民励德' + self.belong + self.position;
            for(let i in self.ally){
                let target = self.ally[i];
                let block1 = new AttrBlock(self,this,'谋略',0,99);
                block1.isBenefit = true;
                block1[tag] = 0;
                block1.Init(target,false);
                let block2 = new AttrBlock(self,this,'防御',0,99);
                block2.isBenefit = true;
                block1[tag] = 0;
                block2.Init(target,false);
            }
            let action = function(){
                if(self.driver.turn == 2 || self.driver.turn == 4 || self.driver.turn == 6){
                    let r = new Report();
                    r.InitExecuteReport(1,self,`执行【抚民励德】`);
                    for(let i in self.ally){
                        let target = self.ally[i];
                        for(let j in target.ATTR){
                            if(target.ATTR[j].tactics == that){
                                target.ATTR[j].value = value;
                                target.ATTR[j][tag] = 8;
                                let r = new Report();
                                r.InitExecuteReport(2,target,`${target.ATTR[j].attrType}属性提高${value}(${q.GetAttr(target,target.ATTR[j].attrType)})`)
                            }
                        }
                    }
                }
            }
            let block = new ExecuteBlock(self,this,'回合开始',action,99);
            block.Init(self,false);
            for(let i in self.enemy){
                let en = self.enemy[i];
                let action = function(){
                    let target = this.damageFromWho;//伤害来源者抚民励德层数降低
                    for(let j in target.ATTR){
                        if(target.ATTR[j].tactics == that && target.ATTR[j][tag] > 0){
                            target.ATTR[j].value -= decline;
                            target.ATTR[j][tag]--;
                            let r = new Report();
                            r.InitTwoTargetReport(3,self,target,`【抚民励德】令`,`${target.ATTR[j].attrType}属性提高${target.ATTR[j].value}(${q.GetAttr(target,target.ATTR[j].attrType)})`)
                        }
                    }
                }
                let block = new AfterDamageBlock(self,this,action,99,true,true,true,true,true,true,true);
                block.Init(en,'抚民励德');
            }
            let r = new Report();
            r.InitExecuteReport(2,self,`【抚民励德】效果已施加`);
            this.STATISTICS.times += 1;
        }
    },
]

export default hanSArr;