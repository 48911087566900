<template>
    <div class="c-chess_config_container">
        <!-- <div class="select_my_team current" v-if="$store.state.equipment == 'phone'" ref="select_my_team" @click="SwitchTeam(0)">我的队伍</div> -->
        <div class="select_my_team current"  ref="select_my_team" @click="SwitchTeam(0)">我的队伍</div>
        <div class="texture" ></div>
        <div class="select_enemy_team"  ref="select_enemy_team" @click="SwitchTeam(1)">敌方队伍</div>
        
        <!-- 武将战法配置 -->
        <div class="current_frame" v-if="$store.state.equipment != 'phone'" ref="current_frame"></div>
        
        <div class="visual_container" ref="visual_container">
            <div class="team_container"  v-for="t in teams" :key="t.index">
                <div class="warrior_config" v-for="w in t.warriorArr" :key="w.index">
                    <div class="warrior_box" ref="warrior_config" @click="ConfigWarrior(w)">
                        
                        <img :src="w.img.banner" alt="" class="warrior_img" v-if="w.id != -1">
                        <ul class="red_list" v-show="w.name != ''">
                            <li v-for="s in w.red" :key="s.index" class="red"><img class="cover" src="../../assets/ui/ui (74).png" alt=""></li>
                            <li v-for="s in (5-w.red)" :key="s.index"><img class="cover" src="../../assets/ui/ui (76).png" alt=""></li>
                        </ul>
                        <div class="bottom_black_tape"></div>
                        
                        <div class="name_tape_han" v-show="w.name != ''"></div>
                        <div :class="CampTape(w.camp)">{{w.name}}</div>
                        
                        <div class="warrior_sign" v-show="w.name != ''">
                            <img src="../../assets/ui/ui (66).png" alt="" v-show="w.sign == 's2'">
                            <img src="../../assets/ui/ui (67).png" alt="" v-show="w.sign == 's3'">
                            <img src="../../assets/ui/ui (68).png" alt="" v-show="w.sign == 'sp'">
                            <img src="../../assets/ui/ui (69).png" alt="" v-show="w.sign == 'xp'">
                        </div>

                        <img src="../../assets/ui/ui (73).png" alt="" class="up_mark" v-if="w.name != '' && w.red == 5">
                        <img src="../../assets/ui/ui (65).png" alt="" class="up_mark" v-if="w.name != '' && w.red != 5">

                        <div class="arm4" v-show="w.name != ''">
                            <img src="../../assets/ui/ui (82).png" alt="">
                            <img :src="require(`../../assets/arms/${w.up_arms[w.choseArms]}.png`)" alt="" v-if="w.name != ''" class="icon">
                            <div class="arm_num">{{w.range}}</div>
                        </div>
                        <div class="level" v-show="w.name != ''">Lv.{{w.level}}</div>
                    </div>
                    <div class="tactics" v-show="w.name != ''">
                        <li @click="ConfigTacticsPanel(null,0,w,true)">
                            <!-- <div class="name_s" v-if="t.quality == 'S'">{{t.name}}</div>
                            <div class="name_a" v-if="t.quality == 'A'">{{t.name}}</div>
                            <div class="name_b" v-if="t.quality == 'B'">{{t.name}}</div>
                            <div class="s_sign" v-if="t.quality == 'S'"></div>
                            <div class="a_sign" v-if="t.quality == 'A'"></div>
                            <div class="b_sign" v-if="t.quality == 'B'"></div> -->
                            <div class="box">
                                <img :src="TacticsStyle(w.tactics_type,true)" alt="" class="tactics_img">
                                <img src="../../assets/ui/ui (100).png" class="tactics_frame" v-if="w.tactics_quality == 'A'">
                                <img src="../../assets/ui/ui (101).png" class="tactics_frame" v-if="w.tactics_quality == 'S'">
                                <img src="../../assets/ui/ui (102).png" class="tactics_frame" v-if="w.tactics_quality == 'B'">
                                <div class="name">
                                    <img src="../../assets/ui/ui (114).png" class="cover" v-if="w.tactics_quality == 'A'" alt="">
                                    <img src="../../assets/ui/ui (113).png" class="cover" v-if="w.tactics_quality == 'S'" alt="">
                                    <img src="../../assets/ui/ui (115).png" class="cover" v-if="w.tactics_quality == 'B'" alt="">
                                    <div class="text">{{ w.tactics_name }}</div>
                                </div>
                                <img src="../../assets/ui/ui (95).png" class="quality_sign" v-if="w.tactics_quality == 'S'" alt="">
                                <img src="../../assets/ui/ui (96).png" class="quality_sign" v-if="w.tactics_quality == 'A'" alt="">
                                <img src="../../assets/ui/ui (97).png" class="quality_sign" v-if="w.tactics_quality == 'B'" alt="">
                            </div>
                        </li>
                        <li v-for="s,id in w.tacticsArr" :key="s.index" @click="ConfigTacticsPanel(s,id,w,false)">
                            <div class="box">
                                <img :src="TacticsStyle(s.type,true)" alt="" class="tactics_img">
                                <img src="../../assets/ui/ui (100).png" class="tactics_frame" v-if="s.quality == 'A' && s.sign != '典藏'">
                                <img src="../../assets/ui/ui (101).png" class="tactics_frame" v-if="s.quality == 'S' && s.sign != '典藏'">
                                <img src="../../assets/ui/ui (102).png" class="tactics_frame" v-if="s.quality == 'B' && s.sign != '典藏'">
                                <img src="../../assets/ui/ui (99).png" class="tactics_frame" v-if="s.quality == 'A' && s.sign == '典藏'">
                                <img src="../../assets/ui/ui (98).png" class="tactics_frame" v-if="s.quality == 'S' && s.sign == '典藏'">
                                <img src="../../assets/ui/ui (163).png" class="tactics_frame" v-if="s.quality == 'B' && s.sign == '典藏'">
                                
                                <div class="name">
                                    <img src="../../assets/ui/ui (114).png" class="cover" v-if="s.quality == 'A'" alt="">
                                    <img src="../../assets/ui/ui (113).png" class="cover" v-if="s.quality == 'S'" alt="">
                                    <img src="../../assets/ui/ui (115).png" class="cover" v-if="s.quality == 'B'" alt="">
                                    <div class="text">{{ s.name }}</div>
                                </div>
                                <img src="../../assets/ui/ui (95).png" class="quality_sign" v-if="s.quality == 'S'" alt="">
                                <img src="../../assets/ui/ui (96).png" class="quality_sign" v-if="s.quality == 'A'" alt="">
                                <img src="../../assets/ui/ui (97).png" class="quality_sign" v-if="s.quality == 'B'" alt="">
                            </div>
                        </li>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="lock_frame" v-if="$store.state.equipment != 'phone'" ref="lock_frame"></div>
    </div>
</template>

<script>
export default {
    name : 'ChessConfig',
    data(){
        return{
            game : this.$store.state.BorderChess,
            teams : [this.$store.state.BorderChess.BlueTeam,this.$store.state.BorderChess.RedTeam],
            SIGN_CAMP : 0,
        }
    },
    methods:{
        //战法名称条背景色
        TacticsNameBgColor(type){
            switch(type){
                case "command" : return "name command_type_linear";
                case "active" : return "name active_type_linear";
                case "combo" : return "name combo_type_linear";
                case "passive" : return "name passive_type_linear";
            }
        },
        //战法的类型图像
        TacticsStyle(type,isUrl){
            switch(type){
                case "command" : return isUrl ? require("../../assets/ui/ui (92).png") : require("../../assets/ui/ui (103).png");
                case "active" : return isUrl ? require("../../assets/ui/ui (93).png") : require("../../assets/ui/ui (103).png");
                case "combo" : return isUrl ?require("../../assets/ui/ui (94).png") : require("../../assets/ui/ui (103).png");
                case "passive" : return isUrl ?require("../../assets/ui/ui (91).png") : require("../../assets/ui/ui (103).png");
                default : return require("../../assets/ui/ui (103).png");
            }
        },
        //阵营色块
        CampTape(camp){
            switch(camp){
                case "汉" : return "middle_name_tape_linear_han";
                case "魏" : return "middle_name_tape_linear_wei";
                case "蜀" : return "middle_name_tape_linear_shu";
                case "吴" : return "middle_name_tape_linear_wu";
                case "群" : return "middle_name_tape_linear_qun";
                case "晋" : return "middle_name_tape_linear_jin";
            }
        },
        //切换队伍
        SwitchTeam(value){
            if(value == 0){
                this.$refs.select_my_team.classList.add('current');
                this.$refs.select_enemy_team.classList.remove('current');
                if(this.$store.state.equipment == 'phone'){
                    this.$refs.visual_container.style.left = 0;
                }
                else{
                    this.$refs.current_frame.style.left = 0;
                    this.$refs.lock_frame.style.left = 56 + 'vw';;
                }
                this.game.currentTeam = this.game.BlueTeam;
            }else{
                this.$refs.select_my_team.classList.remove('current');
                this.$refs.select_enemy_team.classList.add('current');
                if(this.$store.state.equipment == 'phone'){
                    this.$refs.visual_container.style.left = -100 + 'vw';
                }else{
                    this.$refs.current_frame.style.left = 56 + 'vw';
                    this.$refs.lock_frame.style.left = 0;
                }
                this.game.currentTeam = this.game.RedTeam;
            }
        },
        //打开武将容器面板
        ConfigWarrior(warriorItem){
            this.game.currentWarriorItem = warriorItem;
            warriorItem.name == '' ? this.$bus.$emit('ShowWarriorContainer',false) : this.$bus.$emit('ShowWarriorAdjustPanel',true);
        },
        //打开战法配置面板
        ConfigTacticsPanel(tacticsItem,id,warrior,isMain){
            this.game.control.isMainTactics = isMain;
            if(isMain){
                this.game.currentWarriorItem = warrior;
                let item = {
                    type : warrior.tactics_type,
                    quality : warrior.tactics_quality,
                    name : warrior.tactics_name,
                    odds : warrior.tactics_odds,
                    range : warrior.tactics_range,
                    describe : warrior.tactics_describe,
                }
                this.game.currentTacticsItem = item;
                this.$bus.$emit('ShowTacticsInfo',true);
            }else{
                this.game.currentWarriorItem = warrior;
                if(tacticsItem.id == -1){
                    this.game.currentTacticsItem = tacticsItem;
                    this.$bus.$emit('ShowTacticsContainer',true);
                }
                else{
                    this.game.currentTacticsItem = tacticsItem;
                    this.$bus.$emit('ShowTacticsInfo',true);
                }
            }
            
        }
    }
}
</script>

<style lang="less">
.c-chess_config_container{
    position: absolute;left: 0; top: 0;
    width: 100%; height: 100vh;
    .select_my_team,.select_enemy_team{
        position: absolute; left: 0; top: 12vh;
        margin: 5vw 0 0 0;
        width: 40vw;height: 10vw;
        line-height: 10vw;
        color: rgb(147, 147, 147);
        font-family: "FZLBJW";
        font-size: 4vw;
        background: url('../../assets/ui/ui (116).png')no-repeat;
        background-size: 100% 100%;
    }
    .select_enemy_team{
        background: url('../../assets/ui/ui (57).png')no-repeat;
        background-size: 100% 100%;
        left: 60vw;
    }
    .select_my_team.current,.select_enemy_team.current{
        color: white;
        background-size: 100% 100%;
    }
    .texture{
        position: absolute; left: 50%; top : 11.5vh;
        margin: 4vw 0 0 -5vw;
        width: 10vw;height: 15vw;
        background: url('../../assets/background/bg (34).png')no-repeat;
        background-size: 100% 100%;
    }
    //武将调整容器
    .visual_container{
        position: absolute; left: 0; top: 20vw;
        margin: 12vh 0 0 0;
        width: 200vw;height: 60vw;
        transition: all .2s;
        .team_container{
            float: left;position: relative;
            width: 100vw;height: 60vw;
            .warrior_config{
                position: relative;float: left;
                margin-top:2vw ;
                width: 100vw;height: 18vw;
                background: rgb(37, 37, 37);
                background-size: cover;
                box-shadow: 0 0 2px black;
                //武将的小盒子
                .warrior_box{
                    position: absolute; left: 0; top: 0vw;
                    width: 50vw;height: 18vw;
                    overflow: hidden;
                    background: url('../../assets/background/bg (21).png') center no-repeat;
                    background-size: cover;
                    transition: all .1s;
                    .warrior_img{
                        position: absolute; left:0; top: 0;
                        height: 18vw;
                    }
                    .bottom_black_tape{
                        top: auto; left: 0; bottom: 0;
                        width: 100%;height: 5vw;
                        background:linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(0, 0, 0, 0.6));
                    }
                    
                    .add_btn{
                        position: absolute; left: 50%; top : 50%;
                        margin: -3vw 0 0 -3vw;
                        width: 6vw;height: 6vw;
                        background: #000;
                    }
                    .warrior_camp{
                        position: absolute; left: 1vw; top: 1vw;
                        width: 6vw;height: 6vw;
                    }
                    .warrior_sign{
                        position: absolute; left: 0vw; bottom: 0vw;top: auto;
                        width: 6vw;height: 6vw;
                        img{
                            position: absolute; left: 0; top: 0;
                            width: 100%;height: 100%;
                        }
                    }
                    .red_list{
                        position: absolute; right: 0; top: 0;
                        width: 100%;
                        height: 5vw;
                        background:linear-gradient(to bottom,rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0));
                        li{
                            float: left;position: relative;
                            width: 3vw;height: 3vw;
                            margin: 1.5vw 0 0 0;
                        }
                        li:nth-of-type(1){
                            margin: 1.5vw 0 0 27vw;
                        }
                        
                    }
                    .level{
                        font-family: "微软雅黑";
                        bottom: 0; left: 6vw; top: auto;
                        width: 10vw; height: 5vw;
                        text-align: left;
                        line-height: 5vw;
                    }
                    .up_mark{
                        position: absolute; right: .5vw; top: .5vw; left: auto;
                        width: 6vw;
                        height: 6vw;
                    }
                    .arm4{
                        position: absolute; right: .6vw; bottom: 0.2vw; left: auto; top: auto;
                        width: 5.7vw;
                        height: 5.7vw;
                        img{
                            position: absolute; left: 50%; top : 50%;
                            transform : translate(-50%,-50%);
                            width: 100%;height: 100%;
                        }
                        .icon{
                            position: absolute; left: 50%; top : 50%;
                            transform : translate(-50%,-50%);
                            width: 85%;height: 85%;
                        }
                        .arm_num{
                            position: absolute; left: 0; top: 0;
                            width: 6vw;height: 100%;
                            text-align: right;
                            line-height: 8vw;
                            font-weight: 500;
                            font-size: 2.8vw;
                            color: rgb(213, 213, 213);
                            text-shadow: 0 0 3px black;
                        }
                    }
                    .middle_name_tape_linear_han,.middle_name_tape_linear_wei,.middle_name_tape_linear_qun,
                    .middle_name_tape_linear_shu,
                    .middle_name_tape_linear_wu,
                    .middle_name_tape_linear_jin{
                        font-family: "微软雅黑";
                        position: absolute; left: 0; bottom:0;
                        padding-top: 2vw;
                        color: rgb(238, 228, 191);
                        text-align: left;
                        line-height: 2.5vw;
                        font-size: 2.3vw;
                        text-align: center;
                        
                        width : 4vw;
                        height:16vw;
                        background:linear-gradient(to bottom,rgb(52, 0, 71),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_shu{
                        background:linear-gradient(to bottom,rgb(0, 74, 19),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_wei{
                        background:linear-gradient(to bottom,rgb(0, 46, 93),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_qun{
                        background:linear-gradient(to bottom,rgb(46, 46, 46),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_wu{
                        background:linear-gradient(to bottom,rgb(116, 9, 9),rgba(255, 255, 255, 0));
                    }
                    .middle_name_tape_linear_jin{
                        background:linear-gradient(to bottom,rgb(0, 75, 70),rgba(255, 255, 255, 0));
                    }
                }
                .transition_mask{
                    right: 49vw;top: 0;
                    width: 15vw;height: 100%;
                    background:linear-gradient(to right,rgba(50, 55, 55, 0),rgb(50, 55, 55));
                }
                .warrior_box.click{
                    opacity: .4;
                }
                .tactics{
                    position: absolute; left: 50vw; top: 1.8vw;
                    width: 50vw;
                    height: 12vw;
                    li{
                        position: relative; float: left;
                        width: 12vw;height: 12vw;
                        background: url('../../assets/ui/ui (103).png')no-repeat;
                        background-size: 100% 100%;
                        margin: 0 0 0 3vw;
                        // background: #000;
                        .box{
                            width: 100%; height: 100%;
                            border-radius: 50%;
                            .quality_sign{
                                left: .3vw; top: .4vw;
                                width: 3.6vw; height: 3.6vw;
                            }
                            .tactics_frame{
                                left: 50%; top: 50%;
                                width: 11.9vw;height: 11.9vw;
                                margin: -6vw 0 0 -5.9vw;
                                // border-radius: 50%;
                            }
                            .tactics_img{
                                left: 50%; top: 50%;
                                width: 10.8vw;height: 10.8vw;
                                margin: -5.4vw 0 0 -5.4vw;
                                // border-radius: 50%;
                            }
                        }
                        .name{
                            left: 50%; top: 11.8vw;
                            width: 13vw;height: 3.3vw;
                            font-family: "FZLBJW";
                            font-size: 2.5vw;
                            margin: 0 0 0 -6.5vw;
                            .text{
                                position: absolute; left: 0; top: 0;
                                width: 100%; height: 100%;
                                line-height: 3.6vw;
                                color: white;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}
</style>